class Extranet.Models.ChannelManagerMapping extends Extranet.Model

  url: () ->
    "/extranet/api/channel_managers/#{@channelManagerName}/hotels/#{@hotelUuid}/mapping"

  idAttribute: 'hotelUuid'

  initialize: (options = {})->
    @hotelUuid  = options.hotelUuid
    @channelManagerName = options.channelManagerName
