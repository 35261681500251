require "../models/product_set_model"

class Extranet.Collections.ProductSets extends Backbone.Collection

  url: ()->
    if @hotelUuid
      return "/getaways/extranet/hotels/#{@hotelUuid}/product_sets"
    else
      return "/getaways/extranet/product_sets"

  model: Extranet.Models.ProductSetModel

  initialize: (options)->
    @hotelUuid = options?.hotelUuid

  parse: (response) ->
    @pagination = response.pagination if response.pagination
    response.productSets

  # check if there are active ones in the collection of product sets
  hasActiveDeals: () ->
    this.some (productSet) ->
      productSet.get('status') in ['approved', 'review-violations', 'live']