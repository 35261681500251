require "../../admin/frontend/views/admin_header_view"
require '../../admin/frontend/views/rates_inventory_upload'
require "../../admin/frontend/views/rates_upload_buttons_view"
require "./hotels_list_controller"
require "./hotels_list_router"

$(document).ready ->

  # TODO: Need to move to client side
  if $('.hotel_list_view').length > 0
    Extranet.adminHeaderView = new Extranet.Views.AdminHeaderView()
    Extranet.adminHeaderView.selectHotelSearchTab()
    if Extranet.FeatureFlags?.unified_rates_inventory_report
      ratesInventoryUploadView = new Extranet.Views.RatesInventoryUpload()
      ratesInventoryUploadView.render()
    else
      Extranet.ratesUploadButtonsView = new Extranet.Views.RatesUploadButtonsView()
      Extranet.ratesUploadButtonsView.render()
    hotelsListController = new Extranet.Controllers.HotelsListController({itemsPerPage: 25})

    new Extranet.Routers.HotelsListRouter(hotelsListController)
    Backbone.history.start()
