class Extranet.Views.AdminDeletePopup extends Backbone.View

  templateName: 'products_templates_admin_delete_popup'

  initialize: (options = {})->
    @adminEmail = options.adminEmail
    @adminIdName = options.adminIdName
    @productSetModel = options.productSetModel
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    @alertMessage = Extranet.Helper.AlertView.getAlertView()

  selectors:
    closeModal: ".close-modal"

  events:
    'click #delete-admin': 'deleteAdmin'
    'click #cancel-admin': 'closeModal'

  render: ()->
    @$el.html @autoTemplate.render @templateName, {@adminEmail}
    @delegateEvents()

  deleteAdmin: ()->
    return if _.isEmpty @adminEmail

    @alertMessage.hide()
    hash = {_csrf: $('#csrf_token').val()}
    hash[@adminIdName] = ""

    @productSetModel.save hash,
      wait: true
      patch: true
      success: (model, response, options)=>
        message = I18n.translate('js.modules.common.alerts.success.user_removed_from_owners_list')
        requestId = options?.xhr?.getResponseHeader('x-request-id')
        @alertMessage.renderSuccess(message, requestId)
        @closeModal()
      error: (model, response, options)=>
        requestId = options?.xhr?.getResponseHeader('x-request-id')
        @alertMessage.renderDefaultError(requestId)
        @closeModal()

  closeModal: ()->
    @$(@selectors.closeModal).click()
