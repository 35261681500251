require "../../presenters/validations/failed_validation_dates_presenter"
require "../../../../calendarV2/frontend/views/calendar_view"

class Extranet.Views.InventoryCalendarViewV2 extends Backbone.View
  Extranet.Common.Utilities.Mixin.extends this, Extranet.Helpers.ChildManager
  utility = Extranet.Common.Utilities.Utility

  LAST_MINUTE: 'lastMinute'
  addDateOutsideTravelWindow: false
  initialize: (options = {}) ->
    @inventoryData = options?.inventoryData
    calendarOptions =
      startDate: options?.startDate
      ignoredDaysOfWeek: options.ignoredDaysOfWeek
      eventManagerKey: options.eventManagerKey
    @productTypes = options.productTypes     # list of productTypes
    @calendarView = @addChild new Extranet.Views.CalendarView(calendarOptions)
    @ratesUtility = Extranet.Inventory.RatesUtility
    @inventoryHelper = Extranet.Helper.Inventory
    @dateCellUtility = Extranet.Inventory.DateCellUtility
    @failedValidationPresenter = Extranet.Presenters.FailedValidationDatesPresenter
    @eventManagerView = Extranet.Helpers.EventManagerHelper.getEventManagerView(options.eventManagerKey)
    @listenTo @eventManagerView, 'filterChange', @updateCalendarByFilter
    @listenTo @eventManagerView, 'selectedDate', @showSelection
    @listenTo @eventManagerView, 'ql2uploaded', @refreshCalendar
    @listenTo @eventManagerView, 'ratesInventorySaved', @refreshCalendar
    @listenTo @eventManagerView, 'highlightFailureDates', @addValidationFailuresByDate

  render: () ->
    @calendarView.setElement(@$el)
    @calendarView.render()
    this

  updateCalendarData: ({data, beginDate, endDate}) ->
    @dates = DateTimeHelper.getDatesInRange beginDate, endDate
    @inventoryData = data
    @updateCalendar()

  updateCalendar: () ->
    @fillCalendar()
    @calendarView.selectDateRange()

  updateCalendarByFilter: ({checkIn, checkOut, ignoredDaysMask}) ->
    @calendarView.setIgnoredDaysOfWeek(ignoredDaysMask)
    if _.isEmpty checkIn
      @calendarView.selectDateRange()
      return

    @calendarView.setStartAndEndDate(checkIn, checkOut)
    if @calendarView.isDateInCalendar(checkIn) and @calendarView.isDateInCalendar(checkOut)
      @calendarView.selectDateRange()
    else
      @calendarView.goToDate(checkIn)
      @eventManagerView.triggerEvent 'calendarMonthUpdated', @calendarView.getDateRangeToFetchInventory()

  refreshCalendar: ->
    @eventManagerView.triggerEvent 'calendarMonthUpdated', @calendarView.getDateRangeToFetchInventory()

  # noDateSelected Flag is set if selectedDates list is empty
  # dateOutsideTravelWindow is set if there is no inventory for selected dates.
  # multipleInventoryManagementModeFound is set if there are multiple productSets found with different inventory management modes.
  showSelection: ({selectedDates}) ->
    inventoryList = []
    unless selectedDates?.length > 0
      @eventManagerView.triggerEvent 'selectedDates', {inventoryData: inventoryList, noDateSelected: true}
      return

    # 17712 - need to know timezone here
    today = DateTimeHelper.getCurrentTime()
    selectedDay = moment(selectedDates[0])
    isTodaySelected = selectedDates.length is 1 and selectedDay.isSame(today, 'day')

    formattedList = @inventoryData?.formattedListByDate

    _.each selectedDates, (date) =>
      selectedDaysInventory = _.filter formattedList, (dateObject) =>
        dateObject.date is date

      inventoryList = inventoryList.concat(selectedDaysInventory)

    # only returns date cells within travel window
    dateCells = @dateCellUtility.getDateCells(inventoryList, @inventoryData?.hotelInfo, selectedDates, @addDateOutsideTravelWindow)

    if dateCells.length is 0
      dateOutsideTravelWindow = true
    else
      filteredDateCells = _.reject dateCells, (dateCell)->
        # 17712 - timezone needed
        DateTimeHelper.isPastDate dateCell.date, dateCell.hotel?.timeZone?.identifier

      if filteredDateCells.length is 0
        onlyPastDatesSelected = true
        filteredDateCells = dateCells

      filteredDates = _.uniq(_.collect(filteredDateCells, (dateCell)-> dateCell.date))
      @calendarView.addHighlightClass(filteredDates)
      someDatesSkipped = if filteredDates.length < selectedDates.length then true else false

    @eventManagerView.triggerEvent 'selectedDates',
      inventoryData: filteredDateCells
      dateOutsideTravelWindow: dateOutsideTravelWindow
      onlyPastDatesSelected: onlyPastDatesSelected
      someDatesSkipped: someDatesSkipped
      isTodaySelected: isTodaySelected
      multipleInventoryManagementModesFound: @_multipleInventoryManagementModesFound(filteredDateCells)

  _multipleInventoryManagementModesFound: (inventoryList)->
    inventoryManagementModeCounts = _.countBy inventoryList, (dateCell) ->
      dateCell.productSet?.inventoryManagementMode
    return inventoryManagementModeCounts[Constants.INVENTORY_MANAGEMENT_MODE.RATE_PLAN] > 0 and
      inventoryManagementModeCounts[Constants.INVENTORY_MANAGEMENT_MODE.ROOM_TYPE] > 0

  # returns true if rateplan is present for given date
  isRatePlanPresent: (inventoryList, ratePlan, date) ->
    hash = _.find inventoryList, (dayInventory) ->
      ratePlan?.uuid is dayInventory?.ratePlan?.uuid and dayInventory.date is date
    hash isnt undefined

  fillCalendar: () ->
    {formattedListByDate, hotelInfo} = @inventoryData
    formattedList = @dateCellUtility.getDateCells(formattedListByDate, hotelInfo, @dates, @addDateOutsideTravelWindow)
    @calendarView.removeClasses(["show-partial-blackout", 'black-out', 'sold-out', 'error', 'highlight'])
    @calendarView.clearCalendarData()
    listGroupedByDate = _.groupBy formattedList, (hash) ->
      hash.date

    dateCellsList = []
    _.each listGroupedByDate, (dataList) =>
      date = dataList?[0]?.date
      classes = @getClasses(dataList)
      if _.contains classes, 'black-out'
        centerData = ""
        bottomData = ""
      else
        centerData = @getMinimumRate(dataList)
        bottomData = @inventoryHelper.getTotalInventoryCount(dataList)

      dateCell = {date, centerData, bottomData, classes}
      dateCellsList.push(dateCell)

    @calendarView.fillData dateCellsList

  getMinimumRate: (data) ->
    data = _.reject data, @_isBlackout

    rates = _.collect data, (hash) =>
      rates = hash.rates
      if rates
        rateHash = _.find rates, (rateHash) =>
          rateHash.rate.productType in @productTypes
        amount = rateHash?.rate?.amount
        amount/100 if amount

    rates = _.filter rates, (rate) ->
      rate isnt undefined

    return "" if rates.length is 0

    minRate = _.min(rates)

    reduceCallback = (count, val) ->
      count + (val is minRate)
    numberOfOccurences = rates.reduce(reduceCallback, 0)

    currencyCode = data?[0]?.hotel?.currencyCode or ""
    rate = I18n.toCurrency(minRate, {currencyCode, short: true})
    if rates.length > 1 and numberOfOccurences < rates.length
      rate = "+#{rate}"
    rate

  # returns 'full', 'partial' or 'none' based on blackout values of all ratePlans
  getBlackout: (data) ->
    blackOutList = _.collect data, @_isBlackout

    andValue = @ratesUtility.computeAnd blackOutList
    orValue  = @ratesUtility.computeOr blackOutList
    return 'full' if andValue
    return 'partial' if orValue
    'none'

  # If at least one room sold for the day and there is no more inventory left for any of the room type
  # then the day is considered as sold out
  isSoldOut: (inventoryData) ->
    totalSoldCount = @inventoryHelper.getTotalSoldCount(inventoryData)
    totalInventoryCount = @inventoryHelper.getTotalInventoryCount(inventoryData)
    totalSoldCount > 0 and totalInventoryCount is 0

  isValidationFailure: (data) ->
    faliureList = _.collect data, (dateCell) =>
      failureEntities = @failedValidationPresenter.getFailedEntities dateCell
      failureEntities?.length > 0

    orValue = @ratesUtility.computeOr faliureList
    orValue

  addValidationFailuresByDate: ->
    {formattedListByDate, hotelInfo} = @inventoryData
    formattedList = @dateCellUtility.getDateCells(formattedListByDate, hotelInfo, @dates, @addDateOutsideTravelWindow)
    listGroupedByDate = _.groupBy formattedList, (hash) ->
      hash.date

    failureDates = []
    _.each listGroupedByDate, (dateCellsListByDate) =>
      date = dateCellsListByDate?[0]?.date
      failureDates.push(date) if @isValidationFailure(dateCellsListByDate)

    @calendarView.addErrorClass(failureDates)

  getClasses: (data) ->
    classes = []

    blackoutValue = @getBlackout(data)
    classes.push('show-partial-blackout') if blackoutValue is 'partial'
    classes.push('black-out') if blackoutValue is 'full'
    classes.push('sold-out') if @isSoldOut(data)
    classes.push('error') if @isValidationFailure(data)
    classes

  _isBlackout: (data) =>
    data?.restrictions?.blackout is 'black'
