class Extranet.Presenters.ProductSetRulesPresenter

  toHash: (model)->
    modelHash = model.toJSON()
    restriction = modelHash.restriction
    blackoutDaysOfWeek = restriction?.maxBlackOutDates?.maxByDayOfWeek

    hash =
      minNightly:restriction?.minDailyDiscountPercentage
      minAverage:restriction?.minAverageDiscountPercentage
      blackoutDaysMax:restriction?.maxBlackOutDates?.max
      minDailyAllotment:restriction?.minDailyAllotment
      blackoutSundays: blackoutDaysOfWeek.sunday if blackoutDaysOfWeek?.sunday
      blackoutMondays: blackoutDaysOfWeek.monday if blackoutDaysOfWeek?.monday
      blackoutTuesdays: blackoutDaysOfWeek.tuesday if blackoutDaysOfWeek?.tuesday
      blackoutWednesdays: blackoutDaysOfWeek.wednesday if blackoutDaysOfWeek?.wednesday
      blackoutThursdays: blackoutDaysOfWeek.thursday if blackoutDaysOfWeek?.thursday
      blackoutFridays: blackoutDaysOfWeek.friday if blackoutDaysOfWeek?.friday
      blackoutSaturdays: blackoutDaysOfWeek.saturday if blackoutDaysOfWeek?.saturday

    hash
