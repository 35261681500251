class Extranet.Presenters.WeekViewSummaryPresenter

  getSummaryArray: (dateCells=[])->
    hash = {}
    summaryArray = []

    # Calculate inventory and sold count for the selected week
    listByDate = _.groupBy dateCells, (item) ->
      item.date

    _.each listByDate, (dateCellsByDate, date)->

      hash[date] =
        date: date
        inventoryCount: 0
        soldCount: 0
        bookingPageUrl: ExtranetUrlBuilder.getBookingsPageUrlWithDates
          hotelUuid: Extranet.ClientConfig?.hotelData?.uuid
          checkInBegin: date
          checkInEnd: date

      listByRoomType = _.groupBy dateCellsByDate, (item) ->
        item.roomType?.uuid

      _.each listByRoomType, (dateCellsByRoomType)->
        dateCell = dateCellsByRoomType[0]
        inventoryCount = dateCell?.inventory?.inventoryCount or 0
        hash[date].inventoryCount += parseInt(inventoryCount)

      _.each dateCellsByDate, (dateCell)->
        if dateCell.rates?.length
          for rateObj in dateCell.rates
            unless rateObj.rate?.productType is 'base'
              soldCount = dateCell.soldCount or 0
              hash[date].soldCount += parseInt(dateCell.soldCount)

    summaryArray = _.values hash

    summaryArray
