require "../../../../products/frontend/collections/product_set_collection"
require "./ps_search_filters_view"
require "./ps_table_view"

class Extranet.Views.PSSearchView extends Backbone.View

  Extranet.Common.Utilities.Mixin.extends this, Extranet.Helpers.ChildManager

  selectors:
    promotionsFilterSelector: '#ps_filter'
    promotionsTableSelector: '#ps_results'
    paginationSelector: '.pagination_footer'
    alertMessageSelector: '.alert-message'

  initialize: (options) ->
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    @paginationFooterView = new Extranet.Views.PaginationFooterView
      el: @selectors.paginationSelector
    @listenTo @paginationFooterView, 'pagechange', @pageChanged
    @itemsPerPage = 25
    @psSearchFilterView = new Extranet.Views.PSSearchFilterView
        el: @selectors.promotionsFilterSelector
    @alertMessage = new Extranet.Views.AlertMessage
      selector: @selectors.alertMessageSelector
    @addChild @psSearchFilterView
    @listenTo @psSearchFilterView, 'searchSubmitted', @newSearch
    @psTableView = new Extranet.Views.PSTableView
      el: @selectors.promotionsTableSelector
    @collection = new Extranet.Collections.ProductSets
    @listenTo @collection, 'sync', @renderProductSets
    @searchParameters = {}

  render: () ->
    @psSearchFilterView.render()

  newSearch: (searchParameters) ->
    @searchParameters = searchParameters
    pageNum = 1
    @collection.reset()  # Reset Collection for a new Search Query
    @paginationFooterView.resetIndex()
    @internalCollectionArray = []
    @fetchProductSets pageNum

  fetchProductSets:(pageNum) ->
    @showLoading()
    @collection.fetch
      data: @getQueryString(pageNum)
      global: false
      success: () =>
        @alertMessage.hide()
        @cacheModels pageNum
        models = @collection.models

        @psTableView.render(models)

        if models.length > 0
          @renderFooterView pageNum
        else
          @paginationFooterView.hide()
      error: (collection, response, options)=>
        requestId = options?.xhr?.getResponseHeader('x-request-id')
        @alertMessage.renderDefaultError(requestId)
      complete: () =>
        @hideLoading()

  showLoading: () ->
    @$(@selectors.promotionsTableSelector).addClass('loading')

  hideLoading: () ->
    @$(@selectors.promotionsTableSelector).removeClass('loading')

  renderFooterView: (pageNum)->
    @paginationFooterView.render
      totalPages: Math.ceil @collection.pagination?.count/@itemsPerPage
      pageNum: pageNum

  getQueryString:(pageNum) ->
    $.param ( _.extend @searchParameters, {offset: (pageNum - 1) * @itemsPerPage, limit: @itemsPerPage} )

  pageChanged: (data) ->
    {pageNum} = data
    pageFirstItem = @getPageFirstItem pageNum
    models = @getModels {pageNum, pageFirstItem}
    if _.isEmpty(@internalCollectionArray[pageFirstItem])  # Models are not fetched already
      @fetchProductSets pageNum
    else
      @psTableView.render(models)
      @renderFooterView pageNum

  getModels: (pageDetails) ->
    {pageNum, pageFirstItem} = pageDetails
    return @internalCollectionArray.slice(pageFirstItem, pageFirstItem + @itemsPerPage)

  cacheModels : (pageNum) ->
    pageFirstItem = @getPageFirstItem pageNum
    if _.isEmpty(@internalCollectionArray)
      @internalCollectionArray = new Array @collection.pagination.count

    i = pageFirstItem
    @collection.models.forEach (model) =>
      @internalCollectionArray[i] = model
      i = i + 1

  getPageFirstItem: (pageNum) ->
    (pageNum - 1)*@itemsPerPage

  download: () ->
    if @collection.length > 0
      currentPage = @paginationFooterView.currentPage
      queryString = @getQueryString(currentPage)
      window.open ExtranetUrlBuilder.getProductsDownloadUrl queryString
    else
      # Pagination view not initialized, so search has not been made yet,
      errorMessage = I18n.translate("js.modules.admin.templates_search_page.download_validation_message")
      @alertMessage.renderError(errorMessage)
