class Extranet.Views.BookingsDownload extends Backbone.View

  el: '#bookings-download'
  templateName: 'bookings_download_button'

  selectors:
    alertMessage: '.download-alert-message'
    checkInBegin: '#check-in-begin'
    checkInEnd: '#check-in-end'
    bookingsDownloadModal: '#booking-download-modal'

  events:
    'click #booking-download-button': '_showDownloadModal'
    'click #cancel-download': '_hideDownloadModal'
    'click #submit-download': '_startDownload'
    'click .close-modal': '_hideDownloadModal'

  initialize: ()->
    @alertMessage = new Extranet.Views.AlertMessage {selector: @selectors.alertMessage}

  render: ()->
    hash = { datePickerPlaceholder: DateTimeHelper.getDateFormat 'numeric' }
    @$el.html TemplateHelper.render(@templateName, hash)
    @_initializeDatePicker()

  _startDownload: () ->
    unless @$(@selectors.bookingsDownloadModal).parsley('validate')
      @alertMessage.renderDefaultValidationError()
      return

    checkInBegin = DateTimeHelper.formatDate @$(@selectors.checkInBegin).val(), 'api'
    checkInEnd = DateTimeHelper.formatDate @$(@selectors.checkInEnd).val(), 'api'

    unless DateTimeHelper.isBeforeOrSameDate checkInBegin, checkInEnd
      message = I18n.translate('js.modules.common.alerts.validation.from_date_less_than_to_date')
      @alertMessage.renderError(message)
      return

    hotelUuid = Extranet.ClientConfig?.hotelData?.uuid
    window.open ExtranetUrlBuilder.getBookingsDownloadUrl {hotelUuid, checkInBegin, checkInEnd}
    @_closeDownloadModal()

  _showDownloadModal: ()->
    @$(@selectors.bookingsDownloadModal).modal('show')
    @$(@selectors.bookingsDownloadModal).parsley(Extranet.ParsleyConfig)

  _hideDownloadModal: ()->
    @$(@selectors.bookingsDownloadModal).modal('hide')
    @$(@selectors.alertMessage).empty()
    @_resetDatePicker()

  _initializeDatePicker: () ->
    @$(@selectors.checkInBegin).datepicker
      onSelect: (minDate) =>
        maxDate = moment(minDate).add(1, 'year').format('YYYY/MM/DD')
        @$(@selectors.checkInEnd).datepicker 'option', {minDate, maxDate}

    @$(@selectors.checkInEnd).datepicker
      onSelect: (maxDate) =>
        minDate = moment(maxDate).subtract(1, 'year').format('YYYY/MM/DD')
        @$(@selectors.checkInBegin).datepicker 'option', {minDate, maxDate}

  _resetDatePicker: () ->
    minDate = maxDate = null
    @$(@selectors.checkInBegin).val('')
    @$(@selectors.checkInEnd).val('')
    @$(@selectors.checkInBegin).datepicker 'option', {minDate, maxDate}
    @$(@selectors.checkInEnd).datepicker 'option', {minDate, maxDate}
