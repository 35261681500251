{omit} = require 'underscore'

class PermissionsHelper
  # This will filter the data by the passed entity permissions.
  # This is mainly used to filter the data before passing to the API Services.
  @getEditableData: (entity, data={}, permissions={}) ->
    entityPermissions = permissions?[entity]
    return {} unless entityPermissions

    filteredData = {}
    filteredData._csrf = data._csrf if data._csrf

    for key, value of data
      if entityPermissions[key]?.edit
        filteredData[key] = value

    filteredData

if window?
  window.PermissionsHelper =
    # This is mainly used to filter the data before sending the AJAX POST/PUT requests to Extranet server
    getEditableDataForClient: (entity, data)->
      permissions = Extranet?.RolePermissions
      PermissionsHelper.getEditableData(entity, data, permissions)
else
  module.exports =
    # This is mainly used to filter the data on the incoming requests on the Extranet server
    getEditableDataForServer: (req, entity)->
      permissions = req.config?.permissions
      data = omit req.body, '_csrf'
      PermissionsHelper.getEditableData(entity, data, permissions)
