require "../presenters/product_owners_presenter"

class Extranet.Views.ProductOwnersView extends Backbone.View
  Extranet.Common.Utilities.Mixin.extends this, Extranet.Helpers.ChildManager

  templateName: 'products_templates_product_owners'

  selectors:
    onBoardingTeam: 'input#on-boarding-team'
    marketingManager: 'input#marketing-manager'
    planner: 'input#planner'
    salesRep: 'input#opportunity-owner'

  initialize: (options) ->
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    @presenter = new Extranet.Presenters.ProductOwnersPresenter

  render: () ->
    @$el.html @autoTemplate.render @templateName, {}
    @initializeEmailAutoComplete()
    this

  initializeEmailAutoComplete: ()->
    self = this
    @$el.find('input[type="email"]').each ->
      self.addChild new Extranet.Views.UserPickerView
        el: $(this)

  getOwnerHash: ->
    @presenter.getHashToSave(this)
