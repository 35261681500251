require './tax'

class Extranet.Presenters.Taxes

  constructor: ()->
    @presenter = new Extranet.Presenters.Tax()

  toHash: (hotelModel) ->
    {taxes} = hotelModel.toJSON()
    @_formatTaxes(taxes)
  
  formatSelection: (collection) ->
    taxes = collection.toJSON()
    @_formatTaxes(taxes)
  
  getHashToUnassign: (view, taxUuid) ->
    taxes = _.map view.hash?.taxes, (tax) -> tax.uuid
    taxes = _.reject taxes, (uuid) -> uuid is taxUuid
    hash = 
      _csrf: $('#csrf_token').val()
      taxes: taxes
    PermissionsHelper.getEditableDataForClient 'hotel', hash

  getHashToAssign: (view) ->
    currentTaxes = _.map view.hash?.taxes, (tax) -> tax.uuid
    newTaxes = @_getSelectedTaxUuids view
    hash = 
      _csrf: $('#csrf_token').val()
      taxes: _.compact _.union(currentTaxes, newTaxes)
    PermissionsHelper.getEditableDataForClient 'hotel', hash
  
  hasNoTaxesSelected: (view) ->
    _.isEmpty @_getSelectedTaxUuids view

  _formatTaxes: (taxes)->
    if taxes?.length
      taxes = _.map taxes, (tax) => @presenter.format tax
    {taxes}
  
  _getSelectedTaxUuids: (view) ->
    _.map view.$(view.selectors.selectedTaxSelections), (element) -> view.$(element).val()