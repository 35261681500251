require '../presenters/basic_info'

class Extranet.Views.BasicInfo extends Backbone.View

  selectors:
    content: '.content'
    countrySelector: '#country-selector'
    stateSelector: '#state-selector'
    citySelector: '#city-selector'
    timezoneSelector: '#time-zone-selector'
    ql2IdInput: '#ql2-id-input'
    updateQl2ConfirmationPopup: '#update-ql2-confirmation-modal'
    actionButtons: '.action-buttons button'
    hotelName: '.page-header .hotel-name'

  events:
    'click #update-profile-button': '_update'
    'click #reset-profile-button': '_reset'
    'click select': '_handleDataChange'
    'keyup input': '_handleDataChange'
    'click [type="checkbox"]': '_handleDataChange'
    'focus #ql2-id-input[readonly]': '_showQl2ConfirmationPopup'
    'click #update-ql2-confirmation-modal .btn.approve': '_enableQl2Id'
    'click #update-ql2-confirmation-modal .btn.cancel': '_closeQl2ConfirmationPopup'

  el: '#basic-info'
  templateName: 'profile_basic_info'

  initialize: (options) ->
    @alertMessage = Extranet.Helper.AlertView.getAlertView()
    @presenter = new Extranet.Presenters.BasicInfo()
    @errorPresenter = new Extranet.Presenters.Error()
    
  render: () ->
    @hash = @presenter.toHash @model
    @$el.html TemplateHelper.render @templateName, @hash
    @$(@selectors.content).parsley Extranet.ParsleyConfig
    @_renderLocationSelector()
    @_renderTimeZones()

  _renderLocationSelector: () ->
    @countrySelector = @$(@selectors.countrySelector)
    @stateSelector = @$(@selectors.stateSelector)
    @citySelector = @$(@selectors.citySelector)
    options =
      defaults:
        countryUuid: @hash.address?.country?.uuid
        stateUuid: @hash.address?.state?.uuid
        cityUuid: @hash.address?.city?.uuid
      forceCityFetch: true
    @locationSelector = new Extranet.Helpers.LocationSelector @countrySelector, @stateSelector, @citySelector, options

  _renderTimeZones: ()->
    return unless Extranet.RolePermissions?.hotel?.timeZoneId?.edit
    @timezoneSelector = @$(@selectors.timezoneSelector)
    options =
      optionValueKey: 'zone'
      optionNameKey: 'zone'
      selectedOption: @hash.timeZone?.identifier
    Extranet.Common.Utilities.Render.updateDropdownOptions 'time_zones', @timezoneSelector, {}, options

  _update: (e) ->
    e.preventDefault()
    return unless Extranet.RolePermissions?.hotel?.edit
    unless @$(@selectors.content).parsley 'validate'
      @alertMessage.renderDefaultValidationError()
      return
    @alertMessage.hide()
    saveAttrs = @presenter.getHashToSave(this)
    options =
      patch: true
      wait: true
    onSuccess = (data, textStatus, jqXHR) =>
      @render()
      $(@selectors.hotelName).html @model.get('name')
      message = I18n.translate 'js.modules.common.alerts.success.hotel_saved'
      requestId = jqXHR?.getResponseHeader 'x-request-id'
      @alertMessage.renderSuccess message, requestId
    onFailure = (jqXHR, textStatus, errorThrown) =>
      requestId = jqXHR?.getResponseHeader 'x-request-id'
      responseJSON = jqXHR?.responseJSON
      duplicateQl2IdFoundError = @errorPresenter.findError responseJSON, 'DUPLICATE_QL2_ID'
      hotelRestrictionNetrateFoundError = @errorPresenter.findError responseJSON, 'HOTEL_RESTRICTION_NET_RATE'
      if duplicateQl2IdFoundError
        message = @errorPresenter.format duplicateQl2IdFoundError
        @alertMessage.renderError message, requestId
      else if hotelRestrictionNetrateFoundError
        message = @errorPresenter.format hotelRestrictionNetrateFoundError
        @alertMessage.renderError message, requestId
      else
        @alertMessage.renderDefaultError requestId
    @model.save(saveAttrs, options).then(onSuccess, onFailure)

  _reset: (e) ->
    e.preventDefault()
    @alertMessage.hide()
    @render()

  _handleDataChange: (e) ->
    @$(@selectors.actionButtons).removeAttr 'disabled'

  _enableQl2Id: () ->
    @_closeQl2ConfirmationPopup()
    @$(@selectors.ql2IdInput).removeAttr('readonly').focus()
  
  _showQl2ConfirmationPopup: () ->
    @$(@selectors.updateQl2ConfirmationPopup).modal 'show'

  _closeQl2ConfirmationPopup: () ->
    @$(@selectors.updateQl2ConfirmationPopup).modal 'hide'