class Extranet.Presenters.RatePlanAggregator

  @ratesUtility: Extranet.Inventory.RatesUtility

  @getMinBaseRate: (ratePlanData) ->
    @_getMinRate ratePlanData, 'base', 'amount'

  @getMinGrouponRate: (ratePlanData) ->
    @_getMinRate ratePlanData, 'lastMinute', 'amount'

  @getMinNetRate: (ratePlanData) ->
    productType = @_getProductType ratePlanData
    @_getMinRate ratePlanData, productType, 'netRate'

  @getMinDiscountPercentage: (ratePlanData) ->
    @_getMinRate ratePlanData, 'lastMinute', 'discountPercentage', isPercent = true

  @getAggregatedClosedToArrival: (ratePlanData) ->
    @_getAggregatedRestriction ratePlanData, 'closedToArrival'

  @getAggregatedNoLastNight: (ratePlanData) ->
    @_getAggregatedRestriction ratePlanData, 'noLastNight'

  @getAggregatedClosedOut: (ratePlanData) ->
    @_getAggregatedRestriction ratePlanData, 'closedOut'

  @getAggregatedBlackOut: (ratePlanData) ->
    blackoutList = _.collect ratePlanData, (dayInventory) ->
      if dayInventory.restrictions?.blackout is 'black'
        return true
      else
        return false

    blackoutValues = _.uniq(blackoutList)
    if blackoutValues.length > 1
      # Both true and false are present so it is a partial blackout
      return 'partial'
    else if blackoutValues[0] is true
      return 'blackout'
    else
      return 'none'

  @getAggregatedNoCheckIn: (ratePlanData) ->
    noCheckInList = _.collect ratePlanData, (dayInventory) ->
      dayInventory.restrictions?.closedToArrival or false

    noCheckInValues = _.uniq(noCheckInList)
    if noCheckInValues.length > 1
      # Both true and false are present so it is a partial no check in
      return 'partial'
    else if noCheckInValues[0] is true
      return 'no-check-in'
    else
      return 'none'

  @getTotalSoldCount: (ratePlanData) ->
    soldOutCount = _.reduce ratePlanData, (( prevCount, ratePlan) -> prevCount + (ratePlan.soldCount ? 0) ), 0

  @_getProductType: (ratePlanData) ->
    ratePlanData?[0]?.ratePlan?.productType

  @_getMinRate: (ratePlanData, rateType, rateAttrName, isPercent) ->
    ratesUtility= Extranet.Inventory.RatesUtility
    ratesList =  @extractList ratePlanData, @_getRatesCallback(rateType, rateAttrName, isPercent)
    aggregateRates = ratesUtility.getMinHash ratesList

  @_getRatesCallback: (type, rateAttrName, isPercent) ->
    (hash) ->
      rates = hash.rates
      if rates
        rateHash = _.find rates, (rateHash) ->
          rateHash.rate.productType is type
        value = rateHash?.rate?[rateAttrName]
        if value >= 0 and not isPercent
          value = value/100
          value = value.toFixed(2)
        value

  @_getRestrictionsCallback: (restrictionType) ->
    (dayInventory) ->
      dayInventory.restrictions?[restrictionType]

  @_getAggregatedRestriction: (ratePlanData, restrictionType) ->
    ratesUtility= Extranet.Inventory.RatesUtility
    restrictionList = @extractList ratePlanData, @_getRestrictionsCallback restrictionType
    aggregatedRestriction = ratesUtility.computeAnd(restrictionList)

  @extractList: (ratePlanData, collectCallback) ->
    list = _.collect ratePlanData, collectCallback
    list = _.filter list, (value) ->
      value isnt undefined
    list
