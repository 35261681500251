require "../presenters/product_set_presenter"
require "../collections/product_set_collection"
require "./inventory_section_view"
require "./product_owners_view"

class Extranet.Views.ProductSetCreationView extends Backbone.View

  Extranet.Common.Utilities.Mixin.extends this, Extranet.Helpers.ChildManager

  templateName:'products_templates_product_set_creation'

  attributes:
    'data-bind': true

  selectors:
    inventorySection: '.inventory-section'
    radioButtonForNo: '.radio #no'
    maxLengthOfStay: '#maxLengthOfStay'
    marginPercentage: '#margin'
    productOwners: '.promotion-owners'

  events:
    'click #booking-deal-view-save': 'createDeal'
    'click #booking-deal-view-cancel': 'cancel'

  initialize: (options = {}) ->
    @hotelUuid = options.hotelUuid
    @alertMessage = Extranet.Helper.AlertView.getAlertView()
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    @utility = new Extranet.Common.Utilities.Utility
    @presenter = new Extranet.Presenters.ProductSetPresenter options.model
    @placeHolder = options.placeHolder
    if options.model
      @model = options.model
    else
      @model = new Extranet.Models.ProductSetModel options

  render: (options = {}) ->
    hash = @presenter.getHashToDisplay(@model)
    hash.status = 'creation'
    hash.accessPermissionSubKey = hash.status
    hash.productsPageUrl = ExtranetUrlBuilder.getProductsPageUrl hotelUuid: @hotelUuid
    this.$el.html(@autoTemplate.render @templateName, hash)

    this.$el.appendTo @placeHolder if @placeHolder

    options.el = @selectors.inventorySection
    @inventorySectionView = new Extranet.Views.InventorySectionView options
    @addChild @inventorySectionView

    @productOwnersView = @addChild new Extranet.Views.ProductOwnersView
      el: @selectors.productOwners

    @inventorySectionView.render({hash})
    @productOwnersView.render()
    @$(@selectors.radioButtonForNo).removeProp("checked")
    this.$el?.parsley('destroy')?.parsley(Extranet.ParsleyConfig)
    @show()
    @fetchHotelAndUpdateField()
    this

  createDeal: (e)->
    e.preventDefault()
    if not this.$el.parsley('validate')
      @alertMessage.renderDefaultValidationError()
      return
    @alertMessage.hide()
    hash = @presenter.getHashToSave(this.inventorySectionView)
    ownersHash = @productOwnersView.getOwnerHash()
    hash = $.extend hash, ownersHash
    if not @inventorySectionView.validateLengthOfStay()
      return
    hash.name = @$el.find('.deal-name').val()
    @model.save hash,
      wait: true
      success: (model, response, options)=>
        @navigateToDealDetails(model)
        message = I18n.translate('js.modules.common.alerts.success.promotion_created')
        requestId = options?.xhr?.getResponseHeader('x-request-id')
        @alertMessage.renderSuccess(message, requestId)
      error: (model, response, options)=>
        requestId = options?.xhr?.getResponseHeader('x-request-id')
        @alertMessage.renderDefaultError(requestId)

  navigateToDealDetails: (model)->
    Extranet.productsRouter.navigate(
      ExtranetUrlBuilder.getBdDetailsFragment(
        productSetUuid: model.get('uuid')
      ), trigger: true
    )

  cancel: ()->
    @removeChildren()
    @render()

  fetchHotelAndUpdateField: ()->
    @hotelModel ?=  new Extranet.Models.Hotel {uuid: @hotelUuid}
    @hotelModel.fetch()
    .done(()=>
      @updateField()
    ).fail((xhr)=>
      requestId = xhr?.getResponseHeader('x-request-id')
      @alertMessage.renderDefaultError(requestId)
    )

  updateField: ()->
    @$(@selectors.marginPercentage).val @hotelModel.get 'marginPercentage'

