class Extranet.Models.ProductSetModel extends Extranet.Model
  urlRoot:()->
    if not @hotelUuid
      return @collection?.url()
    "/getaways/extranet/hotels/#{@hotelUuid}/product_sets"

  initialize: (options)->
    @hotelUuid = options.hotelUuid

  defaults:
    languageCode: "en_US"
    cancellationBuffer: 48
    restriction:
      maxBlackOutDates:
        max: 50
      minAverageDiscountPercentage: 0
      minDailyAllotment: 0
      minDailyDiscountPercentage: 0
      minLengthOfStay: 1
