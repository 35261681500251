require '../presenters/taxes'
require './tax_row'

class Extranet.Views.Taxes extends Backbone.View

  selectors:
    taxesList: '#taxes-list'
    unassignTaxConfirmationModal: '#unassign-tax-confirmation-modal'
    countrySelector: '.country-selector'
    stateSelector: '.state-selector'
    citySelector: '.city-selector'
    taxesFilter: '.filter'
    taxesSelection: '#taxes-selection'
    taxesSelectionActionButtons: '#taxes-selection .action-buttons'
    selectAllTaxesCheckbox: '#taxes-selection #assign-all-taxes-checkbox'
    taxSelections: '#taxes-selection .assign-tax input'
    selectedTaxSelections: '#taxes-selection .assign-tax input:checked'

  events:
    'click .unassign-tax-button': '_showUnassignTaxConfirmationModal'
    'click #unassign-tax-confirmation-modal .btn.cancel': '_hideUnassignTaxConfirmationModal'
    'click #unassign-tax-confirmation-modal .btn.approve': '_unassignTax'
    'click #filter-taxes-button': '_filterTaxes'
    'click #taxes-selection #assign-taxes-button': '_assignTaxes'
    'click #taxes-selection #reset-filter-button': '_resetFilter'
    'click #taxes-selection #assign-all-taxes-checkbox': '_selectAllTaxes'
    'click #taxes-selection .assign-tax-checkbox': '_updateSelectAllTaxesCheckbox'
  
  el: '#taxes'
  templates:
    taxes: 'profile_taxes'
    taxesSelection: 'profile_taxes_selection'

  initialize: (options) ->
    @hotelModel = options.model
    @alertMessage = Extranet.Helper.AlertView.getAlertView()
    @presenter = new Extranet.Presenters.Taxes()
    @collection = new Extranet.Collections.Taxes()
    @listenTo @collection, 'sync', @_renderTaxesSelection
    @listenTo @hotelModel, 'change', @render

  render: () ->
    @hash = @presenter.toHash @hotelModel
    @$el.html TemplateHelper.render @templates.taxes, @hash
    @_renderLocationSelector()

  _renderLocationSelector: () ->
    countrySelector = @$(@selectors.countrySelector)
    stateSelector = @$(@selectors.stateSelector)
    citySelector = @$(@selectors.citySelector)
    locationSelector = new Extranet.Helpers.LocationSelector countrySelector, stateSelector, citySelector, {}
  
  _unassignTax: (e) ->
    e.preventDefault()
    {taxUuid} = @$(@selectors.unassignTaxConfirmationModal).data()
    return unless taxUuid
    @alertMessage.hide()
    saveAttributes = @presenter.getHashToUnassign this, taxUuid
    options = 
      wait: true
      patch: true
    onSuccess = (data, textStatus, jqXHR) =>
      message = I18n.translate 'js.modules.common.alerts.success.tax_unassigned'
      requestId = jqXHR?.getResponseHeader 'x-request-id'
      @alertMessage.renderSuccess message, requestId
    onFailure = (jqXHR, textStatus, errorThrown) =>
      requestId = jqXHR?.getResponseHeader 'x-request-id'
      @alertMessage.renderDefaultError requestId
    onFinish = () =>
      @_hideUnassignTaxConfirmationModal()
    @hotelModel.save(saveAttributes, options).then(onSuccess, onFailure).always(onFinish)
  
  _showUnassignTaxConfirmationModal: (e) ->
    data = @$(e.currentTarget).data()
    @$(@selectors.unassignTaxConfirmationModal).data 'taxUuid', data?.uuid 
    @$(@selectors.unassignTaxConfirmationModal).modal 'show'
  
  _hideUnassignTaxConfirmationModal: (e) ->
    @$(@selectors.unassignTaxConfirmationModal).modal 'hide'

  _assignTaxes: (e) ->
    e.preventDefault()
    if @presenter.hasNoTaxesSelected this
      message = I18n.translate 'js.modules.common.alerts.validation.no_taxes_selected'
      @alertMessage.renderError message
      return
    saveAttributes = @presenter.getHashToAssign this
    options = 
      wait: true
      patch: true
    onSuccess = (data, textStatus, jqXHR) =>
      message = I18n.translate 'js.modules.common.alerts.success.taxes_assigned'
      requestId = jqXHR?.getResponseHeader 'x-request-id'
      @alertMessage.renderSuccess message, requestId
    onFailure = (jqXHR, textStatus, errorThrown) =>
      requestId = jqXHR?.getResponseHeader 'x-request-id'
      @alertMessage.renderDefaultError requestId
    @hotelModel.save(saveAttributes, options).then(onSuccess, onFailure)
  
  _filterTaxes: (e) ->
    e.preventDefault()
    data = @_getFilterData()
    unless data
      errorMessage = I18n.translate 'js.modules.common.alerts.validation.select_highlighted_filters'
      @alertMessage.renderError errorMessage
      @_highlightFilter()
      return
    @alertMessage.hide()
    @_dehighlightFilter()
    options =
      data: data
      reset: true
    onFailure = (jqXHR, textStatus, errorThrown) =>
      requestId = jqXHR?.getResponseHeader 'x-request-id'
      @alertMessage.renderDefaultError requestId
    @collection.fetch(options).fail(onFailure)
  
  _getFilterData: () ->
    state = @$(@selectors.stateSelector).val()
    city = @$(@selectors.citySelector).val()
    data = {}
    data.city_uuid = city if city
    data.state_uuid = state if state and not city
    if _.isEmpty data then null else data
  
  _renderTaxesSelection: () ->
    hash = @presenter.formatSelection @collection
    @$(@selectors.taxesSelection).html TemplateHelper.render @templates.taxesSelection, hash
  
  _resetFilter: () ->
    @_renderLocationSelector()
    @$(@selectors.taxesSelection).empty()
  
  _dehighlightFilter: () ->
    @$(@selectors.countrySelector).removeClass 'error'
    @$(@selectors.stateSelector).removeClass 'error'
    @$(@selectors.citySelector).removeClass 'error'

  _highlightFilter: () ->
    @$(@selectors.countrySelector).addClass 'error' unless @$(@selectors.countrySelector).val()
    @$(@selectors.stateSelector).addClass 'error'
    @$(@selectors.citySelector).addClass 'error'
  
  _selectAllTaxes: () ->
    selectAll = @$(@selectors.selectAllTaxesCheckbox).prop 'checked'
    @$(@selectors.taxSelections).prop 'checked', selectAll
  
  _updateSelectAllTaxesCheckbox: () ->
    allSelected = _.all @$(@selectors.taxSelections), (element) -> $(element).prop 'checked'
    @$(@selectors.selectAllTaxesCheckbox).prop 'checked', allSelected