###
1. If we have template hierarchy like "modules->templateName" then we need to
define the key in en.coffee as templateName
    templateName:
      key: "some text"
2. If we have our template hierarchy folders like "modules->module_name->template->subfolder->templateName"
then we need to define the key in en.coffee as template_*_templateName
eg. template_subfolder_templateName:
      key: "value"
###

class Extranet.Common.Utilities.AutoTemplate

  utility = Extranet.Common.Utilities.Utility

  # Will store translation and keys for each template
  # templatesInfo = {
  #   template1: {
  #     translations: TRANSLATION HASH,
  #     accessPermissions: PERMISSIONS HASH,
  #     path: [
  #       moduleName, translationKey
  #     ]
  #   }
  # }
  @templatesInfo: {}

  # This function is to find the node on a Hash for the given key array
  # It also returns the path of the node found
  @findNode: (node={}, keyArray=[], path=[])->
    return {node,path} if keyArray.length is 0

    key = keyArray[0]
    i = 1
    while i <= keyArray.length
      if node[key]
        result = @findNode node[key], keyArray.slice(i), path.concat [key]
        return result if result

      key = key + '_' + keyArray[i]
      i += 1

    return

  @getAccessPermissions: (path=[])->
    return {} unless path?.length
    [moduleName, templateName] = path

    accessPermissions = Extranet.RolePermissions?[moduleName]?[templateName] or {}
    accessPermissions

  @render: (templateName, data={})->
    {translations, accessPermissions} = @getTemplateInfo templateName
    hash = _.clone data
    hash.translations = translations

    unless hash.accessPermissions   # Skipping if already set from view
      hash.accessPermissions = if hash.accessPermissionSubKey
        accessPermissions[hash.accessPermissionSubKey]
      else
        accessPermissions

    TemplateHelper.render templateName, hash

  @getTemplateInfo: (templateName)->
    templateInfo = @templatesInfo[templateName]
    return templateInfo if templateInfo

    translations = I18n?.translations?.js?.modules
    return {} unless translations and templateName?.length
    keys = templateName.split "_"

    {node, path} = @findNode(translations, keys) or {} # Finding Translations Node
    translations = node or {}

    if path   # If translations exist, path will be available
      accessPermissions = @getAccessPermissions path
    else   # Need to find permissions node if path is not available
      {node, path} = @findNode(Extranet.RolePermissions, keys) or {} # Finding permissions Node
      accessPermissions = node or {}

    templateInfo = {translations, accessPermissions, path}
    @templatesInfo[templateName] = templateInfo
    templateInfo
