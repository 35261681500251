class Extranet.Collections.Hotel extends Backbone.Collection

  model: Extranet.Models.GenericModel
  pagination: {}

  initialize: (options) ->
    # @url = "/v2/getaways/extranet/countries/#{options.countryId}/cities"

  setUrl: (url) ->
    @url = url

  parse: (response) ->
    @pagination = response.pagination
    response.hotels
