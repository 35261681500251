class Extranet.Presenters.Contact

  NOTIFY: 'notify'
  MUST_NOTIFY: 'mustNotify'
  DONT_NOTIFY: 'none'

  toHash: (model, collection) ->
    data = model.toJSON()
    data.isFirstContact = collection?.isEmpty()
    hash = 
      name: data.fullName
      email: data.email
      role: data.role?.name
      roles: @_getRoles data
      phoneNumber: data.phoneNumber
      faxNumber: data.faxNumber
      receiveNotifications: @_receiveNotifications data
      notifications: @_getNotifications data
      isMainContact: @_isMainContact data
      canBeMainContact: @_canBeMainContact data
    hash
    
  getHashToSave: (view) ->
    hash =
      _csrf: $('#csrf_token').val()
      fullName: view.$('#contact-name-input').val()
      email: view.$('#contact-email-input').val()
      phoneNumber: view.$('#contact-phone-number-input').val()
      faxNumber: view.$('#contact-fax-number-input').val()
      roleUuid: view.$('#contact-role-selector').val()
      notifications: view.$('#contact-notification-selector').val()
      hotelUuids: [view.hotelModel.id]
    PermissionsHelper.getEditableDataForClient 'contact', hash
  
  getConstants: () ->
    {@NOTIFY, @MUST_NOTIFY, @DONT_NOTIFY}
  
  _receiveNotifications: (data) ->
    data.notifications is @NOTIFY or @_isMainContact data
  
  _isMainContact: (data) ->
    data.notifications is @MUST_NOTIFY

  _canBeMainContact: (data) ->
    @_hasEmail(data) and @_hasPhoneNumber(data) and @_receiveNotifications(data)
  
  _hasEmail: (data) ->
    not _.isEmpty data.email
  
  _hasPhoneNumber: (data) ->
    not _.isEmpty data.phoneNumber
  
  _getRoles: (data) =>
    _.map Extranet.ClientConfig?.ContactRoles, (role) ->
      name: role.name
      uuid: role.uuid
      isSelected: role.uuid is data.role?.uuid
  
  _getNotifications: (data) ->
    options = []
    if @_isMainContact(data) or data.isFirstContact
      options.push
        isSelected: true
        value: @MUST_NOTIFY
        text: I18n.translate 'js.modules.profile.contact_details.main_contact'
    else
      options.push
        isSelected: @_receiveNotifications data
        value: @NOTIFY
        text: I18n.translate 'js.modules.profile.contact_details.yes'
      options.push
        isSelected: not @_receiveNotifications data
        value: @DONT_NOTIFY
        text: I18n.translate 'js.modules.profile.contact_details.no'
    options
