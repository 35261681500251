require "./available_rooms_view"
require "./rate_plan_view"

class  Extranet.Views.WeekInventory.RoomInventoryView extends Backbone.View
  Extranet.Common.Utilities.Mixin.extends this, Extranet.Helpers.ChildManager

  templateName: 'inventory_templates_weekview_room_inventory'
  selectors:
    roomsInventoryTable: '.room-inventory-table'
  events: {}

  initialize: (data={})->
    {datecells} = data

    @roomTypeName = datecells[0]?.roomType?.name

    @availableRooms = @addChild new Extranet.Views.WeekInventory.AvailableRoomsView
      datecells: datecells

    @ratePlanViews = @getRatePlans datecells
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate

  getRatePlans: (datecells)->
    ratePlanViews = []
    listByRateplan = _.groupBy datecells, (item) ->
      item?.ratePlan?.uuid

    _.each listByRateplan, (dateCellsByRateplan) =>
      datecellInsideTravelWindow = _.find(dateCellsByRateplan, (datecell) -> not datecell.dateOutsideTravelWindow)
      if datecellInsideTravelWindow
        ratePlanView = @addChild new Extranet.Views.WeekInventory.RatePlanView
          datecells: dateCellsByRateplan
        ratePlanViews.push ratePlanView

    ratePlanViews

  render: () ->
    this.$el.html @autoTemplate.render @templateName, {@roomTypeName}

    this.$('table tbody').append @availableRooms.render().el

    for ratePlanView in @ratePlanViews
      ratePlanView.render({
        placeHolder: this.$('table tbody')
      })

    this.$(@selectors.roomsInventoryTable).parsley('destroy').parsley(Extranet.ParsleyConfig)
    this

  validate: () ->
    @$(@selectors.roomsInventoryTable).parsley('destroy').parsley(Extranet.ParsleyConfig)
    return @$(@selectors.roomsInventoryTable).parsley('validate')

  submitRates: (requestIdPrefix) ->
    deferreds = []
    _.each @ratePlanViews, (ratePlanView) ->
      deferreds.push ratePlanView.save(requestIdPrefix)

    $.when.apply(null, deferreds
    ).done(->
    ).fail(->)

  save:(requestIdPrefix)=>
    $.when(@availableRooms.save(requestIdPrefix), @submitRates(requestIdPrefix)
    ).done(->
    ).fail(->
    )
