require './bookings_download'
require './bookings_filters'
require '../presenters/bookings'

DEFAULT_PAGE_SIZE = 25

class Extranet.Views.BookingsPage extends Backbone.View

  el: 'section#page'
  templateName: 'bookings_page'

  selectors:
    alertMessage: '.alert-message'
    pagination: '.bookings-pagination'
    bookingsTable: '#bookings-table'
    perPage: '#per-page'

  events:
    'change #per-page': '_updatePageSize'

  initialize: ()->
    @alertMessage = new Extranet.Views.AlertMessage {selector: @selectors.alertMessage}
    @bookingsCollection = new Extranet.Collections.Bookings()
    @presenter = new Extranet.Presenters.Bookings({collection: @bookingsCollection})

  render: () ->
    @$el.html TemplateHelper.render(@templateName)
    @_renderBookingsDownloadView()
    @_renderBookingsFiltersView()

  searchBookings: (searchParams={})->
    @bookingsFiltersView?.updateSearchParams(searchParams)
    @bookingsCollection.updateQueryParams(searchParams)
    @_loadBookings()

  _updatePageSize: (e)=>
    e.preventDefault()
    pageSize = parseInt($(e.currentTarget).val()) or DEFAULT_PAGE_SIZE
    @bookingsCollection.state.pageSize = pageSize
    @_loadBookings()

  _renderBookingsDownloadView: ()->
    @bookingsDownloadView ?= new Extranet.Views.BookingsDownload()
    @bookingsDownloadView.render()

  _renderBookingsFiltersView: ()->
    @bookingsFiltersView ?= new Extranet.Views.BookingsFilters()
    @bookingsFiltersView.render()

  _loadBookings: (pageNumber=1) ->
    @alertMessage.clear()
    successCallback = () =>
      bookings = @presenter.toHash()
      paginationInfo = I18n.translate('js.modules.bookings.pagination_info', @bookingsCollection.state)
      @$(@selectors.bookingsTable).html TemplateHelper.render('bookings_table', {bookings, paginationInfo})
      @$(@selectors.perPage).val @bookingsCollection.state?.pageSize
      @_initializePagination()
    failureCallback = (xhr)=>
      if xhr?.status is 404
        @$(@selectors.bookingsTable).html TemplateHelper.render('bookings_table', {})
        @$(@selectors.perPage).val @bookingsCollection.state?.pageSize
      else
        requestId = xhr?.getResponseHeader('x-request-id')
        @alertMessage.renderDefaultError(requestId)
        @$(@selectors.bookingsTable).empty()

    @bookingsCollection.getPage(pageNumber).done(successCallback).fail(failureCallback)

  _initializePagination: ()=>
    paginationEl = @$(@selectors.pagination)
    @pagination = paginationEl.pagination
      pages: @bookingsCollection.state?.totalPages
      currentPage: @bookingsCollection.state?.currentPage
      cssStyle: 'light-theme'
      selectOnClick: false
      prevText: '«'
      nextText: '»'
      onPageClick: (pageNumber, event) =>
        event?.preventDefault()
        @_loadBookings(pageNumber)
