class RatesUtility
# this is used to provide values to display on various pages for a specific
# date or range of dates

  # returns hash with minimum value and flag if there are different values in the
  # list
  @getMinHash: (list) ->
    minValue = @getMinimum list
    hasMultipleValues= @hasMultipleValues list, minValue
    {value: minValue, hasMultipleValues}

  @getMinimum: (list) ->
    list = _.filter(list, @isValidValue)

    if list.length is 0
      return null
    if list.length is 1
      return list[0]
    else
      return _.min(list, (num)-> +num)

  # should it display an indicator that there are multiple values over a
  # selected range
  @hasMultipleValues: (list, minValue) ->
    if @isValidValue(minValue) && list.length > 1
      maxValue = _.max(list, (num)-> +num)
      return true if (minValue isnt maxValue)
    return false

  @isValidValue: (val) ->
    return ( val not in [undefined, null, ''] && !isNaN(+val) && val >= 0)

  # are all values in an array of bools true
  @computeAnd: (list) ->
    return ( list.length > 0 && !_.contains(list, false) )

  # are any values in an array of bools true
  @computeOr: (list) ->
    _.contains(list, true)

  @getSum: (list) ->
    if list.length is 0
      return 0
    else if list.length is 1
      return list[0]

    sum = list.reduce (previousValue, currentValue) ->
      previousValue + currentValue
    return sum

  # this is reversing the formatting done in the compute_x_Rate methods below
  # used for display.
  @getRateValue: (amount) ->
    if @isValidValue(amount)
      amount = +amount
      amount = Math.round( amount*100 )
      return amount
    else
      return null

  @fixedValue: (value)->
    value = parseFloat value
    return null if _.isNaN(value)
    value.toFixed(2)

  # only used for booking. Would be nice to refactor to account for LM also
  @computeNetRate: (grouponRate, margin) ->
    if @isValidValue(grouponRate) and @isValidValue(margin)
      rate = grouponRate * (1 - margin/100)
      return rate.toFixed(2)
    return null

  # only used for booking. Would be nice to refactor to account for LM also
  @computeGrouponRate: (netRate, margin) ->
    if @isValidValue(netRate) and @isValidValue(margin)
      rate = netRate / (1 - margin/100)
      return rate.toFixed(2)
    return null

if window?
  window.Extranet.Inventory ?= {}
  window.Extranet.Inventory.RatesUtility = RatesUtility
else
  module.exports = RatesUtility
