require "./views/products_page_view"

class Extranet.Routers.ProductsRouter extends Backbone.Router
  routes:
    '': 'renderProducts'
    'product-sets': 'renderProductSetCreate'
    'product-sets/:productSetUuid': 'renderProductSetUpdate'
    'product-sets/:productSetUuid/rate-plans(/:ratePlanUuid)': 'renderRatePlan'
    'product-sets/:productSetUuid/rates': 'renderProductSetRates'

  hotelUuid: Extranet.ClientConfig?.hotelData?.uuid
  hotelConnected: Extranet.ClientConfig?.hotelData?.channelManager?.name?

  renderProducts: ()->
    options=
      hotelUuid: @hotelUuid
      view: 'products'
    @renderPage options

  renderProductSetCreate: ()->
    unless Extranet.RolePermissions.is_admin_user
      return
    options=
      hotelUuid: @hotelUuid
    @renderPage options

  renderProductSetUpdate: (productSetUuid)->
    options=
      hotelUuid: @hotelUuid
      hotelConnected: @hotelConnected
      productSetUuid: productSetUuid
    @renderPage options

  renderProductSetRates: (productSetUuid)->
    options =
      hotelUuid: @hotelUuid
      productSetUuid: productSetUuid
      view: 'rates'
    @renderPage options

  renderPage: (options)->
    @productsPageView ?= new Extranet.Views.ProductsPageView options
    @productsPageView.render options

  renderRatePlan: (productSetUuid, ratePlanUuid)->
    options=
      hotelUuid: @hotelUuid
      productSetUuid: productSetUuid
      ratePlanUuid: ratePlanUuid
      view: "ratePlanDetailsView"
    @renderPage options
