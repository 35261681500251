require "./products_row_view"
require "../collections/product_set_collection"

class Extranet.Views.ProductsListView extends Backbone.View

  Extranet.Common.Utilities.Mixin.extends this, Extranet.Helpers.ChildManager

  el: "#products-list"

  templateName: 'products_templates_products_table'

  selectors:
    tableBody: 'tbody'

  initialize: (options = {}) ->
    @hotelUuid = options.hotelUuid
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    @alertMessage = Extranet.Helper.AlertView.getAlertView()
    @collection = new Extranet.Collections.ProductSets {
      hotelUuid:@hotelUuid
    }
    @collection.reset()

  fetchProducts: () ->
    @showLoading()
    @collection.fetch({reset: true, global: false})
    .done(()=>
      @render()
    ).fail((xhr)=>
      requestId = xhr?.getResponseHeader('x-request-id')
      @alertMessage.renderDefaultError(requestId)
    ).always(()=>
      @hideLoading()
    )

  render: () ->
    hasModels = @collection.length > 0
    @removeChildren()
    @$el.html @autoTemplate.render @templateName, {
      productSetUrl: ExtranetUrlBuilder.getProductSetUrl {@hotelUuid}
      hasModels: hasModels
    }
    @renderProducts() if hasModels
    @show()

  renderProducts: () ->
    @collection.each (model) =>
      productsRowView = new Extranet.Views.ProductsRowView({model})
      @addChild productsRowView
      @$el.find(@selectors.tableBody).append productsRowView.render().el

  showLoading: ()->
    @$el.addClass('loading')

  hideLoading: ()->
    @$el.removeClass('loading')
