require "../presenters/product_owners_update_presenter"
require "./admin_delete_popup_view"

class Extranet.Views.ProductOwnersUpdateView extends Backbone.View
  Extranet.Common.Utilities.Mixin.extends this, Extranet.Helpers.ChildManager

  templateName : 'products_templates_product_owners_update'

  productsEventManagerKey: Extranet.Helpers.EventManagerHelper.PRODUCTS_KEY

  events:
    'click .owners-hidden': 'showOwners'
    'click .owners-trigger': 'hideOwners'
    'click .icon-plus': 'addAdmin'
    'click .icon-close': 'removeAdmin'
    'blur [type=email]': 'saveModel'
    # 'keyup .owner-name [type=email]': 'saveOnEnter'

  selectors:
    ownersTable: '.owners-table'
    showOwnersButton: '.owners-hidden'
    hideOwnersButton: '.owners-trigger'
    popupModal: '#admin-remove-modal .alert-warning'
    removeButton: '.icon-close'
    adminEmail: '.owner-value'
    inputUserPicker: "[type=email]"
    openInputs: ".owners-table .ui-autocomplete-input:not(.hide)"

  initialize: (options = {}) ->
    @productSetModel = options.productSetModel
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    @alertMessage = Extranet.Helper.AlertView.getAlertView()
    @presenter = new Extranet.Presenters.ProductOwnersUpdatePresenter()
    @listenTo @productSetModel, 'sync', @render
    @showOwnersList = false

  render: () ->
    hash = @presenter.toHash @productSetModel
    @$el.html @autoTemplate.render @templateName, hash
    @initUserPicker()
    @showOwners() if @showOwnersList
    this

  initUserPicker: ()->
    @removeChildren()
    self = this
    @$(@selectors.inputUserPicker).each ->
      self.addChild new Extranet.Views.UserPickerView
        el: $(this)

  showOwners: ()->
    @$(@selectors.showOwnersButton).addClass 'hide'
    @$(@selectors.hideOwnersButton).removeClass 'hide'
    @showOwnersList = true
    @toggleOwnersTable()

  hideOwners: ()->
    if @validate()
      @alertMessage.hide()
      @$(@selectors.showOwnersButton).removeClass 'hide'
      for input in @$(@selectors.openInputs)
        if  @$(input).val() == ""
          @$(input).siblings('.icon-plus').removeClass 'hide'
          @$(input).addClass('hide')
      @showOwnersList = false
      @toggleOwnersTable()
      @$(@selectors.hideOwnersButton).addClass 'hide'
    return

  toggleOwnersTable: ()->
    @$(@selectors.ownersTable).slideToggle()

  addAdmin: (e)->
    e.preventDefault()
    $(e.currentTarget).addClass 'hide'
    tdEl = $(e.currentTarget).parents 'td'
    return unless tdEl.length
    tdEl.find(@selectors.removeButton).addClass 'hide'
    tdEl.find(@selectors.adminEmail).addClass 'hide'
    tdEl.find(@selectors.inputUserPicker).removeClass 'hide'

  removeAdmin: (e)->
    e.preventDefault()
    adminIdName = $(e.currentTarget).data 'admin-id-name'
    adminEmail = @productSetModel.get adminIdName
    @renderPopup {adminIdName, adminEmail}

  renderPopup: ({adminIdName, adminEmail})->
    @popupView?.remove()
    @popupView = new Extranet.Views.AdminDeletePopup {
      adminIdName, adminEmail, @productSetModel
    }
    @$(@selectors.popupModal).html @popupView.render().el

  # saveOnEnter: (e)->
  #   @saveModel(e) if e.which is 13 #enter key

  saveModel: (e)->
    return unless e
    e.preventDefault()

    if !@validate()
      @alertMessage.renderDefaultValidationError()
      return

    el = $(e.currentTarget)
    newValue = el.val()

    if _.isEmpty(newValue) or _.isEmpty(newValue.trim?())
      return

    adminIdName = el.data 'admin-id-name'
    hash = _csrf: $('#csrf_token').val()
    hash[adminIdName] = newValue

    @alertMessage.hide()
    @productSetModel.save hash,
      wait: true
      patch: true
      success: (model, response, options)=>
        message = I18n.translate('js.modules.common.alerts.success.user_added_to_owners_list')
        requestId = options?.xhr?.getResponseHeader('x-request-id')
        @alertMessage.renderSuccess(message, requestId)
      error: (model, response, options)=>
        requestId = options?.xhr?.getResponseHeader('x-request-id')
        @alertMessage.renderDefaultError(requestId)

  validate: ()->
    @$(@selectors.ownersTable).parsley('destroy').parsley(Extranet.ParsleyConfig)
    @$(@selectors.ownersTable).parsley('validate')
