require "./product_set_manager_view"
require "./product_set_creation_view"
require "./products_list_view"

class Extranet.Views.ProductsPageView extends Backbone.View

  Extranet.Common.Utilities.Mixin.extends this, Extranet.Helpers.ChildManager

  selectors:
    alertMessage: ".alert-message"
    psManagerPlaceHolder: '.deal-manager'
    psCreationPlaceHolder: '.new-bd-creation'

  childIdentifiers:
    productsListView:       'productsListView'
    productSetCreationView: 'productSetCreationView'
    psManagerView:          'psManagerView'

  initialize: (options = {}) ->
    @alertMessage = Extranet.Helper.AlertView.initialize @selectors.alertMessage

  render: (options = {}) ->
    # TODO: hide active view
    if options.view is 'products'
      return @renderProducts options
    if not options.productSetUuid
      options.placeHolder = @selectors.psCreationPlaceHolder
      return @renderProductSetCreate options
    else
      return @renderProductSetUpdate options

  renderProducts: (options)->
    @alertMessage.hide()
    @hidePsManagerView()
    @productSetCreationView?.hide()
    @productsView = @getProductsView options
    @productsView.fetchProducts()

  getProductsView: (options)->
    if not @productsListView
      productsListView = new Extranet.Views.ProductsListView options
      @addChild productsListView, @childIdentifiers.productsListView
      return productsListView

    if @productsListView?.hotelUuid is options?.hotelUuid
      return @productsListView
    else
      @removeChild @childIdentifiers.productsListView
      productsListView = new Extranet.Views.ProductsListView options
      @addChild productsListView, @childIdentifiers.productsListView
      return productsListView

  renderProductSetCreate: (options) ->
    @hidePsManagerView()
    @getProductsView(options)?.hide()
    @removeChild @childIdentifiers.productSetCreationView
    @productSetCreationView = new Extranet.Views.ProductSetCreationView options
    @addChild @productSetCreationView, @childIdentifiers.productSetCreationView
    @productSetCreationView.render options

  renderProductSetUpdate: (options) ->
    @productSetCreationView?.hide()
    @getProductsView(options)?.hide()
    @getpsManagerView(options).render(options)

  getpsManagerView: (options)->
    newOptions = $.extend deepCopy = true, {}, options
    newOptions.placeHolder = $ @selectors.psManagerPlaceHolder

    if not @psManagerView
      @psManagerView = new Extranet.Views.PSManagerView newOptions
      @addChild @psManagerView, @childIdentifiers.psManagerView
      return @psManagerView

    @removeChild @childIdentifiers.psManagerView
    @psManagerView = new Extranet.Views.PSManagerView newOptions
    @addChild @psManagerView, @childIdentifiers.psManagerView
    return @psManagerView

  hidePsManagerView: ()->
    $(@selectors.psManagerPlaceHolder).addClass 'hide'
