require "../models/rate_plan_model"

class Extranet.Collections.RatePlans extends Backbone.Collection
  url: ()->
    if @roomTypeUuid
      return "/v2/getaways/extranet/hotels/#{@hotelUuid}/room_types/#{@roomTypeUuid}/rate_plans"
    if @productSetUuid
      return "/v2/getaways/extranet/hotels/#{@hotelUuid}/product_sets/#{@productSetUuid}/rate_plans"

  model: Extranet.Models.RatePlanModel

  initialize: (options)->
    @hotelUuid = options.hotelUuid
    @roomTypeUuid = options.roomTypeUuid
    @productSetUuid = options.productSetUuid

  parse: (response) ->
    response.ratePlans
