class Extranet.Routers.HotelsListRouter extends Backbone.Router

  routes:
    # "#{/page-(\d+)$/}" : 'paginate'
    ''           :  ''
    'page-:num'          :  'paginate'

  initialize: (controller) ->
    # Extranet.Routers.BookingsListRouter.__super__.initialize()
    @controller = controller

  paginate: (pageNum) ->
    @controller.paginate pageNum



