require "./week_view/week_view"
require "./month_view/month_view_v2"
require "./inventory_download"

class Extranet.Views.InventoryPageView extends Backbone.View

  el: '#inventory_page'

  selectors:
    monthView: '#month_view'
    weekView: '#week_view'
    inventoryDownload: "#div-inventory-download"
    monthViewSection: '#inventory_month_view_section'
    weekviewSection: '#inventory_week_view_section'
    alertMessage: '.error-message'

  initialize: ()->
    @alertMessage = Extranet.Helper.AlertView.initialize @selectors.alertMessage

  render: (options = {}) ->
    @removeWeekview()
    @hideAllContent()
    @_renderRecentAutoUpdates options
    if options.view is 'monthview' or options.hideWeekView
      @renderMonthView options
    else if options.view is 'weekview'
      @renderWeekView options

  # renders monthview
  renderMonthView: (options)->
    @$('#hotel-inventory').removeClass 'hide'
    this.$('#month_view').removeClass 'hide'
    unless @monthView
      options = $.extend options,
        productTypes: ['bookingDeal', 'lastMinute']
        el: @selectors.monthViewSection
      @monthView = new Extranet.Views.MonthViewV2 options
    @monthView.render()
    weekViewUrl = ExtranetUrlBuilder.getWeekViewUrl(options)
    monthViewUrl = ExtranetUrlBuilder.getMonthViewUrl(options)
    this.$('#week').attr 'href', "#{weekViewUrl}"
    this.$('#month').attr 'href', "#{monthViewUrl}"
    unless @inventoryDownload
      options.el = @selectors.inventoryDownload
      @inventoryDownload = new Extranet.Views.InventoryDownload(options)
    @inventoryDownload.render()

  # renders weekview inventory
  renderWeekView: (options) ->
    @$('#hotel-inventory').removeClass 'hide'
    @hideMonthView()
    unless @weekview
      @weekview = new Extranet.Views.WeekView()
    @$(@selectors.weekviewSection).html @weekview.render(options).el
    unless @inventoryDownload
      options.el = @selectors.inventoryDownload
      @inventoryDownload = new Extranet.Views.InventoryDownload(options)
    @inventoryDownload.render()

  hideAllContent: ()->
    contentSelector = '.inventory-content'
    @$(contentSelector).addClass 'hide'
    @alertMessage.hide()

  removeWeekview: ()->
    if @weekview
      @weekview.remove()
      @weekview = undefined

  hideMonthView: ()->
    @$(@selectors.monthView).addClass 'hide'
    @monthView?.removeChildren()

  hideWeekView: ()->
    @$(@selectors.weekView).addClass 'hide'
    @weekview?.removeChildren()

  _renderRecentAutoUpdates: (options) ->
    return unless Extranet.ClientConfig?.recentAutoUpdatesEnabled
    unless @recentAutoUpdatesView
      @recentAutoUpdatesView = new Extranet.Views.RecentAutoUpdates options
    @recentAutoUpdatesView.render()
