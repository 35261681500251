'use strict'

tracky = require '../helpers/m_trackEvent'

CC_SELECTORS = {
  ERROR_MSG_ID: 'cc-error-msg'
  FORM_ID: 'cc-form'
  FUNCTIONAL_INPUT_ID: 'cc-functional-input'
  LOADING_CLASS: 'ls-loading-white'
  MARKETING_INPUT_ID: 'cc-marketing-input'
  NO_CLICK_CLASS: 'cc-no-click'
  PAGE_ID: 'ls-cc-page'
  SUBMIT_BTN_ID: 'cc-submit-btn'
  TERMS_VERSION_ATTR: 'ccTermsVersion'
  TRANSLATIONS_ATTR: 'ccTranslations'
}

getConsentSelection = (functionalInput, marketingInput) ->
  consentSelection = 0
  if (functionalInput.checked) then consentSelection += 1
  if (marketingInput.checked) then consentSelection += 2
  return consentSelection

init = ->
  return unless pageEl = document.getElementById CC_SELECTORS.PAGE_ID

  consentSelection = 3
  if (splitCookie = Cookie.get('c3s')?.split('-') or []).length > 2
    consentSelection = parseInt(splitCookie[2])


  termsVersion = pageEl.dataset[CC_SELECTORS.TERMS_VERSION_ATTR] or '-1'

  functionalInput = document.getElementById CC_SELECTORS.FUNCTIONAL_INPUT_ID
  marketingInput = document.getElementById CC_SELECTORS.MARKETING_INPUT_ID

  functionalInput.checked = (consentSelection is 1 or consentSelection is 3)
  marketingInput.checked = (consentSelection is 2 or consentSelection is 3)

  submitBtn = document.getElementById CC_SELECTORS.SUBMIT_BTN_ID
  submitTxt = submitBtn.querySelector 'span'

  submitBtn.addEventListener 'click', ->
    errMsgEl = document.getElementById(CC_SELECTORS.ERROR_MSG_ID)
    errMsgEl.style.opacity = 0
    setTimeout () ->
      errMsgEl.style.display = 'none'
      errMsgEl.setAttribute 'aria-hidden', true
    , 250

    consentSelection = getConsentSelection(functionalInput, marketingInput)

    submitTxt.innerHTML = "Saving..."
    # submitTxt.style.opacity = 0
    this.classList.add(CC_SELECTORS.NO_CLICK_CLASS, CC_SELECTORS.LOADING_CLASS)

    url = "/extranet/api/consent-service/consent/v1?c3s=#{consentSelection}&termsVersion=#{termsVersion}"
    fetch(url, { credentials: 'same-origin' })
      .then (res) ->
        if !res.ok
          throw({ error: 'consent-service failed'})
        animateAfterFormSubmit(true)
      .catch (e) ->
        animateAfterFormSubmit(false)
        tracky e.event, e.error
        return

    animateAfterFormSubmit = (isSuccess) ->
      pageEl = document.getElementById CC_SELECTORS.PAGE_ID

      # Step 1: After 1s, fade in 'Saved' or 'Not Saved' text
      setTimeout () ->
        submitBtn.classList.remove CC_SELECTORS.LOADING_CLASS
        submitTxt.innerHTML = if isSuccess then "Saved" else "Not saved"
        submitTxt.style.opacity = 1
        if not isSuccess
          errMsgEl.style.display = 'block'
          errMsgEl.style.opacity = 1
          errMsgEl.setAttribute 'aria-hidden', false

        # Step 2: After 2s, fade out button text
        setTimeout () ->
          submitTxt.style.opacity = 0

          # Step 3: After 300ms, fade in 'Submit' text
          setTimeout () ->
            submitBtn.classList.remove CC_SELECTORS.NO_CLICK_CLASS
            submitTxt.innerHTML = "Submit"
            submitTxt.style.opacity = 1
          , 300
        , 2000
      , 1000
      return

module.exports = {
  init
}
