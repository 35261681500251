require "./admin_tax_row_view"

class Extranet.Views.AdminTaxesView extends Backbone.View
  Extranet.Common.Utilities.Mixin.extends this, Extranet.Helpers.ChildManager

  el: '#taxes_content'

  selectors:
    alertMessage: "#taxes_content .section-info .alert-message"
    countrySelector: ".location-filters .country-selector"
    stateSelector: ".location-filters .state-selector"
    citySelector: ".location-filters .city-selector"
    createTax : "#createTax"

  events:
    'click #add-tax': 'showNewTax'
    'click #edit-taxes': 'showNewTax'
    'click #search-button': 'searchTaxes'

  template_name: 'admin_taxes'

  initialize: () ->
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    @$el.html @autoTemplate.render @template_name, {}
    @countrySelector = $(@el).find(@selectors.countrySelector)
    @stateSelector = $(@el).find(@selectors.stateSelector)
    @citySelector = $(@el).find(@selectors.citySelector)
    @locationSelector = new Extranet.Helpers.LocationSelector @countrySelector, @stateSelector, @citySelector, {}
    @collection = new Extranet.Collections.Taxes()
    @alertMessage = new Extranet.Views.AlertMessage {selector: @selectors.alertMessage}
    @listenTo @collection, 'sync', @renderTaxes
    $(@el).find(@selectors.createTax).hide()

  searchTaxes: ()->
    @selectedCountryUuid = $(@el).find(@selectors.countrySelector).val()
    @selectedStateUuid = $(@el).find(@selectors.stateSelector).val()
    @selectedCityUuid = $(@el).find(@selectors.citySelector).val()

    data = {}
    if @selectedCityUuid
      data.city_uuid = @selectedCityUuid
    else if @selectedStateUuid
      data.state_uuid = @selectedStateUuid

    if _.isEmpty data
      errorMessage = I18n.translate("js.modules.common.alerts.validation.select_highlighted_filters")
      @alertMessage.renderError(errorMessage)
      @_highlightFilter(@countrySelector) unless @selectedCountryUuid
      @_highlightFilter(@stateSelector)
      @_highlightFilter(@citySelector)
    else
      @fetchCollection({data})
      @displayAddTax()

  displayAddTax: () ->
    $(@el).find(@selectors.createTax).show()

  fetchCollection: (params={})->
    $(@el).find('.select-taxes-table table').hide()
    $(@el).find('.select-taxes-table').addClass('loading')
    @collection.fetch _.extend params,
      reset: true
      global: false
      success: (collection, response, options) =>
        @removeErrorMessage()
        $(@el).find('.select-taxes-table').removeClass('loading')
      error: (collection, response, options)=>
        $(@el).find('.select-taxes-table').removeClass('loading')
        requestId = options?.xhr?.getResponseHeader('x-request-id')
        @alertMessage.renderDefaultError(requestId)

  renderTaxes: () ->
    $(@el).find('.select-taxes-table table').show()
    this.$('table tbody').empty()
    if @collection.length
      @collection.each (tax)=>
        @renderTax tax
    else
      this.$('table tbody').append Extranet.Templates["admin_no_tax_results"].render()

    return this

  renderTax:(tax)=>
    row = new Extranet.Views.AdminTaxRowView
      model: tax
      modalSelector: '#taxes_content .modal-body'
      alertMessage: @alertMessage
      selectedStateUuid: @selectedStateUuid
      selectedCountryUuid: @selectedCountryUuid
    this.$('table tbody').append row.render().el

  showNewTax: (e) ->
    newTax = new Extranet.Views.TaxDetailsView
      collection:@collection
      alertMessage: @alertMessage
      selectedStateUuid: @selectedStateUuid
      selectedCountryUuid: @selectedCountryUuid
      selectedCityUuid: @selectedCityUuid
    this.$('.modal-body').html newTax.render().el

  removeErrorMessage: ()->
    @alertMessage.hide()
    @countrySelector.removeClass 'error'
    @stateSelector.removeClass 'error'
    @citySelector.removeClass 'error'

  _highlightFilter: (selector) ->
    selector.addClass 'error'

