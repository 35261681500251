class Extranet.Presenters.Bookings

  constructor: ( options ) ->
    @collection = options.collection

  toHash: () ->
    reservations = @collection.toJSON()

    _.map reservations, (reservation) =>
      timeZoneIdentifier = reservation.timeZoneIdentifier
      currencyCode = reservation.currencyCode
      dateTimeFormat = 'string'
      hash =
        bookingId: reservation.bookingId
        status: reservation.status
        guestName: reservation.guestName
        checkInDate: DateTimeHelper.formatDate reservation.checkInDate, dateTimeFormat
        checkOutDate: DateTimeHelper.formatDate reservation.checkOutDate, dateTimeFormat
        bookingDate: DateTimeHelper.formatDate reservation.bookingDate, dateTimeFormat, timeZoneIdentifier
        bookingTime: reservation.bookingTime or DateTimeHelper.formatTime reservation.bookingDate, dateTimeFormat, timeZoneIdentifier
        cancellationDate: DateTimeHelper.formatDate reservation.cancellationDate, dateTimeFormat, timeZoneIdentifier
        cancellationTime: reservation.cancellationTime or DateTimeHelper.formatTime reservation.cancellationDate, dateTimeFormat, timeZoneIdentifier
        numberOfNights: reservation.numberOfNights
        roomTypeName: reservation.roomName or reservation.roomTypeName
        ratePlanName: reservation.ratePlanName
        grouponRate: @_formatCurrency reservation.grouponRate, currencyCode
        netRate: @_formatCurrency reservation.netRate, currencyCode
        tax: @_formatCurrency reservation.tax, currencyCode
        ccFee: @_formatCurrency reservation.ccFee, currencyCode
        totalToHotel: @_formatCurrency reservation.totalToHotel, currencyCode
        isConfirmed: reservation.status is 'confirmed'
        isCanceled: reservation.status is 'canceled'
        roomNightsDetails: @_mapRoomNightlyDetailsToUsableOutput reservation.roomNights

  _formatCurrency: (price, currencyCode) ->
    return "" unless currencyCode
    price = (price or 0) / 100
    I18n.toCurrency(price, currencyCode)


  _mapRoomNightlyDetailsToUsableOutput: (roomNightsDetails) ->
    sortedRoomNightsDetails = @_sortRoomNightDetailsByStayDate roomNightsDetails

    _.map sortedRoomNightsDetails, (element) =>
      nightDetail=
        stayDate: element.date
        customerSalePrice: @_formatCurrency  element.subtotal.amount, element.subtotal.currencyCode
        taxAmount: @_formatCurrency element.taxes.amount, element.taxes.currencyCode
        netRate: @_formatCurrency element.totalToHotel.amount, element.totalToHotel.currencyCode


  _sortRoomNightDetailsByStayDate: (roomNightsDetails) ->
    roomNightsDetails.sort (a,b) ->
      return new Date(a.date) - new Date(b.date)