require "../../presenters/week_view/summary_presenter"

class Extranet.Views.WeekInventory.SummaryView extends Backbone.View

  templateName: 'inventory_templates_weekview_summary'
  selector:
    availableSelector: '#summary-available'
    soldSelector: '#summary-sold'
  summary: []

  initialize: ()->
    @presenter = new Extranet.Presenters.WeekViewSummaryPresenter()
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate

  render: ()->
    this.$el.html @autoTemplate.render @templateName, {@summary}
    this

  updateSummary: (datecells=[])->
    @summary = @presenter.getSummaryArray datecells
    @render()

  reset: ()->
    @summary = []
    @render()
