###
Handled filter to display in week view. This view doesn't have submit
button. When ever the filter values changes, this view fires
filterChange event with all filter values.
###


class Extranet.Views.WeekViewFilter extends Backbone.View
  Extranet.Common.Utilities.Mixin.extends this, Extranet.Helpers.ChildManager

  eventName: 'filterChange'
  templateName: 'inventory_templates_weekview_filters'
  selectors:
    weekSelector: '#week-picker'
    roomSelector: '#room-picker'
    thisWeek: '.this_week'
    prevToolTip: '.ui-datepicker-prev'
    nextToolTip: '.ui-datepicker-next'

  initialize: ()->
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate

  events:
    'click .date-field': 'showCalendar'
    'click .this_week': 'thisWeek'
    'change #week-picker': 'fireEvent'
    'change #room-picker': 'fireEvent'

  render: (options) ->
    @hotelUuid = options.hotelUuid
    monthViewUrl = ExtranetUrlBuilder.getMonthViewUrl {@hotelUuid}
    weekViewUrl = ExtranetUrlBuilder.getWeekViewUrl {@hotelUuid}
    this.$el.html @autoTemplate.render @templateName, {
      monthViewUrl
      weekViewUrl
    }
    @selectThisWeek()
    this

  showCalendar: (e)->
    e.preventDefault()
    this.$(@selectors.weekSelector).datepicker(@getCalendarSettings())
    this.$(@selectors.weekSelector).datepicker('show')
    $(@selectors.prevToolTip).removeAttr('title')
    $(@selectors.nextToolTip).removeAttr('title')

  thisWeek: (e)->
    e.preventDefault()
    @selectThisWeek()

  getCurrentWeek: ()->
    today = DateTimeHelper.getCurrentTime()
    return @formatWeek(DateTimeHelper.getBoundaryWeekDates today)

  formatWeek: (dates)->
    start = DateTimeHelper.formatDate dates.startDateOfWeek, 'string'
    end = DateTimeHelper.formatDate dates.endDateOfWeek, 'string'
    return "#{start} - #{end}"

  selectThisWeek: ()->
    this.$(@selectors.weekSelector).datepicker @getCalendarSettings()
    # 17712 - need to get and pass timezone, currently defaulting to browser tz
    this.$(@selectors.weekSelector).val @getCurrentWeek()
    this.$(@selectors.weekSelector).change()  # triggers change event

  updateWeek: (dates)=>
    startDateOfWeek = dates[0]
    endDateOfWeek = dates[dates.length-1]
    this.$(@selectors.weekSelector).val(@formatWeek({startDateOfWeek, endDateOfWeek}))
    this.$(@selectors.weekSelector).change()  # triggers change event

  getCalendarSettings:()->
    hash =
      # firstDay: 1
      onSelect: @calendarOnSelect

  parseWeek: (weekStr) ->
    week = {}
    return week unless weekStr
    tokens = weekStr.split(" - ")
    week.startDate = moment(tokens[0]).toDate()
    week.endDate = moment(tokens[1]).toDate()
    week

  calendarOnSelect: (date) =>
    $('ui-datepicker a').removeAttr('href') # To remove href link to '#' that causes the page to be reloaded
    weekRange = DateTimeHelper.getBoundaryWeekDates date
    this.$(@selectors.weekSelector).val(@formatWeek(weekRange))
    this.$(@selectors.weekSelector).change()

  highlightThisWeek: (weekStr) ->
    thisWeek = @getCurrentWeek()
    if weekStr isnt thisWeek
      $(@selectors.thisWeek).removeClass('active')
    else
      $(@selectors.thisWeek).addClass('active')

  getWeekStr: ()->
    @$(@selectors.weekSelector).val()

  getFilterOptions: ()->
    data = {}
    data.week = @parseWeek @getWeekStr()
    data.dates = DateTimeHelper.getDatesInRange data.week.startDate, data.week.endDate
    data.hotelUuid = @hotelUuid
    data

  fireEvent: (e)->
    e.preventDefault()
    weekStr = @getWeekStr()
    return if _.isEmpty weekStr
    @highlightThisWeek(weekStr)

   # fire event
    this.trigger @eventName, @getFilterOptions()
