presenter = require '../presenters/rates_inventory_upload'

class Extranet.Views.RatesInventoryUpload extends Backbone.View

  el: '#rates-inventory-upload'
  templateName: 'admin_rates_inventory_upload'

  selectors:
    alertMessage: '#upload-rates-inventory-modal .alert-message'
    uploadRatesInventoryModal: '#upload-rates-inventory-modal'
    uploadRatesInventoryInput: '#upload-rates-inventory-input'
    uploadRatesInventoryActionInfo: '#upload-rates-inventory-modal .action-info'
    submitFileAction: '#upload-rates-inventory-modal .action-info.submit'
    selectFileButton: '#upload-rates-inventory-modal .btn.select'
    submitFileButton: '#upload-rates-inventory-modal .btn.submit'
  
  events:
    'click #upload-rates-inventory-button': '_showUploadRatesInventoryModal'
    'click #upload-rates-inventory-modal .btn.select': '_selectFile'
    'click #upload-rates-inventory-modal .btn.submit': '_uploadFile'
    'click #upload-rates-inventory-modal .btn.cancel': '_hideUploadRatesInventoryModal'

  initialize: () ->
    @presenter = new Extranet.Presenters.RatesInventoryUpload()
    @alertMessage = new Extranet.Views.AlertMessage {selector: @selectors.alertMessage}

  render: () ->
    hash = @presenter.toHash()
    @$el.html TemplateHelper.render @templateName, hash
    fileUploadOptions =
      autoUpload: false
      headers: {'x-csrf-token': $('#csrf_token').val()}
      url: ExtranetUrlBuilder.getRatesInventoryUploadUrl()
      add: @_handleFileChange
    @$(@selectors.uploadRatesInventoryInput).fileupload fileUploadOptions
  
  _handleFileChange: (event, data) =>
    file = _.first data?.files
    if file? then @_prepareFileSubmission(data, file.name) else @_prepareFileSelection()

  _selectFile: () ->
    @$(@selectors.uploadRatesInventoryInput).trigger 'click'
  
  _showUploadRatesInventoryModal: () ->
    @alertMessage.hide()
    @_prepareFileSelection()
    @$(@selectors.uploadRatesInventoryModal).modal 'show'
  
  _hideUploadRatesInventoryModal: () ->
    @$(@selectors.uploadRatesInventoryModal).modal 'hide'

  _prepareFileSelection: () ->
    actionInfo = I18n.translate 'js.modules.admin.rates_inventory_upload.please_select_file'
    @$(@selectors.uploadRatesInventoryActionInfo).text actionInfo
    @$(@selectors.selectFileButton).removeClass 'hide'
    @$(@selectors.submitFileButton).addClass 'hide'
    @fileUploadData = null

  _prepareFileSubmission: (data, filename) ->
    actionInfo = I18n.translate 'js.modules.admin.rates_inventory_upload.about_to_upload', {filename}
    @$(@selectors.uploadRatesInventoryActionInfo).text actionInfo
    @$(@selectors.selectFileButton).addClass 'hide'
    @$(@selectors.submitFileButton).removeClass 'hide'
    @fileUploadData = data
  
  _uploadFile: () ->
    return unless @fileUploadData
    @alertMessage.hide()
    onSuccess = (result, textStatus, jqXHR) =>
      requestId = jqXHR?.getResponseHeader 'x-request-id'
      message = I18n.translate 'js.modules.admin.rates_inventory_upload.upload_success'
      @alertMessage.renderSuccess message, requestId
      @_prepareFileSelection()
    onFailure = (jqXHR, textStatus, errorThrown) =>
      requestId = jqXHR?.getResponseHeader 'x-request-id'
      responseJSON = jqXHR?.responseJSON
      message = @presenter.formatError responseJSON
      @alertMessage.renderError message, requestId
      @_prepareFileSelection()
    @fileUploadData.submit().then onSuccess, onFailure
