class Extranet.Presenters.Error
  
  findError: (responseJSON, errorCode) ->
    nestedErrors = responseJSON?.body?.nestedErrors
    isError = (error) -> error?.code is errorCode
    nestedError = _.find nestedErrors, (nestedError) ->
      _.any nestedError?.errors, isError
    error = _.find nestedError?.errors, isError

  findErrorEntity: (responseJSON, errorCode) ->
    nestedErrors = responseJSON?.body?.nestedErrors
    isError = (error) -> error?.entity is errorCode
    nestedError = _.find nestedErrors, (nestedError) ->
      _.any nestedError?.errors, isError
    error = _.find nestedError?.errors, isError
  
  format: (error) ->
    switch error.code
      when 'DUPLICATE_QL2_ID'
        ql2Id = error.errorData?.ql2Id
        hotelUuid = error.errorData?.hotelUuid
        hotel = "<a href='/extranet/hotels/#{hotelUuid}' target='_blank'>#{hotelUuid}</a>"
        message = I18n.translate 'js.modules.common.alerts.error.ql2id_mapping_conflict', {ql2Id, hotel}
      when 'CURRENT_CHANNEL_MANAGER_HAS_PRODUCT_SET_IN_REVIEW_VIOLATION_APPROVED_LIVE'
        message = I18n.translate 'js.modules.common.alerts.error.channel_manager_update_active_product_set'
      when 'CHANNEL_MANAGER_UPDATE_ACTIVE_CONNECTION'
        message = I18n.translate 'js.modules.common.alerts.error.channel_manager_update_active_connection'
      when 'INVENTORY_MANAGEMENT_MODE_UPDATE_CONNECTED_HOTEL'
        message = I18n.translate 'js.modules.common.alerts.error.inventory_management_mode_update_connected_hotel'
      when 'INVENTORY_MANAGEMENT_MODE_UPDATE_NON_READY_PRODUCT_SET'
        message = I18n.translate 'js.modules.common.alerts.error.inventory_management_mode_update_non_ready_product_set'
      when 'HOTEL_RESTRICTION_NET_RATE'
        message = I18n.translate 'js.modules.common.alerts.error.hotel_restriction_net_rate'
      when 'PRODUCT_SET_RESTRICTION_NET_RATE'
        message = I18n.translate 'js.modules.common.alerts.error.product_set_restriction_net_rate'
      when 'RATE_PLAN_RESTRICTION_NET_RATE'
        message = I18n.translate 'js.modules.common.alerts.error.rate_plan_restriction_net_rate'
      else
        message = I18n.translate 'js.modules.common.alerts.error.default'

  formatErrorEntity: (error) ->
    switch error.entity
      when 'DAILY_RATE_RESTRICTION_NET_RATE'
        message = I18n.translate 'js.modules.common.alerts.error.daily_rate_restriction_net_rate'
      else
        message = I18n.translate 'js.modules.common.alerts.error.default'
