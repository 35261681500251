require "../../../../inventory/frontend/views/month_view/month_view_v2"

class Extranet.Views.ProductSetRatesView extends Backbone.View
  Extranet.Common.Utilities.Mixin.extends this, Extranet.Helpers.ChildManager

  el: '#ps-rates-section'

  selectors:
    monthView: '#ps-rates-month-view'

  initialize: (options) ->
    deepCopy = true
    productTypes = ['bookingDeal']
    newOptions = $.extend deepCopy, options, {el: @selectors.monthView, productTypes}
    @monthView = @addChild new Extranet.Views.MonthViewV2 newOptions

  render: ()->
    @show()
    @monthView.render()

    this
