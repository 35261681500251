class Extranet.Views.UserPickerView extends Backbone.View

  initialize: (options = {})->
    @$el.autocomplete(
      @autoCompleteOptions()
    ).data("ui-autocomplete")._renderItem = (ul, item)->
      text = "#{item.firstName} #{item.lastName} - #{item.email}"
      $('<li></li>').data('ui-autocomplete-item', item).append("<a>"+ text + "</a>").appendTo(ul)

  autoCompleteOptions: ->
    minLength: 2
    source: (request, response)=>
      $.ajax
        type: 'GET'
        url: ExtranetUrlBuilder.getUsers(searchKeyword: request.term)
        dataType:"json"
        contentType: "application/json; charset=utf-8"
        global: false
        success: (results) =>
          response(results)

    select: (event, ui)=>
      @$el.val(ui.item.email)
      return false

    focus: (event, ui)=>
      @$el.val(ui.item.email)
      return false
