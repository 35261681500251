'use strict';

let DEFAULT_TIMEZONE;

const moment = require('moment-timezone');

const { isUndefined } = require('underscore');

const DEFAULT_FORMAT = 'api';
const FORMAT = {
  DATETIME: {
    api: 'YYYY-MM-DD[T]HH:mm:ss[Z]',
    string: 'MMM DD, YYYY @ h:mm:ssa z',
  },
  DATE: {
    api: 'YYYY-MM-DD',
    string: 'MMM DD, YYYY',
    numeric: 'YYYY/MM/DD',
    filename: 'YYYYMMMDD',
  },
  TIME: {
    string: 'h:mm:ssa z',
  },
};

class DateTimeHelper {
  // Format Getter
  static getDateTimeFormat(format) {
    return FORMAT.DATETIME[format];
  }

  static getDateFormat(format) {
    return FORMAT.DATE[format];
  }

  static getTimeFormat(format) {
    return FORMAT.TIME[format];
  } // DateTime Formatter

  static formatDateTime(
    dateTime,
    format = DEFAULT_FORMAT,
    timezone = DEFAULT_TIMEZONE
  ) {
    return this._format(dateTime, this.getDateTimeFormat(format), timezone);
  }

  static formatDate(
    dateTime,
    format = DEFAULT_FORMAT,
    timezone = DEFAULT_TIMEZONE
  ) {
    return this._format(dateTime, this.getDateFormat(format), timezone);
  }

  static formatTime(
    dateTime,
    format = DEFAULT_FORMAT,
    timezone = DEFAULT_TIMEZONE
  ) {
    return this._format(dateTime, this.getTimeFormat(format), timezone);
  }

  static _format(dateTime, formatString, timezone = DEFAULT_TIMEZONE) {
    if (!this._isValidDateTime(dateTime)) {
      return '';
    }

    return this._getDateTime(dateTime, timezone).format(formatString);
  } // Date Comparator

  static isPastDate(dateTime, timezone = DEFAULT_TIMEZONE) {
    return this.isBeforeDate(
      dateTime,
      this.formatDate(this.getCurrentTime(), 'api', timezone)
    );
  }

  static isBeforeDate(
    dateTime,
    comparisonDateTime,
    timezone = DEFAULT_TIMEZONE
  ) {
    if (
      !this._isValidDateTime(dateTime) ||
      !this._isValidDateTime(comparisonDateTime)
    ) {
      return false;
    }

    const baseDate = this._getStartOfDate(dateTime, timezone);

    const comparisonDate = this._getStartOfDate(comparisonDateTime, timezone);

    return baseDate.isBefore(comparisonDate);
  }

  static isAfterDate(
    dateTime,
    comparisonDateTime,
    timezone = DEFAULT_TIMEZONE
  ) {
    if (
      !this._isValidDateTime(dateTime) ||
      !this._isValidDateTime(comparisonDateTime)
    ) {
      return false;
    }

    const baseDate = this._getStartOfDate(dateTime, timezone);

    const comparisonDate = this._getStartOfDate(comparisonDateTime, timezone);

    return baseDate.isAfter(comparisonDate);
  }

  static isBeforeOrSameDate(
    dateTime,
    comparisonDateTime,
    timezone = DEFAULT_TIMEZONE
  ) {
    if (
      !this._isValidDateTime(dateTime) ||
      !this._isValidDateTime(comparisonDateTime)
    ) {
      return false;
    }

    const baseDate = this._getStartOfDate(dateTime, timezone);

    const comparisonDate = this._getStartOfDate(comparisonDateTime, timezone);

    return !baseDate.isAfter(comparisonDate);
  }

  static isAfterOrSameDate(
    dateTime,
    comparisonDateTime,
    timezone = DEFAULT_TIMEZONE
  ) {
    if (
      !this._isValidDateTime(dateTime) ||
      !this._isValidDateTime(comparisonDateTime)
    ) {
      return false;
    }

    const baseDate = this._getStartOfDate(dateTime, timezone);

    const comparisonDate = this._getStartOfDate(comparisonDateTime, timezone);

    return !baseDate.isBefore(comparisonDate);
  } // DateTime Utilities

  static getDatesInRange(
    startDateTime,
    endDateTime,
    format = DEFAULT_FORMAT,
    timezone = DEFAULT_TIMEZONE
  ) {
    let currentDate = this._getDateTime(startDateTime, timezone);

    const endDate = this._getDateTime(endDateTime, timezone);

    const dates = [];

    while (this.isBeforeOrSameDate(currentDate, endDate, timezone)) {
      dates.push(this.formatDate(currentDate, format, timezone));
      currentDate = currentDate.add(1, 'day');
    }

    return dates;
  }

  static getBoundaryWeekDates(
    dateTime,
    format = DEFAULT_FORMAT,
    timezone = DEFAULT_TIMEZONE
  ) {
    const startDateOfWeek = this.formatDate(
      moment(dateTime).startOf('week').startOf('day'),
      format,
      timezone
    );
    const endDateOfWeek = this.formatDate(
      moment(dateTime).endOf('week').startOf('day'),
      format,
      timezone
    );
    return {
      startDateOfWeek,
      endDateOfWeek,
    };
  }

  static getWeekRangeForDate(
    dateTime,
    format = DEFAULT_FORMAT,
    timezone = DEFAULT_TIMEZONE
  ) {
    const startDate = moment(dateTime).startOf('week').startOf('day');
    const endDate = moment(dateTime).endOf('week').startOf('day');
    return this.getDatesInRange(startDate, endDate, format, timezone);
  }

  static isCorrectDateFormat(dateTimeString) {
    return (
      moment(dateTimeString, this.getDateFormat('api'), true).isValid() ||
      moment(dateTimeString, this.getDateFormat('numeric'), true).isValid()
    );
  }

  static getCurrentTime(timezone = DEFAULT_TIMEZONE) {
    if (timezone) {
      return moment.tz(timezone);
    } else {
      return moment();
    }
  }

  static _getStartOfDate(dateTime, timezone = DEFAULT_TIMEZONE) {
    return this._getDateTime(dateTime, timezone).startOf('day');
  }

  static _getDateTime(dateTime, timezone = DEFAULT_TIMEZONE) {
    // moment-timezone does not work well with "YYYY/MM/DD" format so converting the 'datetime' input to "YYYY-MM-DD"
    if (typeof dateTime === 'string') {
      dateTime = dateTime.replace(/\//g, '-');
    }

    if (timezone) {
      return moment.tz(dateTime, timezone);
    } else {
      return moment(dateTime);
    }
  }

  static _isValidDateTime(dateTime) {
    return !isUndefined(dateTime) && moment(dateTime).isValid();
  }
}

if (typeof window !== 'undefined' && window !== null) {
  DEFAULT_TIMEZONE = moment.tz.guess();
  window.DateTimeHelper = DateTimeHelper;
} else {
  DEFAULT_TIMEZONE = 'UTC';
  module.exports = DateTimeHelper;
}
