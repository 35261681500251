class Extranet.Common.Utilities.Utility

  translate: (key, hash = {}) ->
    TRANSALTION_PREFIX = "js.modules"
    I18n.translate "#{TRANSALTION_PREFIX}.#{key}", hash

  @isAdmin: () ->
    Extranet?.RolePermissions?.is_admin_user ? false

  @getParameterByName: (name) ->
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]")
    regex = new RegExp("[\\?&]" + name + "=([^&#]*)")
    results = regex.exec(location.search)
    if results?
      return decodeURIComponent(results[1].replace(/\+/g, " "))
    ""

  @getRandomString: (limit)->
    Math.random().toString(36).replace(/[^a-z0-9]+/g, '').substr(0,limit)

  @getCurrencySymbol: (currencyCode)->
    return '' unless currencyCode?.length
    I18n.lookup("number.symbols.currency.#{currencyCode}")

  @shouldRatesIncludeTaxes: ()->
    # There are two hotel rate types, Customer Rate and Sell Rate.
    # Customer Rate type means the rates will include taxes.
    # Sell Rate type means the rates will NOT include taxes.
    Extranet?.ClientConfig?.hotelData?.rateType is "customerRate"

  @shouldDisableBaseRate: ()->
    # Temporarily using shouldRatesIncludeTaxes check to determine whether the hotel is from EMEA.
    # It should be changed to the condition based on country code / region
    @shouldRatesIncludeTaxes() and not @isAdmin()
