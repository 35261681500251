class Extranet.Presenters.ProductOwnersUpdatePresenter

  toHash: (productSetModel)->
    deepCopy = true
    hash = $.extend deepCopy, {}, productSetModel.toJSON()
    hash.hasOnboardingTeamId = not _.isEmpty productSetModel.get 'onboardingTeamId'
    hash.hasOpportunityOwnerId = not _.isEmpty productSetModel.get 'opportunityOwnerId'
    hash.hasPlannerId = not _.isEmpty productSetModel.get 'plannerId'
    hash.hasMarketManagerId = not _.isEmpty productSetModel.get 'marketManagerId'

    hash
