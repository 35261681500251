class Extranet.Presenters.ProductOwnersPresenter
  getHashToSave: (view) ->
    saveAttrs = {}
    onboardingTeamId = view.$('#on-boarding-team input').val()
    marketManagerId = view.$('#marketing-manager input').val()
    plannerId = view.$('#planner input').val()
    opportunityOwnerId = view.$('#opportunity-owner input').val()
    saveAttrs =
      opportunityOwnerId: opportunityOwnerId or null
      marketManagerId: marketManagerId or null
      plannerId: plannerId or null
      onboardingTeamId: onboardingTeamId or null
