require "../views/event_manager_view"

class Extranet.Helpers.EventManagerHelper

  @PS_MONTH_VIEW_KEY = 'monthView'
  @SETTINGS_KEY = 'settings'
  @PRODUCTS_KEY = 'products'
  @viewsHash: {}

  @initialize: (viewName) ->
    @viewsHash[viewName] ?= new Extranet.Views.EventManagerView()

  @getEventManagerView: (viewName) ->
     @viewsHash[viewName]
