require "../collections/rate_descriptions"

class Extranet.Views.RoomMappingView extends Backbone.View

  el: 'body'

  selectors:
    roomTypeRateDescriptions: '#room-type-rate-descriptions'
    closeModalButton: '#room-mapping-save-confirmation .close-modal'
    savePopup: '#room-mapping-save-confirmation'
    roomMappingSection: '.room-mapping-section'
    alertMessage: '.alert-message'
    unassignedList: '.rate-description-group.unassigned .rate-description-list'
    optedOutList: '.rate-description-group.opted-out .rate-description-list'
    hoterLarList: '.rate-description-group.hotel-lar .rate-description-list'
    unassignedRateDescriptionCount: '.unassigned-rate-description-count'
    roomLarList: (roomTypeUuid)->
      "##{roomTypeUuid}.rate-description-group.room-lar .rate-description-list"

  events:
    'click .rate-description-group:not(.empty) .toggle-link': 'toggleRateDescriptionList'
    'click #reset': 'renderLarRooms'
    'click #submit': 'handleSavePopup'
    'click #cancel-room-mapping': 'closeConfirmationPopup'
    'click #submit-room-mapping': 'saveRateDescriptions'
    'click .toggle-context-menu': 'toggleContextMenu'
    'click .context-menu-option': 'handleContextMenuOptionSelection'
    'click .rate-description': 'toggleRateDescriptionSelection'
    'click .select-all': 'toggleSelectAll'
    'click': 'hideContextMenu'
    'keydown': 'handleKeyPress'

  initialize: (options) ->
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    @alertMessage = new Extranet.Views.AlertMessage {selector: @selectors.alertMessage}
    @rateDescriptionsCollection = new Extranet.Collections.RateDescriptions()
    @roomsCollection = new Extranet.Collections.Rooms {hotelUuid: Extranet.ClientConfig.hotelData.uuid}
    @rateDescriptionStatuses = Extranet.ClientConfig.rateDescriptionStatuses or {}
    
    @hotelLarEnabled = Extranet.ClientConfig.hotelLarEnabled
    @readOnlyMode = Extranet.RolePermissions?.is_readonly_user
    $('.rate-description-list').on 'scroll', @hideContextMenu
  
  render: () ->
    @loadResources()

  hideLoading: ()->
    $(@selectors.roomMappingSection).removeClass 'loading'

  showLoading: ()->
    $(@selectors.roomMappingSection).addClass 'loading'

  toggleRateDescriptionSelection: (e)->
    rateDescriptionEl = $(e.currentTarget)
    if e.ctrlKey or e.metaKey
      rateDescriptionEl.toggleClass('selected')
    else
      rateDescriptionEl.addClass("selected").siblings().removeClass('selected')

    selectedRateDescriptions = rateDescriptionEl.parents('.rate-description-group').find('.rate-description.selected')
    @deselectAllRateDescriptions()
    selectedRateDescriptions.addClass('selected')
    @setRateDescriptionSelectionStatus()

  setRateDescriptionSelectionStatus: ()->
    rateDescriptionGroups = $('.rate-description-group')
    for rateDescriptionGroup in rateDescriptionGroups
      unselectedRateDescriptions = $(rateDescriptionGroup).find('.rate-description:not(.selected)').length
      selectedRateDescriptions = $(rateDescriptionGroup).find('.rate-description.selected').length
      $(rateDescriptionGroup).toggleClass('multi-selection', selectedRateDescriptions > 1)
      $(rateDescriptionGroup).find('.select-all').toggleClass('active', unselectedRateDescriptions is 0)

  deselectAllRateDescriptions: ()->
    $('.rate-description').removeClass('selected')
    $('.select-all').removeClass('active')
    $('.rate-description-group').removeClass('multi-selection')

  toggleSelectAll: (e)->
    return if @readOnlyMode
    selectAllEl = $(e.currentTarget)
    rateDescriptionGroup = selectAllEl.parents('.rate-description-group')
    shouldSelectAll = not selectAllEl.hasClass('active')
    @deselectAllRateDescriptions()
    selectAllEl.toggleClass('active', shouldSelectAll)
    rateDescriptionGroup.find('.rate-description').toggleClass('selected', shouldSelectAll)
    @setRateDescriptionSelectionStatus()

  toggleRateDescriptionList: (e)->
    toggleLink = $(e.currentTarget)
    toggleLink.parents('.rate-description-group').find('.rate-description-list').slideToggle(200)
    toggleLink.toggleClass('expanded')

  loadResources: () ->
    @showLoading
    fetchRateDescriptions = @rateDescriptionsCollection.fetch
      reset: true
      global: false
    fetchRoomTypes = @roomsCollection.fetch
      reset: true
      global: false
    requestIds = []

    finalSuccess = () =>
      @renderLarRooms()
      @_updateUnassignedRateDescriptionCount()
    finalFailure = () =>
      @hideLoading()
      @alertMessage.renderDefaultError(requestIds.join(","))
    eachRequestComplete = (data, state, xhr) =>
      if state is 'error'
        requestIds.push xhr?.getResponseHeader 'x-request-id'
    
    $.whenAll([fetchRateDescriptions, fetchRoomTypes]).then finalSuccess, finalFailure, eachRequestComplete
  
  renderLarRooms: () ->
    @larRooms = @roomsCollection.getLarRooms()
    roomTypeRateDescriptionsEl = @autoTemplate.render 'room_mapping_templates_room_type_rate_descriptions', {@larRooms, @readOnlyMode, @rateDescriptionStatuses}
    $(@selectors.roomTypeRateDescriptions).html roomTypeRateDescriptionsEl
    @renderRateDescriptions()
  
  renderRateDescriptions: ()->
    $('.rate-description-list').empty()
    rateDescriptions = @rateDescriptionsCollection.toJSON()
    if rateDescriptions?.length
      for rateDescription in rateDescriptions
        rateDescriptionEl = $(@autoTemplate.render('room_mapping_templates_rate_description', {rateDescription, @larRooms, @rateDescriptionStatuses, @readOnlyMode, @hotelLarEnabled}))
        rateDescriptionGroup = @getRateDescriptionGroup(rateDescription.status, rateDescription.roomTypeUuid)
        rateDescriptionGroup.append(rateDescriptionEl)
        @hideCurrentRoomOption(rateDescriptionEl)
    @updateRateDescriptionCount()
    @initializeDraggable()
    @hideLoading()

  getRateDescriptionGroup: (status, roomTypeUuid)->
    listSelector = switch status
      when @rateDescriptionStatuses.unassigned then @selectors.unassignedList
      when @rateDescriptionStatuses.optedOut then @selectors.optedOutList
      when @rateDescriptionStatuses.hotelLar then @selectors.hoterLarList
      when @rateDescriptionStatuses.roomLar then @selectors.roomLarList(roomTypeUuid)
      else @selectors.unassignedList

    return $(listSelector)

  updateRateDescriptionCount: ()->
    rateDescriptionGroups = $('.rate-description-group')
    if rateDescriptionGroups
      for rateDescriptionGroup in rateDescriptionGroups
        rateDescriptionCount = $(rateDescriptionGroup).find('.rate-description').length
        $(rateDescriptionGroup).find('.rate-description-count').text(rateDescriptionCount)
        $(rateDescriptionGroup).toggleClass('empty', rateDescriptionCount is 0)

  initializeDraggable: ()->
    return if @readOnlyMode

    # Please refer https://api.jqueryui.com/1.9/sortable/ for more details
    $('.rate-description-group').disableSelection().sortable
      connectWith: '.rate-description-group'
      containment: ".room-mapping-section"
      items: '.rate-description'
      cancel: '.context-menu-popup'
      placeholder: "invisible-placeholder"
      delay: 150
      forceHelperSize: true
      revert: 0
      distance: 5
      cursor: "move"
      cursorAt: { top: 20 }
      over: (event, ui) ->
        $('.rate-description-group').removeClass 'active'
        $(this).addClass 'active'
      receive: (event, ui)=>
        @handlePositionChange $(ui.item)
      helper: (event, item) =>
        helper = $('<div class="draggable-window"/>')
        unless item.hasClass('selected')
          item.addClass('selected').siblings().removeClass 'selected'
        elements = item.parent().children('.selected').clone()
        elements.addClass('changed')
        item.data('multidrag', elements).siblings('.selected').remove()
        if elements.length > 1
          helper.addClass('multiple')
          helper.append elements[0]
          additionalInfo = I18n.translate("js.modules.room_mapping.more_rate_descriptions", {rateDescriptionCount: elements.length-1})
          helper.append "<div class='more-descriptions'>#{additionalInfo}</div>"
        else
          helper.append elements
      stop: (event, ui) =>
        ui.item.after(ui.item.data('multidrag')).remove()
        @handlePositionChange $(ui.item)
      update: (event, ui) =>
        rateDescriptionGroup = ui.item.parents('.rate-description-group')
        @highlightGroup(rateDescriptionGroup)

  handlePositionChange: (rateDescriptionEl)->
    rateDescriptionEl.addClass 'changed'
    rateDescriptionGroup = rateDescriptionEl.parents('.rate-description-group')
    @highlightGroup(rateDescriptionGroup)
    @handleRateDescriptionPosition()
    @updateRateDescriptionCount()
    @closeAllContextMenus()
    @deselectAllRateDescriptions()

  handleRateDescriptionPosition: ()->
    rateDescriptionElements = $('.rate-description')
    for rateDescriptionElement in rateDescriptionElements
      @hideCurrentRoomOption($(rateDescriptionElement))
      unless $(rateDescriptionElement).parent().hasClass('rate-description-list')
        rateDescriptionGroup = $(rateDescriptionElement).parents('.rate-description-group')
        rateDescriptionGroup.find('.rate-description-list').prepend($(rateDescriptionElement).detach())

  highlightGroup: (rateDescriptionGroup)->
    $('.rate-description-group').removeClass('active')
    rateDescriptionGroup.addClass('highlight')
    setTimeout (-> rateDescriptionGroup.removeClass 'highlight'), 1000

  hideCurrentRoomOption: (rateDescriptionEl)->
    rateDescriptionGroup = rateDescriptionEl.parents('.rate-description-group')
    status = rateDescriptionGroup.find('.rate-description-status').val()
    contextMenuRoomOptions = rateDescriptionGroup.find('.context-menu-option.room-lar')
    contextMenuRoomOptions.removeClass 'hide'
    return unless status is @rateDescriptionStatuses.roomLar
    roomTypeUuid = rateDescriptionGroup.find('.room-type-uuid').val()
    contextMenuRoomOptions.filter("[roomTypeUuid='#{roomTypeUuid}']").addClass 'hide'

  closeConfirmationPopup: ()->
    @$(@selectors.closeModalButton).click()

  handleSavePopup: ()->
    return if @readOnlyMode

    if $('.rate-description.changed').length > 0
      $(@selectors.savePopup).addClass('show-modal')
      $('body').addClass('modal-open')
    else
      message = I18n.translate("js.modules.common.alerts.error.no_changes_to_be_saved")
      @alertMessage.renderError(message)

  saveRateDescriptions: ()->
    return if @readOnlyMode

    # close the popup first
    @$(@selectors.closeModalButton).click()

    successCallback = (data, textStatus, jqxhr)=>
      requestId = jqxhr?.getResponseHeader('x-request-id')
      message = I18n.translate("js.modules.common.alerts.success.default")
      @alertMessage.renderSuccess(message, requestId)
      @render()

    errorCallback = (jqxhr, status, errorThrown)=>
      requestId = jqxhr?.getResponseHeader('x-request-id')
      @alertMessage.renderDefaultError(requestId)

    updatedRateDescriptions = @getUpdatedRateDescriptions()
    if updatedRateDescriptions?.length
      @rateDescriptionsCollection.save(updatedRateDescriptions, successCallback, errorCallback)
    else
      message = I18n.translate("js.modules.common.alerts.error.no_changes_to_be_saved")
      @alertMessage.renderError(message)

  getUpdatedRateDescriptions: ()->
    updatedRateDescriptions = []
    if @rateDescriptionsCollection.models?.length
      for rateDescriptionModel in @rateDescriptionsCollection.models
        uuid = rateDescriptionModel.get('uuid')
        rateDescriptionEl = $(".rate-description##{uuid}")

        if rateDescriptionEl.hasClass 'changed'
          rateDescriptionGroup = rateDescriptionEl.parents('.rate-description-group')
          status = rateDescriptionGroup.find('.rate-description-status').val()
          roomTypeUuid = if status is @rateDescriptionStatuses.roomLar then rateDescriptionGroup.find('.room-type-uuid').val() else null
          updatedRateDescriptions.push({uuid,status, roomTypeUuid})

    updatedRateDescriptions

  toggleContextMenu: (e)->
    return if @readOnlyMode
    e.preventDefault()
    e.stopPropagation()
    contextMenuEl = $(e.currentTarget).siblings('.context-menu-popup')
    rateDescriptionEl = contextMenuEl.parents('.rate-description')
    unless rateDescriptionEl.hasClass('selected')
      @deselectAllRateDescriptions()
      rateDescriptionEl.addClass('selected')
    if contextMenuEl.hasClass 'hide'
      @closeAllContextMenus()
      contextMenuEl.removeClass 'hide'
      contextMenuEl.css('top', $(e.currentTarget).position().top + 20)
    else
      @closeAllContextMenus()

  closeAllContextMenus: (e)=>
    $('.context-menu-popup').addClass 'hide'

  hideContextMenu: (e)=>
    unless $(e.target).is('.context-menu-popup *') or $(e.target).is('.toggle-context-menu')
      @closeAllContextMenus()

  handleKeyPress: (e)=>
    @closeAllContextMenus() if e.keyCode is 27 # Checking if 'ESC' key pressed

  handleContextMenuOptionSelection: (e)=>
    return if @readOnlyMode
    e.preventDefault()
    e.stopPropagation()
    contextMenuOption = $(e.currentTarget)
    status = contextMenuOption.attr('status')
    roomTypeUuid = contextMenuOption.attr('roomTypeUuid')
    rateDescriptionEl = contextMenuOption.parents('.rate-description')
    rateDescriptionGroup = @getRateDescriptionGroup(status, roomTypeUuid)
    unless rateDescriptionEl.hasClass('selected')
      rateDescriptionEl.addClass('selected').siblings().removeClass 'selected'
    selectedRateDescriptions = rateDescriptionEl.parent().children('.selected')
    selectedRateDescriptions.addClass('changed')

    for selectedRateDescription in selectedRateDescriptions
      rateDescriptionGroup.prepend($(selectedRateDescription).detach())
    @handlePositionChange(rateDescriptionEl)

  _updateUnassignedRateDescriptionCount: () ->
    rateDescriptions = @rateDescriptionsCollection.toJSON()
    unassignedRateDescriptionCount = _.filter(rateDescriptions, (rateDescription) -> rateDescription.status is 'unassigned').length
    $(@selectors.unassignedRateDescriptionCount).toggleClass 'hide', unassignedRateDescriptionCount is 0
    $(@selectors.unassignedRateDescriptionCount).html unassignedRateDescriptionCount