require "../collections/product_set_collection"

class Extranet.Views.ProductSetStatusButtonsView extends Backbone.View
  Extranet.Common.Utilities.Mixin.extends this, Extranet.Helpers.ChildManager

  templateName: 'products_templates_product_set_status_buttons'

  eventManagerKey: Extranet.Helpers.EventManagerHelper.PS_MONTH_VIEW_KEY

  selectors:
    moveToShopRatesButton: '#move-to-shop-rates'
    moveToReadyButton: '#move-to-ready'
    moveToApprovedButton: '#move-to-approved'

  events:
    'click #move-to-shop-rates': 'moveToShopRates'
    'click #move-to-ready': 'moveToReady'
    'click #move-to-approved': 'moveToApproved'

  initialize: (options) ->
    {@productSetModel, @hotelUuid, @isRatesTab} = options
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    @alertMessage = Extranet.Helper.AlertView.getAlertView()
    @statusHelper = Extranet.Helpers.ProductSetStatusHelper
    @eventManagerView = Extranet.Helpers.EventManagerHelper.getEventManagerView(@eventManagerKey)
    @productSetsCollection = new Extranet.Collections.ProductSets {
      hotelUuid:@hotelUuid
    }

    @listenTo @productSetModel, 'change:status', @render
    @listenTo @productSetModel, 'change:ratePlans', @render
    @listenTo @eventManagerView, 'showProductSetStatusChangeButtons', @show
    @listenTo @eventManagerView, 'hideProductSetStatusChangeButtons', @hide
    # The goal of the following code is catching the event of the rate & inventory success.
    # The rate & inventory view is Extranet.Views.SummaryCollectionView, there you can find that
    # both validationSuccess and ratesInventorySaved events are triggered when the saving is succeeded.
    # I chose the 2nd event, validationSuccess, for capturing successful saving event.
    @listenTo @eventManagerView, 'validationSuccess', @show

  render: () ->
    hash =
      accessPermissionSubKey: @productSetModel?.get('status')
      isRatesTab: @isRatesTab
      hasRatePlans: @productSetModel.get('ratePlans')?.length > 0
    @$el.html @autoTemplate.render @templateName, hash
    @show()
    this

  moveToShopRates: (e)->
    e.preventDefault()
    return if @productSetModel.get('status') isnt 'created'
    @changeProductSetStatus(@statusHelper.states.SHOP_RATES)

  moveToReady: (e)->
    e.preventDefault()
    return if @productSetModel.get('status') isnt 'shop-rates'
    @_validateAndMoveToReadyStatus()

  moveToApproved: (e)->
    e.preventDefault()
    return if @productSetModel.get('status') isnt 'ready'
    @changeProductSetStatus(@statusHelper.states.APPROVED)
  
  # validate if the deal is allowed to be moved to ready state,
  # if it is allowed, move the deal to be ready
  _validateAndMoveToReadyStatus: () ->
    @$el.addClass('loading')

    @productSetsCollection.fetch({reset: true, global: false})
    .done(()=>
      if @productSetsCollection.hasActiveDeals()
        message = I18n.translate('js.modules.products.error_messages.back_to_back_deal_exists_error')
        @alertMessage.renderError(message)
      else
        @changeProductSetStatus(@statusHelper.states.READY)
    ).fail((xhr)=>
      requestId = xhr?.getResponseHeader('x-request-id')
      @alertMessage.renderDefaultError(requestId)
    ).always(()=>
      @$el.removeClass('loading')
    )

  changeProductSetStatus: (newState)->
    options =
      newState: newState
      _csrf: $("#csrf_token").val()
      hotelUuid: @hotelUuid
      productSetModel: @productSetModel

    ajaxCall = @statusHelper.changeStateTo(options)
    ajaxCall.done( (data, textStatus, jqxhr)=>
      newStatus = "<span class='product-set-status #{options.newState.name}'>#{options.newState.name}</span>"
      message = I18n.translate('js.modules.common.alerts.success.status_changed', {newStatus})
      requestId = jqxhr?.getResponseHeader('x-request-id')
      @alertMessage.renderSuccess(message, requestId)
    ).fail( (jqxhr)=>
      @displayErrorMesssage(newState, jqxhr)
    )

  displayErrorMesssage: (newState, jqxhr)->
    requestId = jqxhr?.getResponseHeader('x-request-id')

    if jqxhr.status is 422 and jqxhr.responseJSON?.errors?[0]?.entity is 'contacts'
      message = I18n.translate('js.modules.inventory.error_messages.no_contacts_error')
      @alertMessage.renderError(message, requestId)
    else if jqxhr.status isnt 403
      @alertMessage.renderDefaultError(requestId)

  show: ()->
    @$el.show()

  hide: ()->
    @$el.hide()
