require "./validations/failed_validation_dates_presenter"

class Extranet.Presenters.RatePlanSummaryPresenter
  utility = Extranet.Common.Utilities.Utility
  ratesUtility = Extranet.Inventory.RatesUtility

  constructor: () ->
    @aggregator = Extranet.Presenters.RatePlanAggregator

  toHash: (ratePlanData) ->
    @failedValidationPresenter = Extranet.Presenters.FailedValidationDatesPresenter
    model =
      name: @getRatePlanName ratePlanData
      ratePlanUrl: @getBdRatePlanUrl ratePlanData
      errors: @getErrorsHash ratePlanData
      ratePlanUuid: ratePlanData?[0]?.ratePlan?.uuid
      disableBaseRate: utility.shouldDisableBaseRate()
      isRatePlanInventoryMode: @_isRatePlanInventoryMode ratePlanData
      isInventoryAtRatePlanEnabled: @_isInventoryAtRatePlanEnabled()

    currencyCode = ratePlanData?[0]?.hotel?.currencyCode
    model.currencySymbol = utility.getCurrencySymbol(currencyCode) if currencyCode

    minLengthOfStay = ratePlanData?[0]?.minLengthOfStay
    model.minLengthOfStay = minLengthOfStay if minLengthOfStay > 1

    aggredatedData = @getAggregatedData ratePlanData
    model = $.extend model, aggredatedData

    if model.blackoutStatus is 'partial'
      model.blackoutText = I18n.translate('js.modules.inventory.partial_blackout')
    else
      model.blackoutText = I18n.translate('js.modules.inventory.blackout')
    model.isCompleteBlackout = model.blackoutStatus is 'blackout'

    if model.noCheckInStatus is 'partial'
      model.noCheckInText = I18n.translate('js.modules.inventory.partial_no_check_in')
    else
      model.noCheckInText = I18n.translate('js.modules.inventory.no_check_in')

    if model.approvedDiscountPercentage?
      if model.approvedDiscountPercentage.hasMultipleValues
        model.discountPercentageText = I18n.translate('js.modules.inventory.multi_discount_percentage', model.approvedDiscountPercentage)
      else
        model.discountPercentageText = I18n.translate('js.modules.inventory.single_discount_percentage', model.approvedDiscountPercentage)
    return model

  getRatePlanName: (ratePlanData) ->
    ratePlanData?[0]?.ratePlan?.name or ""

  getBdRatePlanUrl: (ratePlanData) ->
    options =  @getHotelInfo ratePlanData
    options = _.omit options, 'roomTypeUuid'
    ExtranetUrlBuilder.getBdRatePlanUrl options

  getHotelInfo: (ratePlanData) ->
    if ratePlanData?.length > 0
      hotelUuid = ratePlanData[0]?.hotel?.uuid
      roomTypeUuid = ratePlanData[0]?.roomType?.uuid
      productSetUuid = ratePlanData[0]?.productSet?.uuid
      ratePlanUuid = ratePlanData[0]?.ratePlan?.uuid
      {hotelUuid, productSetUuid, ratePlanUuid, roomTypeUuid}

  getErrorsHash: (ratePlanData) ->
    inventoryList = @extractList ratePlanData, @getErrorsCallback('inventoryCount')
    baseRatesList = @extractList ratePlanData, @getErrorsCallback('baseRate')
    LARList = @extractList ratePlanData, @getErrorsCallback('lar')
    grouponRateList = @extractList ratePlanData, @getErrorsCallback('grouponRate')
    marginPercentageList = @extractList ratePlanData, @getErrorsCallback('marginPercentage')
    minPercentList = @extractList ratePlanData, @getErrorsCallback('minDiscountPercentage')
    minAllotmentList = @extractList ratePlanData, @getErrorsCallback('minDailyAllotment')
    bookingLimitList = @extractList ratePlanData, @getErrorsCallback('bookingLimit')
    errors =
      inventory: ratesUtility.computeOr(inventoryList)
      baseRate: ratesUtility.computeOr(baseRatesList)
      lar: ratesUtility.computeOr(LARList)
      grouponRate: ratesUtility.computeOr(grouponRateList)
      marginPercentage: ratesUtility.computeOr(marginPercentageList)
      minAllotment: ratesUtility.computeOr(minPercentList)
      minPercent: ratesUtility.computeOr(minAllotmentList)
      bookingLimit: ratesUtility.computeOr(bookingLimitList)
    errors

  getErrorsCallback: (key) ->
    (dateCell) =>
      failedEntities =  @failedValidationPresenter.getFailedEntities dateCell
      _.contains(failedEntities, key)

  getAggregatedData: (ratePlanData)  ->
    baseRatesList = @extractList ratePlanData, @getFormattedRateCallback('base', 'amount')
    baseRate = ratesUtility.getMinHash baseRatesList

    LARList = @extractList ratePlanData, @getFormattedRateCallback('base', 'ql2Price')
    LAR = ratesUtility.getMinHash LARList

    grouponRateList = @extractList ratePlanData, @getFormattedRateCallback('bookingDeal', 'amount')
    grouponRate = ratesUtility.getMinHash grouponRateList

    netRate = @aggregator.getMinNetRate ratePlanData

    marginPercentageList = @extractList ratePlanData, (dayInventory) ->
      dayInventory.marginPercentage
    marginPercentage = ratesUtility.getMinHash marginPercentageList

    minPercentList = @extractList ratePlanData, (dayInventory) ->
      dayInventory?.minDiscountPercentage
    minPercent = ratesUtility.getMinHash minPercentList

    minAllotmentList = @extractList ratePlanData, (dayInventory) ->
     dayInventory?.minDailyAllotment
    minAllotment = ratesUtility.getMinHash minAllotmentList

    bookingLimitList = @extractList ratePlanData, (dayInventory) ->
      dayInventory?.bookingLimit
    bookingLimit = ratesUtility.getMinHash bookingLimitList

    soldCountList = @extractList ratePlanData, @getSoldCountCallBack()
    soldCount = ratesUtility.getSum soldCountList

    inventoryCountList = @extractList ratePlanData, (dayInventory) ->
      dayInventory?.ratePlanInventory?.inventoryCount
    inventoryCount = ratesUtility.getMinHash inventoryCountList

    blackoutStatus = @aggregator.getAggregatedBlackOut(ratePlanData)
    noCheckInStatus = @aggregator.getAggregatedNoCheckIn(ratePlanData)

    # use the list of lar rates and groupon rates to compute discount percentages when
    # the deal is not approved or live or finished
    larRateList = _.collect ratePlanData, @getFormattedRateCallback('base', 'ql2Price')
    grouponRateList = _.collect ratePlanData, @getFormattedRateCallback('bookingDeal', 'amount')
    discountPercentage = @getDiscountPercentage(larRateList, grouponRateList)

    approvedDiscountPercentage = @getApprovedDiscountPercentage(ratePlanData)

    {baseRate, LAR, grouponRate, netRate, marginPercentage, minPercent, inventoryCount, minAllotment, bookingLimit,
      blackoutStatus, noCheckInStatus, soldCount, discountPercentage, approvedDiscountPercentage, larRateList, grouponRateList}

  getDiscountPercentage: (larRateList, grouponRateList)->
    return null unless larRateList and grouponRateList
    return null if larRateList.length != grouponRateList.length
    larAndGrouponRatePairList = _.zip larRateList, grouponRateList
    discountPercentageList = _.map larAndGrouponRatePairList, ([larRate, grouponRate]) =>
      @calculateDiscountPercentage larRate, grouponRate
    discountPercentageList = _.filter discountPercentageList, (discountPercentage) -> discountPercentage?

    return null if _.isEmpty discountPercentageList
    min = _.min discountPercentageList
    max = _.max discountPercentageList
    hasMultipleValues = min isnt max
    {min, max, hasMultipleValues}

  calculateDiscountPercentage: (larRate, grouponRate)->
    if ratesUtility.isValidValue(grouponRate) and larRate > 0
      discountPercentage = (((larRate - grouponRate)/larRate) * 100).toFixed(3)
      return +(discountPercentage.slice 0, -1)
    else
      return null

  getSoldCountCallBack: () ->
    (hash) ->
      rates = hash.rates
      if rates
        # the soldCount property of rate whose productType is bookingDeal is the source of truth
        rateHash = _.find rates, (rateItem) ->
          rateItem.rate.productType is 'bookingDeal'
        soldCount = rateHash?.soldCount
      return soldCount or undefined

  getApprovedDiscountPercentage: (ratePlanData) ->
    approvedDiscountPercentageList = []

    for hash in ratePlanData
      rates = hash.rates
      return null unless rates?.length

      rateHash = _.find rates, (rateItem) ->
        rateItem.rate.productType is 'bookingDeal'
      approvedDiscountPercentage = rateHash?.rate?.approvedDiscountPercentage
      if approvedDiscountPercentage?
        approvedDiscountPercentageList.push approvedDiscountPercentage

    return null if approvedDiscountPercentageList?.length is 0

    min = _.min(approvedDiscountPercentageList)
    max = _.max(approvedDiscountPercentageList)
    hasMultipleValues = (min isnt max)
    {min, max, hasMultipleValues}

  getFormattedRateCallback: (productType, rateName) ->
    (hash) ->
      rates = hash.rates
      if rates
        rateHash = _.find rates, (rateItem) ->
          rateItem.rate.productType is productType
        rateTypeValue = rateHash?.rate?[rateName]
        if ratesUtility.isValidValue(rateTypeValue)
          formattedValue = rateTypeValue/100
          formattedValue = formattedValue.toFixed(2)
          return formattedValue
        else
          return null

  extractList: (ratePlanData, collectCallback) =>
    list = _.collect ratePlanData, collectCallback
    list = _.filter list, (value) ->
      value isnt undefined
    list

  _isRatePlanInventoryMode: (ratePlanData)->
    ratePlanData?[0]?.productSet?.inventoryManagementMode is Constants.INVENTORY_MANAGEMENT_MODE.RATE_PLAN

  _isInventoryAtRatePlanEnabled: ()->
    Extranet.FeatureFlags?.inventory_at_rate_plan