class Extranet.Presenters.PsRatePlanRowPresenter

  toHash: (ratePlanHash, roomTypeHash, dealOptions, status) ->
    # BD Rate plans will have only one Restriction.
    model = $.extend {}, ratePlanHash
    model.roomTypeName = roomTypeHash?.name
    model.startDate = ratePlanHash?.travelWindow?.startAt?.replace(/-/g,'/')
    model.endDate = ratePlanHash?.travelWindow?.endAt?.replace(/-/g,'/')
    model.ratePlanUrl = @getBdRatePlanUrl ratePlanHash
    model.dealOptions = dealOptions
    model.hasOptions = true unless _.isEmpty dealOptions
    model.accessPermissionSubKey = status
    return model

  getBdRatePlanUrl: (model) ->
    options=
      hotelUuid: model.hotelUuid
      productSetUuid: model.productSetUuid
      ratePlanUuid: model.uuid

    ExtranetUrlBuilder.getBdRatePlanUrl options
