require '../presenters/rate_plan'

class Extranet.Views.RatePlanRow extends Backbone.View
  
  tagName: 'tr'
  className: 'room-info-row'
  templateName: 'profile_rate_plan_row'

  initialize: (options) ->
    @roomModel = options.roomModel
    @ratePlanPresenter = new Extranet.Presenters.RatePlan()
    @hash = @ratePlanPresenter.format options.hash, @roomModel

  render: () ->
    @$el.html TemplateHelper.render @templateName, @hash