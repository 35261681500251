require './views/profile_page'

class Extranet.Routers.Profile extends Backbone.Router

  routes:
    '(:view)': 'navigateToView'

  navigateToView: (view) ->
    view ?= 'basic-info'
    if @profilePageView
      @profilePageView.renderView {view}
    else
      @profilePageView = new Extranet.Views.ProfilePage()
      @profilePageView.render {view}