class Extranet.Presenters.InventoryValidationErrsPresenter
  @helper = Extranet.Helpers.ValidationPresentersHelper
  @defaultPresenter = Extranet.Presenters.DefaultFailurePresenter
  @translationKeys =
    allKeys: 'inventory.inventory_validation_err_presenter'
    objectType: 'inventory.inventory_validation_err_presenter.object_type'
    minDailyAllotmentNotMet: 'inventory.inventory_validation_err_presenter.min_daily_allotment_below_required'
    unmetBookingLimit: 'inventory.inventory_validation_err_presenter.unmet_booking_limit'

  @restrictionTypes =
    minDailyAllotment:
      name: "minDailyAllotment"
      restrictions:
        notNull: "not null && > 0"
    inventoryDefined:
      name: "inventoryDefined"
      restrictions:
        notNull: "not null"
    bookingLimit:
      name: 'bookingLimit'

  @getErrorMessages: ({failedValidation, hotelInfo})->
    errorMessages = []
    validationAttrs = _.pick failedValidation, 'description', 'restrictionType'
    roomType = @helper.getRoomTypeInfo hotelInfo, failedValidation.entityUuid
    failures = _.sortBy failedValidation.failures, (failure) ->
      failure.instance
    messages = "<ul>"
    for failure in failures
      message = "<li>" + @getMessage(failure, validationAttrs) + "</li>"
      messages += message
    messages += "</ul>"

    # yes making it worse I know
    # done as part of 16825
    objectUuid = failedValidation.entityUuid
    objectType = @helper.getTranslations @translationKeys.objectType
    objectName = roomType?.name
    totalViolations = failures.length
    violationName = validationAttrs.description

    # if a violation falls through that we didn't cover, fall back on the old messaging.
    violationHandled = _.has(I18n.translate("js.modules.inventory.templates_monthview_single_violation_summary"), failedValidation.restrictionType)

    if (failedValidation.failures.length > 1) && violationHandled
      messagesHeader = I18n.translate("js.modules.inventory.templates_monthview_single_violation_summary.#{failedValidation.restrictionType}", {totalViolations})
      messages = Extranet.Templates['inventory_templates_monthview_single_violation_summary'].render({messagesHeader: messagesHeader, messages: messages})

    errorMessages.push messages

    {
      messages: errorMessages
      objectUuid: objectUuid
      objectType: objectType
      objectName: objectName
    }

  @getMessage: (failure, validationAttrs) ->
    translations = @helper.getTranslations @translationKeys.allKeys
    message = switch validationAttrs.restrictionType
      when @restrictionTypes.inventoryDefined.name
        date = DateTimeHelper.formatDate failure.instance, 'numeric'
        date + ' ' + translations.invalid_inventory
      when @restrictionTypes.minDailyAllotment.name
        restrictions = @restrictionTypes.minDailyAllotment.restrictions
        date = DateTimeHelper.formatDate failure.instance, 'numeric'
        if failure.restriction is restrictions.notNull
          date + ' ' + translations.invalid_min_allotment
        else if @helper.isInt failure.restriction
          date + ' ' + @helper.getTranslations @translationKeys.minDailyAllotmentNotMet, failure
        else
          translations.description + ': ' + validationAttrs.description + ';' + @defaultPresenter.getMessage failure
      when @restrictionTypes.bookingLimit.name
        date = DateTimeHelper.formatDate failure.instance, 'numeric'
        date + ' ' + @helper.getTranslations @translationKeys.unmetBookingLimit, failure
      else 
        translations.description + ': ' + validationAttrs.description + ';' + @defaultPresenter.getMessage failure
    