class Extranet.Presenters.DefaultValidationPresenter
  @defaultPresenter = Extranet.Presenters.DefaultFailurePresenter
  @translationKeys =
    allKeys: 'inventory.default_validation_presenter'
  @helper = Extranet.Helpers.ValidationPresentersHelper

  @getErrorMessages: ({failedValidation})->
    description = failedValidation.description
    translations = @helper.getTranslations @translationKeys.allKeys
    errorMessages = []
    message = translations.description + ' = ' + description + '; '
    for failure in failedValidation.failures
      message += @defaultPresenter.getMessage failure
      errorMessages.push message

    {
      messages: errorMessages
      objectUuid: failedValidation.entityUuid
      objectType: failedValidation.entityType
    }
