# UrlBuilder.coffee
# ======
#
# Authors:
#   Venkata Harikrishna Nukala
#
# This is a utility class used for constructing the URLs for
# all resources in extranet. This file will be used at
# client side as well as server side.

class ExtranetUrlBuilder
  @_rootUrl:'/extranet'

  @hashToParams: (queryHash) ->
    # Return queryString if param is queryString
    return queryHash if typeof queryHash is "string"
    return "" unless typeof queryHash is "object" and queryHash

    queryKeys = Object.keys(queryHash)
    queryArray = queryKeys.map (queryKey) ->
      "#{encodeURIComponent(queryKey)}=#{encodeURIComponent(queryHash[queryKey])}"
    queryString = queryArray.join('&')

    queryString

  ###
  Administration Page
  ###
  @_adminPage: "#{@_rootUrl}/administration"

  @getAdminTaxesUrl: ()->
    @_adminPage + "/#taxes"

  @getAdminPromotionsSearchUrl: () ->
    @_adminPage + "#promotions_search"

  ###
  Promotions Advanced Search Page
  ###
  @getProductsDownloadUrl: (queryString) ->
    "#{@_rootUrl}/promotions/report_download?#{queryString}"

  ###
  Bookings Page
  ###
  @getBookingsPageUrl: (options)->
    "#{@_rootUrl}/hotels/#{options.hotelUuid}/bookings"

  @getPaymentsPageUrl: (options)->
    "#{@_rootUrl}/hotels/#{options.hotelUuid}/payments"

  @getPaymentDownloadUrl: (options)->
    "#{@_rootUrl}/hotels/#{options.hotelUuid}/payments_report_download?statementIds=#{options.statementUuid}"

  @getBookingsPageUrlWithDates: (options) ->
    "#{@getBookingsPageUrl(options)}#?check_in_begin=#{options.checkInBegin}&check_in_end=#{options.checkInEnd}"

  @getBookingsPageUrlWithRoomType: (options) ->
    "#{@getBookingsPageUrlWithDates(options)}&room_type=#{options.roomTypeUuid}&rate_plan=#{options.ratePlanUuid}"

  @getBookingsDownloadUrl: (options) ->
    "#{@_rootUrl}/hotels/#{options.hotelUuid}/booking_report_download?check_in_begin=#{options.checkInBegin}&check_in_end=#{options.checkInEnd}"

  ###
  Products: Booking Deal Page
  ###

  @getProductsPageUrl: (options)->
    "#{@_rootUrl}/hotels/#{options.hotelUuid}/products"

  @getBdDetailsFragment: (options)->
    fragment = "product-sets"
    fragment = fragment + "/#{options.productSetUuid}" if options.productSetUuid
    fragment

  @getProductSetRatesFragment: (options)->
    "product-sets/#{options.productSetUuid}/rates"

  @getProductSetUrl: (options)->
    @getProductsPageUrl(options) + "#" + @getBdDetailsFragment(options)

  # RatePlans for Booking Deals
  @getBdRatePlanUrl: (options) ->
    ratePlanUrl = @getProductSetUrl(options) + '/rate-plans'
    ratePlanUrl = ratePlanUrl + '/' + options.ratePlanUuid if options.ratePlanUuid
    ratePlanUrl

  @getPsRatePlanDetailsFragment: (options)->
    ratePlanUrl = @getBdDetailsFragment(options) + '/rate-plans'
    ratePlanUrl = ratePlanUrl + '/' + options.ratePlanUuid if options.ratePlanUuid
    ratePlanUrl

  @getPsStatusChangeApiUrl: (options)->
    {hotelUuid, productSetUuid, qs} = options
    url = "/getaways/extranet/hotels/#{hotelUuid}/product_sets/#{productSetUuid}/status"
    url += "?#{qs}" if qs
    url

  @getDeleteRatePlanUrl: (options) ->
    {hotelUuid, ratePlanUuid, roomTypeUuid} = options
    "/extranet/api/hotels/#{hotelUuid}/room_types/#{roomTypeUuid}/rate_plans/#{ratePlanUuid}"

  # Room Mapping page
  @getRoomMappingPageUrl: (options)->
    "#{@_rootUrl}/hotels/#{options.hotelUuid}/room_mapping"

  ###
  Profile Page
  ###

  @getProfilePageUrl: (options)->
    "#{@_rootUrl}/hotels/#{options.hotelUuid}/profile"

  @getHotelRatePlansFragment: ()->
    "rate-plans"

  @getHotelRatePlanDetailUrl: (options= {})->
    @getProfilePageUrl(options) + "#" + @getRatePlanFragment options

  @getHotelChannelManagerUrl: (options= {})->
    @getProfilePageUrl(options) + "#channel-manager"

  ###
  Inventory Page
  ###
  @_inventoryPage: "#{@_rootUrl}/inventory"

  @getInventoryPageUrl: (options)->
    "#{@_rootUrl}/hotels/#{options.hotelUuid}/inventory"

  # TODO: [GETAWAYS-23273] Remove getInventoryDownloadUrl
  @getInventoryDownloadUrl: (options) ->
    url = "#{@_rootUrl}/inventory_report_download"
    url += "?#{@hashToParams(options)}" if options
    url

  @getRatesInventoryDownloadUrl: (options) ->
    url = "#{@_rootUrl}/rates_inventory_report"
    url += "?#{@hashToParams(options)}" if options
    url

  @getRatesInventoryUploadUrl: (options) ->
    "#{@_rootUrl}/rates_inventory_report"

  @getMonthViewUrl: (options)->
    @getInventoryPageUrl(options) + '#monthview'

  @getWeekViewUrl: (options)->
    @getInventoryPageUrl(options) + '#weekview'

  @getUploadInventoryUrl: (options)->
    # if get params are there, update them
    url = "/v2/getaways/extranet/hotels/#{options.hotelUuid}/room_types/#{options.roomTypeUuid}/inventory"
    url = url + "?productSetUuid=#{options.qs.productSetUuid}" if options?.qs
    url

  @getUploadRatesUrl: (options) ->
    if options.roomTypeUuid
      "/v2/getaways/extranet/hotels/#{options.hotelUuid}/room_types/#{options.roomTypeUuid}/rate_plans/#{options.ratePlanUuid}/rates"
    else if options.productSetUuid
      "/getaways/extranet/hotels/#{options.hotelUuid}/product_sets/#{options.productSetUuid}/rate_plans/#{options.ratePlanUuid}/rates"

  @getUploadRatePlanInventoryUrl: (options) ->
    url = "/v2/getaways/extranet/hotels/#{options.hotelUuid}/room_types/#{options.roomTypeUuid}/rate_plans/#{options.ratePlanUuid}/inventory"
    url += "?#{@hashToParams(options.queryParams)}" if options?.queryParams
    url

  @getRatePlanFragment: (options= {})->
    roomTypeUuid = options.roomTypeUuid
    ratePlanUuid = options.ratePlanUuid

    fragment = "room_types/#{roomTypeUuid}/rate_plans"
    fragment = fragment + "/#{ratePlanUuid}" if ratePlanUuid
    fragment

  # Advanced Search Page
  @getHotelSearchDownloadUrl: (options) ->
    "#{@_rootUrl}/inventory_report_download?advancedSearchParameters=#{options.advancedSearchParameters}&offset=#{options.offset}&check_in_begin=#{options.check_in_begin}&check_in_end=#{options.check_in_end}&productType=#{options.productType}"

  @ql2UploadUrl: (options)->
    {hotelUuid, productSetUuid} = options
    "/extranet/hotels/#{hotelUuid}/product_sets/#{productSetUuid}/uploadql2"

  # TODO: [GETAWAYS-23273] Remove getProductSetRatesUploadUrl
  @getProductSetRatesUploadUrl: ()->
    '/getaways/extranet/upload_deal_rates'

  @getUsers: (options) ->
    "/getaways/extranet/users?searchKeyword=#{options.searchKeyword}"

  ###
  My Hotels Url
  ###
  @getMyHotelsUrl: (options)->
    role = options?.role
    if role is 'admin'
      # for admin
      return "#{@_adminPage}/hotel_search"
    else
      # for hotelier
      return "#{@_rootUrl}/hotels"

# exporting this class to work on both
# client side as well as server side
module.exports = ExtranetUrlBuilder
window.ExtranetUrlBuilder = ExtranetUrlBuilder if window?
