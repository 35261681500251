class Extranet.Collections.Rooms extends Backbone.Collection
  model: Extranet.Models.Room

  url: () ->
    "/v2/getaways/extranet/hotels/#{@hotelUuid}/room_types"

  initialize: (options)->
    @hotelUuid  = options.hotelUuid

  setHotelUuid: (hotel_uuid) ->
    @hotelUuid = hotel_uuid
  
  getLarRooms: () ->
    larRooms = this.filter (roomModel)->
      roomModel.attributes?.name?.slice(-1) is '*'
    _.map larRooms, (larRoomModel) ->
      larRoomModel.toJSON()
