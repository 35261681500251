require './advance_purchase_window_helper'

class Extranet.Presenters.PsRatePlanPresenter

  MIN_LENGTH_OF_STAY = "min_length_of_stay"
  MAX_LENGTH_OF_STAY = "max_length_of_stay"

  constructor: (templateName, productSetModel) ->
    @productSetModel = productSetModel
    @advancePurchaseHelper = Extranet.Presenters.AdvancePurchaseWindowHelper
    {@accessPermissions} = Extranet.Common.Utilities.AutoTemplate.getTemplateInfo templateName
    @accessPermissions = @accessPermissions?[productSetModel.get('status')]
    @permissions = new Extranet.Common.Permissions
    @utility = new Extranet.Common.Utilities.Utility

  toHash: (ratePlanModel) ->
    productSet = @productSetModel?.toJSON()
    ratePlan = ratePlanModel?.toJSON()
    maxLengthOfStayList = @prepareLengthOfStay ratePlan, productSet, MAX_LENGTH_OF_STAY
    minLengthOfStayList = @prepareLengthOfStay ratePlan, productSet, MIN_LENGTH_OF_STAY
    deepCopy = true
    ratePlanHash = $.extend deepCopy, {}, ratePlan
    ratePlanRestriction = ratePlanHash?.restrictions?[0]
    productSetRestriction = productSet?.restriction

    advancePurchaseWindow = @advancePurchaseHelper.getAdvancePurchaseWindow ratePlanRestriction
    # Compute restrictions from productset if not present in ratePlan
    ratePlanHash.minAverageDiscountPercentage = ratePlanRestriction?.minAverageDiscountPercentage or productSetRestriction?.minAverageDiscountPercentage
    ratePlanHash.minDailyDiscountPercentage = ratePlanRestriction?.minDailyDiscountPercentage or productSetRestriction?.minDailyDiscountPercentage
    ratePlanHash.minDailyAllotment = ratePlanRestriction?.minDailyAllotment or productSetRestriction?.minDailyAllotment
    ratePlanHash.maxBlackOutDatesTotal = ratePlanRestriction?.maxBlackOutDates?.max or productSetRestriction?.maxBlackOutDates?.max
    ratePlanHash.marginPercentage = ratePlanHash?.marginPercentage or productSet?.marginPercentage
    ratePlanHash.cancellationBuffer = ratePlanHash?.cancellationBuffer or productSet?.cancellationBuffer
    ratePlanHash.useNetRateForBookings = ratePlanHash?.useNetRateForBookings or productSet?.useNetRateForBookings
    ratePlanHash.isNetRate = ratePlanHash?.isNetRate or productSet?.isNetRate
      

    if ratePlanHash?.applyCreditCardFee is null
      ratePlanHash.applyCreditCardFee = productSet?.applyCreditCardFee

    unless ratePlan
      return {model: ratePlanHash, maxLengthOfStayList, minLengthOfStayList, advancePurchaseWindow}

    ratePlanHash.startDate = DateTimeHelper.formatDate ratePlanRestriction?.travelWindow?.startAt, 'numeric'
    ratePlanHash.endDate = DateTimeHelper.formatDate ratePlanRestriction?.travelWindow?.endAt, 'numeric'
    ratePlanHash.maxByDayOfWeek = ratePlanRestriction?.maxBlackOutDates?.maxByDayOfWeek

    ratePlanHash.bonusAdd.isPerNight = ratePlanHash.bonusAdd.type is "per_night" if ratePlanHash.bonusAdd?.type
    ratePlanHash.bonusAdd.isPerStay = ratePlanHash.bonusAdd.type is "per_stay" if ratePlanHash.bonusAdd?.type
    ratePlanHash.bonusAdd.value.amount = MathHelper.divide ratePlanHash.bonusAdd.value.amount, 100 if ratePlanHash.bonusAdd?.value?.amount
    {model: ratePlanHash, maxLengthOfStayList, minLengthOfStayList, advancePurchaseWindow}

  prepareLengthOfStay: (ratePlan, productSet, type) ->
    lengthOfStayList = []
    minimum = 1
    MIN_LENGTH_OF_STAY_MAX_VAL = 14
    MAX_LENGTH_OF_STAY_MAX_VAL = 29
    if type is MAX_LENGTH_OF_STAY
      text= @utility.translate "products.templates_ps_rate_plan_details.max"
      selectedValue = parseInt ratePlan?.restrictions?[0]?.maxLengthOfStay
      selectedValue = parseInt(productSet?.restriction?.maxLengthOfStay) if isNaN(selectedValue)
    else
      text= @utility.translate "products.templates_ps_rate_plan_details.min"
      selectedValue = parseInt(ratePlan?.restrictions?[0]?.minLengthOfStay or productSet?.restriction?.minLengthOfStay)

    for i in [minimum .. MIN_LENGTH_OF_STAY_MAX_VAL]
      lengthHash =
        value: i
        text: "#{text} #{i} nights"
        isSelected: i is selectedValue
      lengthOfStayList.push lengthHash
    if type is MAX_LENGTH_OF_STAY #Limit is 29 for Max length of stay
      for i in [MIN_LENGTH_OF_STAY_MAX_VAL+1 .. MAX_LENGTH_OF_STAY_MAX_VAL]
        lengthHash =
          value: i
          text: "#{text} #{i} nights"
          isSelected: i is selectedValue

        lengthOfStayList.push lengthHash
      # Any Length of Stay
      selectAnyLengthOfStay =
        value: 30
        text: @utility.translate "products.templates_ps_rate_plan_details.any"
        isSelected: selectedValue >= 30 or selectedValue is 0

      lengthOfStayList.push selectAnyLengthOfStay

    return lengthOfStayList

  getHashToSave: (view) ->
    saveAttrs = {}
    saveAttrs.roomTypeUuid = view.$(".room-type").val()
    saveAttrs.activeStatus = "active"
    saveAttrs.name = view.$('#name').val() if @canEdit("name")
    saveAttrs.internalName = view.$('#internalName').val() if @canEdit("internalName")
    saveAttrs.maxAdults = view.$('#maxAdults').val() if view.$('#maxAdults').val()
    saveAttrs.maxChildren = view.$('#maxChildren').val() if view.$('#maxChildren').val()

    productSet = @productSetModel?.toJSON()
    rateThresholdEnabled = productSet?.livePricingRule is "dailyDiscount"

    if @canEdit("margin") and view.dataChanged["marginPercentage"]
      saveAttrs.marginPercentage = +view.$('#marginPercentage').val() if view.$('#marginPercentage').val()

    if @canEdit("bonusAdd")
      saveAttrs.bonusAdd = @getBonusAdd(view)

    if @canEdit("cc_fee") and view.dataChanged["applyCcFee"]
      saveAttrs.applyCreditCardFee = true if view.$('.radio #yes').is(':checked')
      saveAttrs.applyCreditCardFee = false if view.$('.radio #no').is(':checked')
    
    if @canEdit("use_net_rate") and view.dataChanged["useNetRate"]
      saveAttrs.useNetRateForBookings = true if view.$('.radio #yes').is(':checked')
      saveAttrs.useNetRateForBookings = false if view.$('.radio #no').is(':checked')
    
    if @canEdit("is_net_rate") and view.dataChanged["isNetRate"]
      saveAttrs.isNetRate = true if view.$('.radio #yes').is(':checked')
      saveAttrs.isNetRate = false if view.$('.radio #no').is(':checked')  
        
    saveAttrs.restrictions = @getRestrictions view
    if @canEdit("externalOptionId")
      saveAttrs.externalOptionId = view.$('#external-option-id').val()?.trim() or null
    if @canEdit "cancellation_window"
      saveAttrs.cancellationBuffer = if view.$('#cancellation-window').val() then parseInt view.$('#cancellation-window').val() else null
      if view.$("#cancellation-options").val() is 'days' and saveAttrs.cancellationBuffer
        saveAttrs.cancellationBuffer = saveAttrs.cancellationBuffer*24
    if @canEdit("rateThreshold") and rateThresholdEnabled
      saveAttrs.rateThreshold = if view.$('#rate-threshold').val() then parseFloat view.$('#rate-threshold').val() else null
    #CSRF token
    saveAttrs._csrf = $('#csrf_token').val()
    saveAttrs

  getRestrictions: (view) ->

    hash = {}
    if @canEdit("travelStart") and @canEdit("travelEnd")
      hash.travelWindow = {}
      hash.travelWindow.startAt = DateTimeHelper.formatDate view.$("#startDate").val(), 'api' if @canEdit("travelStart")?
      hash.travelWindow.endAt = DateTimeHelper.formatDate view.$("#endDate").val(), 'api' if @canEdit("travelEnd")?
    hash.productType = 'bookingDeal'
    if @canEdit("minLengthOfStay") and view.$("#minLengthOfStay").val()
      hash.minLengthOfStay = +view.$("#minLengthOfStay").val()
    if @canEdit("maxLengthOfStay") and view.dataChanged["maxLengthOfStay"]
      maxLengthOfStayVal = view.$("#maxLengthOfStay").val()
      hash.maxLengthOfStay = parseInt(maxLengthOfStayVal) or null
    if @canEdit("minimumAveragePercent") and view.dataChanged["minimum-average-percentage"] and view.$('#minimum-average-percentage').val()
      hash.minAverageDiscountPercentage = +view.$('#minimum-average-percentage').val()
    if @canEdit("minimumPercent") and view.dataChanged["minimum-percentage"] and view.$('#minimum-percentage').val()
      hash.minDailyDiscountPercentage = +view.$('#minimum-percentage').val()
    if @canEdit("minimumDailyAllotment") and view.dataChanged["default-daily-allotment"] and  view.$("#default-daily-allotment").val()
      hash.minDailyAllotment = +view.$("#default-daily-allotment").val()

    if @canEdit("advancePurchaseWindow") and view.dataChanged["advancePurchaseWindow"]
      advancePurchaseEndVal = view.$("#advancePurchaseWindow").val()
      hash.advancePurchaseEnd = if advancePurchaseEndVal then +advancePurchaseEndVal else null
    if @canEdit "maxBlackOutDays"
      hash.maxBlackOutDates ={}
      hash.maxBlackOutDates.max = +view.$("#min-per-deal").val() if @canEdit("maxBlackOutDays") and view.dataChanged["min-per-deal"] and view.$("#min-per-deal").val()

    if @canEdit "maxBlackOutByDayOfWeek"
      unless hash.maxBlackOutDates?
        hash.maxBlackOutDates = {}
      hash.maxBlackOutDates.maxByDayOfWeek = {}
      hash.maxBlackOutDates.maxByDayOfWeek.sunday = +view.$("#sun").val() if view.$("#sun").val()
      hash.maxBlackOutDates.maxByDayOfWeek.monday = +view.$("#mon").val() if view.$("#mon").val()
      hash.maxBlackOutDates.maxByDayOfWeek.tuesday = +view.$("#tue").val() if view.$("#tue").val()
      hash.maxBlackOutDates.maxByDayOfWeek.wednesday = +view.$("#wed").val() if view.$("#wed").val()
      hash.maxBlackOutDates.maxByDayOfWeek.thursday = +view.$("#thu").val() if view.$("#thu").val()
      hash.maxBlackOutDates.maxByDayOfWeek.friday = +view.$("#fri").val() if view.$("#fri").val()
      hash.maxBlackOutDates.maxByDayOfWeek.saturday = +view.$("#sat").val() if view.$("#sat").val()

    [hash]

  getBonusAdd: (view) ->
    amount = +view.$('#bonus-value').val() if view.$('#bonus-value').val()
    currencyCode = "USD"
    hash =
      name : view.$('#bonus-name').val()
      type : view.$('#bonus-type').val()
      value:
        currencyCode: currencyCode
        formattedAmount: I18n.toCurrency amount, currencyCode
        amount: amount*100 #Sending amount in cents to save
    if not hash.name or not hash.type or not amount
      return null
    hash

  canEdit: (fieldName) ->
    @permissions.canEdit(@accessPermissions, fieldName)
