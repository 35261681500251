class Extranet.Presenters.StateTransitionErrsPresenter
  @invalidTransitionKey = 'inventory.state_transition_error_presenter.invalid_transition'
  @objectTypeKey = 'inventory.state_transition_error_presenter.object_type'
  @helper = Extranet.Helpers.ValidationPresentersHelper

  @getErrorMessages: ({failedValidation, newState, hotelInfo})->
    productSetInfo = @helper.getProductSetInfo hotelInfo, failedValidation.entityUuid
    fromState = failedValidation.failures[0]?.actual # There will be only one failure
    fromState = fromState.toUpperCase()
    toState = newState.name.toUpperCase()
    message =
      @helper.getTranslations @invalidTransitionKey, {fromState, toState}

    {
      messages: [message]
      objectUuid: failedValidation.entityUuid
      objectType: @helper.getTranslations @objectTypeKey
      objectName: productSetInfo?.name
    }
