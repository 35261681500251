class Extranet.Views.AdminHeaderView extends Backbone.View
  el: '#admin-header'

  events:
    'click li': 'handleTabClick'

  templateName: 'admin_header'

  initialize: (flags={}) ->
    @render()

  render: ()->
    @$el.html Extranet.Templates[@templateName].render {}

  handleTabClick: (e)->
    currentTabId = $(e.currentTarget).attr 'id'
    @selectTab currentTabId

  selectTab: (tabId)->
    @$el.find('li').removeClass 'active'
    @$el.find("li##{tabId}").addClass 'active'

  selectDashboardTab: ()->
    @selectTab 'tab_dashboard'

  selectTaxesTab: ()->
    @selectTab 'tab_taxes'

  selectHotelSearchTab: ()->
    @selectTab 'tab_hotel_search'
