class Extranet.Presenters.HotelPresenter

  constructor: ( model ) ->
    @model = model

  toHash: () ->
    name: @name()
    city: @city()
    country: @country()
    stars: @stars()
    email: @email()
    phoneNumber: @phoneNumber()
    uuid: @uuid()
    profilePageUrl: @profilePageUrl()

  profilePageUrl: ()->
    ExtranetUrlBuilder.getProfilePageUrl({hotelUuid: @model.get("uuid")})

  uuid: () ->
    @model.get "uuid"

  name: () ->
    @model.get "name"

  city: () ->
    hotelAddress = @model.get "hotelAddress"
    hotelAddress?.city?.name

  country: () ->
    hotelAddress = @model.get "hotelAddress"
    hotelAddress?.country?.name

  stars: () ->
    starRatings = @model.get "starRatings"
    starRatings?[0]?.rating

  email: () ->
    # contacts = @model.get "contacts"
    # contacts?[0]?.email
    @model.get 'email'


  phoneNumber: () ->
    # contacts = @model.get "contacts"
    # contacts?[0]?.phoneNumber
    phoneNumbers = @model.get 'phoneNumbers'
    phoneNumbers?[0]?.number

  grouponId: () ->
    grouponMerchant = @model.get 'grouponMerchant'
    grouponMerchant?.join ", "

  values: () ->
    [
      @name()
      ''
      @city()
      @country()
      @stars()
      @email()
      @phoneNumber()
    ]
