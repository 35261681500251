require './basic_info'
require './description'
require './room_info'
require './rate_plans'
require './contacts'
require './taxes'
require './channel_manager'

class Extranet.Views.ProfilePage extends Backbone.View

  selectors:
    profileNavLink: (view) -> ".sidenav a[href='#{view}']"
    activeLink: '.active a'
    activeStatus: '#active-status'
    alertMessage: '.alert-message'
    basicInfoView: '#basic-info'
    descriptionView: '#description'
    contactsView: '#contacts'
    roomInfoView: '#room-info'
    ratePlansView: '#rate-plans'
    taxesView: '#taxes'
    channelManagerView: '#channel-manager'
    activeView: '.active.view'
  
  events:
    'click #active-status': '_toggleActiveStatus'
  
  el: 'section#page'
  templateName: 'profile_page'
  
  initialize: (options) ->
    @alertView = Extranet.Helper.AlertView.initialize @selectors.alertMessage
    @model ?= new Extranet.Models.Hotel Extranet.ClientConfig.hotelData
  
  render: (options) ->
    channelManagerEnabled = Extranet.ClientConfig.channelManagerEnabled
    @$el.html TemplateHelper.render @templateName, {channelManagerEnabled}
    @_renderActiveStatus()
    @renderView options
  
  renderView: (options) ->
    switch options.view
      when 'basic-info' then @_renderBasicInfo()
      when 'description' then @_renderDescriptionView()
      when 'contacts' then @_renderContactsView()
      when 'room-info' then @_renderRoomInfoView()
      when 'rate-plans' then @_renderRatePlansView()
      when 'taxes' then @_renderTaxesView()
      when 'channel-manager' then @_renderChannelManagerView()
      else
        window.location.hash = ''
  
  _renderActiveStatus: () ->
    isActive = @model?.get('activeStatus') is 'active'
    @$(@selectors.activeStatus).attr 'checked', isActive

  _toggleActiveStatus: () ->
    return unless Extranet.RolePermissions?.hotel?.activeStatus?.edit
    saveAttrs = PermissionsHelper.getEditableDataForClient 'hotel', 
      _csrf: $('#csrf_token').val()
      activeStatus: if @$(@selectors.activeStatus).is(':checked') then 'active' else 'inactive'
    options =
      patch: true
    onFinish = () =>
      @_renderActiveStatus()
    @model.save(saveAttrs, options).always(onFinish)

  _renderBasicInfo: () ->
    @_switchView @selectors.basicInfoView
    unless @basicInfoView
      @basicInfoView = new Extranet.Views.BasicInfo {model: @model}
      @basicInfoView.render()

  _renderDescriptionView: () ->
    @_switchView @selectors.descriptionView
    unless @descriptionView
      @descriptionView = new Extranet.Views.Description {model: @model}
      @descriptionView.render()
  
  _renderContactsView: () ->
    @_switchView @selectors.contactsView
    unless @contactsView
      @contactsView = new Extranet.Views.Contacts {model: @model}
      @contactsView.render()
  
  _renderRoomInfoView: () ->
    @_switchView @selectors.roomInfoView
    unless @roomInfoView
      @roomInfoView = new Extranet.Views.RoomInfo {model: @model}
      @roomInfoView.render()

  _renderRatePlansView: () ->
    @_switchView @selectors.ratePlansView
    unless @ratePlansView
      @ratePlansView = new Extranet.Views.RatePlans {model: @model}
      @ratePlansView.render()

  _renderTaxesView: () ->
    @_switchView @selectors.taxesView
    unless @taxesView
      @taxesView = new Extranet.Views.Taxes {model: @model}
      @taxesView.render()

  _renderChannelManagerView: () ->
    unless Extranet.RolePermissions.profile?.channel_manager?.view
      window.location.hash = ''
      return

    @_switchView @selectors.channelManagerView
    unless @channelManagerView
      @channelManagerView = new Extranet.Views.ChannelManager {hotelModel: @model}
      @channelManagerView.render()
    
  _switchView: (view) ->
    @alertView.hide()
    @_deselectLink @selectors.activeLink
    @_deselectView @selectors.activeView
    @_selectLink @selectors.profileNavLink view
    @_selectView view
  
  _deselectLink: (link) ->
    @$(link)?.parent()?.removeClass 'active'

  _selectLink: (link) ->
    @$(link)?.parent()?.addClass 'active'
  
  _deselectView: (view) ->
    @$(view)?.addClass 'hide'
    @$(view)?.removeClass 'active'
  
  _selectView: (view) ->
    @$(view)?.removeClass 'hide'
    @$(view)?.addClass 'active'