class Extranet.Helpers.ValidationPresentersHelper
  @utility = new Extranet.Common.Utilities.Utility

  @getTranslations: (key, hash)->
    @utility.translate key, hash

  # Below method uses hotelInfo object of
  @getRoomTypeInfo: (hotelInfo, uuid)->
    hash = _.find hotelInfo, (hotelHash)->
      hotelHash.roomType.uuid is uuid or hotelHash.ratePlan.uuid is uuid

    hash?.roomType

  @getRatePlanInfo: (hotelInfo, ratePlanUuid)->
    hash = _.find hotelInfo, (hotelHash)->
      hotelHash.ratePlan.uuid is ratePlanUuid

    hash?.ratePlan

  @getProductSetInfo: (hotelInfo, productSetUuid)->
    hash = _.find hotelInfo, (hotelHash)->
      hotelHash.productSet.uuid is productSetUuid

    hash?.productSet

  @isInt: (n)->
    typeof(n) is "number" and isFinite(n) and n%1 is 0

  @isNumber: (n)->
    not isNaN(parseFloat(n)) and isFinite(n)
