require "../../../../inventory_common/frontend/rates_utility"
require "../../presenters/lm_rateplan_summary_presenter"
require "../rate_plan/rate_plan_readonly_view"

class Extranet.Views.LmRatePlanSummaryView extends Backbone.View

  Extranet.Common.Utilities.Mixin.extends this, Extranet.Helpers.ChildManager

  eventManagerKey: Extranet.Helpers.EventManagerHelper.SETTINGS_KEY

  selectors:
    grouponRateField: '.groupon-rate-field'
    netRateField: '.net-rate-field'
    blackoutStatus: '.blackout-status'
    blackoutText: '.blackout-text'
    grouponRate: '.groupon-rate'
    netRate: '.net-rate'
    baseRate: '.base-rate'
    ratesSection: '.rates-section'
    ratesInputFields: '.input-rates'
    discount: '.discount'
    modal: (ratePlanUuid) ->
      "#ratePlanPopup-#{ratePlanUuid} .modal-body"

  templateName: 'inventory_templates_monthview_lm_rateplan_summary'

  events:
    'change .net-rate': 'updateDiscountAndGrouponRate'
    'change .discount': 'updateNetAndGrouponRate'
    'change .groupon-rate': 'updateDiscountAndNetRate'
    'change .base-rate': 'updateDiscount'
    'click .blackout-status': 'toggleBlackoutStatus'
    'click .rateplan-name': 'renderRatePlanPopup'

  initialize: (options) ->
    {@ratePlanData, @isReadonly} = options
    @ratesUtility = Extranet.Inventory.RatesUtility
    firstDay = _.min @ratePlanData, (ratePlan) ->
      return moment ratePlan.date
    lastDay = _.max @ratePlanData, (ratePlan) ->
      return moment ratePlan.date

    @settingsHelper = Extranet.Settings
    # this are the numeral of the day, ie 18 for 2016-02-18
    # 17719 - logic is duplicated somewhere in weekview
    @firstDate = firstDay.date
    @lastDate = lastDay.date
    @marginPercentage = @ratePlanData[0]?.marginPercentage or 0
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    @apiClient = new Extranet.Inventory.Utilities.InventoryApiClient()
    @presenter = new Extranet.Presenters.LmRatePlanSummaryPresenter
    @rateSettingsView = Extranet.Helpers.RateSettings.getRateSettingsView()
    @settingsEventManagerView = Extranet.Helpers.EventManagerHelper.getEventManagerView(@eventManagerKey)
    @listenTo @settingsEventManagerView, 'grouponRate', @showGrouponRate
    @listenTo @settingsEventManagerView, 'netRate', @showNetRate

  addValidationFailures: ->
    # Validation failures can be added if needed

  updateDiscountAndGrouponRate: (e) ->
    @dataChanged.rate = true
    @updateGrouponRateFromNetRate()
    @updateDiscount(e)

  updateDiscountAndNetRate: (e) ->
    @dataChanged.rate = true
    @updateDiscount(e)
    @updateNetRate()

  updateNetAndGrouponRate: (e) ->
    @dataChanged.rate = true
    @updateGrouponRate()
    @updateNetRate()

  updateGrouponRateFromNetRate: () ->
    netRate = parseFloat @$(@selectors.netRate).val()
    grouponRate = ""
    unless isNaN netRate
      grouponRate = Extranet.divide(netRate,(1-Extranet.divide(@marginPercentage,100)))
      grouponRate = "" if isNaN(grouponRate)
      grouponRate = grouponRate.toFixed(2)
    +@$(@selectors.grouponRate).val grouponRate

  changetoFixedValue: (element)->
    element.val @ratesUtility.fixedValue(element.val())

  getGrouponRate: () ->
    baseRate =  parseFloat @$(@selectors.baseRate).val()
    discountPercentage = parseFloat @$(@selectors.discount).val()
    return if isNaN(baseRate) or isNaN(discountPercentage)
    grouponRate = baseRate * (1 - Extranet.divide(discountPercentage,100))
    grouponRate.toFixed(2)

  updateGrouponRate: () ->
    grouponRate = @getGrouponRate()
    grouponRate = "" if isNaN(grouponRate)
    @$(@selectors.grouponRate).val grouponRate

  updateNetRate: () ->
    grouponRate = @getGrouponRate()
    netRate = @presenter.computeNetRate grouponRate, @marginPercentage
    if isNaN(netRate)
      netRate= ""
    else
      netRate= netRate.toFixed(2)
    @$(@selectors.netRate).val netRate

  updateDiscount: (e) ->
    @dataChanged.rate = true
    @changetoFixedValue $(e.currentTarget)
    grouponRate = parseFloat @$(@selectors.grouponRate).val()
    baseRate = parseFloat @$(@selectors.baseRate).val()
    discountPercentage = MathHelper.calculatePercentage(baseRate, grouponRate)
    if isNaN(discountPercentage)
      discountPercentage = ""
    @$(@selectors.discount).val(discountPercentage)

  render: () ->
    rateTypeToShowFunctionMap =
      grouponRate: @showGrouponRate
      netRate: @showNetRate
    @resetChangedFlags()
    hash = @presenter.toHash {@ratePlanData, @firstDate, @lastDate}
    @$el.html @autoTemplate.render @templateName, _.extend hash, {@isReadonly, accessPermissionSubKey: 'lastMinute'}
    usedRatePlan = @settingsHelper.getRateType()
    rateTypeToShowFunctionMap[usedRatePlan]()
    this

  resetChangedFlags: () ->
    @dataChanged =
      rate: false
      blackout: false

  toggleBlackoutStatus: (e) ->
    return if @isReadonly or @$(@selectors.blackoutStatus).hasClass('disabled')
    @dataChanged.blackout = true
    blackoutText = I18n.translate('js.modules.inventory.blackout')
    @$(@selectors.blackoutText).text(blackoutText)
    if @$(@selectors.blackoutStatus).hasClass('blackout') or @$(@selectors.blackoutStatus).hasClass('partial')
      @$(@selectors.blackoutStatus).removeClass('partial blackout')
      @$(@selectors.ratesSection).slideDown(Extranet.Constants.BLACKOUT_TOGGLE_DURATION)
    else
      @$(@selectors.blackoutStatus).removeClass('partial none')
      @$(@selectors.blackoutStatus).addClass('blackout')
      @$(@selectors.ratesSection).slideUp(Extranet.Constants.BLACKOUT_TOGGLE_DURATION)

  showNetRate: =>
    @$(@selectors.grouponRateField).addClass('hide')
    @$(@selectors.netRateField).removeClass('hide')

  showGrouponRate: =>
    @$(@selectors.grouponRateField).removeClass('hide')
    @$(@selectors.netRateField).addClass('hide')

  renderRatePlanPopup: (e) ->
    unless @ratePlanReadOnlyView
      @ratePlanReadOnlyView = @addChild new Extranet.Views.RatePlanReadOnlyView {
        @ratePlanData
        el: @selectors.modal @ratePlanData?[0]?.ratePlan?.uuid
      }
    @ratePlanReadOnlyView.render()

  getRates: (hash) ->
    baseRate =
      date: hash.date
      type: 'base'
      rate: @ratesUtility.getRateValue(@$el.find(@selectors.baseRate).val())

    grouponRate =
      date: hash.date
      type: 'lastMinute'
      rate: @ratesUtility.getRateValue(@$el.find(@selectors.grouponRate).val())

    rates = []
    rates.push(baseRate)
    rates.push(grouponRate)
    rates

  getRestrictions: (hash) ->
    restrictions =
      date: hash.date
      closedToArrival: false
      blackout: @getBlackoutToSave hash.restrictions
      closedOut: false
      noLastNight: false
    restrictions

  getBlackoutToSave: (restrictions) ->

    blackoutValue = if @$(@selectors.blackoutStatus).hasClass('blackout') then 'black'
    else 'white'

    return blackoutValue if @dataChanged.blackout
    blackoutSource = restrictions?.blackoutSource   # if initially no restrictions, none is saved

    return if blackoutSource is 'dailyRate' then restrictions?.blackout else 'none'

  isChanged: ->
    for key of @dataChanged
      if @dataChanged[key]
        return true
    return false

  save: (requestIdPrefix) ->
    return unless @isChanged()

    saveHash =
      rates: []
      restrictions: []
      _csrf: $('#csrf_token').val()

    _.each @ratePlanData, (hash) =>
      rates = @getRates hash
      restrictions = @getRestrictions hash
      saveHash.rates = saveHash.rates.concat rates
      saveHash.restrictions = saveHash.restrictions.concat restrictions

    urlParams =
      hotelUuid: @ratePlanData?[0]?.hotel?.uuid
      ratePlanUuid: @ratePlanData?[0]?.ratePlan?.uuid
      roomTypeUuid: @ratePlanData?[0]?.roomType?.uuid

    url = ExtranetUrlBuilder.getUploadRatesUrl(urlParams)

    headers = {'request-id-prefix': requestIdPrefix}
    callback = ()->
      # noop

    @apiClient.save(url, saveHash, headers, callback)
