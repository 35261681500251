class Extranet.Views.HotelListTable extends Backbone.View

  render: (models, presenter) ->
    html = ""
    for model in models
      context = new presenter(model)
      html += Mustache.to_html Extranet.Templates.hotel_search_templates_search_results.text, context.toHash()

    tbody = $(@el).find('tbody')
    tbody.empty()
    tbody.html html