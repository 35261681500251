class Extranet.Helper.Inventory

  @getTotalSoldCount: (inventoryData)->
    totalSoldCount = 0
    _.each inventoryData, (item) ->

      totalSoldCount += item?.soldCount or 0
    totalSoldCount

  @getTotalInventoryCount: (inventoryData)->
    filteredInventoryData = _.reject inventoryData, (item)->
      item?.restrictions?.blackout is 'black'

    unless filteredInventoryData?.length
      return 0

    inventoryManagementMode = filteredInventoryData[0].productSet?.inventoryManagementMode
    if inventoryManagementMode is Constants.INVENTORY_MANAGEMENT_MODE.RATE_PLAN
      @_getRatePlanTotalInventoryCount(filteredInventoryData)
    else
      @_getRoomTypeTotalInventoryCount(filteredInventoryData)

  @_getRatePlanTotalInventoryCount: (inventoryData)->
    totalInventoryCount = 0
    _.each inventoryData, (item) ->
      totalInventoryCount += item?.ratePlanInventory?.inventoryCount or 0
    totalInventoryCount

  # There will be one date cell for each rate plan and for each date
  # For example, if there are two rate plans under a room type
  # and the user selected three days on the calendar
  # Then we will have totally 6 date cells (3 days x 2 rate plans)
  # Even if there are two rate plans under a room type we should consider only one date dell
  # As the inventory count of the room type is copied to two date cells for its rate plans
  @_getRoomTypeTotalInventoryCount: (inventoryData)->
    totalInventoryCount = 0
    inventoryDataByRoomType = _.groupBy inventoryData, (item) -> item.roomType?.uuid
    _.each inventoryDataByRoomType, (list) ->
      roomTypeDataByDate = _.groupBy list, (item) -> item.date
      _.each roomTypeDataByDate, (item)->
        totalInventoryCount += item?[0]?.roomTypeInventory?.inventoryCount or 0
    totalInventoryCount
