class Extranet.Models.Room extends Extranet.Model

  idAttribute: 'uuid'
  defaults:
    languageCode: 'en_US'
    activeStatus: 'active'

  urlRoot: () ->
    if not @hotelUuid
      return @collection?.url()
    "/v2/getaways/extranet/hotels/#{@hotelUuid}/room_types"

  initialize: (options={})->
    @hotelUuid  = options.hotelUuid
    @id = options.roomTypeUuid if options.roomTypeUuid

  _ratePlans: []

  getRatePlans: ()->
    @_ratePlans

  setRatePlans: (ratePlans)->
    @_ratePlans = ratePlans

  parse: (response, options)->
    @setRatePlans response.ratePlans if response.ratePlans
    _.omit response, 'ratePlans'
