class Extranet.Presenters.WeekViewRateCellPresenter
  utility = Extranet.Common.Utilities.Utility

  getRateCell: (dateCell={})->
    rateCell = _.clone dateCell

    rateCell.blackout = rateCell.restrictions?.blackout is 'black'
    rateCell.closedOut = rateCell.restrictions?.closedOut
    rateCell.stayOnly = rateCell.restrictions?.closedToArrival
    rateCell.isAdmin = utility.isAdmin()
    rateCell.disableBaseRate = utility.shouldDisableBaseRate()

    rateCell.disabled = rateCell.dateOutsideTravelWindow

    rateCell.isLastMinute = rateCell.ratePlan?.productType is 'lastMinute'

    rateCell.soldCount = rateCell.soldCount or 0
    rateCell.soldOut = rateCell.inventory?.inventoryCount is 0 and rateCell.soldCount > 0

    currencyCode = rateCell.hotel?.currencyCode
    rateCell.currencySymbol = utility.getCurrencySymbol(currencyCode) if currencyCode

    if rateCell.rates?.length
      for rateObj in rateCell.rates

        amount = rateObj.rate?.amount or 0
        netRate = rateObj.rate?.netRate or 0
        discountPercentage = rateObj.rate?.discountPercentage
        ql2Price = rateObj.rate?.ql2Price or 0

        if rateObj.rate?.productType is 'base'
          rateCell.baseRate = (amount / 100).toFixed(2)
          rateCell.larRate = (ql2Price / 100).toFixed(2)
          if (not rateCell.isLastMinute) and (amount is 0)
            rateCell.baseRate = ""
          if (not rateCell.isLastMinute) and (ql2Price is 0)
            rateCell.larRate = ""
        else
          rateCell.grouponRate = (amount / 100).toFixed(2)
          rateCell.netRate = (netRate / 100).toFixed(2)
          rateCell.percentage = discountPercentage
          if (not rateCell.isLastMinute) and (amount is 0)
            rateCell.grouponRate = ""
          if (not rateCell.isLastMinute) and (netRate is 0)
            rateCell.netRate = ""

    rateCell
