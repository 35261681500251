require '../presenters/room_details'

class Extranet.Views.RoomDetails extends Backbone.View

  selectors:
    errorMessage: '.error-message'
    roomDetailsModal: '.room-details-modal'
    actionButtons: '.action-buttons button'

  events:
    'click #save-room-button': '_save'
    'click #cancel-save-button': '_closeModal'
    'keyup input': '_handleDataChange'
    'keyup textarea': '_handleDataChange'

  templateName: 'profile_room_details'

  initialize: (options) ->
    @alertMessage = Extranet.Helper.AlertView.getAlertView()
    @errorMessage = new Extranet.Views.AlertMessage {selector: @selectors.errorMessage}
    @presenter = new Extranet.Presenters.RoomDetails()
    @hash = @presenter.toHash @model
  
  render: () ->
    @$el.html TemplateHelper.render @templateName, @hash
    @$(@selectors.roomDetailsModal).modal 'show'
    @$(@selectors.roomDetailsModal).parsley Extranet.ParsleyConfig

  _save: (e) ->
    e.preventDefault()
    return unless Extranet.RolePermissions?.roomType?.edit
    unless @$(@selectors.roomDetailsModal).parsley 'validate'
      @errorMessage.renderDefaultValidationError()
      return
    isNewRoom = @model.isNew()
    saveAttributes = @presenter.getHashToSave this
    options =
      wait: true
      patch: true
    onSuccess = (data, textStatus, jqXHR) =>
      @_closeModal()
      if isNewRoom
        @collection.add @model if isNewRoom
        message = I18n.translate 'js.modules.common.alerts.success.room_created'
      else
        message = I18n.translate 'js.modules.common.alerts.success.room_updated'
      requestId = jqXHR?.getResponseHeader 'x-request-id'
      @alertMessage.renderSuccess message, requestId
    onFailure = (jqXHR, textStatus, errorThrown) =>
      requestId = jqXHR?.getResponseHeader 'x-request-id'
      @errorMessage.renderDefaultError requestId
    @model.save(saveAttributes, options).then(onSuccess, onFailure)

  _closeModal: () ->
    @$(@selectors.roomDetailsModal).modal 'hide'
    @remove()
  
  _handleDataChange: (e) ->
    @$(@selectors.actionButtons).removeAttr 'disabled'