require "../presenters/product_set_presenter"
require "../collections/product_set_collection"
require "../models/deal_model"
require "../presenters/sf_deal_presenter"
require "./ps_rate_plan_list_view"

class Extranet.Views.PSDetailsView extends  Backbone.View
  Extranet.Common.Utilities.Mixin.extends this, Extranet.Helpers.ChildManager

  templateName: 'products_templates_product_set_details'

  el: '#tab-information #bd-details .bd-info'

  productsEventManagerKey: Extranet.Helpers.EventManagerHelper.PRODUCTS_KEY

  selectors:
    inventorySection: '.inventory-section-details'
    editableDealName: '.editable-deal-name'
    ratePlanList: '.rate-plan-table'
    dealUuid: '.deal-uuid-apply .deal-uuid'
    dealUuidText: '.deal-uuid-text'
    dealUuidApply: '.deal-uuid-apply'
    inventoryManagementModeRadio: 'input[name="inventory-management-mode"]'
    updateInventoryManagementModeConfirmationModal: '.update-inventory-management-mode-confirmation-modal'
    updateInventoryManagementModeToRoomTypeConfirmationModal: '#update-inventory-management-mode-to-room-type-confirmation-modal'
    updateInventoryManagementModeToRatePlanConfirmationModal: '#update-inventory-management-mode-to-rate-plan-confirmation-modal'

  events:
    'click .btn-section #save'  : 'save'
    'click .btn-section #cancel': 'cancel'
    'click #apply-deal-uuid': 'applyDealUuid'
    'click #unlink-deal-uuid': 'unlinkDealUuid'
    'click #refresh-deal-uuid': 'refreshDealInfo'
    'click #unlink-deal-modal button.cancel': 'closeUnlinkDealPopup'
    'click input[name="inventory-management-mode"]': '_openUpdateInventoryManagementModeConfirmationModal'
    'click #update-inventory-management-mode-to-room-type-confirmation-modal .btn.approve': () -> @_setInventoryManagementMode 'roomType'
    'click #update-inventory-management-mode-to-rate-plan-confirmation-modal .btn.approve': () -> @_setInventoryManagementMode 'ratePlan'
    'click .update-inventory-management-mode-confirmation-modal .btn.cancel': '_closeUpdateInventoryManagementModeConfirmationModal'

  initialize: (options= {}) ->
    @hotelUuid = options.hotelUuid
    @hotelConnected = options.hotelConnected
    @productSetUuid = options.productSetUuid
    @productSetModel= options.productSetModel
    @alertMessage = Extranet.Helper.AlertView.getAlertView()
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    @presenter = new Extranet.Presenters.ProductSetPresenter @productSetModel
    @errorPresenter = new Extranet.Presenters.Error()
    options.el  = @selectors.inventorySection
    @inventorySectionView = new Extranet.Views.InventorySectionView options
    @addChild @inventorySectionView
    @productsEventManager = Extranet.Helpers.EventManagerHelper.getEventManagerView(@productsEventManagerKey)
    @statusHelper = Extranet.Helpers.ProductSetStatusHelper
    @sfDealPresenter = Extranet.Presenters.SfDealPresenter

  render: () ->
    hash = {}
    if @productSetModel.get('dealUuid') and (not @dealModel?.get('id'))
      @refreshDealInfo()
    hash = @presenter.getHashToDisplay(@productSetModel, @hotelConnected)
    hash.status = @productSetModel.get 'status'
    hash.accessPermissionSubKey = hash.status
    this.$el.html(@autoTemplate.render @templateName, hash)
    _.extend hash, @getDealHash()
    @inventorySectionView.render({hash})
    @renderRatePlanList()
    this.$el?.parsley('destroy')?.parsley(Extranet.ParsleyConfig)
    @show()
    @productsEventManager.triggerEvent 'psDetailsRendered', @productSetModel
    this

  renderRatePlanList: () ->
    @psRatePlanListView?.remove()
    options =
      el: @selectors.ratePlanList
      hotelUuid : @hotelUuid
      productSetUuid : @productSetUuid
      productSetModel: @productSetModel
      dealOptions: @dealModel.get('options') if @dealModel?.get('options')
    @psRatePlanListView = new Extranet.Views.PsRatePlanListView options
    @addChild @psRatePlanListView
    @psRatePlanListView.render()

  validateDealName: ()->
    $(@selectors.editableDealName).parsley('validate')

  save: (e)->
    e.preventDefault()
    if not this.$el.parsley('validate') or not @validateDealName()
      @alertMessage.renderDefaultValidationError()
      return
    @alertMessage.hide()
    if not @inventorySectionView.validateLengthOfStay()
      return

    @psRatePlanListView.save().then(=>
      @saveProductSet()
    ).fail( (jqXHR, textStatus, errorThrown)=>
      requestId = jqXHR?.getResponseHeader 'x-request-id'
      responseJSON = jqXHR?.responseJSON
      connectedHotelError = @errorPresenter.findError responseJSON, 'INVENTORY_MANAGEMENT_MODE_UPDATE_CONNECTED_HOTEL'
      nonReadyProductSetError = @errorPresenter.findError responseJSON, 'INVENTORY_MANAGEMENT_MODE_UPDATE_NON_READY_PRODUCT_SET'
      productSetRestrictionNetRateError = @errorPresenter.findError responseJSON, 'PRODUCT_SET_RESTRICTION_NET_RATE'
      knownError = connectedHotelError or nonReadyProductSetError or productSetRestrictionNetRateError
      if knownError
        message = @errorPresenter.format knownError
        @alertMessage.renderError message, requestId
      else
        @alertMessage.renderDefaultError requestId
    ).done( (data, textStatus, jqXHR)=>
      @render()
      message = I18n.translate 'js.modules.common.alerts.success.promotion_updated'
      requestId = jqXHR?.getResponseHeader 'x-request-id'
      @alertMessage.renderSuccess message, requestId
    )

  saveProductSet: ()->
    hash = @presenter.getHashToSave(this)
    hash.name = $(@selectors.editableDealName).val()
    @productSetModel.save hash, { patch: true, wait: true }

  updateProductSetWithDealInformation: ()->
    hash = @sfDealPresenter.toHash @dealModel
    hash._csrf = $("#csrf_token").val()
    @productSetModel.save hash, { patch: true, wait: true }

  cancel: (e)->
    @render()

  unlinkDealUuid: (e) ->
    @closeUnlinkDealPopup()
    @alertMessage.hide()
    $.ajax
      type: "PUT"
      url: "/extranet/api/hotels/#{@hotelUuid}/product_sets/#{@productSetUuid}/dissociate_deal"
      dataType:"json"
      contentType: "application/json; charset=utf-8"
      data: JSON.stringify({_csrf: $("#csrf_token").val()})
      success: (data, textStatus, jqxhr)=>
        requestId = jqxhr?.getResponseHeader('x-request-id')
        @dealModel = undefined
        @productSetModel.fetch()
        .done((data, textStatus, jqxhr)=>
          @render()
          message = I18n.translate('js.modules.common.alerts.success.deal_unlinked')
          @alertMessage.renderSuccess(message, requestId)
        ).fail((jqxhr)=>
          requestId = jqxhr?.getResponseHeader('x-request-id')
          @alertMessage.renderDefaultError(requestId)
        )

      error: (jqxhr, status, errorThrown)=>
        errorMessage = I18n.translate('js.modules.common.alerts.error.deal_not_unlinked')
        requestId = jqxhr?.getResponseHeader('x-request-id')
        @alertMessage.renderError(errorMessage, requestId)

  closeUnlinkDealPopup: ()->
    @$('#unlink-deal-modal a.close-modal').click()

  applyDealUuid: (e)->
    dealUuid = @$(@selectors.dealUuid).val()?.trim()
    unless @$(@selectors.dealUuid).parsley('validate')
      errorMessage = I18n.translate("js.modules.products.templates_product_set_details.invalid_uuid")
      @alertMessage.renderError(errorMessage)
      return
    @fetchAndSaveDealUuid(dealUuid)

  fetchAndSaveDealUuid: (dealUuid, options={}) ->
    {isRefresh} = options
    handleValidPromotionWindowDeal = (data, textStatus, jqXHR) =>
      @render()
      if isRefresh
        message = I18n.translate('js.modules.common.alerts.success.deal_refreshed')
      else
        message = I18n.translate('js.modules.common.alerts.success.deal_linked')
      requestId = jqXHR?.getResponseHeader('x-request-id')
      @alertMessage.renderSuccess(message, requestId)
    handleInvalidPromotionWindowDeal = (data, textStatus, jqXHR) =>
      message = I18n.translate('js.modules.common.alerts.error.invalid_promotion_window')
      requestId = jqXHR?.getResponseHeader('x-request-id')
      @alertMessage.renderError(message, requestId)
    handleFailure = (jqXHR, textStatus, errorStatus) =>
      @dealModel = undefined
      requestId = jqXHR?.getResponseHeader('x-request-id')
      message = @_getDealUuidApplyErrorMessage(jqXHR?.responseJSON)
      @alertMessage.renderError(message, requestId)
    handleSuccess = (data, textStatus, jqXHR) =>
      if moment(@dealModel.get 'closeAt') < moment(@dealModel.get 'launchAt')
        handleInvalidPromotionWindowDeal(data, textStatus, jqXHR)
      else
        @updateProductSetWithDealInformation().then handleValidPromotionWindowDeal, handleFailure
    @fetchDealInfo(dealUuid).then handleSuccess, handleFailure

  refreshDealInfo: ()->
    return unless Extranet.RolePermissions?.is_admin_user and not Extranet.RolePermissions?.is_readonly_user
    dealUuid = @productSetModel.get('dealUuid')
    @fetchAndSaveDealUuid(dealUuid, {isRefresh: true})

  fetchDealInfo: (dealUuid)->
    @dealModel = new Extranet.Models.DealModel(id : dealUuid)
    @dealModel.fetch
      data: $.param({'expand[]': 'options'})

  getDealHash: ()->
    return {} unless @productSetModel.get('dealUuid')
    dealUuidApplied: true
    dealPermalink: @productSetModel.get('dealSeoName')
    dealUuid: @productSetModel.get('dealUuid')
    launchAt: @productSetModel.get('restriction')?.promotionalWindow?.startAt
    closeAt: @productSetModel.get('restriction')?.promotionalWindow?.endAt

  _getDealUuidApplyErrorMessage: (errorResponse={})->
    defaultErrorMessage = I18n.translate('js.modules.common.alerts.error.default')
    nestedErrors = errorResponse.body?.nestedErrors
    errorMessages = []
    return defaultErrorMessage unless nestedErrors?.length
    for nestedError in nestedErrors
      if nestedError?.httpCode is 422
        continue unless nestedError.errors?.length
        for error in nestedError.errors
          errorMessages.push error.message

    knownErrors =
      DUPLICATE_DEAL_UUID: "The Product Set contains a value for dealUuid which is already used"
      DUPLICATE_DEAL_SEO_NAME: "The Product Set contains a value for dealSeoName which is already used"

    errorMessage = if knownErrors.DUPLICATE_DEAL_UUID in errorMessages
      I18n.translate('js.modules.common.alerts.error.duplicate_deal_uuid')
    else if knownErrors.DUPLICATE_DEAL_SEO_NAME in errorMessages
      I18n.translate('js.modules.common.alerts.error.duplicate_deal_seo_name')
    else defaultErrorMessage

    return errorMessage
  
  _openUpdateInventoryManagementModeConfirmationModal: (event) ->
    event.preventDefault()
    @_closeUpdateInventoryManagementModeConfirmationModal()
    selectedInventoryManagementMode = $(event.currentTarget).val()
    switch selectedInventoryManagementMode
      when 'roomType'
        @$(@selectors.updateInventoryManagementModeToRoomTypeConfirmationModal).modal 'show'
      when 'ratePlan'
        @$(@selectors.updateInventoryManagementModeToRatePlanConfirmationModal).modal 'show'
  
  _closeUpdateInventoryManagementModeConfirmationModal: () ->
    @$(@selectors.updateInventoryManagementModeConfirmationModal).modal 'hide'
  
  _setInventoryManagementMode: (inventoryManagementMode) ->
    # To set the value for radio inputs, we need to pass an array
    @$(@selectors.inventoryManagementModeRadio).val [inventoryManagementMode]
    @_closeUpdateInventoryManagementModeConfirmationModal()