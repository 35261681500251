class Extranet.Presenters.BookingsFilters

  formatRooms: (roomCollection) ->
    roomModels = roomCollection.models
    return [] unless roomModels?.length
    rooms = []

    roomModels = _.sortBy roomModels, (roomModel)-> roomModel.get 'name'

    for roomModel in roomModels
      room =
        roomUuid: roomModel.get 'uuid'
        roomName: roomModel.get 'name'
        ratePlans: []

      ratePlans = roomModel.getRatePlans()
      if ratePlans?.length
        ratePlans = _.sortBy ratePlans, (ratePlan)-> ratePlan.name

        for ratePlan in ratePlans
          room.ratePlans.push
            ratePlanUuid: ratePlan.uuid
            ratePlanName: ratePlan.name

      rooms.push room

    rooms
