class Extranet.Presenters.RatesInventoryUpload

  MAX_ELEMENTS_BEFORE_TRUNCATED = 6

  toHash: () ->
    hash = {}
  
  formatError: (error) ->
    if error?.failure?
      return @_formatValidationError error.failure
    I18n.translate 'js.modules.common.alerts.error.default'

  _formatValidationError: (failure) ->
    message = I18n.translate 'js.modules.admin.rates_inventory_upload.error.unmet_validations'
    unmetValidations = _.map failure, (list, errorCode) =>
      list = @_truncate list, MAX_ELEMENTS_BEFORE_TRUNCATED
      I18n.translate "js.modules.admin.rates_inventory_upload.error.#{errorCode}", {list}
    error = _.map unmetValidations, (unmetValidation) -> "<div>#{unmetValidation}</div>"
    message + error.join ''
  
  _truncate: (array, threshold) ->
    return unless array
    list = array[..(threshold - 1)].join ', '
    list += ', …' if array.length > threshold
    list