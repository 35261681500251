class Extranet.Presenters.TaxRowPresenter

  toHash: (model) ->
    modelHash = null
    if model instanceof Extranet.Models.Tax
      modelHash = model.toJSON()
    else
      modelHash = model

    deepCopy = true
    data = $.extend deepCopy, {}, modelHash
    data.taxCategory = if data.taxCategory is 'merchant'
      "Merchant"
    else if data.taxCategory is 'collectOnsite'  # New Tax Category added to display in the Tax List Page(Tax row) after searching.
      "Collect On-site"
    else
      "Groupon"

    data.duration = if data.perDurationApplicationType is 'per_stay'
      "Per Stay"
    else
      "Per Night"

    data.tax = @_calculateTax data
    data.perGuestType = if data.isPerTraveler
      "Per Traveler"
    else
      "Per Booking"

    data

  _calculateTax: (modelHash)->
    # calculationType can have only two values ('percent' and 'amount').
    if modelHash.calculationType is 'percent'
      return modelHash.taxAmount.amount + '%'
    else
      taxAmount = modelHash.taxAmount.amount
      taxAmount = MathHelper.divide taxAmount, 100
      taxAmount = I18n.toCurrency taxAmount, modelHash.taxAmount.currencyCode
      return taxAmount
