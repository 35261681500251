class Extranet.Models.RatePlanModel extends Extranet.Model

  urlRoot: ()->
    if @roomTypeUuid
      return "/v2/getaways/extranet/hotels/#{@hotelUuid}/room_types/#{@roomTypeUuid}/rate_plans"
    if @productSetUuid
      return "/v2/getaways/extranet/hotels/#{@hotelUuid}/product_sets/#{@productSetUuid}/rate_plans"

  initialize: (options)->
    @roomTypeUuid = options.roomTypeUuid
    @hotelUuid = options.hotelUuid
    @productSetUuid = options.productSetUuid
    @inventoryProductId = options.inventoryProductId
    if not @productSetUuid
      @setDefaultsLM()

  defaults: () ->
    languageCode =  "en_US"
    {languageCode}

  setDefaultsLM: () ->
    this.set 'lastMinuteDiscountPercent', 0
    this.set 'guestCountInBaseRate', 2
    this.set 'margin', 0

  setHotelId: (hotel_id) ->
    @hotelUuid = hotel_id

  setRoomTypeId: (roomTypeId) ->
    @roomTypeUuid = roomTypeId
