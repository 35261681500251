require "./taxes/admin_taxes_view"
require "./search_manager_view"

class Extranet.Views.AdminPageView extends Backbone.View

  Extranet.Common.Utilities.Mixin.extends this, Extranet.Helpers.ChildManager
  PROMOTIONS = "productset_search"

  # initialize: (options) ->
  #   {@view} = options

  render: (options) ->
    if options?.view is PROMOTIONS
      @taxes?.hide()
      Extranet.adminHeaderView.selectHotelSearchTab()
      if not @searchManagerView
        @searchManagerView = new Extranet.Views.SearchManagerView
        @addChild @searchManagerView
      @searchManagerView.render()
    else
      Extranet.adminHeaderView.selectTaxesTab()
      @searchManagerView?.hide()
      if not @taxes
        @taxes = new Extranet.Views.AdminTaxesView()
        @addChild @taxes
      else
        @taxes.show()
