class Extranet.Views.TwoFALoginFormView extends Backbone.View

  el: 'form#login-form-2fa'

  selectors:
    errorMessage: '.error-message'

  events:
    'keyup input': 'handleKeyPress'
    'click #submit': 'submitForm'
    'click #back': 'redirectToLogin'

  initialize: () ->
    @$el.attr('data-bind', true)
    @$el.parsley(Extranet.ParsleyConfig)

  handleKeyPress: (e)->
    e.preventDefault()
    code = e.keyCode or e.which
    if code is 13
      @submitForm()

  submitForm: ()->
    unless @validate()
      errorMessage = I18n.translate("js.modules.common.alerts.validation.default")
      $(@selectors.errorMessage).html(errorMessage)
      return
    @$el.submit()

  redirectToLogin: (e)->
    e.preventDefault()
    window.location.href = "/extranet/login"

  validate: ()->
    return @$el.parsley('validate')
