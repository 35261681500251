DEFAULT_IGNORED_DAYS = '0000000'  # SUN(0) TO SAT(6)

class Extranet.Views.BDInventoryFilter extends Backbone.View

  eventsTrigger:
    filterChange: 'filterChange'

  eventsListen:
    calendarSelection: 'calendarSelection'

  selectors:
    checkInFrom: '#input-checkin-from'
    checkInTo: '#input-checkin-to'
    prevToolTip: '.ui-datepicker-prev'
    nextToolTip: '.ui-datepicker-next'
    daysList: '.rate-applies-tabs li[data-day!=-1]'
    allDays: '.rate-applies-tabs li[data-day=-1]'
    day: (dayNumber)->
      ".rate-applies-tabs li[data-day=#{dayNumber}]"

  templateName: 'inventory_common_inventory_filter'
  allDays: -1

  events:
    'click .date-field': 'showCalendar'
    'change .date-field input[type=text]': 'filterByDate'
    'click .list-btn.month-view-days li': 'filterByDayOfWeek'

  initialize: (options) ->
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    @eventManagerView = Extranet.Helpers.EventManagerHelper.getEventManagerView(options.eventManagerKey)
    @listenTo @eventManagerView, @eventsListen.calendarSelection, @updateDatepickerDates

  render: () ->
    this.$el.html @autoTemplate.render @templateName, {datePickerPlaceholder: DateTimeHelper.getDateFormat 'numeric'}
    @$el.find(@selectors.checkInFrom).datepicker()
    @$el.find(@selectors.checkInTo).datepicker()
    @selectAllDaysTab()
    this

  ignoredDaysMask: () ->
    daysList = @$el.find(@selectors.daysList)
    booleanArray = _.map daysList, (dayElement) =>
      if @$el.find(dayElement).hasClass('active') then 0 else 1
    booleanArray.join("")

  showCalendar: (e) ->
    e.preventDefault()
    element = @$el.find(e.currentTarget)
    element.find('input').datepicker('show')
    @$el.find(@selectors.prevToolTip).removeAttr('title')
    @$el.find(@selectors.nextToolTip).removeAttr('title')

  filterByDayOfWeek: (e) ->
    el = $(e.currentTarget)
    dayNumber = $(el).data('day')
    if dayNumber is @allDays
      @toggleAllDaysTab()
    else
      @$el.find(@selectors.day(dayNumber)).toggleClass 'active'

    checkIn  = @$el.find(@selectors.checkInFrom).val()
    checkOut = @$el.find(@selectors.checkInTo).val()
    checkIn  = checkOut if _.isEmpty(checkIn)
    checkOut = checkIn if _.isEmpty(checkOut)
    ignoredDaysMask = @ignoredDaysMask()
    if ignoredDaysMask is DEFAULT_IGNORED_DAYS then @selectAllDaysTab() else @deselectAllDaysTab()
    @eventManagerView.triggerEvent @eventsTrigger.filterChange, {checkIn, checkOut, ignoredDaysMask}

  filterByDate: () ->
    checkInEl  = @$el.find(@selectors.checkInFrom)
    checkOutEl = @$el.find(@selectors.checkInTo)
    checkInString  = checkInEl.val()
    checkOutString = checkOutEl.val()

    checkIn = new Date(checkInString)
    checkOut = new Date(checkOutString)

    if not _.isEmpty checkInString
      checkOutEl.datepicker( "option", "minDate", checkIn)
    else
      checkIn = checkOut
      checkInString = checkOutString
      checkOutEl.datepicker( "option", "minDate", null)

    if not _.isEmpty checkOutString
      checkInEl.datepicker( "option", "maxDate", checkOut)
    else
      checkOut = checkIn
      checkOutString = checkInString
      checkInEl.datepicker( "option", "maxDate", null)

    if @areValidDates(checkIn, checkOut)
      @eventManagerView.triggerEvent @eventsTrigger.filterChange, {
        checkIn: checkInString, checkOut: checkOutString, ignoredDaysMask: @ignoredDaysMask()
      }

  updateDatepickerDates:(options={}) ->
    {startDate, endDate} = options
    displayStartDate = DateTimeHelper.formatDate startDate, 'numeric'
    displayEndDate = DateTimeHelper.formatDate endDate, 'numeric'
    @$el.find('#input-checkin-from').val(displayStartDate)
    @$el.find('#input-checkin-to').val(displayEndDate)

  areValidDates: (checkIn, checkOut) ->
    return moment(checkIn).diff(checkOut, 'days') <= 0

  deselectOtherDays: () ->
    daysList = @$el.find(@selectors.daysList)
    _.each daysList, (dayItem) =>
      @$el.find(dayItem).removeClass 'active'

  selectAllDaysTab: () ->
    @$el.find(@selectors.allDays)?.addClass 'active'
    daysList = @$el.find(@selectors.daysList)
    _.each daysList, (dayItem) =>
      @$el.find(dayItem).addClass 'active'

  deselectAllDaysTab: () ->
    @$el.find(@selectors.allDays)?.removeClass 'active'

  toggleAllDaysTab: ()->
    if @$el.find(@selectors.allDays)?.hasClass('active')
      @deselectAllDaysTab()
      @deselectOtherDays()
    else
      @selectAllDaysTab()
