require '../presenters/tax'

class Extranet.Views.TaxRow extends Backbone.View

  events:
    'click .delete-tax-button': '_deleteContact'
  
  tagName: 'tr'
  className: 'tax-row'
  templateName: 'profile_tax_row'
  
  initialize: (options) ->
    @hotelModel = options.hotelModel
    @alertMessage = Extranet.Helper.AlertView.getAlertView()
    @presenter = new Extranet.Presenters.Tax()
  
  render: () ->
    @hash = @presenter.toHash @model
    @$el.html TemplateHelper.render @templateName, @hash