class Extranet.Collections.Payments extends Backbone.Collection
  model: Extranet.Models.Payment

  url: ()->
    return "/v2/getaways/extranet/hotels/#{@hotelUuid}/payments"

  initialize: (options)->
    @hotelUuid = Extranet.ClientConfig?.hotelData?.uuid


  parse: (response) ->
    response.payments
