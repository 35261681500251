class Extranet.Views.ProductsNotesView extends Backbone.View

  events:
    'click button': 'saveNotes'

  selectors:
    notes: '.products-notes'
    closeModal: '.close-modal'

  templateName: 'products_templates_product_notes'

  initialize: (options={}) ->
    @options = options
    @model = options.model
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    @alertMessage = Extranet.Helper.AlertView.getAlertView()

  render:() ->
    hash = {}

    if @options?.rejectionNote
      hash.rejectionNote = true
    hash.notes = @model?.get('notes')

    @$el.html(@autoTemplate.render @templateName, hash)
    $(@options.placeHolder).html @el

    @delegateEvents()
    this

  saveNotes: (e)->
    hash =
      notes: @$(@selectors.notes).val()
      _csrf: $("#csrf_token").val()
    @model.save hash, {
      patch: true
      wait: true
      success: (model, response, options)=>
        @$(@selectors.closeModal).click()
        message = I18n.translate('js.modules.common.alerts.success.notes_saved')
        requestId = options?.xhr?.getResponseHeader('x-request-id')
        @alertMessage.renderSuccess(message, requestId)
        @moveToRejectState() if @options?.rejectionNote
      error: (model, response, options)=>
        requestId = options?.xhr?.getResponseHeader('x-request-id')
        @alertMessage.renderDefaultError(requestId)
    }

  moveToRejectState: ->
    eventManagerKey = Extranet.Helpers.EventManagerHelper.PS_MONTH_VIEW_KEY
    eventMgr =
      Extranet.Helpers.EventManagerHelper.getEventManagerView eventManagerKey
    eventMgr.triggerEvent 'moveToReject'
