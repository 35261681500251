class Extranet.Inventory.Utilities.InventoryApiClient
  save: (url, data, headers, cb)->
    $.ajax
      type: 'POST'
      url: url
      data: JSON.stringify data
      headers: headers
      dataType:"json"
      contentType: "application/json; charset=utf-8"
      success: ()->
        cb()
      error: ()->
        # TODO: do we have to call call back here??

  fetch: (options, data) ->
    return $.ajax
      type: options.type
      url: options.url
      data: JSON.stringify if data then data else options.data
      dataType:"json"
      global: options.global
      contentType: "application/json; charset=utf-8"

  fetchInventory: (options)->
    deepCopy = true
    defaultOptions =
      type: 'POST'
      url: '/getaways/extranet/products/rates_inventory_by_date'
      global: false
    newOptions = $.extend deepCopy, defaultOptions, options

    @fetch newOptions
