class StringHelper

  @capitalize: (string) ->
    return "" unless string?.length
    string.charAt(0).toUpperCase() + string.substring(1).toLowerCase()

if window?
  window.StringHelper = StringHelper
else
  module.exports = StringHelper
