require "./hotel_list_table"
require "./advance_search_inventory_download"
require "../collections/hotel"
require "../presenters/hotel_presenter"

class Extranet.Views.HotelsListView extends Backbone.View

  el: '.advanced_search'
  selectors:
    hotelSearchForm: "#hotel_search_form"
    noSearchResult: "#no_search_result"
    createHotelLink: ".create-hotel-link"
    hotelListView: ".hotel_list_view"
    alertMessage: ".alert-message"
    hotelName: "#hotel-name"
    countrySelector: "#hotel_search_form .country-selector"
    stateSelector: "#hotel_search_form .state-selector"
    citySelector: "#hotel_search_form .city-selector"
  events:
    'click .download_hotels'   :   'toggleDownloadView'
    'change .select_filter'    :   'toggleDateRangeFilter'
    'click .hotel-search-btn'  :   'submitSearch'
    # 'click .hotel-download-btn':    'submitDownloadForm'

  collectionUrlBase: '/v2/getaways/extranet/hotels'

  initialize: (options) ->

    _.bindAll this,'toggleDateRangeFilter', 'submitSearch', 'refreshViews'

    @countrySelector = $(@el).find(@selectors.countrySelector)
    @stateSelector = $(@el).find(@selectors.stateSelector)
    @citySelector = $(@el).find(@selectors.citySelector)

    @childView            = new Extranet.Views.HotelListTable {el: $ ".grid"}
    @collection           = new Extranet.Collections.Hotel()
    @alertMessage         = new Extranet.Views.AlertMessage {selector: @selectors.alertMessage}
    @inventoryDownload    = new Extranet.Views.AdvanceSearchInventoryDownload({alertMessage: @alertMessage})
    @paginationFooterView = new Extranet.Views.PaginationFooterView {el: '.pagination_footer'}
    @locationSelector     = new Extranet.Helpers.LocationSelector @countrySelector, @stateSelector, @citySelector, {}

    @itemsPerPage = options.itemsPerPage
    @pageNum = 1
    @pageFirstItem = 0
    
    @collection.bind "reset", @refreshViews
    @listenTo @paginationFooterView, 'pagechange', @pageChanged

  refreshViews: () ->
    $(@el).find(@selectors.createHotelLink).removeClass('hide')
    $(@el).find(@selectors.noSearchResult).hide()
    $(@el).find(@selectors.alertMessage).hide()
    $(@el).find(@selectors.hotelListView).show()
    presenter = Extranet.Presenters.HotelPresenter

    models =
      if @internalCollectionArray and @internalCollectionArray[@pageFirstItem]
        @internalCollectionArray.slice(@pageFirstItem, @pageFirstItem + @itemsPerPage)
      else
        @collection.models

    @childView.render models, presenter
    hotelsList = []
    models.forEach (model) ->
      hotelsList.push(model.get "uuid")

    @paginationFooterView.render
      pageNum: @pageNum
      totalPages: Math.ceil @collection.pagination.count/@itemsPerPage

  updatePage: () ->
    @pageFirstItem = (@pageNum - 1)*@itemsPerPage
    @inventoryDownload.setOffset @pageFirstItem
    @inventoryDownload.setSearchParameters @searchParameters
    unless @internalCollectionArray and @internalCollectionArray?[@pageFirstItem]
      @collection.setUrl "#{@collectionUrlBase}?#{@_getQueryString(@pageNum)}"
      @collection.fetch {
        success: (collection) =>
          @noSearchResult() if not collection.length

          unless @internalCollectionArray
            @internalCollectionArray = new Array @collection.pagination.count

          i = @pageFirstItem
          @collection.models.forEach (model) =>
            @internalCollectionArray[i] = model
            i = i + 1

        error: (collection, response, options)=>
          requestId = options?.xhr?.getResponseHeader('x-request-id')
          @alertMessage.renderDefaultError(requestId)
        reset: true
      }
    else
      @refreshViews()

  noSearchResult: () ->
    $(@selectors.hotelListView).hide()
    $(@selectors.noSearchResult).show()

  toggleDateRangeFilter: (evt) ->
    dateRangeDiv = $(@el).find('.date-range')
    if $('select option:selected').text() == "For Date Range"
      dateRangeDiv.show()
    else
      dateRangeDiv.hide()

  submitSearch: (evt) ->
    @searchParameters = {}
    @searchParameters.hotel_name = $(@el).find(@selectors.hotelName).val()
    @searchParameters.country_uuid = @countrySelector.val()
    @searchParameters.state_uuid = @stateSelector.val()
    @searchParameters.city_uuid = @citySelector.val()
    @searchParameters.groupon_offer_type = 'grouponInventoryLastMinute' if @$el.find('#last-minute-hotel').is(':checked')
    # $('.show-download').show()
    @pageNum = 1
    @pageFirstItem = 0
    @internalCollectionArray = undefined
    @paginationFooterView.resetIndex()
    @updatePage()
    return false

  # toggleDownloadView: (evt) ->
  #   $("#download_form").slideToggle()

  # submitDownloadForm: (evt) ->
  #   downloadParameters = $('#download_form').serializeObject()
  #   hotelsList = []
  #   @collection.models.forEach (model) ->
  #     hotelsList.push(model.get "uuid")

  #   @collection.setUrl @collectionUrlBase
  #   @collection.fetch()
  #   return false

  _getQueryString: (pageNum) ->
    $.param ( _.extend @searchParameters, {offset: (pageNum - 1) * @itemsPerPage, limit: @itemsPerPage} )

  pageChanged: (data) ->
    @pageNum = data.pageNum
    @updatePage()
