class Extranet.Views.PaginationFooterView extends Backbone.View

  Extranet.Common.Utilities.Mixin.extends this, Extranet.Helpers.ChildManager

  template_name: 'common_pagination_footer'

  selectors:
    listItems: '.pagination .page-numbers'
    currentpage: (currentPage)->
      ".pagination .page-numbers[data-pagenumber=#{currentPage}]"

  events:
    'click .page-numbers'    :   'getPage'
    'click .left-navigation' :   'previousPage'
    'click .right-navigation':   'nextPage'

  initialize: ()->
    @resetIndex()

  render: (options) ->
    @totalPages = options.totalPages
    @currentPage = options.pageNum
    pageList = []
    @endIndex = @totalPages if @totalPages < @endIndex

    for i in [@startIndex..@endIndex]
      pageList.push({pageNum: i})

    html = Extranet.Templates[@template_name].render {pageList: pageList}
    @$el.empty()
    @$el.html html
    @$el.find(@selectors.listItems).removeClass 'active'
    @$(@selectors.currentpage @currentPage).addClass 'active'
    @show()
    this

  previousPage: () ->
    if @currentPage is 1
      return
    if @startIndex > 1
      @startIndex--
      @endIndex--
    @currentPage = @currentPage - 1
    @changePage()

  nextPage: () ->
    if @currentPage is @totalPages
      return

    @currentPage = @currentPage + 1
    if @endIndex < @totalPages
      @startIndex++
      @endIndex++
    @changePage()

  getPage: (e) ->
    el = $(e.currentTarget)
    @currentPage = parseInt(el.text(),10)
    @changePage()

  changePage: () ->
    data =
      pageNum: @currentPage
    this.trigger 'pagechange', data

  resetIndex: () ->
    @startIndex =1
    @endIndex = 5
