require "./rates_upload_view"

class Extranet.Views.RatesUploadButtonsView extends Backbone.View
  Extranet.Common.Utilities.Mixin.extends this, Extranet.Helpers.ChildManager

  RATES_UPLOAD_VIEW_KEY = "ratesUploadView"
  el: '#upload-rates'

  events:
    'click button#upload-deal-rates-button': 'renderDealRatesUploadView'

  templateName: 'admin_templates_rates_upload_buttons'

  initialize: () ->
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate

  render: ()->
    @$el.html @autoTemplate.render @templateName, {}
    this

  renderDealRatesUploadView: ()->
    @removeChild(RATES_UPLOAD_VIEW_KEY) # Clearing if already exists

    hash =
      placeholder: '#upload-deal-rates'
      isDealRatesUpload: true

    @dealRatesUploadView = @addChild new Extranet.Views.RatesUploadView(hash), RATES_UPLOAD_VIEW_KEY
    @dealRatesUploadView.render()
