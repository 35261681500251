require "./tax_details_view"
require "../../../../common/frontend/presenters/tax_row_presenter"

class Extranet.Views.AdminTaxRowView extends Backbone.View
  tagName: 'tr'
  className: 'first'
  template_name: 'admin_tax_row'
  events:
    'click #view-tax': 'viewTax'
    'click #delete-tax': 'deleteTax'

  initialize: (options)->
    @model = options.model
    @modalSelector = options.modalSelector
    @alertMessage = options.alertMessage
    @listenTo @model, 'destroy', @removeThisView
    {@selectedStateUuid, @selectedCountryUuid} = options

  render: () ->
    if @model
      presenter = new Extranet.Presenters.TaxRowPresenter()
      hash = presenter.toHash @model
    this.$el.html Extranet.Templates[@template_name].render hash
    this

  deleteTax: (e)->
    e.preventDefault()
    @model.destroy
      headers:
        'x-csrf-token': $('#csrf_token').val()
      success: () ->
        console.log "Successfully deleted contact"
      error: () ->
        console.log "Failed to delete contact"

  removeThisView: (model, collection, options)->
    this.remove()

  viewTax: (e)->
    tax = new Extranet.Views.TaxDetailsView
      model: @model
      alertMessage: @alertMessage
      selectedCountryUuid: @selectedCountryUuid
      selectedStateUuid: @selectedStateUuid

    $(@modalSelector).html tax.render().el
