class Extranet.Presenters.ProductSetPresenter

  constructor: ()->
    @util = new Extranet.Common.Utilities.Utility()
    @permission = new Extranet.Common.Permissions()
    @viewPermissions = Extranet?.RolePermissions

  #==
  # converting model hash to a hash which can be used for rendering
  #==
  getHashToDisplay: (model, isHotelConnected) ->
    hash =
      name: model.get 'name'
      status: model.get 'status'
      opportunityId: model.get 'opportunityId'
      marginPercentage: model.get 'marginPercentage'
      applyCreditCardFee: model.get 'applyCreditCardFee'
      useNetRateForBookings: model.get 'useNetRateForBookings'
      isNetRate: model.get 'isNetRate'
      cancellationBuffer: model.get 'cancellationBuffer'
      restriction: model.get 'restriction'
      livePricingEnabled: model.get('livePricingRule') is 'dailyDiscount'
      inventoryManagedAtRoomType: model.get('inventoryManagementMode') is 'roomType'
      inventoryManagedAtRatePlan: model.get('inventoryManagementMode') is 'ratePlan'
      isHotelConnected: isHotelConnected
      ratePlanInventoryEnabled: Extranet?.FeatureFlags?.inventory_at_rate_plan
      isAdmin: @viewPermissions?.is_admin_user

  #===
  # Prepare hash for saving
  #===

  getHashToSave: (view) ->
    saveAttrs =
      marginPercentage: if view.$('#margin').val() then parseFloat view.$('#margin').val()
      restriction:
        minAverageDiscountPercentage: if  view.$('#minimum-average-percentage').val() then parseFloat view.$('#minimum-average-percentage').val()
        minDailyDiscountPercentage: if view.$('#minimum-percentage').val() then parseFloat view.$('#minimum-percentage').val()
        minDailyAllotment:  if view.$('#default-daily-allotment').val() then parseInt view.$('#default-daily-allotment').val()
        minLengthOfStay: if view.$("#minLengthOfStay").val() then parseInt view.$("#minLengthOfStay").val()
        maxLengthOfStay: if view.$("#maxLengthOfStay").val() then parseInt view.$("#maxLengthOfStay").val() else null
        maxBlackOutDates:
          max: if view.$("#min-per-deal").val() then parseInt view.$("#min-per-deal").val()
          maxByDayOfWeek:
            sunday: if view.$(".blackout-days #sun").val() then parseInt view.$(".blackout-days #sun").val()
            monday: if view.$(".blackout-days #mon").val() then parseInt view.$(".blackout-days #mon").val()
            tuesday: if view.$(".blackout-days #tue").val() then parseInt view.$(".blackout-days #tue").val()
            wednesday: if  view.$(".blackout-days #wed").val() then parseInt view.$(".blackout-days #wed").val()
            thursday: if view.$(".blackout-days #thu").val() then parseInt view.$(".blackout-days #thu").val()
            friday: if view.$(".blackout-days #fri").val() then parseInt view.$(".blackout-days #fri").val()
            saturday: if view.$(".blackout-days #sat").val() then parseInt view.$(".blackout-days #sat").val()

    saveAttrs.cancellationBuffer =  if view.$('#cancellation-window').val() then parseInt view.$('#cancellation-window').val()
    if view.$("#cancellation-options").val() is 'days' and saveAttrs.cancellationBuffer
      saveAttrs.cancellationBuffer = saveAttrs.cancellationBuffer*24

    saveAttrs.applyCreditCardFee = view.$("#cc-fee input[type='radio']:checked").val() is 'yes'
    saveAttrs.useNetRateForBookings = view.$("#use-net-rate input[type='radio']:checked").val() is 'yes'
    saveAttrs.isNetRate = view.$("#is-net-rate input[type='radio']:checked").val() is 'yes'
    saveAttrs.livePricingRule = view.$("#live-pricing input[type='radio']:checked").val()
    saveAttrs.inventoryManagementMode = view.$("#inventory-management-mode input[type='radio']:checked").val()
    saveAttrs._csrf = $('#csrf_token').val()

    saveAttrs
