require "../presenters/payments"

class Extranet.Views.Payments extends Backbone.View
  el: 'section#page'
  templateName: 'payments_page'
  selectors:
    alertMessage: '.alert-message'

  events:
    'click .btn-download': 'downloadReport'

  initialize: () ->
    @paymentsCollection = new Extranet.Collections.Payments()
    @presenter = new Extranet.Presenters.Payments()
    @alertMessage = Extranet.Helper.AlertView.initialize @selectors.alertMessage

  downloadReport: (e) ->
    btn = $(e.currentTarget)
    statementUuid = btn.data('statement')
    hotelUuid = Extranet.ClientConfig?.hotelData?.uuid
    if statementUuid != undefined and statementUuid != ''
      window.open ExtranetUrlBuilder.getPaymentDownloadUrl {hotelUuid, statementUuid}

  render: () ->
    @paymentsCollection.fetch()
    .done(()=>
      data = @presenter.toHash @paymentsCollection
      @$el.html TemplateHelper.render @templateName, data

    ).fail((xhr)=>
      requestId = xhr?.getResponseHeader('x-request-id')
      @alertMessage.renderDefaultError(requestId)
    )
