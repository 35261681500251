window.higlightContainer = (inputEl, {focus})->
  containerElSelector = inputEl.attr('data-container-el')

  if containerElSelector?.length
    focusEl = inputEl.parents(containerElSelector)
  else
    focusEl = inputEl

  if focus
    focusEl.addClass 'focus'
  else
    focusEl.removeClass 'focus'

$("body").on "focus", "input", ->
  higlightContainer $(this), { focus: true}

$("body").on "blur", "input", ->
  higlightContainer $(this), { focus: false}

