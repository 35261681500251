class Extranet.Views.CalendarView extends Backbone.View

  selectors:
    calendar: '#calendar'
    dateCells: 'div.fc-content tbody td'
    overlay: '.fc-cell-overlay'
    monthInDateCell: (date) ->
      "td[data-date=#{date}] .month"
    centerData: (date) ->
      "td[data-date=#{date}] .center-data"
    bottomData: (date) ->
      "td[data-date=#{date}] .bottom-data"
    dateCell: (date) ->
      "td[data-date=#{date}]"

  calendarTemplateName: 'calendarV2_calendar'
  dateCellTemplateName: 'calendarV2_calendar_cell'
  partialBlackoutTemplate: 'calendarV2_partial_blackout'

  events:
    'click .fc-header-div .fc-button-prev': 'navigatePreviousMonth'
    'click .fc-header-div .fc-button-next': 'navigateNextMonth'

  initialize: (options) ->
    # 17712 - need to know hotel timezone when initiating calendar
    @startDate = if options.startDate then new Date(options.startDate) else new Date()
    @endDate = @startDate       # Making selected date range to startDate as single date is given
    @ignoredDaysOfWeek = options.ignoredDaysOfWeek
    @eventManagerView = Extranet.Helpers.EventManagerHelper.getEventManagerView(options.eventManagerKey)

  render: () ->
    this.$el.html Extranet.Templates[@calendarTemplateName].render()
    calendarElement = @$el.find(@selectors.calendar)
    calendarElement.fullCalendar
      selectable: true
      selectHelper: true
      unselectAuto: true
      editable: true
      select: (startDate, endDate, allDay, jsEvent, view) =>
        @removeOverlay()
        @eventManagerView.triggerEvent 'calendarSelection', {startDate, endDate}
        @setStartAndEndDate startDate, endDate
        @selectDateRange()

    @initializeCalendar()
    this

  initializeCalendar: () =>
    @$el.find(@selectors.calendar).fullCalendar 'gotoDate', @startDate
    @initializeWithDefaultData()

  initializeWithDefaultData: ->
    @addEmptyData()
    @addMonthNamesToFirstDates()

  # Sets start and end date of selected Date Range
  # 17712 - need to know hotel timezone here
  setStartAndEndDate: (start, end) ->
    @startDate = new Date(start)
    @endDate = new Date(end)

  setIgnoredDaysOfWeek: (ignoredDaysOfWeek) ->
    @ignoredDaysOfWeek = ignoredDaysOfWeek

  isDateInCalendar: (date) ->
    date = DateTimeHelper.formatDate date, 'api'
    dateCell = @$el.find(@selectors.dateCell(date))
    dateCell?.length > 0

  removeOverlay: ->
    @$el.find(@selectors.overlay).css({display: "none"})

  addEmptyData: ->
    dateCells = @getCurrentDateCells()
    html = Extranet.Templates[@dateCellTemplateName].render()
    dateCells.not(":has(.inventory-info)").append(html)

    # prepending partial blackout
    partialBlackoutHtml = Extranet.Templates[@partialBlackoutTemplate].render()
    dateCells.not(":has(.partial-blackout)").prepend partialBlackoutHtml

  addMonthNamesToFirstDates: ->
    dateCells = @getCurrentDateCells()
    _.each dateCells, (dateCell) =>
      date = @$el.find(dateCell).data('date')
      if @isStartOfMonth(date)
        monthName = moment(date).format 'MMM'
        @$el.find(@selectors.monthInDateCell(date)).text(monthName)

  selectDateRange: () ->
    @removeSelection()
    selectedDates = @getFilteredDates @startDate, @endDate
    @addSelection selectedDates
    @eventManagerView.triggerEvent 'selectedDate', {selectedDates}

  navigatePreviousMonth: (e) ->
    calendarEl = @$el.find(@selectors.calendar)
    calendarEl.fullCalendar 'prev'
    @initializeWithDefaultData()
    @eventManagerView.triggerEvent 'calendarMonthUpdated', @getDateRangeToFetchInventory()

  navigateNextMonth: (e) ->
    calendarEl = @$el.find(@selectors.calendar)
    calendarEl.fullCalendar 'next'
    @initializeWithDefaultData()
    @eventManagerView.triggerEvent 'calendarMonthUpdated', @getDateRangeToFetchInventory()

  getDateRangeToFetchInventory: ()->
    calendarView = @$el.find(@selectors.calendar).fullCalendar 'getView'

    # Explanation
    #
    #              Visible Date Range
    #             |------------------|
    #
    # Case 1   Case 2A   Case 3   Case 2B   Case 1
    # |----|   |-----|   |----|   |-----|   |----|
    #
    #                    Case 4
    #          |------------------------|
    #
    #
    # Case 1: Selected date range is entirely outside visible date range
    # Fetch date range should be the visible date range, remove date selection
    #
    # Case 2: Selected date range partly overlaps with visible date range
    # Fetch date range should be the union of selected date range and visible date range, keep date selection
    #
    # Case 3: Selected date range is entirely inside visible date range
    # Fetch date range should be the visible date range, keep date selection
    #
    # Case 4: Visible date range is entirely inside selected date range
    # Fetch date range should be the selected date range, keep date selection

    dateStart = calendarView.visStart
    dateEnd = calendarView.visEnd

    if @startDate and @endDate
      switch
        # Case 1
        when @endDate < dateStart or dateEnd < @startDate
          @_unselectCalendar()
        # Case 2A
        when @startDate < dateStart and dateStart < @endDate < dateEnd
          dateStart = @startDate
        # Case 2B
        when dateStart < @startDate < dateEnd and dateEnd < @endDate
          dateEnd = @endDate
        # Case 4
        when @startDate < dateStart and dateEnd < @endDate
          dateStart = @startDate
          dateEnd = @endDate
        # Case 3
        else

    {dateStart: moment(dateStart), dateEnd: moment(dateEnd)}

  getCurrentDateCells: ->
    @$el.find(@selectors.dateCells)

  isStartOfMonth: (date) ->
    currentDate = moment(date).format('DD')
    startDate = moment(date).startOf('month').format('DD')
    currentDate is startDate

  getFilteredDates: (startDate, endDate) ->
    ignoredDays = @getIgnoredDays()
    dates = DateTimeHelper.getDatesInRange startDate, endDate
    _.filter dates, (date) =>
      not _.contains ignoredDays, moment(date).day()

  getIgnoredDays: ->
    ignoredDays = []
    _.each @ignoredDaysOfWeek, (value, index) ->
      ignoredDays.push(index) if value is '1'
    ignoredDays

  removeSelection: ->
    @getCurrentDateCells().removeClass 'selected'

  addSelection: (selectedDates) ->
    _.each selectedDates, (date) =>
      @$el.find(@selectors.dateCell(date)).addClass 'selected'

  goToDate: (date) ->
    # 17712 - needs timezone
    @$el.find(calendar).fullCalendar 'gotoDate', new Date(date)
    @initializeWithDefaultData()

  removeClasses: (classesList) ->
    classes = classesList.join(" ")
    @$(@selectors.dateCells)?.removeClass(classes)

  clearCalendarData: () ->
    dataCellsEl = @$(@selectors.dateCells)
    dataCellsEl.find('.center-data').text('')
    dataCellsEl.find('.bottom-data').text('')

  # format of data: [{date, centerData, bottomData, classes}]
  # dateFormat = 'YYYY-MM-DD'
  # classesFormat = ['class1', 'class2', 'class3']
  fillData: (dataArray) ->
    _.each dataArray, (item) =>
      date = item.date
      classes = item?.classes?.join(" ")
      @$el.find(@selectors.centerData(date))?.text(item.centerData)
      @$el.find(@selectors.bottomData(date))?.text(item.bottomData)
      @$el.find(@selectors.dateCell(date))?.addClass classes

  addErrorClass: (datesList) ->
    _.each datesList, (date) =>
      @$el.find(@selectors.dateCell(date))?.addClass 'error'

  addHighlightClass: (datesList)->
    @$(@selectors.dateCells)?.removeClass('highlight')
    _.each datesList, (date) =>
      @$el.find(@selectors.dateCell(date))?.addClass('highlight')

  _unselectCalendar: () ->
    @setStartAndEndDate()
    @selectDateRange()
