require '../presenters/recent_auto_updates'

class Extranet.Views.RecentAutoUpdates extends Backbone.View 

  el: '.recent-auto-updates'
  templateName: 'common_recent_auto_updates'

  initialize: (options) ->
    {hotelUuid, @productSetModel} = options
    @recentAutoUpdatesModel = new Extranet.Models.RecentAutoUpdates {hotelUuid}
    @presenter = new Extranet.Presenters.RecentAutoUpdates()
    @listenTo @recentAutoUpdatesModel, 'sync', @_renderRecentAutoUpdates

  render: () ->
    @_fetchRecentAutoUpdates()

  _fetchRecentAutoUpdates: () ->
    options =
      reset: true
      global: false
    @recentAutoUpdatesModel.fetch options

  _renderRecentAutoUpdates: () ->
    hash = @presenter.toHash @recentAutoUpdatesModel

    if @productSetModel?
      livePricingRule = @productSetModel.get('livePricingRule')
      productSetStatus = @productSetModel.get('status')
      unless productSetStatus in ['live', 'approved'] and livePricingRule in ['dailyDiscount']
        hash.grpnRateUpdatedAt = null

    @$el.html TemplateHelper.render @templateName, hash

