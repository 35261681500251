require "./views/admin_page_view"

class Extranet.Routers.AdminRouter extends Backbone.Router
  routes:
    ''      : 'showTaxes'
    'taxes' : 'showTaxes'
    'promotions_search' : 'showProductSearch'

  showTaxes: ()->
    @adminPageView ?= new Extranet.Views.AdminPageView
    @adminPageView.render()

  showProductSearch: () ->
    @adminPageView ?= new Extranet.Views.AdminPageView
    options =
      view: "productset_search"
    @adminPageView.render options
