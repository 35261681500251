require "./rateplan_aggregator"

class Extranet.Presenters.LmRatePlanSummaryPresenter
  utility = Extranet.Common.Utilities.Utility

  constructor : () ->
    @aggregator = Extranet.Presenters.RatePlanAggregator

  toHash: (data) ->
    {ratePlanData, firstDate, lastDate} = data
    model =
      name: @getRatePlanName ratePlanData
      bookingsPageLinkWithRoomType: ExtranetUrlBuilder.getBookingsPageUrlWithRoomType
        hotelUuid: ratePlanData?[0]?.hotel?.uuid
        ratePlanUuid: ratePlanData?[0]?.ratePlan?.uuid
        roomTypeUuid: ratePlanData?[0]?.roomType?.uuid
        checkInBegin: firstDate
        checkInEnd: lastDate
      ratePlanUuid: ratePlanData?[0]?.ratePlan?.uuid
      disableBaseRate: utility.shouldDisableBaseRate()

    currencyCode = ratePlanData?[0]?.hotel?.currencyCode
    model.currencySymbol = utility.getCurrencySymbol(currencyCode) if currencyCode

    aggregatedLmData = @getAggregatedLmData ratePlanData
    model = $.extend model, aggregatedLmData

    if model.blackoutStatus is 'partial'
      model.blackoutText = I18n.translate('js.modules.inventory.partial_blackout')
    else
      model.blackoutText = I18n.translate('js.modules.inventory.blackout')
    model.isCompleteBlackout = model.blackoutStatus is 'blackout'
    return model

  getAggregatedLmData: (ratePlanData) ->
    baseRate = @aggregator.getMinBaseRate ratePlanData
    grouponRate = @aggregator.getMinGrouponRate ratePlanData
    netRate = @aggregator.getMinNetRate ratePlanData

    discountPercentage = @aggregator.getMinDiscountPercentage ratePlanData
    closedOut = @aggregator.getAggregatedClosedOut ratePlanData
    noLastNight = @aggregator.getAggregatedNoLastNight ratePlanData
    closedToArrival = @aggregator.getAggregatedClosedToArrival ratePlanData
    blackoutStatus = @aggregator.getAggregatedBlackOut ratePlanData

    soldCount = @aggregator.getTotalSoldCount ratePlanData
    isAdmin = utility.isAdmin()

    {baseRate, grouponRate, netRate, closedOut, noLastNight, discountPercentage
    closedToArrival, blackoutStatus, soldCount, isAdmin }

  getRatePlanName: (ratePlanData) ->
    ratePlanData?[0]?.ratePlan?.name or ""

  computeNetRate: (grouponRate, margin) ->
   unless _.isEmpty(grouponRate?.toString()) or _.isEmpty(margin?.toString())
     return grouponRate * (1 - margin/100)
   return grouponRate
