window.Extranet ?= {}
Extranet.Inventory ?= {}
Extranet.Inventory.Utilities ?= {}
Extranet.Views ?= {}
Extranet.Views.WeekInventory ?= {}
Extranet.Models ?= {}
Extranet.Collections ?= {}
Extranet.Presenters ?= {}
Extranet.Validators ?= {}
Extranet.Common ?= {}
Extranet.Helper ?= {}
Extranet.Common.Utilities ?= {}
Extranet.Common.Utilities.Mixin ?= {}

NAMESPACES = [
  'Collections'
  'Controllers'
  'Helpers'
  'Models'
  'Presenters'
  'Routers'
  'Views'
  'Services'
]

registerTopLevelNamespace = =>
  _(NAMESPACES).each (ns) =>
    Extranet[ns] = {}

registerNamespace = (name) =>
  _(NAMESPACES).each (ns) =>
    Extranet[ns][name] = {}

registerNamespaceToOne = (parentNameSpace, childNameSpace) =>
  Extranet[parentNameSpace][childNameSpace] = {}

registerTopLevelNamespace()
