require "./views/bookings_page"

class Extranet.Routers.Bookings extends Backbone.Router

  routes:
    '?*queryString': 'search'
    '': 'search'

  initialize: () ->
    @bookingsPageView ?= new Extranet.Views.BookingsPage()
    @bookingsPageView.render()

  search: (queryString) ->
    searchParams = if queryString then $.parseParams(queryString) else {}
    if _.isEmpty(searchParams)
      # Defaulting to today bookings
      today = DateTimeHelper.formatDate DateTimeHelper.getCurrentTime(), 'api'
      searchParams =
        check_in_begin: today
        check_in_end: today
      window.location.hash = "?#{$.param(searchParams)}"
    else
      @bookingsPageView.searchBookings(searchParams)
