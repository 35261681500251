require "./summary_collection_view"
require "./inventory_calendar_view_v2"
require "./product_set_rules_view"
require "./validation_err_msg_view"
require "./status_change_validation_messages_view"
require "../../../../inventory_common/frontend/views/filter"
require "../../../../products/frontend/helpers/productset_status_helper"

class Extranet.Views.MonthViewV2 extends Backbone.View
  Extranet.Common.Utilities.Mixin.extends this, Extranet.Helpers.ChildManager

  templateName: 'inventory_templates_month_view_v2'
  eventManagerKey: Extranet.Helpers.EventManagerHelper.PS_MONTH_VIEW_KEY

  selectors:
    filterView: '.filter-section'
    calendarView: '.calendar-month-view'
    summaryCollectionView: '#room_inv_section'
    rulesView: '#ps-rules'
    validationMessages: '#validation-messages'
    ratePlanSelector: ".rate-plan-selector"

  events:
    'change .rate-plan-selector': 'updateCalendarForSelectedRatePlan'
    'click .today-selector': 'selectToday'

  initialize: (options)->
    @hotelUuid = options.hotelUuid
    @productSetUuid = options.productSetUuid
    @productSetModel = options.productSetModel
    @productTypes = options.productTypes
    @alertMessage = Extranet.Helper.AlertView.getAlertView()
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    Extranet.Helpers.EventManagerHelper.initialize(@eventManagerKey)
    @eventManagerView = Extranet.Helpers.EventManagerHelper.getEventManagerView(@eventManagerKey)
    @listenTo @eventManagerView, 'calendarMonthUpdated', @fetchInventory
    @listenTo @eventManagerView, 'selectedDates', @showSummary
    @listenTo @eventManagerView, 'selectedDates', @hightlightTodayIfSelected
    @listenTo @eventManagerView, 'stateChangeSuccess', @render
    @inventoryClient = new Extranet.Inventory.Utilities.InventoryApiClient()
    @statusHelper = Extranet.Helpers.ProductSetStatusHelper
    @hideWeekView = options.hideWeekView

  render: ()->
    @$el.html @autoTemplate.render @templateName,
      {
        isPSMonthView: @isPSMonthView()
        hideWeekView: @hideWeekView
      }
    @getFilterView().render()
    @getCalendarView().render()
    @initValidationMsgView()
    @initStatusChangeValidationView()
    if @isPSMonthView()
      @getRulesView().render()
      {startAt, endAt} = @getTravelWindow()

    fetchOptions =
      dateStart: moment(startAt or new Date()).startOf('month').subtract(6, 'days')
      dateEnd: moment(endAt or new Date()).endOf('month').add(12, 'days')
    @fetchInventory(fetchOptions)

    @delegateEvents()
    this

  isPSMonthView:  ->
    @productSetModel isnt undefined

  isReviewViolations: ()->
    @isPSMonthView() and @productSetModel.get('status') is @statusHelper.states.REVIEW_VIOLATIONS.name

  initStatusChangeValidationView: ()->
    @statusChangeValidationMessagesView =
      @addChild new Extranet.Views.StatusChangeValidationMessagesView
        el: @selectors.validationMessages
        hotelUuid: @hotelUuid
        productSetModel: @productSetModel
        eventManager: @eventManagerView

  # NOTE: ValidationErrMsgView should be initialized before fetching inventory.
  initValidationMsgView: ()->
    @validationMsgView = @addChild new Extranet.Views.ValidationErrMsgView
      el: @$ @selectors.validationMessages
      eventManager: @eventManagerView

  getSummaryCollectionView: (options = {})->
    @removeChild("summaryCollectionView")
    options = _.extend options,
      hotelUuid: @hotelUuid
      productSetUuid: @productSetUuid
      productSetModel: @productSetModel
      eventManagerKey: @eventManagerKey

    @summaryCollectionView = @addChild new Extranet.Views.SummaryCollectionView(options), "summaryCollectionView"

    @summaryCollectionView

  getFilterView: ()->
    @filterView?.remove()
    @filterView = @addChild new Extranet.Views.BDInventoryFilter {
      el: @selectors.filterView
      eventManagerKey: @eventManagerKey
    }
    @filterView

  getCalendarView: (options)->
    @calendarView?.remove()
    @calendarView = @addChild new Extranet.Views.InventoryCalendarViewV2 {
      el: @selectors.calendarView
      eventManagerKey: @eventManagerKey
      productTypes: @productTypes
    }
    @calendarView

  fetchInventory: (options)->
    fetchParams = @getFetchParams(options)
    {@beginDate, @endDate} = fetchParams
    req = @inventoryClient.fetchInventory {data: fetchParams}
    @showLoading()
    $.when(req).done( (data)=>
      @inventoryData = data
      selectedRatePlanData = @getSelectedRatePlanData()
      @updateRatePlansList()
      @eventManagerView.triggerEvent 'fetchInventory', selectedRatePlanData
      @calendarView.updateCalendarData({data: selectedRatePlanData, @beginDate, @endDate})
      if @isReviewViolations()
        @statusChangeValidationMessagesView.render()
    ).fail( (xhr)=>
      requestId = xhr?.getResponseHeader('x-request-id')
      @alertMessage.renderDefaultError(requestId)
    ).always( ()=>
      @hideLoading()
    )

  getFetchParams: (options)->
    dateStart = moment(options.dateStart)
    dateEnd = moment(options.dateEnd or options.dateStart)

    data =
      _csrf: $('#csrf_token').val()
      beginDate: DateTimeHelper.formatDate dateStart, 'api'
      endDate: DateTimeHelper.formatDate dateEnd, 'api'

    if @isPSMonthView()
      $.extend data, {productSetUuids: [@productSetUuid], productType: 'bookingDeal'}
      if @productSetModel.get('status') in ['approved', 'live', 'finished']
        $.extend data, {fields: 'approvedDiscountPercentage'}
    else
      $.extend data, {hotelUuids: [@hotelUuid], productType: 'lastMinute', onlyLiveRatePlans: true, fields: 'approvedDiscountPercentage'}
    data

  getRulesView: ()->
    @rulesView ?= @addChild new Extranet.Views.ProductSetRulesView {
      @productSetModel
      el: @selectors.rulesView
    }

    @rulesView

  showSummary: (options) ->
    @$el.find(@selectors.summaryCollectionView).html(@getSummaryCollectionView(options).render().el)

  getTravelWindow: ->
    restrictions = @productSetModel.get('restriction')
    travelWindow = restrictions?.maxBlackOutDates?.travelWindow ? {}

  showLoading: ()->
    @$('.month-view-calendar').addClass 'loading'

  hideLoading: ()->
    @$('.month-view-calendar').removeClass 'loading'

  updateRatePlansList: ()->
    selectedRatePlan = @$el.find(@selectors.ratePlanSelector).val()
    @$el.find(@selectors.ratePlanSelector).empty()
    allRatePlansText = I18n.t("js.modules.inventory.templates_month_view_v2.show_all_rates")
    @$el.find(@selectors.ratePlanSelector).append("<option value=''>#{allRatePlansText}</option>")

    ratePlans = _.uniq(_.collect(@inventoryData?.hotelInfo, (hotelData)-> hotelData.ratePlan))
    sortedRatePlans = _.sortBy(ratePlans, (ratePlan)-> ratePlan.name)

    for ratePlan in sortedRatePlans
      selected = if ratePlan.uuid is selectedRatePlan then 'selected' else ''
      @$el.find(@selectors.ratePlanSelector).append("<option value='#{ratePlan.uuid}' #{selected}>#{ratePlan.name}</option>")

  updateCalendarForSelectedRatePlan: ()->
    data = @getSelectedRatePlanData()
    @calendarView.updateCalendarData({data, @beginDate, @endDate})

  getSelectedRatePlanData: ()->
    selectedRatePlan = @$el.find(@selectors.ratePlanSelector).val()
    return @inventoryData unless selectedRatePlan?.length

    data = {}
    data.hotelInfo = _.filter(@inventoryData?.hotelInfo, (hotelData)-> hotelData.ratePlan.uuid is selectedRatePlan)
    data.formattedListByDate = _.filter(@inventoryData?.formattedListByDate, (inventoryObj)-> inventoryObj.ratePlan.uuid is selectedRatePlan)
    data

  selectToday: ()->
    # 17712 - find "today" with the hotel's timezone. This is going to be off more often for EMEA hotels.
    today = DateTimeHelper.formatDate DateTimeHelper.getCurrentTime(), 'numeric'
    @$el.find('#input-checkin-from').val(today)
    @$el.find('#input-checkin-to').val(today).trigger('change')

  hightlightTodayIfSelected: (options={})->
    if options.isTodaySelected
      @$el.find('.today-selector').parent().addClass('active')
    else
      @$el.find('.today-selector').parent().removeClass('active')
