window.Cookie = {
  build: function() {
    var parts = Array.prototype.slice.apply(arguments);
    for(var i = 0; i < parts.length; i++) {
      if(!parts[i])
        parts.splice(i, 1);
    }
    return parts.join("; ");
  },
  secondsFromNow: function(seconds) {
    var d = new Date();
    d.setTime(d.getTime() + (seconds * 1000));
    return d.toGMTString();
  },

  // This weird encoding/decoding of cookie values is due to the way
  // Rack::Utils#escape handles cookie serialization. In order to
  // stay consistent with how rack will serialize cookies, we
  // are performing it on the client side.
  //
  set: function(name,value,seconds, domain){
    var cookieExpiry = this._getExpiry(seconds),
        cookieDomain = this._getDomain(domain);

    if (typeof value === "string") {
      value = value.replace(/\s/g, '+');
    }
    document.cookie = Cookie.build(name + "=" + encodeURIComponent(value), cookieExpiry, "path=/", cookieDomain);
  },
  get: function(name){
    var valueMatch = new RegExp("(?:^|;(?:\\s*))" + name + "=([^;]+)").exec(document.cookie);
    var value = valueMatch ? valueMatch[1] : null;
    return value !== null ? decodeURIComponent(value).replace(/\+/g, ' ') : null;
  },

  unset: function(name, domain){
    Cookie.set(name, '', -1, domain);
  },

  _getExpiry: function(seconds, domain) {
    return seconds ? 'expires=' + Cookie.secondsFromNow(seconds) : null;
  },
  _getDomain: function(domain) {
    return domain ? 'domain=' + domain : null;
  }
};
