require "./room_inventory_view"
require "./summary_view"

class Extranet.Views.WeekViewInventory extends Backbone.View
  Extranet.Common.Utilities.Mixin.extends this, Extranet.Helpers.ChildManager
  dateCellUtility = Extranet.Inventory.DateCellUtility
  utility = new Extranet.Common.Utilities.Utility()

  templateName: 'inventory_templates_weekview_inventory'
  selectors:
    roomsInventory: '.rooms-inventory'
    alertMessage: '.error-message'
    buttonSection: '.btn-section'
    inventory: '#inventory'
    weekviewSummary: '#week-view-summary'

  roomInventories: []
  events:
    'click #week-view-save': 'save'
    'click #week-view-cancel': 'cancel'

  initialize: () ->
    @inventoryClient = new Extranet.Inventory.Utilities.InventoryApiClient()
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    @alertMessage = Extranet.Helper.AlertView.getAlertView()

  render: (filters={}) ->
    @hotelUuid = filters.hotelUuid
    @dates = filters.dates
    this.$el.html @autoTemplate.render @templateName, {}

    @clear()
    @initAlertMessage()
    @renderSummaryView()
    @showRoomsInventory()
    @delegateEvents()
    this

  clear: ()->
    @removeChildren()
    @roomInventories = []

  initAlertMessage: ()->
    unless @alertMessage
      @alertMessage = new Extranet.Views.AlertMessage
        selector: @selectors.alertMessage

  renderSummaryView: ()->
    unless @summaryView
      @summaryView = @addChild new Extranet.Views.WeekInventory.SummaryView()

    @$(@selectors.weekviewSummary).html @summaryView.render().el

  showRoomsInventory: ()->
    # fetch inventory
    params =
      _csrf: $('#csrf_token').val()
      hotelUuids: [@hotelUuid]
      beginDate: DateTimeHelper.formatDate @dates[0], 'api'
      endDate: DateTimeHelper.formatDate @dates[@dates.length-1], 'api'
      productType: 'lastMinute'
      onlyLiveRatePlans: true

    @fetchRatesAndInventory params

  fetchRatesAndInventory: (params)=>
    @showLoading()

    alwaysCallback = ()=>
      @hideLoading()

    successCallback = (data)=>
      @renderInventory data, params

    errorCallback = (xhr)=>
      @summaryView.reset()
      requestId = xhr?.getResponseHeader('x-request-id')
      @alertMessage.renderDefaultError(requestId)

    req = @inventoryClient.fetchInventory {data: params}

    $.when(req).always(alwaysCallback).done(successCallback).fail(errorCallback)

  renderInventory: (inventoryData={}, params={})=>
    {beginDate, endDate} = params
    {formattedListByDate, hotelInfo} = inventoryData

    unless hotelInfo?.length
      return @renderNoResultsMessage()

    dates = DateTimeHelper.getDatesInRange beginDate, endDate
    datecells = dateCellUtility.getDateCells formattedListByDate, hotelInfo, dates

    @summaryView.updateSummary datecells

    listByRoomType = _.groupBy datecells, (item) ->
        item.roomType.uuid

    _.each listByRoomType, (dateCellsByRoomType)=>
      datecellInsideTravelWindow = _.find(dateCellsByRoomType, (datecell) -> not datecell.dateOutsideTravelWindow)
      if datecellInsideTravelWindow
        roomInventoryView = @addChild new Extranet.Views.WeekInventory.RoomInventoryView
          datecells: dateCellsByRoomType
        @$(@selectors.roomsInventory).append roomInventoryView.render().el
        @roomInventories.push roomInventoryView

    unless DateTimeHelper.isPastDate endDate, hotelInfo[0]?.hotel?.timeZone?.identifier
      @showSubmitButtons()

  renderNoResultsMessage: ()->
    message = utility.translate "inventory.error_messages.no_records_found"
    @$(@selectors.roomsInventory).html message

  save: (e)->
    e.preventDefault()
    # call save of all sub views.
    @alertMessage.hide()
    requests = []
    validationSuccess = true
    # Validate all the roominventories first
    for roomInventory in @roomInventories
      validationSuccess = validationSuccess and roomInventory.validate()
    if not validationSuccess
      @alertMessage.renderDefaultValidationError()
      return

    requestIdPrefix = Extranet.Common.Utilities.Utility.getRandomString(20)

    # Save once validation is success
    for roomInventory in @roomInventories
      requests = requests.concat roomInventory.save(requestIdPrefix)

    $.when.apply(undefined, requests).done( =>
      message = I18n.translate('js.modules.common.alerts.success.saved_rates_and_inventory')
      @alertMessage.renderSuccess(message, requestIdPrefix)
      @render({@dates, @hotelUuid})
    ).fail =>
      @alertMessage.renderDefaultError(requestIdPrefix)

  cancel: (e)->
    e.preventDefault()
    for roomInventoryView in @roomInventories
      roomInventoryView.render()

  showLoading: ()->
    @$el.addClass 'loading'
    @clearData()

  hideLoading: ()->
    @$el.removeClass 'loading'

  clearData: ()->
    @$(@selectors.roomsInventory).empty()
    @hideSubmitButtons()

  showSubmitButtons: ()->
    @$(@selectors.buttonSection).removeClass 'hide'

  hideSubmitButtons: ()->
    @$(@selectors.buttonSection).addClass 'hide'
