require "../helpers/create_hotel"

class Extranet.Views.CreateHotelFormView extends Backbone.View
  el: '#create-hotel'

  selectors:
    alertMessage: "#create-hotel .alert-message"
    successMessagePopup: '.success-message-popup'
    countrySelector: '#country-selector'
    stateSelector: '#state-selector'
    citySelector: '#city-selector'
    timezoneSelector: '#timezone-selector'

  successMessagePopupTemplate: "hotels_templates_success_popup"

  events:
    'click #create-hotel-submit': 'save'
    'click #create-hotel-cancel': 'cancel'

  initialize: () ->
    @alertMessage = new Extranet.Views.AlertMessage {selector: @selectors.alertMessage}
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    @model = new Extranet.Models.Hotel()
    @errorPresenter = new Extranet.Presenters.Error()
    @$el.parsley(Extranet.ParsleyConfig)

  render: () ->
    @countrySelector = $(@el).find(@selectors.countrySelector)
    @stateSelector = $(@el).find(@selectors.stateSelector)
    @citySelector = $(@el).find(@selectors.citySelector)
    @timeZoneSelector = $(@el).find(@selectors.timezoneSelector)
    @locationSelector = new Extranet.Helpers.LocationSelector @countrySelector, @stateSelector, @citySelector, {}
    @renderTimeZones()

  renderTimeZones: ()->
    options =
      optionValueKey: 'zone'
      optionNameKey: 'zone'
      includeEmptyOption: true
    Extranet.Common.Utilities.Render.updateDropdownOptions "time_zones", @timeZoneSelector, {}, options

  save: (e) ->
    e.preventDefault()
    @alertMessage.hide()
    unless @$el.parsley('validate')
      @alertMessage.renderDefaultValidationError()
      return

    saveAttrs = Extranet.Helpers.CreateHotel.getSaveAttrs(this)

    @model.save saveAttrs, {
      patch: true
      wait: true
      success: (model, response, options)=>
        requestId = options?.xhr?.getResponseHeader('x-request-id')
        @renderSuccessPopup(requestId)
      error: (model, response, options)=>
        requestId = options?.xhr?.getResponseHeader('x-request-id')
        responseJSON = options?.xhr?.responseJSON
        duplicateQl2IdFoundError = @errorPresenter.findError responseJSON, 'DUPLICATE_QL2_ID'
        if duplicateQl2IdFoundError
          message = @errorPresenter.format duplicateQl2IdFoundError
          @alertMessage.renderError message, requestId
        else
          @alertMessage.renderDefaultError requestId
    }

  renderSuccessPopup: (requestId)->
    hotelName = "<span class='hotel-name'>#{@model.get('name')}</span>"
    hotelUuid = @model.get('uuid')

    hash =
      message: I18n.translate('js.modules.common.alerts.success.hotel_created', {hotelName})
      requestIdText: I18n.translate("js.modules.common.alerts.request_id", {requestId})
      hotelProfileUrl: ExtranetUrlBuilder.getProfilePageUrl({hotelUuid})

    $(@selectors.successMessagePopup).html(@autoTemplate.render(@successMessagePopupTemplate, hash))
    $(@selectors.successMessagePopup).addClass('active')

  cancel: (e) ->
    e.preventDefault()
    @render()
