###
Sample format of QL2 failed validation:
failedValidations": [
  {
    "failures": [
      {
        "restriction": "not null and > 0",
        "actual": "",
        "instance": "2014-11-06"
      }
    ],
    "restrictionType": "ql2Rates",
    "entityUuid": "f5e91793-05a6-4e0a-b497-4211e5362313",
    "description": "Validate QL2 Rates for each travel window date.",
    "validator": "Ql2Validator",
    "entityType": "RatePlan"
  }
}
###

class Extranet.Presenters.Ql2RatesErrorsPresenter
  @invalidQL2Restriction = "not null and > 0"
  @translationKeys =
    allKeys: 'inventory.ql2_rates_errors_presenter'
    objectType: 'inventory.ql2_rates_errors_presenter.object_type'

  @defaultPresenter = Extranet.Presenters.DefaultFailurePresenter
  @helper = Extranet.Helpers.ValidationPresentersHelper

  @getErrorMessages: ({failedValidation, hotelInfo})->
    errorMessages = []
    ratePlanInfo = @helper.getRatePlanInfo hotelInfo, failedValidation.entityUuid
    validationAttrs = _.pick failedValidation, 'description', 'restrictionType'
    messages = '<ul>'
    for failure in failedValidation.failures
      messages += '<li>' + @getMessage(failure, validationAttrs) + '</li>'

    messages += "</ul>"

    # yes making it worse I know
    # done as part of 16825
    objectUuid = failedValidation.entityUuid
    objectType = @helper.getTranslations @translationKeys.objectType
    objectName = ratePlanInfo?.name
    totalViolations = failedValidation.failures.length
    violationName = validationAttrs.description

    # if a violation falls through that we didn't cover, fall back on the old messaging.
    violationHandled = _.has(I18n.translate("js.modules.inventory.templates_monthview_single_violation_summary"), failedValidation.restrictionType)

    if (failedValidation.failures.length > 1) && violationHandled
      messagesHeader = I18n.translate("js.modules.inventory.templates_monthview_single_violation_summary.#{failedValidation.restrictionType}", {totalViolations})
      messages = Extranet.Templates['inventory_templates_monthview_single_violation_summary'].render({messagesHeader: messagesHeader, messages: messages})

    errorMessages.push messages

    {
      messages: errorMessages
      objectUuid: objectUuid
      objectType: objectType
      objectName: objectName
    }

  @getMessage: (failure, validationAttrs)->
    translations = @helper.getTranslations @translationKeys.allKeys
    if failure.restriction is @invalidQL2Restriction
      date = DateTimeHelper.formatDate failure.instance, 'numeric'
      message = date + ' ' + translations.invalid_ql2_rate
    else
      message = translations.description + ':' + validationAttrs.description +
      '; ' + @defaultPresenter.getMessage failure

    return message
