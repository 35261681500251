require "./validations/validation_presenters_helper"
require "./validations/default_failure_presenter"
require "./validations/default_validation_presenter"
require "./validations/state_transition_errs_presenter"
require "./validations/ql2_rates_errs_presenter"
require "./validations/ps_fields_err_presenter"
require "./validations/rates_restriction_err_presenter"
require "./validations/inventory_validation_errs_presenter"

class Extranet.Presenters.ProductSetValidationPresenter

  presenters =
    'productsetstatetransitionvalidator': Extranet.Presenters.StateTransitionErrsPresenter
    'ql2validator': Extranet.Presenters.Ql2RatesErrorsPresenter
    'productsetinventoryvalidator': Extranet.Presenters.InventoryValidationErrsPresenter
    'productsetratesvalidator': Extranet.Presenters.RatesRestrictionsErrPresenter
    'productsetfieldsvalidator': Extranet.Presenters.PsFieldsErrPresenter


  _getParentData = (hotelInfo, uuid) ->
  # frontend was making improper assumptions correlating entityUuid and
  # validator. We want to always nest the violations under the object where the
  # restrictions are actually set - only either Rate Plan, Room Type, or Product Set. By this
  # point we have lost the data needed to determine where this uuid is coming from,
  # so rather than change it in 6+ places probably improperly, I'm just doing it
  # here with the understanding we will rewrite the entire violations handling.
    @helper = Extranet.Helpers.ValidationPresentersHelper
    ratePlanInfo = @helper.getRatePlanInfo hotelInfo, uuid
    roomTypeInfo = @helper.getRoomTypeInfo hotelInfo, uuid
    productSetInfo = @helper.getProductSetInfo hotelInfo, uuid
    if ratePlanInfo
      parentData = {
        name: ratePlanInfo.name
        objectType: "Rate Plan"
      }
    else if roomTypeInfo
      parentData = {
        name: roomTypeInfo.name
        objectType: "Room Type"
      }
    else
      parentData = {
        name: productSetInfo?.name
        objectType: "Product Set"
      }
    return parentData

  # this logic is replicated in
  # modules/inventory/frontend/views/month_view/validation_err_msg_view.coffee,
  # but I don't see those methods being called. Instead it appears that this
  # presenter, despite the Product Set in the name, handles violation messages
  # of all kinds
  _getGroupedMessages = (errorMessages=[], hotelInfo={})->
    return [] unless errorMessages?.length

    # Group all related messages by the uuid passed in. There is no guarantee
    # of what type of object the uuid is representing, regardless of
    # "objectType" passed in.
    errorMessagesByObject = _.groupBy errorMessages, (singleMessage)->
      singleMessage.objectUuid

    messagesArray = _.toArray errorMessagesByObject
    groupedMessages = []
    for messageObj in messagesArray
      parentData = _getParentData(hotelInfo, messageObj[0].objectUuid)
      objectName = parentData.name
      objectType = parentData.objectType
      messages = []

      for msg in messageObj
        messages = messages.concat msg.messages

      # objectType: can be "Room Type", "Rate Plan"... other?
      # objectName: the "name" property of whatever the object type is, ie
      #   rate plan name, product set name, room name
      # messages: array of strings of concatenated html elements,
      #   which are <ul>s describing the invidiual violations for this specific
      #   item and violation type
      groupedMessages.push {objectType, objectName, messages}
    groupedMessages

  # this calls @getErrorMessages in the respective Violation error presenters which are shown at top.
  @getViolationMessages: (failedValidations=[], hotelInfo={})->
    return [] unless failedValidations?.length

    messages = []

    for failedValidation in failedValidations
      presenter = presenters[failedValidation.validator.toLowerCase()]
      if presenter
        messages.push presenter.getErrorMessages({failedValidation, hotelInfo})
    messages = _.reject messages, (num) ->
      num == null

    violationMessages = _getGroupedMessages(messages, hotelInfo)
    violationMessages
