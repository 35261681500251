require '../../inventory_common/frontend/rates_utility'
require '../../inventory_common/utilities/date_cell_utility'
require './helpers/inventory_api_client'
require './helpers/inventory'
require './views/inventory_page_view'

class Extranet.Routers.InventoryRouter extends Backbone.Router
  routes:
    '(:view)': 'showHotelInventory'

  showHotelInventory: (view) ->
    unless @inventoryPageView
      @inventoryPageView = new Extranet.Views.InventoryPageView()

    @inventoryPageView.render
      hotelUuid: Extranet.ClientConfig.hotelData?.uuid
      view: view ? 'monthview'
      hideWeekView: Extranet?.FeatureFlags?.hide_week_view
