'use strict';
require('I18n');
require('expose-loader?exposes[]=$&exposes[]=jQuery!jQuery');
require('jQueryUI');
require('jQueryTE');
require('jQueryFileUpload');
require('expose-loader?exposes[]=_!underscore');
require('backbone');
require('backbonePaginator');
require('expose-loader?exposes[]=moment!moment');
require('momentTimezone');
require('expose-loader?exposes[]=Mustache!mustache');
require('jQuerySimplePagination');
require('fullCalendar');
require('toolstrap');
require('parsley');
require('cookie');
require('optimizeSuite');
require('boomerang');
require('select2');

require('../../modules/common/frontend/main');
require('../../modules/hotels/frontend/main');
require('../../modules/hotel_search/frontend/main');
require('../../modules/admin/frontend/main');
require('../../modules/authentication/frontend/main');
require('../../modules/authentication_2fa/frontend/main');
require('./cookie-consent');
