require "../../../../profile/frontend/presenters/rate_plan_presenter"

class Extranet.Views.RatePlanReadOnlyView extends Backbone.View
  templateName:'inventory_rate_plan_popup'
  blackoutDayTemplate: 'inventory_rate_plan_blackout_date'

  selectors:
    alertMessage: ".alert"
    modalCloseButton: ".close-modal"
    modalContent: ".modal-content"

  initialize: (options = {}) ->
    {@ratePlanData} = options
    @hotelUuid = @ratePlanData?[0]?.hotel?.uuid
    @ratePlanUuid = @ratePlanData?[0]?.ratePlan?.uuid
    @roomTypeDetails = @ratePlanData?[0]?.roomType
    @roomTypeUuid = @roomTypeDetails.uuid
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    @valueAdds = new Extranet.Collections.ValueAdds()
    @alertMessage = Extranet.Helper.AlertView.getAlertView()

  fetchRatePlan: ()->
    @model = new Extranet.Models.RatePlanModel
      uuid: @ratePlanUuid
      hotelUuid: @hotelUuid
      roomTypeUuid: @roomTypeUuid
    @showLoading()
    @model.fetch(
      global : false
    ).done(()=>
      @model.isFetched = true
      @render()
    ).fail((xhr)=>
      @closeView()
      requestId = xhr?.getResponseHeader('x-request-id')
      @alertMessage.renderDefaultError(requestId)
    ).always(()=>
      @hideLoading()
    )

  hideLoading: ()->
    @$(@selectors.modalContent).removeClass 'loading'

  showLoading: ()->
    @$(@selectors.modalContent).addClass 'loading'

  render: () ->
    unless @model?.isFetched
      @fetchRatePlan()
      return

    presenter = new Extranet.Presenters.RatePlanPresenter()
    model = presenter.toHash @model
    valueAdds = presenter.getValueAdds(@model)
    ratePlanDetailUrl = ExtranetUrlBuilder.getHotelRatePlanDetailUrl {@hotelUuid, @roomTypeUuid, @ratePlanUuid}
    toolTipTitleHash = presenter.gettoolTipTitle()
    @$(@selectors.modalContent).html @autoTemplate.render @templateName, {
      model
      valueAdds
      ratePlanDetailUrl
      toolTipTitleHash
      room:@roomTypeDetails
    }
    this

  closeView: (e)->
    @$(@selectors.modalCloseButton).click()
