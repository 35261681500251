HOTEL_ID_MAX_LIMIT = 14
ROOM_TYPE_ID_MAX_LIMIT = 20
RATE_PLAN_ID_MAX_LIMIT = 20

class Extranet.Helpers.ChannelManagerValidation
  errorCodes:
    validationFail: 'VALIDATION_FAIL'
    emptyHotelId: 'EMPTY_HOTEL_ID'
    emptyRoomTypeId: 'EMPTY_ROOM_TYPE_ID'
    duplicatedHotelId: 'DUPLICATED_HOTEL_ID'
    duplicatedRoomTypeId: 'DUPLICATED_ROOM_TYPE_ID'
    duplicatedRatePlanId: 'DUPLICATED_RATE_PLAN_ID'

  getChannelManagerParsleyConfig: ()->
    channelManagerParsleyConfig =  $.extend true, {}, Extranet.ParsleyConfig

    $.extend channelManagerParsleyConfig.validators,
      channelmanagerhotelidcheck: @_getChannelManagerHotelIdCheck()
      channelmanagerroomtypeidcheck: @_getChannelManagerRoomTypeIdCheck()
      channelmanagerrateplanidcheck: @_getChannelManagerRatePlanIdCheck()

    channelManagerParsleyConfig

  validateMappingInformation: (view, mappingInformation={}) ->
    errors = []
    {channelManagerId, roomTypes} = mappingInformation
    return false unless roomTypes?.length
    @_verifyHotelForEmptyChannelManagerId channelManagerId, roomTypes, errors
    @_verifyRoomTypesForEmptyChannelManagerId roomTypes, errors
    @_verifyDuplicateRoomTypeChannelManagerIds roomTypes, errors
    @_verifyDuplicateRatePlanChannelManagerIds roomTypes, errors

    if errors.length
      @displayFieldErrors(view, errors)
      return false
    else
      return true

  displayFieldErrors: (view, errors) ->
    return unless errors?.length

    for error in errors
      switch error.errorCode
        when @errorCodes.emptyHotelId
          @_displayEmptyHotelIdError(view, error)
        when @errorCodes.emptyRoomTypeId
          @_displayEmptyRoomTypeIdError(view, error)
        when @errorCodes.duplicatedHotelId
          @_displayDuplicateHotelIdError(view, error)
        when @errorCodes.duplicatedRoomTypeId
          @_displayDuplicateRoomTypeIdError(view, error)
        when @errorCodes.duplicatedRatePlanId
          @_displayDuplicateRatePlanIdError(view, error)
        else
          return

  # Verifying hotel channel manager id for empty
  # Hotel channel manager id should not be empty if any of its room types has channel manager id
  _verifyHotelForEmptyChannelManagerId: (hotelChannelManagerId, roomTypes, errors) ->
    anyRoomTypeHasChannelManagerId = _.some roomTypes, (roomType) ->  roomType.channelManagerId?.length > 0
    if anyRoomTypeHasChannelManagerId and hotelChannelManagerId?.length is 0
      errors.push
        errorCode: @errorCodes.emptyHotelId

  # Verifying room type channel manager id for empty
  # Room type channel manager id should not be empty if any of its rate plans has channel manager id
  _verifyRoomTypesForEmptyChannelManagerId: (roomTypes, errors) ->
    for roomType in roomTypes
      {ratePlans} = roomType
      anyRatePlanHasChannelManagerId = _.some ratePlans, (ratePlan) -> ratePlan.channelManagerId?.length > 0
      if anyRatePlanHasChannelManagerId and roomType.channelManagerId?.length is 0
        errors.push
          errorCode: @errorCodes.emptyRoomTypeId
          uuid: roomType.uuid

  # Verifying duplicate room type channel manager ids
  # The channel manager id can not be duplicated on the room type under the same hotel
  _verifyDuplicateRoomTypeChannelManagerIds: (roomTypes, errors) ->
    filteredRoomTypes = _.filter roomTypes, (roomType)-> roomType.channelManagerId?.length > 0
    groupedRoomTypes = _.groupBy filteredRoomTypes, (roomType)-> roomType.channelManagerId
    for key, roomTypesArray of groupedRoomTypes
      if roomTypesArray?.length > 1
        errors.push
          errorCode: @errorCodes.duplicatedRoomTypeId
          uuids: _.map roomTypesArray, (roomType)-> roomType.uuid

  # Verifying duplicate rate plan channel manager ids
  _verifyDuplicateRatePlanChannelManagerIds: (roomTypes, errors) ->
    for roomType in roomTypes
      {ratePlans} = roomType
      filteredRatePlans = _.filter ratePlans, (ratePlan)-> ratePlan.channelManagerId?.length > 0
      groupedRatePlans = _.groupBy filteredRatePlans, (ratePlan)-> "#{ratePlan.productSetUuid}_#{ratePlan.channelManagerId}"
      for key, ratePlansArray of groupedRatePlans
        if ratePlansArray?.length > 1
          errors.push
            errorCode: @errorCodes.duplicatedRatePlanId
            uuids: _.map ratePlansArray, (ratePlan)-> ratePlan.uuid

  _getChannelManagerHotelIdCheck: ()->
    (val, el, self) ->
      errorMessageEl = self.$element.siblings('.field-error-message')
      errorMessageEl.addClass 'hide'

      # Validating the maximum allowed characters
      if val.length > HOTEL_ID_MAX_LIMIT
        errorMessageEl.text I18n.t('js.modules.common.alerts.validation.max_allowed_characters', {maxCharLimit: HOTEL_ID_MAX_LIMIT})
        errorMessageEl.removeClass 'hide'
        return false

      return true

  _getChannelManagerRoomTypeIdCheck: ()->
    (val, el, self) ->
      errorMessageEl = self.$element.siblings('.field-error-message')
      errorMessageEl.addClass 'hide'

      # Validating the maximum allowed characters
      if val.length > ROOM_TYPE_ID_MAX_LIMIT
        errorMessageEl.text I18n.t('js.modules.common.alerts.validation.max_allowed_characters', {maxCharLimit: ROOM_TYPE_ID_MAX_LIMIT})
        errorMessageEl.removeClass 'hide'
        return false

      return true

  _getChannelManagerRatePlanIdCheck: ()->
    (val, el, self) ->
      errorMessageEl = self.$element.siblings('.field-error-message')
      errorMessageEl.addClass 'hide'

      # Validating the maximum allowed characters
      if val.length > RATE_PLAN_ID_MAX_LIMIT
        errorMessageEl.text I18n.t('js.modules.common.alerts.validation.max_allowed_characters', {maxCharLimit: RATE_PLAN_ID_MAX_LIMIT})
        errorMessageEl.removeClass 'hide'
        return false

      return true

  _displayEmptyHotelIdError: (view, error)->
    errorMessage = I18n.t 'js.modules.profile.channel_manager.alerts.empty_hotel_id'
    @_displayErrorOnHotel view, errorMessage

  _displayEmptyRoomTypeIdError: (view, error)->
    errorMessage = I18n.t 'js.modules.profile.channel_manager.alerts.empty_room_type_id'
    roomTypeUuid = error.uuid
    @_displayErrorOnRoomType view, roomTypeUuid, errorMessage

  _displayDuplicateHotelIdError: (view, error)->
    otherHotelUrl = ExtranetUrlBuilder.getHotelChannelManagerUrl
      hotelUuid: error.ids?.internalId
    otherHotelLink = "<a href='#{otherHotelUrl}' target='_blank'>#{I18n.translate('js.modules.profile.channel_manager.hotel')}</a>"
    errorMessage = I18n.t 'js.modules.profile.channel_manager.alerts.duplicate_hotel_id', {otherHotelLink}
    @_displayErrorOnHotel view, errorMessage

  _displayDuplicateRoomTypeIdError: (view, error)->
    errorMessage = I18n.t 'js.modules.profile.channel_manager.alerts.duplicate_room_type_id'
    for roomTypeUuid in error.uuids
      @_displayErrorOnRoomType view, roomTypeUuid, errorMessage

  _displayDuplicateRatePlanIdError: (view, error)->
    errorMessage = I18n.t 'js.modules.profile.channel_manager.alerts.duplicate_rate_plan_id'
    for ratePlanUuid in error.uuids
      @_displayErrorOnRatePlan view, ratePlanUuid, errorMessage

  _displayErrorOnHotel: (view, errorMessage) ->
    hotelChannelManagerIdEl = view.$('#hotel-channel-manager-id')
    if hotelChannelManagerIdEl.length
      hotelChannelManagerIdEl.addClass 'error'
      errorMessageEl = hotelChannelManagerIdEl.siblings('.field-error-message')
      errorMessageEl.html errorMessage
      errorMessageEl.removeClass 'hide'

  _displayErrorOnRoomType: (view, roomTypeUuid, errorMessage) ->
    roomTypeChannelManagerIdEl = view.$("#room-type-channel-manager-id-#{roomTypeUuid}")
    if roomTypeChannelManagerIdEl.length
      roomTypeChannelManagerIdEl.addClass 'error'
      errorMessageEl = roomTypeChannelManagerIdEl.siblings('.field-error-message')
      errorMessageEl.html errorMessage
      errorMessageEl.removeClass 'hide'

  _displayErrorOnRatePlan: (view, ratePlanUuid, errorMessage) ->
    ratePlanChannelManagerIdEl = view.$("#rate-plan-channel-manager-id-#{ratePlanUuid}")
    if ratePlanChannelManagerIdEl.length
      ratePlanChannelManagerIdEl.addClass 'error'
      errorMessageEl = ratePlanChannelManagerIdEl.siblings('.field-error-message')
      errorMessageEl.html errorMessage
      errorMessageEl.removeClass 'hide'
