class Extranet.Presenters.BasicInfo

  toHash: (model) ->
    data = model.toJSON()
    hash =
      name: data.name
      id: data.uuid
      hotelCheckTimes: @_getCheckTimes data
      # Hotel Location
      address: @_getAddress data
      timeZone: data.timeZone
      # Financial
      currencyOptions: @_getCurrencyOptions data
      margin: data.marginPercentage
      creditCardFee: data.creditCardFeeMargin
      # Contact Information
      phoneNumber: _.first data.phoneNumbers
      faxNumber: _.first data.faxNumbers
      email: data.email
      # Additional Information
      merchantId: _.first data.grouponMerchant
      salesforceId: data.salesForceId
      ql2Id: data.ql2Id
      isSEOVisible: data.isSEOVisible
    hash


  getHashToSave: (view) ->
    hash =
      _csrf: $('#csrf_token').val()
      name: view.$('#hotel-name-input').val()
      checkInTime: view.$('#check-in-selector').val()
      checkOutTime: view.$('#check-out-selector').val()
      # Hotel Location
      hotelAddress:
        countryUuid: view.$('#country-selector').val()
        address1: view.$('#street-address-input').val()
        stateUuid: view.$('#state-selector').val()
        cityUuid: view.$('#city-selector').val()
        postalCode: view.$('#postal-code-input').val()
        latitude: view.$('#latitude-input').val()
        longitude: view.$('#longitude-input').val()
      timeZoneId: view.$('#time-zone-selector').val()
      # Financial
      rateCurrencyCode: view.$('#currency-selector').val()
      marginPercentage: view.$('#margin-input').val()
      creditCardFeeMargin: view.$('#credit-card-fee-input').val()
      # Contact Information
      phoneNumbers: [{type: 'us', number: view.$('#phone-number-input').val()}]
      faxNumbers: [{type: 'us', number: view.$('#fax-number-input').val()}]
      email: view.$('#email-address-input').val()
      isSEOVisible: view.$('#seo-visible-input').is(':checked')
      # Additional Information
      grouponMerchant: [view.$('#merchant-id-input').val()]
      salesForceId: view.$('#salesforce-id-input').val()
      ql2Id: view.$('#ql2-id-input').val()
    PermissionsHelper.getEditableDataForClient 'hotel', hash

  _getAddress: (data) ->
    _.pick data.hotelAddress, 'address1', 'country', 'state', 'city', 'postalCode', 'latitude', 'longitude'

  _getCheckTimes: (data) ->
    hotelCheckTimes = Extranet.ClientConfig?.hotelCheckTimes
    return [] unless hotelCheckTimes
    defaultCheckInTime = _.find(hotelCheckTimes, (hash) -> hash.defaultCheckInTime)?.time
    defaultCheckOutTime = _.find(hotelCheckTimes, (hash) -> hash.defaultCheckOutTime)?.time
    checkInTime = @_formatTime data.checkInTime or defaultCheckInTime
    checkOutTime = @_formatTime data.checkOutTime or defaultCheckOutTime
    checkTimes = _.map hotelCheckTimes, (hash) ->
      time: hash.time
      selectedCheckInTime: hash.time is checkInTime
      selectedCheckOutTime: hash.time is checkOutTime

  _formatTime: (timeString) ->
    # convert 'H:mm' to 'HH:mm'
    moment(timeString, 'H:mm').format('HH:mm')

  _getCurrencyOptions: (data) ->
    currencies = Extranet.ClientConfig?.currencies
    return [] unless currencies
    currencies = _.map currencies, (hash) ->
      currencyCode: hash.currencyCode
      isSelected: hash.currencyCode is data.rateCurrencyCode
    # Pushing the hotel currency if not found in currencies list
    unless _.find(currencies, (hash) -> hash.isSelected)
      currencies.push
        currencyCode: data.rateCurrencyCode
        isSelected: true
    _.sortBy currencies, 'currencyCode'