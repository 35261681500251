require "../../../products/frontend/helpers/productset_status_helper"

class Extranet.Presenters.PSRowPresenter

  toHash: (model) ->
    hash = model.toJSON()
    hash.hotelUrl = ExtranetUrlBuilder.getProfilePageUrl {
      hotelUuid : hash.hotelUuid
    }
    hash.promotionsUrl = ExtranetUrlBuilder.getProductSetUrl {
      productSetUuid: hash.uuid
      hotelUuid : hash.hotelUuid
    }

    startDate = hash.restriction?.promotionalWindow?.startAt
    hash.startDate = if startDate then DateTimeHelper.formatDate startDate, 'numeric' else ''

    endDate = hash.restriction?.promotionalWindow?.endAt
    hash.endDate = if endDate then DateTimeHelper.formatDate endDate, 'numeric' else ''
    
    hash.hasOnboardingTeamId = not _.isEmpty hash.onboardingTeamId
    hash.hasOpportunityOwnerId = not _.isEmpty hash.opportunityOwnerId
    hash.hasPlannerId = not _.isEmpty hash.plannerId
    hash.hasMarketManagerId = not _.isEmpty hash.marketManagerId

    hash.statusAbbreviation = Extranet.Helpers.ProductSetStatusHelper.statusAbbreviations[hash.status]
    hash.statusTooltipText = hash.status?.toUpperCase()

    return hash
