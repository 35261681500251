class Extranet.Views.InventorySectionView extends Backbone.View
  Extranet.Common.Utilities.Mixin.extends this, Extranet.Helpers.ChildManager

  templateName:'products_templates_inventory_section'

  selectors:
    maxLengthOfStay: '#maxLengthOfStay'
    minLengthOfStay: '#minLengthOfStay'
    dealUuidText: '.deal-uuid-text'
    dealUuidApply: '.deal-uuid-apply'

  initialize: (options = {}) ->
    @el = options.el
    @hotelUuid = options.hotelUuid
    @productSetUuid = options.productSetUuid
    @alertMessage = Extranet.Helper.AlertView.getAlertView()
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate

  render: (options = {}) ->
    hash = options.hash or {}
    hash.accessPermissionSubKey = hash.status
    hash.maxLos = @getMaxLosRange()
    hash.displayCloseAt = DateTimeHelper.formatDate hash.closeAt, 'numeric'
    hash.displayLaunchAt = DateTimeHelper.formatDate hash.launchAt, 'numeric'
    $(@el).html(@autoTemplate.render @templateName, hash)
    $(@el).find('#minLengthOfStay').val(hash.restriction.minLengthOfStay) if hash?.restriction?.minLengthOfStay
    $(@el).find('#maxLengthOfStay').val(hash.restriction.maxLengthOfStay) if hash?.restriction?.maxLengthOfStay
    if hash.dealUuidApplied
      $(@el).find(@selectors.dealUuidApply).hide()
    else
      $(@el).find(@selectors.dealUuidText).hide()

    # events don't seem to be working for this view, so manually delegating
    $(@el).delegate('#cancellation-options', 'change', @validateCancellationWindow);
    this

  validateLengthOfStay: ()->
    maxLosEl = $(@el).find(@selectors.maxLengthOfStay)
    minLosEl = $(@el).find(@selectors.minLengthOfStay)
    if(maxLosEl.val()) and (+minLosEl.val() > +maxLosEl.val())
      maxLosEl.addClass 'error'
      errorMessage = I18n.translate("js.modules.products.templates_inventory_section.length_of_stay_validation_messsage")
      @alertMessage.renderError(errorMessage)
      return false
    else
      maxLosEl.removeClass 'error'
      return true

  validateCancellationWindow: ()->
    $('#cancellation-window').parsley('destroy').parsley(Extranet.ParsleyConfig)
    $('#cancellation-window').parsley('validate')

  getMaxLosRange: ()->
    maxLos = ({value: num, option: num} for num in [1..29])
