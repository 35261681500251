require "./views/hotels_list_view"

class Extranet.Controllers.HotelsListController 
 
  constructor: (options) ->
    @hotelsListView = new Extranet.Views.HotelsListView({itemsPerPage: options.itemsPerPage})
    # @bookingsListView.updatePage()

  paginate: (num) ->
    @hotelsListView.updatePage( (num || 1) )
