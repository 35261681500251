class Extranet.Presenters.RoomDetails

  toHash: (model) ->
    hash = model.pick 'name', 'description', 'uuid'
    hash.description ?= I18n.translate 'js.modules.profile.room_details.room_description_default'
    hash.ratePlans = model.getRatePlans()
    hash
  
  getHashToSave: (view) ->
    hash =
      _csrf: $('#csrf_token').val()
      name: view.$('#room-name-input').val()
      internalName: view.$('#room-name-input').val()
      description: view.$('#room-description-textarea').val()
    PermissionsHelper.getEditableDataForClient 'roomType', hash