RESTRICTION_TYPES_MAP =
  'ql2Rates': 'lar'
  'DailyDiscountPercentage': 'grouponRate'
  'minDailyAllotment': 'inventoryCount'
  'inventoryDefined': 'inventoryCount'
  'bookingLimit': 'bookingLimit'

isInitialize = false

class Extranet.Presenters.FailedValidationDatesPresenter

  @initialize: ->
    unless isInitialize
      eventManagerKey = Extranet.Helpers.EventManagerHelper.PS_MONTH_VIEW_KEY
      eventManagerView = Extranet.Helpers.EventManagerHelper.getEventManagerView(eventManagerKey)
      eventManagerView.on 'validationSuccess', @clearFailedValidations
      eventManagerView.on 'validationFailure', @format
      isInitialize = true

  @format: (validationFailures) =>
    @initialize()
    @clearFailedValidations()
    errorsList = validationFailures?.errors
    return unless errorsList
    for errors in errorsList
      @formatFailedValidations(errors.failedValidations)
    eventManagerKey = Extranet.Helpers.EventManagerHelper.PS_MONTH_VIEW_KEY
    eventMgr = Extranet.Helpers.EventManagerHelper.getEventManagerView eventManagerKey
    eventMgr.triggerEvent 'highlightFailureDates'

  @getFailedEntities: (dateCell) ->
    @initialize()
    date = dateCell?.date
    ratePlanUuid = dateCell?.ratePlan?.uuid
    roomTypeUuid = dateCell?.roomType?.uuid
    failure = @failedValidationsDateWise?[date]
    entities = _.union failure?[ratePlanUuid], failure?[roomTypeUuid]
    _.reject entities, (entity) -> typeof entity is 'undefined'

  # -----------------------------------
  # Internal Methods
  # -----------------------------------
  @formatFailedValidations: (failedValidations) ->
    return unless failedValidations
    for failedValidation in failedValidations
      @getFailedHashByDate failedValidation

  @getFailedHashByDate: (failedValidation) ->
    {entityUuid, entityType, restrictionType} = failedValidation

    unless RESTRICTION_TYPES_MAP[restrictionType]
      return
    for failure in failedValidation.failures
      date = DateTimeHelper.formatDate failure.instance, 'api'
      # if the error was for an entity rate plan or room type, add the error to the list of errors for that date
      if @isValidDate(date) and _.contains ['RatePlan', 'RoomType'], entityType 
        @addFailedEntity({date, restrictionType, entityUuid})

  @addFailedEntity: (options) ->
    {date, restrictionType, entityUuid} = options
    @failedValidationsDateWise[date] ?= {}
    @failedValidationsDateWise[date][entityUuid] ?= []
    failedEntity = RESTRICTION_TYPES_MAP[restrictionType]
    unless failedEntity in @failedValidationsDateWise[date][entityUuid]
      @failedValidationsDateWise[date][entityUuid].push(failedEntity)

  # 17719 - not sure what this is for, should probably use helper regex
  @isValidDate: (date) ->
    typeof(date) is 'string' and date?.toLowerCase() isnt 'invalid date'

  @clearFailedValidations: =>
    @failedValidationsDateWise = {}
