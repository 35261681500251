require './room_details'
require '../presenters/room_info'
require '../presenters/rate_plan'

class Extranet.Views.RoomInfoRow extends Backbone.View

  events:
    'click .room': '_showRoomDetailsModal'
  
  tagName: 'tr'
  className: 'room-info-row'
  templateName: 'profile_room_info_row'

  initialize: (options) ->
    @roomDetailsEl = options.roomDetailsEl
    @presenter = new Extranet.Presenters.RoomInfo()

  render: () ->
    @hash = @presenter.toHash @model
    @$el.html TemplateHelper.render @templateName, @hash
  
  _showRoomDetailsModal: (e) ->
    roomDetailsView = new Extranet.Views.RoomDetails
      model: @model
    roomDetailsView.render()
    @roomDetailsEl.html roomDetailsView.el