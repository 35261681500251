require '../constants'
require '../helpers/date_time'
require '../helpers/string'
require '../helpers/permissions'
require '../helpers/url_builder'
require './init/main'
require './config/constants'
require './config/parsley_config'
require './utilities/utility'
require './utilities/auto_template'
require './utilities/permissions'
require './utilities/render'
require './utilities/mixin'
require './helpers/alert_helper'
require './helpers/child_manager'
require './helpers/location_selector'
require './helpers/event_manager'
require './models/generic_model'
require './models/extranet_model'
require './models/channel_manager_mapping'
require './models/rate_plan_model'
require './models/recent_auto_updates'
require './models/tax'
require './models/room'
require './models/hotel'
require './models/contact'
require './models/payment'
require './collections/bookings'
require './collections/contact_roles'
require './collections/hotels_collection'
require './collections/payments'
require './collections/rate_plans'
require './collections/rooms'
require './collections/contacts'
require './collections/taxes'
require './views/alert_message_view'
require './views/auto_complete_view'
require './views/event_manager_view'
require './views/pagination_footer_view'
require './views/recent_auto_updates'
require './views/user_picker_view'
require './presenters/error'
require './presenters/tax_row_presenter'
require './js/math-helper.js'

require '../../inventory/frontend/presenters/value_adds'
require '../../inventory/frontend/collections/value_adds'
