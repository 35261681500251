Extranet.Helpers.ChildManager = {

  ###
  these getters will create the @childHash and @childArray for each instance if they dont have one
  we should not use @childHash and @childArray directly, they should always be used through the getters
  we can't create these in a constructor as we are not instantiating the ChildManager directly
  and are extending the views by copying ChildManagers prototype to them
  ###
  getChildHash: () ->
    @childHash ?= {}

  getChildArray: () ->
    @childArray ?= []

  resetChildHash: () ->
    @childHash = {}

  resetChildArray: () ->
    @childArray = []

  ###
  usage - from parent object
  addChild rowView
  addChild ratesTabView, 'ratestab'
  ###

  addChild: (child, key = null) ->
    if child is this
      return child
    if key
      @getChildHash()[key] = child
    else
      @getChildArray().push child
    return child

  getChild: (key)->
    @getChildHash()[key]

  remove: () ->
    @removeChildren()
    Backbone.View.prototype.remove.call this

  removeChildren: ()->
    for child in @getChildArray()
      child.remove()

    _.each @getChildHash(), (view, key)=>
      view.remove()

    @resetChildArray()
    @resetChildHash()

  removeChild: (key)->
    @getChildHash()[key]?.remove()
    delete @getChildHash()[key]

  ###
  usage - from same/self view:
  ###

  hide: ()->
    for child in @getChildArray()
      @hideView child
    _.each @getChildHash(), (view, key)=>
      @hideView view
    @hideView this

  hideView: (view)->
    view.$el.addClass 'hide'
    view.undelegateEvents()
    view.$el?.parsley('destroy')

  show: ()->
    for child in @getChildArray()
      @showView child
    _.each @getChildHash(), (view, key)=>
      @showView view
    @showView this

  showView: (view)->
    view.$el.removeClass 'hide'
    view.delegateEvents()
    view.$el?.parsley(Extranet.ParsleyConfig)
}
