require './rate_plan_row'

class Extranet.Views.RatePlans extends Backbone.View

  selectors:
    ratePlansList: '#rate-plans-list'

  el: '#rate-plans'
  templateName: 'profile_rate_plans'

  initialize: (options) ->
    @alertMessage = Extranet.Helper.AlertView.getAlertView()
    @roomsCollection = new Extranet.Collections.Rooms {hotelUuid: @model.id} 
    @listenTo @roomsCollection, 'sync', @_renderRooms

  render: () ->
    @$el.html TemplateHelper.render @templateName, {}
    @_fetchRooms()

  _fetchRooms: () ->
    @_showLoading()
    options =
      reset: true
      global: false
      data:
        include_rate_plans: true
    onFailure = (jqXHR, textStatus, errorThrown) =>
      requestId = jqXHR?.getResponseHeader 'x-request-id'
      @alertMessage.renderDefaultError requestId
    onFinish = () =>
      @_hideLoading()
    @roomsCollection.fetch(options).fail(onFailure).always(onFinish)
  
  _renderRooms: () ->
    @_clearRatePlansList()
    @roomsCollection.each (roomModel) =>
      ratePlans = roomModel.getRatePlans()
      @_renderRatePlans ratePlans, roomModel

  _renderRatePlans: (ratePlans, roomModel) =>
    _.each ratePlans, (ratePlan) =>
      ratePlanRowView = new Extranet.Views.RatePlanRow
        roomModel: roomModel
        hash: ratePlan
      ratePlanRowView.render()
      @$(@selectors.ratePlansList).append ratePlanRowView.el

  _clearRatePlansList: () ->
    @$(@selectors.ratePlansList).empty()

  _showLoading: () ->
    @$el.addClass 'loading'

  _hideLoading: () ->
    @$el.removeClass 'loading'