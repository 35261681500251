class Extranet.Views.AdvanceSearchInventoryDownload extends Backbone.View

  el: $ '#div-advance-search-inventory-download'

  selectors:
    closeButton: '.close-modal'
    alertMessage: "#from_date_greater"


  initialize: (options)->
    @alertMessage = new Extranet.Views.AlertMessage {selector: @selectors.alertMessage}
    @alertMessageObj = options.alertMessage
    @downloadReportOffset = 0  # default set to 0
    @advancedSearchParameters = {}
    @datePickerAdjustment()

  events:
    'click #submit_download': 'processDownload'
    'click #cancel_download' : 'cancel'
    'click .close-modal' : 'emptyDates'

  emptyDates: () ->
    @$el.find('#check_in').val('')
    @$el.find('#check_out').val('')
    @hideErrorDiv()

  cancel: (e) ->
    @emptyDates()
    @hideErrorDiv()
    e.preventDefault()
    e.stopPropagation()
    @$(@selectors.closeButton).click()

  processDownload: (e) ->
    e.preventDefault()
    e.stopPropagation()
    form = $($(@el).find('form'))
    form.parsley Extranet.ParsleyConfig
    if form.parsley 'validate'
      formObj = form.serializeObject()
      beginDate = formObj.check_in
      endDate = formObj.check_out
      if not DateTimeHelper.isBeforeDate beginDate, endDate
        message = I18n.translate('js.modules.common.alerts.validation.from_date_less_than_to_date')
        @alertMessage.renderError(message)
        return

      downloadOptions =
        advancedSearchParameters: encodeURIComponent(JSON.stringify @advancedSearchParameters)
        offset: @downloadReportOffset
        check_in_begin: DateTimeHelper.formatDate beginDate, 'api'
        check_in_end: DateTimeHelper.formatDate endDate, 'api'
        productType: 'lastMinute'
      @$el.find('#cancel_download').trigger('click')
      window.open ExtranetUrlBuilder.getHotelSearchDownloadUrl downloadOptions

  setOffset: (offset) ->
    @downloadReportOffset = offset

  setSearchParameters: (searchParameters) ->
    @advancedSearchParameters = _.extend {}, searchParameters

  hideErrorDiv: () ->
    @alertMessage.hide()

  # 17719 compare with
  # modules/bookings_list/frontend/views/booking_download.coffee
  # modules/bookings_list/frontend/views/bookings_list_view.coffee
  # modules/hotel_search/frontend/views/advance_search_inventory_download.coffee
  # modules/inventory/frontend/views/inventory_download.coffee
  datePickerAdjustment: () ->
    $("#check_in").datepicker
      onSelect: (selected) ->
        $("#check_out").datepicker "option", "minDate", selected
        return

    $("#check_out").datepicker
      onSelect: (selected) ->
        $("#check_in").datepicker "option", "maxDate", selected
        return
    return
