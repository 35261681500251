require "../../presenters/product_set_rules_presenter"

class Extranet.Views.ProductSetRulesView extends Backbone.View

  templateName: 'inventory_templates_monthview_product_set_rules'

  selectors:
    popupContents: '#ps-rules-details'

  events:
    'click #ps-rules-link': 'showPopup'
    'click #rules-close-btn': 'closePopup'

  initialize: (options)->
    @model = options.productSetModel
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    @presenter = new Extranet.Presenters.ProductSetRulesPresenter()

  render: ()->
    hash = @presenter.toHash @model
    @$el.html @autoTemplate.render @templateName, hash

  showPopup: (e)->
    e.preventDefault()
    @$el.find(@selectors.popupContents).removeClass 'hide'

  closePopup: (e)->
    e.preventDefault()
    @$el.find(@selectors.popupContents).addClass 'hide'
