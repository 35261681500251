# This file should be deprecated when rate plan details page make use of room_details.coffee instead of room_details_view.coffee

require "../presenters/room_details_presenter"

class Extranet.Views.RoomDetailsView extends Backbone.View
  templateName: 'products_templates_room_details'

  selectors:
    buttons: '.btn-section button'
    closeButton: '.close-modal'
    addRoomType: '#add-room-type'
    errorMessage: "#add-room-type .error-message"
  events:
    'click #save-room': 'save'
    'click #cancel-room': 'cancel'
    'click .close-modal': 'remove'
    'keyup input, textarea': 'handleDataChange'

  initialize: (options)->
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    @errorMessage = new Extranet.Views.AlertMessage {selector: @selectors.errorMessage}
    @alertMessage= Extranet.Helper.AlertView.getAlertView()
    if options.model
      @model = options.model
      @isNewRoomType = false
    else
      @isNewRoomType = true

  handleDataChange: (e)->
    @$(@selectors.buttons).removeAttr('disabled') # Enabling the buttons after any change

  render: () ->
    hash = {}
    if @model
      presenter = new Extranet.Presenters.RoomDetailsPresenter()
      hash = presenter.toHash @model
    hash.description ?= I18n.translate('js.modules.products.templates_room_details.default_room_description')
    this.$el.html(@autoTemplate.render @templateName, {model: hash, @isNewRoomType})
    @$(@selectors.addRoomType).parsley(Extranet.ParsleyConfig)
    this

  save: (e)->
    if not @$(@selectors.addRoomType).parsley('validate')
      @errorMessage.renderDefaultValidationError()
      return
    @errorMessage.hide()
    presenter = new Extranet.Presenters.RoomDetailsPresenter()
    hash = presenter.getHashToSave this

    if @model
      @saveRoom hash
    else
      @createRoom hash

  saveRoom: (hash) ->
    hash._csrf= $('#csrf_token').val()
    @model.save hash,
      wait:true
      success: (model, response, options)=>
        @closeView()
        message = I18n.translate('js.modules.common.alerts.success.room_saved')
        requestId = options?.xhr?.getResponseHeader('x-request-id')
        @alertMessage.renderSuccess(message, requestId)
      error: (model, response, options)=>
        requestId = options?.xhr?.getResponseHeader('x-request-id')
        @errorMessage.renderDefaultError(requestId)

  createRoom: (hash) ->
    model = new Extranet.Models.Room {hotelUuid: @collection?.hotelUuid}
    model.save hash,
      wait:true
      success: (model, response, options)=>
        @collection.add([model])
        @trigger 'newRoomAdded', model?.toJSON()  # Used by Product Set Rate plan page
        message = I18n.translate('js.modules.common.alerts.success.room_created')
        requestId = options?.xhr?.getResponseHeader('x-request-id')
        @alertMessage.renderSuccess(message, requestId)
        @closeView()
      error: (model, response, options)=>
        requestId = options?.xhr?.getResponseHeader('x-request-id')
        @errorMessage.renderDefaultError(requestId)

  cancel: (e)->
    e.preventDefault()
    @closeView()

  closeView: (e)->
    @$(@selectors.closeButton).click()
