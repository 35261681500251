class Extranet.Presenters.RatesRestrictionsErrPresenter
  @translationKeys =
    allKeys: "inventory.rate_restriction_err_presenter"
    objectType: "inventory.rate_restriction_err_presenter.object_type"
    avgDiscoutRestriction: 'inventory.rate_restriction_err_presenter.avg_discount_percent_below_require'
    discoutRestriction: 'inventory.rate_restriction_err_presenter.discount_percent_below_required'
    blackoutPercentageRestriction: "inventory.rate_restriction_err_presenter.blackout_percentage_restriction"

  @restrictionTypes =
    blackoutDates:
      name: 'blackoutDates'
      restrictions:
        blackoutPercentage: "BlackoutDates Percentage"
    dailyDiscountPercent:
      name: 'DailyDiscountPercentage'
      restrictions:
        validQl2Rates: "Valid QL2 rate"
        validRates: "Valid RatePlanDailyRateProductSet"
    avgDicountPercent:
      name: "AverageDiscountPercentage"
      restrictions:
        avgPercent: "AverageDiscountPercentage"

  @validRateRestriction = "Valid RatePlanDailyRateProductSet"
  @validQl2RateRestriction = "Valid QL2 rate"

  @helper = Extranet.Helpers.ValidationPresentersHelper
  @defaultPresenter = Extranet.Presenters.DefaultFailurePresenter

  @getErrorMessages: ({failedValidation, hotelInfo})->

    # API is currently sending duplicate errors for when a rate plan has unset
    # base or ql2 rates - they send an error for the missing values, and also
    # an error on not meeting minimum daily discount. We are going to skip
    # the daily discount as it is not helpful
    if(failedValidation.restrictionType is @restrictionTypes.dailyDiscountPercent.name && failedValidation.failures[0].actual == "null")
      return null

    validationAttrs = _.pick failedValidation, 'description', 'restrictionType'
    errorMessages = []
    ratePlanInfo = @helper.getRatePlanInfo hotelInfo, failedValidation.entityUuid
    # wrap in div if length longer than 1 and set class to determine show/hide
    messages = "<ul>"
    for failure in failedValidation.failures
      msg = @getMessage failure, validationAttrs
      messages += "<li>" + msg + "</li>"

    messages += "</ul>"

    # yes making it worse I know
    # done as part of 16825
    objectUuid = failedValidation.entityUuid
    objectType = @helper.getTranslations @translationKeys.objectType
    objectName = ratePlanInfo?.name
    totalViolations = failedValidation.failures.length
    violationName = validationAttrs.description

    # if a violation falls through that we didn't cover, fall back on the old messaging.
    violationHandled = _.has(I18n.translate("js.modules.inventory.templates_monthview_single_violation_summary"), failedValidation.restrictionType)

    if (failedValidation.failures.length > 1) && violationHandled
      messagesHeader = I18n.translate("js.modules.inventory.templates_monthview_single_violation_summary.#{failedValidation.restrictionType}", {totalViolations})
      messages = Extranet.Templates['inventory_templates_monthview_single_violation_summary'].render({messagesHeader: messagesHeader, messages: messages})

    errorMessages.push messages

    {
      messages: errorMessages
      objectUuid: objectUuid
      objectType: objectType
      objectName: objectName
    }

  @getMessage: (failure, validationAttrs)->
    translations = @helper.getTranslations @translationKeys.allKeys

    if validationAttrs.restrictionType is @restrictionTypes.dailyDiscountPercent.name
      if failure.restriction is @validRateRestriction
        date = DateTimeHelper.formatDate failure.instance, 'numeric'
        message = date + ' ' + translations.invalid_rate
        return message

      if failure.restriction is @validQl2RateRestriction
        date = DateTimeHelper.formatDate failure.instance, 'numeric'
        message = date + ' ' + translations.invalid_ql2_rate
        return message

      if @helper.isNumber failure.restriction
        date = DateTimeHelper.formatDate failure.instance, 'numeric'
        failure.actual = parseFloat(failure.actual).toFixed(2)
        message = date + ' '
        message += @helper.getTranslations @translationKeys.discoutRestriction, failure
        return message

    if validationAttrs.restrictionType is @restrictionTypes.avgDicountPercent.name
      restrictions = @restrictionTypes.avgDicountPercent.restrictions
      if failure.instance is restrictions.avgPercent
        failure.actual = parseFloat(failure.actual).toFixed(2)
        message = @helper.getTranslations @translationKeys.avgDiscoutRestriction, failure
        return message

    if validationAttrs.restrictionType is @restrictionTypes.blackoutDates.name
      restrictions = @restrictionTypes.blackoutDates.restrictions
      if failure.instance is restrictions.blackoutPercentage
        message = @helper.getTranslations(
          @translationKeys.blackoutPercentageRestriction, failure
        )
        return message

    # NOTE: waiting for api to resolve
    # if validationAttrs.restrictionType is @restrictionTypes.psRequiredFields.name
    #   restrictions = @restrictionTypes.psRequiredFields.restrictions

    #   if failure.instance is restrictions.maxBlackoutDates.instance and
    #   failure.restriction is restrictions.maxBlackoutDates.restrction

    #     return translations.invalid_max_blackout_dates

    # we reached here means, no matching message found. Give default message
    message = translations.description + ':' + validationAttrs.description +
    '; ' + @defaultPresenter.getMessage failure
    return message
