require '../presenters/channel_manager'
require '../helpers/channel_manager_validation'

class Extranet.Views.ChannelManager extends Backbone.View

  el: '#channel-manager'
  templateName: 'profile_channel_manager'
  mappingTemplateName: 'profile_channel_manager_mapping'

  selectors:
    headerConnectionIconWrapper : '#connection-status-icon-wrapper'
    headerConnectedIcon: '#connected-status-icon'
    headerDisconnectedIcon: '#disconnected-status-icon'
    connectionStatus: '#connection-status'
    connectionStatusText: '#connection-status-text'
    connectionName: '#connection-name-selector'
    connectionNameWrapper: '#connection-name-selector-wrapper'
    activateConfirmationModal: '#activate-confirmation-modal'
    deactivateConfirmationModal: '#deactivate-confirmation-modal'
    channelManagerMappingContainer: '#channel-manager-mapping-container'
    channelManagerMappingContainerContent: '#channel-manager-mapping-container .content'
    actionButtons: '.action-buttons'
    fieldErrorMessage: '.field-error-message'

  events:
    'click #connection-status': '_displayConnectionStatusUpdatePopup'
    'click #activate-connection': '_activateConnection'
    'click #deactivate-connection': '_deactivateConnection'
    'click button.cancel': '_closeConnectionStatusUpdatePopup'
    'change #connection-name-selector': '_handleChannelManagerChange'
    'click #reset-mapping-information': '_reset'
    'click #save-mapping-information': '_saveMappingInformation'
    'change #channel-manager-mapping-container input': '_setMappingInformationChanged'

  initialize: (options) ->
    @hotelModel = options.hotelModel
    @channelManagerMappingModel = new Extranet.Models.ChannelManagerMapping
      hotelUuid: @hotelModel.get('uuid')
      channelManagerName: @hotelModel.get('channelManager')?.name
    @presenter = new Extranet.Presenters.ChannelManager()
    @errorPresenter = new Extranet.Presenters.Error()
    @validationHelper = new Extranet.Helpers.ChannelManagerValidation()
    @alertMessage = Extranet.Helper.AlertView.getAlertView()

  render: () ->
    @settingsHash = @presenter.getSettingsHashToDisplay @hotelModel
    @$el.html TemplateHelper.render @templateName, @settingsHash
    @alertMessage.clear()
    @_renderConnectionStatus()
    @_renderMappingInformation()
    if Extranet.RolePermissions.is_readonly_user
      @$el.addClass 'disabled'

  _reset: () ->
    @channelManagerMappingModel.channelManagerName = @hotelModel.get('channelManager')?.name
    @render()

  _activateConnection: (e)->
    e.preventDefault()
    isConnectionActive = true
    @_updateChannelManagerConnectionStatus(isConnectionActive)

  _deactivateConnection: (e)->
    e.preventDefault()
    isConnectionActive = false
    @_updateChannelManagerConnectionStatus(isConnectionActive)

  _renderConnectionStatusOnHeader: (channelManagerName, isConnectionActive) -> 
    $(@selectors.headerConnectionIconWrapper).toggleClass 'active', isConnectionActive
    $(@selectors.headerConnectionIconWrapper).toggleClass 'hide', not channelManagerName?
    channelManagerName = StringHelper.capitalize channelManagerName
    if isConnectionActive
      tooltip = I18n.translate 'js.modules.profile.channel_manager.connected_to_cm', {channelManagerName}
      $(@selectors.headerConnectedIcon).attr 'title', tooltip
    else
      tooltip = I18n.translate 'js.modules.profile.channel_manager.disconnected_from_cm', {channelManagerName}
      $(@selectors.headerDisconnectedIcon).attr 'title', tooltip

  _updateChannelManagerConnectionStatus: (isConnectionActive)->
    return unless Extranet.RolePermissions?.hotel?.channelManager?.edit
    channelManagerName = @$(@selectors.connectionName).val() or null
    @alertMessage.hide()
    saveAttrs =
      _csrf: $('#csrf_token').val()
      channelManager: {name: channelManagerName, isConnectionActive}
    options = { patch: true, wait: true}
    onSuccess = (data, textStatus, jqXHR) =>
      @render()
      requestId = jqXHR?.getResponseHeader 'x-request-id'
      @_renderConnectionStatusOnHeader channelManagerName, isConnectionActive
      message = if isConnectionActive
        I18n.translate 'js.modules.common.alerts.success.channel_manager_activated'
      else
        I18n.translate 'js.modules.common.alerts.success.channel_manager_deactivated'
      @alertMessage.renderSuccess message, requestId
    onFailure = (jqXHR, textStatus, errorThrown) =>
      @_handleChannelManagerSettingUpdateFailure jqXHR, textStatus, errorThrown
      @_closeConnectionStatusUpdatePopup()
    @hotelModel.save(saveAttrs, options).then(onSuccess, onFailure)

  _displayConnectionStatusUpdatePopup: ()->
    connectionStatusEl = @$(@selectors.connectionStatus)
    return if connectionStatusEl.hasClass 'disabled'

    if connectionStatusEl.hasClass 'active'
      @_displayDeactivateConnectionPopup()
    else
      @_displayActivateConnectionPopup()

  _displayActivateConnectionPopup: ()->
    confirmMessage = I18n.translate 'js.modules.profile.channel_manager.activate_popup_title'
    actionInfo = I18n.translate 'js.modules.profile.channel_manager.activate_popup_description',
      {channelManagerName: StringHelper.capitalize(@channelManagerMappingModel.channelManagerName)}
    @$(@selectors.activateConfirmationModal).find('.confirm-message').text(confirmMessage)
    @$(@selectors.activateConfirmationModal).find('.action-info').text(actionInfo)
    if @mappingInformationChanged
      warningText = I18n.translate 'js.modules.profile.channel_manager.alerts.unsaved_mapping_information'
      @$(@selectors.activateConfirmationModal).find('.warning-text').text(warningText)
    @$(@selectors.activateConfirmationModal).modal 'show'

  _displayDeactivateConnectionPopup: ()->
    confirmMessage = I18n.translate 'js.modules.profile.channel_manager.deactivate_popup_title'
    actionInfo = I18n.translate 'js.modules.profile.channel_manager.deactivate_popup_description'
    @$(@selectors.deactivateConfirmationModal).find('.confirm-message').text(confirmMessage)
    @$(@selectors.deactivateConfirmationModal).find('.action-info').text(actionInfo)
    if @mappingInformationChanged
      warningText = I18n.translate 'js.modules.profile.channel_manager.alerts.unsaved_mapping_information'
      @$(@selectors.deactivateConfirmationModal).find('.warning-text').text(warningText)
    @$(@selectors.deactivateConfirmationModal).modal 'show'

  _closeConnectionStatusUpdatePopup: ()->
    @$(@selectors.deactivateConfirmationModal).modal 'hide'
    @$(@selectors.activateConfirmationModal).modal 'hide'

  _renderConnectionStatus: ()->
    connectionStatusEl = @$(@selectors.connectionStatus)
    channelManager = @hotelModel.get 'channelManager'

    # The connection status will not be displayed until the connection name has been saved
    # For Example, if there is no connection information previously stored, the connection status will not be displayed
    # Once the channel manager name is selected and is successfully stored, the connection status will be displayed
    unless channelManager?.name?.length
      connectionStatusEl.parent().addClass 'hide'
      connectionStatusEl.removeClass 'active'
      return

    connectionStatusEl.parent().removeClass 'hide'
    connectionStatusEl.toggleClass 'active', channelManager?.isConnectionActive
    # We will enable the connection status if the connection is already active or the connection name is valid
    # Otherwise we will disable the connection status
    if @$(@selectors.connectionName).val()?.length
      @_enableConnectionStatus()
    else
      @_disableConnectionStatus()

  _enableConnectionStatus: ()->
    connectionStatusEl = @$(@selectors.connectionStatus)
    connectionStatusEl.attr 'title', I18n.translate('js.modules.profile.channel_manager.connection_status_tooltip')
    connectionStatusEl.removeClass 'disabled'

  _disableConnectionStatus: ()->
    connectionStatusEl = @$(@selectors.connectionStatus)
    connectionStatusEl.attr 'title', I18n.translate('js.modules.profile.channel_manager.connection_status_not_allowed')
    connectionStatusEl.addClass 'disabled'

  _handleChannelManagerChange: ()->
    @_renderConnectionStatus()
    channelManagerName = @$(@selectors.connectionName).val()
    @channelManagerMappingModel.channelManagerName = channelManagerName
    @_renderMappingInformation()

  _renderMappingInformation: ()->
    @mappingInformationChanged = false
    if @presenter.shouldDisplayMappingInformation @channelManagerMappingModel.channelManagerName
      @_showMappingInformationLoading()
      options =
        global: false
      onFailure = (jqXHR, textStatus, errorThrown) =>
        requestId = jqXHR?.getResponseHeader 'x-request-id'
        @alertMessage.renderDefaultError requestId
      onSuccess = () =>
        mappingHash = @presenter.getMappingHashToDisplay(@channelManagerMappingModel)
        @$(@selectors.channelManagerMappingContainer).html TemplateHelper.render @mappingTemplateName, mappingHash
        @_validateMappingInformation()
      onFinish = () =>
        @_hideMappingInformationLoading()
      @channelManagerMappingModel.fetch(options).fail(onFailure).done(onSuccess).always(onFinish)
    else
      @_clearMappingInformation()

  _clearMappingInformation: ()->
    @$(@selectors.channelManagerMappingContainer).empty()

  _showMappingInformationLoading: ()->
    @$(@selectors.channelManagerMappingContainer).addClass 'loading'
    @$(@selectors.actionButtons).addClass 'hide'

  _hideMappingInformationLoading: ()->
    @$(@selectors.channelManagerMappingContainer).removeClass 'loading'
    @$(@selectors.actionButtons).removeClass 'hide'

  _saveMappingInformation: ()->
    # Saving only the channel manager name on hotel when there is no mapping information displayed
    unless @$(@selectors.channelManagerMappingContainerContent).is(':visible')
      @_saveChannelManagerDetailsOnHotel()
      return

    unless @_validateMappingInformation()
      @alertMessage.renderDefaultValidationError()
      return

    saveAttrs = @presenter.getMappingHashToSave(this)
    options =
      patch: true
      wait: true
    onSuccess = (data, textStatus, jqXHR) =>
      @_saveChannelManagerDetailsOnHotel()
    onFailure = (jqXHR, textStatus, errorThrown) =>
      @_handleErrorMappingSave(jqXHR)
    @channelManagerMappingModel.save(saveAttrs, options).then(onSuccess, onFailure)

  _handleErrorMappingSave: (jqXHR) ->
    requestId = jqXHR?.getResponseHeader 'x-request-id'
    errorResponse = jqXHR?.responseJSON?.body

    if errorResponse?.error?.code is @validationHelper.errorCodes.validationFail
      errorMessage = I18n.t 'js.modules.common.alerts.validation.default'
      @alertMessage.renderError errorMessage, requestId
      @validationHelper.displayFieldErrors(this, errorResponse.errors)
    else
      @alertMessage.renderDefaultError requestId

  _displayPostSaveActivatePopup: ()->
    confirmMessage = I18n.translate 'js.modules.profile.channel_manager.post_save_activate_popup_title'
    actionInfo = I18n.translate 'js.modules.profile.channel_manager.post_save_activate_popup_description',
      {channelManagerName: StringHelper.capitalize(@channelManagerMappingModel.channelManagerName)}
    @$(@selectors.activateConfirmationModal).find('.confirm-message').text(confirmMessage)
    @$(@selectors.activateConfirmationModal).find('.action-info').text(actionInfo)
    @$(@selectors.activateConfirmationModal).modal 'show'

  _saveChannelManagerDetailsOnHotel: ()->
    channelManagerName = @$(@selectors.connectionName).val() or null
    isConnectionActive = @$(@selectors.connectionStatus).hasClass 'active'
    saveAttrs =
      _csrf: $('#csrf_token').val()
      channelManager: {name: channelManagerName, isConnectionActive}
    options = { patch: true, wait: true}
    onSuccess = (data, textStatus, jqXHR) =>
      @render()
      @_renderConnectionStatusOnHeader channelManagerName, isConnectionActive
      requestId = jqXHR?.getResponseHeader 'x-request-id'
      message = I18n.translate 'js.modules.common.alerts.success.channel_manager_mapping_information_saved'
      @alertMessage.renderSuccess message, requestId
      # we want to prompt user to activate channel manager connection only
      # if channel manager is selected and is currently deactivated
      if channelManagerName? and not isConnectionActive 
        @_displayPostSaveActivatePopup()
    onFailure = (jqXHR, textStatus, errorThrown) =>
      @_handleChannelManagerSettingUpdateFailure jqXHR, textStatus, errorThrown
    @hotelModel.save(saveAttrs, options).then(onSuccess, onFailure)

  _setMappingInformationChanged: ()->
    @mappingInformationChanged = true

  _validateMappingInformation: ()->
    return true unless @$(@selectors.channelManagerMappingContainerContent).is(':visible')
    @$(@selectors.fieldErrorMessage).addClass 'hide'
    channelManagerParsleyConfig = @validationHelper.getChannelManagerParsleyConfig()
    @$(@selectors.channelManagerMappingContainerContent).parsley('destroy').parsley channelManagerParsleyConfig
    unless @$(@selectors.channelManagerMappingContainerContent).parsley 'validate'
      return false

    # Validating the form data to see any duplicate or missing ids
    mappingInformation = @presenter.getMappingHashToSave(this)
    @validationHelper.validateMappingInformation(this, mappingInformation)
  
  _handleChannelManagerSettingUpdateFailure: (jqXHR, textStatus, errorThrown) ->
      requestId = jqXHR?.getResponseHeader 'x-request-id'
      responseJSON = jqXHR?.responseJSON
      activeProductSetError = @errorPresenter.findError responseJSON, 'CURRENT_CHANNEL_MANAGER_HAS_PRODUCT_SET_IN_REVIEW_VIOLATION_APPROVED_LIVE'
      activeConnectionError = @errorPresenter.findError responseJSON, 'CHANNEL_MANAGER_UPDATE_ACTIVE_CONNECTION'
      knownError = activeProductSetError or activeConnectionError
      if knownError
        message = @errorPresenter.format knownError
        @alertMessage.renderError message, requestId
      else
        @alertMessage.renderDefaultError requestId