require "../presenters/products_row_presenter"
require "./products_notes_view"

class Extranet.Views.ProductsRowView extends Backbone.View

  Extranet.Common.Utilities.Mixin.extends this, Extranet.Helpers.ChildManager

  tagName: 'tr'

  events:
    'click .notes-link': 'viewNotes'

  selectors:
    notes: (productSetUuid)->
      "#notes-#{productSetUuid}"

  templateName: 'products_templates_products_row'

  initialize: (options) ->
    @model = options.model
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate

  render:() ->
    presenter = new Extranet.Presenters.ProductsRowPresenter()
    modelHash = presenter.toHash(@model)
    @$el.html(@autoTemplate.render @templateName, modelHash)
    this

  viewNotes: ()->
    @notesView ?= @addChild new Extranet.Views.ProductsNotesView
      model: @model
      placeHolder: @selectors.notes(@model.id)

    @notesView.render()
