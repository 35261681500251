class Extranet.Views.AlertMessage extends Backbone.View
  el: 'body'
  templateName: 'common_templates_alert_message'

  events:
    'click .alert .icon-close': 'iconClose'

  initialize: (options)->
    @selector= options?.selector
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate

  clear: ()->
    $(@selector).empty()

  hide: ()->
    $(@selector).hide()

  iconClose: (e)->
    e.preventDefault();
    $('.alert').hide()

  renderSuccess: (message, requestId=null)->
    @_renderMessage({message, requestId, alertType: 'success'})

  renderError: (message, requestId=null)->
    @_renderMessage({message, requestId, alertType: 'error'})

  renderDefaultError: (requestId=null)->
    message = I18n.translate("js.modules.common.alerts.error.default")
    @renderError(message, requestId)

  renderDefaultValidationError: ()->
    message = I18n.translate("js.modules.common.alerts.validation.default")
    @renderError(message)

  renderAlert: (message, requestId=null)->
    @_renderMessage({message, requestId, alertType: 'default'})

  _renderMessage: ({message, requestId, alertType})->
    if requestId
      requestIdText = I18n.translate("js.modules.common.alerts.request_id", {requestId})

    messageEl = @autoTemplate.render(@templateName, {message, requestIdText, alertType})
    $(@selector).html(messageEl).show()
