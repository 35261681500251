require '../presenters/contact'

class Extranet.Views.ContactDetails extends Backbone.View

  selectors:
    errorMessage: '.error-message'
    contactDetailsModal: '.contact-details-modal'
    actionButtons: '.action-buttons button'

  events:
    'click #save-contact-button': '_save'
    'click #cancel-save-button': '_closeModal'
    'click select': '_handleDataChange'
    'keyup input': '_handleDataChange'

  templateName: 'profile_contact_details'

  initialize: (options) ->
    @alertMessage = Extranet.Helper.AlertView.getAlertView()
    @hotelModel = options.hotelModel
    @errorMessage = new Extranet.Views.AlertMessage {selector: @selectors.errorMessage}
    @presenter = new Extranet.Presenters.Contact()
  
  render: () ->
    @hash = @presenter.toHash @model, @collection
    @$el.html TemplateHelper.render @templateName, @hash
    @$(@selectors.contactDetailsModal).modal 'show'
    @$(@selectors.contactDetailsModal).parsley Extranet.ParsleyConfig

  _save: (e) ->
    e.preventDefault()
    return unless Extranet.RolePermissions?.contact?.edit
    unless @$(@selectors.contactDetailsModal).parsley 'validate'
      @errorMessage.renderDefaultValidationError()
      return
    isNewContact = @model.isNew()
    saveAttributes = @presenter.getHashToSave this
    options =
      wait: true
    onSuccess = (data, textStatus, jqXHR) =>
      @_closeModal()
      if isNewContact
        @collection.add @model
        message = I18n.translate 'js.modules.common.alerts.success.contact_created'
      else
        message = I18n.translate 'js.modules.common.alerts.success.contact_updated'
      requestId = jqXHR?.getResponseHeader 'x-request-id'
      @alertMessage.renderSuccess message, requestId
    onFailure = (jqXHR, textStatus, errorThrown) =>
      requestId = jqXHR?.getResponseHeader 'x-request-id'
      @errorMessage.renderDefaultError requestId
    @model.save(saveAttributes, options).then(onSuccess, onFailure)

  _handleDataChange: (e) ->
    @$(@selectors.actionButtons).removeAttr 'disabled'

  _closeModal: () ->
    @$(@selectors.contactDetailsModal).modal 'hide'
    @remove()