class Extranet.Views.AutoComplete extends Backbone.View

  initialize: (options = {})->
    @el = options.citySelector
    @uuid= null
    @noResultsMsg = "No results found"

  render: (list, minlength=2) ->
    @el.empty()
    list = @getFormattedList list
    @el.autocomplete(
      @autoCompleteOptions list, minlength
    ).data("ui-autocomplete")._renderItem= (ul, item)->
      $('<li></li>').data('ui-autocomplete-item', item).append("<a>"+ item.label + "</a>").appendTo(ul)

  autoCompleteOptions: (list, minlength)->
    autoFocus: true
    minLength: minlength
    source: (request, response)=>
      results= $.ui.autocomplete.filter(list, request.term)
      if not results?.length
        results = [{uuid: null, label: @noResultsMsg}]
      response(results)
      messages:
        results: (->)

    focus: (event, ui)=>
      if ui.item.label is @noResultsMsg
        event.preventDefault()

    change: (event, ui)=>
      if not ui.item
        @el.val("")
        @uuid = null

    select: (event, ui)=>
      @uuid= ui.item.uuid
      if ui.item.label is @noResultsMsg
        event.preventDefault()

  getFormattedList: (options)->
    _.map options, (option) ->
          uuid: option['uuid']
          label: option['name']

  getUuid: ()->
    @uuid

  resetUuid: ()->
    @uuid= null

  enableSelector: () ->
    #@$el is not set, so we are using $(@el)
    $(@el).attr("disabled", false).removeClass "loading"

  disableSelector: () ->
    $(@el).attr("disabled", true).addClass "loading"
