require "../helpers/settings"
require "../helpers/event_manager"

class Extranet.Views.RateSettings extends Backbone.View
  el: 'body'

  eventManagerKey: Extranet.Helpers.EventManagerHelper.SETTINGS_KEY

  selectors:
    settingsBox: '.settings-options'
    grouponRate: '#groupon-rate'
    netRate:   '#net-rate'

  events:
    'click .icon-gear': 'showSettings'
    'click .close-settings': 'closeSettings'
    'change :radio': 'changeRates'

  initialize: () ->
    @settings = Extranet.Settings
    Extranet.Helpers.EventManagerHelper.initialize @eventManagerKey
    @eventManagerView = Extranet.Helpers.EventManagerHelper.getEventManagerView(@eventManagerKey)
    if @settings.getRateType() is @settings.GROUPON_RATE
      @$(@selectors.grouponRate).prop('checked', true)
    else
      @$(@selectors.netRate).prop('checked', true)

    # Bind events happening outside this view
    $(document).on 'click', @hideSettingsView.bind(this)
    $(document).on 'keydown', @hideSettingsViewOnEscape.bind(this)

  hideSettingsViewOnEscape: (e) ->
    @closeSettings() if (e.keyCode is 27)

  hideSettingsView: (e) ->
    target = $(e.target)
    unless target.is('.settings-options *') or target.is('.icon-gear')
      @closeSettings()

  showSettings: (e) ->
    e.preventDefault()
    @$(@selectors.settingsBox).show()

  closeSettings: () ->
    @$(@selectors.settingsBox).hide()

  changeRates: () ->
    usedRatePlan = if @$(@selectors.grouponRate).is(':checked') then @settings.GROUPON_RATE else @settings.NET_RATE
    @eventManagerView.triggerEvent usedRatePlan
    $.ajax
      type: "POST"
      url: "/extranet/api/user_preferences"
      dataType:"json"
      contentType: "application/json; charset=utf-8"
      data: JSON.stringify
        _csrf: $("#csrf_token").val()
        usedRatePlan: usedRatePlan
      error: (jqxhr)=>
        requestId = jqxhr?.getResponseHeader('x-request-id')
        @alertMessage.renderDefaultError(requestId)
