class Extranet.Presenters.ProductsRowPresenter

  toHash: (model = {}) ->
    modelHash = model.toJSON()
    modelHash.name = modelHash.name or "-"
    modelHash.startDate = if modelHash.restriction?.promotionalWindow?.startAt then DateTimeHelper.formatDate modelHash.restriction.promotionalWindow.startAt, 'numeric'
    modelHash.endDate =  if modelHash.restriction?.promotionalWindow?.endAt then DateTimeHelper.formatDate modelHash.restriction.promotionalWindow.endAt, 'numeric'
    modelHash.productSetUrl = ExtranetUrlBuilder.getProductSetUrl {
      hotelUuid: modelHash.hotelUuid
      productSetUuid: modelHash.uuid
    }
    modelHash.statusAbbreviation = Extranet.Helpers.ProductSetStatusHelper.statusAbbreviations[modelHash.status]
    modelHash.statusTooltipText = modelHash.status?.toUpperCase()

    return modelHash
