class Extranet.Presenters.Description

  toHash: (model) ->
    model.pick 'description'
  
  getHashToSave: (view) ->
    hash =
      _csrf: $('#csrf_token').val()
      description: view.$('#description-textarea').val()
      languageCode: view.model.get 'languageCode'
    PermissionsHelper.getEditableDataForClient 'hotel', hash