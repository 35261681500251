require "../../presenters/week_view/rate_cell"

class  Extranet.Views.WeekInventory.RateCellView extends Backbone.View
  utility = Extranet.Common.Utilities.Utility

  tagName: 'td'
  className: 'rate-cell'
  templateName: 'inventory_templates_weekview_rate_cell'

  selectors:
    baseRate: '.base-rate input'
    larRate: '.lar-rate input'
    grouponRate: '.groupon-rate input'
    netRate: '.net-rate input'
    percentage: '.percentage input'
    minAllotment: '.min-allotment input'
    minDiscount: '.min-percentage input'
    marginPercentage: '.margin-percentage input'
    blackoutStatus: '.blackout-status'
    noCheckInStatus: '.no-check-in-status'
    ratesSection: '.rates-section'
    restrictionsSection: '.restrictions-section'

  events:
    'change .base-rate input': 'updatePercentageByBaseRate'
    'change .groupon-rate input': 'updateRatesByGrouponRate'
    'change .percentage input': 'updateRatesByPercentage'
    'change .net-rate input': 'updateRatesByNetRate'
    'click #week-view-cancel': 'cancel'
    'click .icon-close': 'closeRate'
    'click .activate-rate': 'activateRate'
    'click .blackout-status': 'toggleBlackout'
    'click .no-check-in-status': 'toggleStayOnly'
    'change input': 'setDataChangeFlag'

  initialize: (data={}) ->
    @presenter = new Extranet.Presenters.WeekViewRateCellPresenter()
    @rateCell = @presenter.getRateCell data
    @hotelUuid = @rateCell.hotel?.uuid
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    @permissions = new Extranet.Common.Permissions
    @inventoryUtility = Extranet.Inventory.RatesUtility

  render: ()->
    @dataChangeFlags = {}
    this.$el.html @autoTemplate.render @templateName,
      rateCell: @rateCell
      closeButtonDescription: (date)->
        utility.prototype.translate "inventory.templates_weekview_rate_cell.close_button_description", {date}
      accessPermissionSubKey: @getAccessPermissionSubKey()

    @addSoldOutClass() if @rateCell.soldOut
    @addRateplanClass()
    this

  canEdit: (fieldName) ->
    permissionsHash = @getAccessPermissions()
    @permissions.canEdit(permissionsHash, fieldName)

  getAccessPermissionSubKey: ()->
    if DateTimeHelper.isPastDate @rateCell.date, @rateCell.hotel?.timeZone?.identifier
      accessPermissionSubKey = "readonly"
    else
      accessPermissionSubKey = @rateCell.productSet?.status or 'lastMinute'
    accessPermissionSubKey

  getAccessPermissions: () ->
    viewPermissions = Extranet.RolePermissions.inventory?.templates_weekview_rate_cell
    accessPermissionSubKey = @getAccessPermissionSubKey()
    accessPermissions = viewPermissions?[accessPermissionSubKey]
    accessPermissions

  addSoldOutClass: ()->
    @$el.addClass 'sold-out'

  addRateplanClass: ()->
    rateplanClass = if @isBookingDeal()
      'booking-deal'
    else
      'last-minute'
    @$el.addClass rateplanClass

  toggleBlackout: ()->
    return if @$(@selectors.blackoutStatus).hasClass('disabled') or not @canEdit 'blackout'

    return unless @validate()

    if @$(@selectors.blackoutStatus).hasClass('blackout')
      @$(@selectors.blackoutStatus).removeClass('blackout')
      @$(@selectors.ratesSection).slideDown(Extranet.Constants.BLACKOUT_TOGGLE_DURATION)
    else
      @$(@selectors.blackoutStatus).addClass('blackout')
      @$(@selectors.ratesSection).slideUp(Extranet.Constants.BLACKOUT_TOGGLE_DURATION)
    @dataChangeFlags.blackout = true

  validate: ()->
    @$el.attr 'data-bind', true
    @$el.parsley('destroy').parsley(Extranet.ParsleyConfig)
    return @$el.parsley('validate')

  toggleStayOnly: ()->
    return if @$(@selectors.noCheckInStatus).hasClass('disabled') or not @canEdit 'stay_only'
    if @$(@selectors.noCheckInStatus).hasClass('no-check-in')
      @$(@selectors.noCheckInStatus).removeClass('no-check-in')
    else
      @$(@selectors.noCheckInStatus).addClass('no-check-in')
    @dataChangeFlags.stayOnly = true

  enableValidation: ()->
    unless @skipRates()
      @$el.find('input').removeAttr 'skip-validation'

  disableValidation: ()->
    if @skipRates()
      @$el.find('input').attr 'skip-validation', true

  getHashToSave: ->
    saveHash = {}

    unless @dataChanged()
      return saveHash

    saveHash.rates = @getRates()
    saveHash.restrictions = @getRestrictions()

    saveHash

  dataChanged: ()->
    _.contains _.values(@dataChangeFlags), true

  skipRates: ()->
    @$(@selectors.blackoutStatus).hasClass('blackout')

  getRates: () ->
    return [] if @skipRates()

    baseRate =
      date: @rateCell.date
      type: 'base'

    grouponRate =
      date: @rateCell.date
      type: @getRateType()

    if @dataChangeFlags.baseRate
      baseRate.rate = @inventoryUtility.getRateValue(@$el.find(@selectors.baseRate).val())

    if @dataChangeFlags.grouponRate
      grouponRate.rate = @inventoryUtility.getRateValue(@$el.find(@selectors.grouponRate).val())

    if @isBookingDeal()
      if @dataChangeFlags.larRate
        baseRate.ql2Price = @inventoryUtility.getRateValue(@$el.find(@selectors.larRate).val())

      if @dataChangeFlags.marginPercentage
        grouponRate.marginPercentage = +@$el.find(@selectors.marginPercentage).val()

    rates = []
    rates.push(baseRate) if @dataChangeFlags.baseRate or @dataChangeFlags.larRate
    rates.push(grouponRate)  if @dataChangeFlags.grouponRate or @dataChangeFlags.marginPercentage
    rates

  isBookingDeal: ()->
    @rateCell.ratePlan?.productType is 'bookingDeal'

  getRateType: ()->
    rateType = if @isBookingDeal()
      'bookingDeal'
    else
      'lastMinute'
    rateType

  getRestrictions: () ->
    restrictions =
      date: @rateCell.date
      closedOut: false
      blackout: @getBlackoutValue()
      noLastNight: false

    if @isBookingDeal()
      restrictions.closedToArrival = @$(@selectors.noCheckInStatus).hasClass('no-check-in')

      if @dataChangeFlags.minAllotment
        restrictions.minDailyAllotment = @getMinAllotment()

      if @dataChangeFlags.minPercentage
        restrictions.minDiscountPercentage = @getMinDiscount()

    restrictions

  getMinAllotment: ()->
    minAllotment = +@$el.find(@selectors.minAllotment).val()

  getMinDiscount: ()->
    minDiscount = +@$el.find(@selectors.minDiscount).val()

  getMarginPercentage: ()->
    marginPercentage = +@$el.find(@selectors.marginPercentage).val()

  getBlackoutValue: ()->
    blackoutValue = if @$(@selectors.blackoutStatus).hasClass('blackout') then 'black' else 'white'

    if @dataChangeFlags.blackout \
    or @rateCell.restrictions?.blackoutSource is 'dailyRate'
      blackout = blackoutValue
    else
      blackout = 'none'

    blackout

  setDataChangeFlag: (e) ->
    field = $(e.currentTarget).parents('.field')

    if field.hasClass('groupon-rate') \
    or field.hasClass('net-rate') \
    or field.hasClass('percentage')
      @dataChangeFlags.grouponRate = true
    else if field.hasClass 'base-rate'
      @dataChangeFlags.baseRate = true
    else if field.hasClass 'lar-rate'
      @dataChangeFlags.larRate = true
      @changetoFixedValue field.find('input')
    else if field.hasClass 'min-allotment'
      @dataChangeFlags.minAllotment = true
    else if field.hasClass 'min-percentage'
      @dataChangeFlags.minPercentage = true
    else if field.hasClass 'margin-percentage'
      @dataChangeFlags.marginPercentage = true

    if field.hasClass('groupon-rate') \
    or field.hasClass('net-rate') \
    or field.hasClass('base-rate') \
    or field.hasClass('lar-rate')
      @changetoFixedValue field.find('input')

  changetoFixedValue: (element)->
    element.val @inventoryUtility.fixedValue(element.val())

  updateRatesByGrouponRate: (e) ->
    grouponRate = @inventoryUtility.fixedValue @$(@selectors.grouponRate).val()
    baseRate = @inventoryUtility.fixedValue @$(@selectors.baseRate).val()
    @$(@selectors.grouponRate).val grouponRate
    unless _.isEmpty(grouponRate) or _.isEmpty(baseRate)
      netRate = @computeNetRate grouponRate
      percentage = MathHelper.calculatePercentage baseRate, grouponRate
      @$(@selectors.percentage).val @inventoryUtility.fixedValue percentage
      @$(@selectors.netRate).val @inventoryUtility.fixedValue netRate

  updateRatesByNetRate: (e) ->
    netRate = @inventoryUtility.fixedValue @$(@selectors.netRate).val()
    baseRate = @inventoryUtility.fixedValue @$(@selectors.baseRate).val()
    @$(@selectors.netRate).val netRate
    unless _.isEmpty(netRate) or _.isEmpty(baseRate)
      grouponRate = @computeGrouponRateUsingNetRate netRate
      percentage = MathHelper.calculatePercentage baseRate, grouponRate
      @$(@selectors.percentage).val @inventoryUtility.fixedValue percentage
      @$(@selectors.grouponRate).val @inventoryUtility.fixedValue grouponRate

  updateRatesByPercentage: (e)->
    percentage = @inventoryUtility.fixedValue @$(@selectors.percentage).val()
    baseRate = @inventoryUtility.fixedValue @$(@selectors.baseRate).val()
    @$(@selectors.percentage).val percentage
    unless _.isEmpty(percentage) or _.isEmpty(baseRate)
      grouponRate = (baseRate* MathHelper.divide((100 - percentage), 100))
      netRate = @computeNetRate grouponRate
      @$(@selectors.grouponRate).val @inventoryUtility.fixedValue grouponRate
      @$(@selectors.netRate).val @inventoryUtility.fixedValue netRate

  updatePercentageByBaseRate: (data) ->
    baseRate = @inventoryUtility.fixedValue @$(@selectors.baseRate).val()
    grouponRate = @inventoryUtility.fixedValue @$(@selectors.grouponRate).val()
    @$(@selectors.baseRate).val baseRate
    unless _.isEmpty(baseRate) or _.isEmpty(grouponRate)
      percentage = MathHelper.calculatePercentage baseRate, grouponRate
      @$(@selectors.percentage).val @inventoryUtility.fixedValue percentage

  computeNetRate :(grouponRate) ->
    marginPercentage = @rateCell.marginPercentage or 0
    +grouponRate * (1 - marginPercentage/100)

  computeGrouponRateUsingNetRate: (netRate) ->
    marginPercentage = @rateCell.marginPercentage or 0
    val = +netRate / (1 - marginPercentage/100)
    return "" if _.isNaN(val)
    val
