require "../presenters/product_set_status_presenter"

class Extranet.Views.ProductSetStatusRollbackView extends Backbone.View
  Extranet.Common.Utilities.Mixin.extends this, Extranet.Helpers.ChildManager

  templateName: 'products_templates_product_set_status_rollback'

  selectors:
    title: '.title'
    prevStatuses: '.prev-statuses'
    currentStatus: '.product-set-status'
    closeModalButton: '.close-modal'
    confirmRollbackButton: '#confirm-rollback'
    rollbackConfirmMessage: '.confirm-message'

  events:
    'click .title.expandable': 'togglePrevStatuses'
    'click .prev-statuses .status-name': 'updateRollbackConfirmation'
    'click #confirm-rollback': 'updateProductSetStatus'
    'click #cancel-rollback': 'closeRollbackConfirmation'

  initialize: (options) ->
    {@productSetModel, @hotelUuid} = options
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    @alertMessage = Extranet.Helper.AlertView.getAlertView()
    @presenter = new Extranet.Presenters.ProductSetStatusPresenter
    @statusHelper = Extranet.Helpers.ProductSetStatusHelper
    @listenTo @productSetModel, 'change:status', @render

  render: () ->
    @currentStatus = @productSetModel.get('status')
    @prevStatuses = @presenter.getPrevStatuses(@currentStatus)

    hash =
      currentStatus: @currentStatus
      prevStatuses: @prevStatuses
      isRollbackAllowed: @isRollbackAllowed()
    @$el.html @autoTemplate.render @templateName, hash
    this

  isRollbackAllowed: ()->
    Extranet.RolePermissions.products.status_rollback and @prevStatuses?.length > 0

  isValidPrevStatus: (status)->
    status = _.findWhere(@prevStatuses, {status})
    if status.disabled
      return false
    else
      return true

  updateProductSetStatus: (e)->
    return unless @isRollbackAllowed()
    selectedStatus = @$(@selectors.confirmRollbackButton).attr('data-status')
    return unless @isValidPrevStatus(selectedStatus)
    @closeRollbackConfirmation()

    options =
      newState:
        name: selectedStatus
      _csrf: $("#csrf_token").val()
      hotelUuid: @hotelUuid
      productSetModel: @productSetModel

    ajaxCall = @statusHelper.changeStateTo options
    ajaxCall.done( (data, textStatus, jqxhr)=>
      newStatus = "<span class='product-set-status #{options.newState.name}'>#{options.newState.name}</span>"
      message = I18n.translate('js.modules.common.alerts.success.status_changed', {newStatus})
      requestId = jqxhr?.getResponseHeader('x-request-id')
      @alertMessage.renderSuccess(message, requestId)
    ).fail( (jqxhr)=>
      requestId = jqxhr?.getResponseHeader('x-request-id')
      @alertMessage.renderDefaultError(requestId)
    )

  togglePrevStatuses: ()->
    @$(@selectors.prevStatuses).animate({ width: "toggle"},300)
    @$(@selectors.title).toggleClass('expanded')

  updateRollbackConfirmation: (e)->
    selectedStatus = $(e.currentTarget).attr('data-status')
    return unless @isValidPrevStatus(selectedStatus)
    @$(@selectors.confirmRollbackButton).attr('data-status', selectedStatus)
    status = "<span class='product-set-status #{selectedStatus}'>#{selectedStatus}</span>"
    rollbackConfirmMessage = I18n.translate('js.modules.products.templates_product_set_status_rollback.rollback_confirmation_message', {status})
    @$(@selectors.rollbackConfirmMessage).html(rollbackConfirmMessage)

  closeRollbackConfirmation: () ->
    @$(@selectors.closeModalButton).click()
