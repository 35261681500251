window.Extranet ?= {}
Extranet.ParsleyConfig =

  errorClass: 'error'
  excluded: "input[type=button], input[type=submit], input[type=reset], input[type=hidden], [skip-validation], [disabled]"

  errors:
    classHandler: (elem) ->
      inputEl = $(elem)
      containerSelector = inputEl.attr('data-container-el')
      return inputEl unless containerSelector?.length

      containerEl = inputEl.parents(containerSelector)
      if containerEl.length then containerEl else inputEl

  validators:
    inventorycheck: (val, elm, self)->
      return false if _.isEmpty val
      reg_exp = /^[0-9]\d*$/
      reg_exp.test val

    uuidcheck: (val, elem, self) ->
      val = val?.trim()
      reg_exp = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i
      reg_exp.test val

    ratecheck: (val, elem, self) ->
      return false unless parseFloat(val) > 0
      reg_exp =  /(^\d*([.]\d{1,2})?)$/
      reg_exp.test val

    taxidcheck: (val, elem, self) ->
      reg_exp = /[0-9][0-9]-[0-9]{7}/
      reg_exp.test val

    discountpercentage: (val, elem, self) ->
      reg_exp = /(^100([.]0{1,2})?)$|(^\d{1,2}([.]\d{1,2})?)$/
      reg_exp.test val

    positivenumber: (val, elem, self) ->
      reg_exp = /(?:^[0-9]*(\.?[0-9]+)$){1}/
      reg_exp.test val?.trim()

    percent: (val, elem, self) ->
      percentageValue = parseFloat(val)
      if percentageValue >= 0 and percentageValue <= 100
        return true
      false

    latitudecheck: (val, elem, self) ->
      reg_exp = /^(\-)?(\d){1,2}((\.)(\d){1,7})?$/
      return false unless reg_exp.test(val)

      value = parseFloat(val)
      return (value >= -90 and value <= 90)

    longitudecheck: (val, elem, self) ->
      reg_exp = /^(\-)?(\d){1,3}((\.)(\d){1,7})?$/
      return false unless reg_exp.test(val)

      value = parseFloat(val)
      return (value >= -180 and value <= 180)

    valuerangecheck: (val, elem, self) ->
      reg_exp = /^(\-)?(\d)*((\.)(\d)+)?$/
      return false unless reg_exp.test(val)

      min = parseFloat(self.$element.attr('data-min'))
      max = parseFloat(self.$element.attr('data-max'))
      value = parseFloat(val)
      return (value >= min and value <= max)

    emailcheck: (val, elem, self) ->
      # Follows RFC 2822
      reg_exp = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
      reg_exp.test val?.trim().toLowerCase()

    phonecheck: (val, elm, self) ->
      reg_exp = /^\d{5,15}$/
      reg_exp.test val?.replace(/[\(\)\-\+\s\.]/g, '')

    abbreviationcheck: (val, elem, self) ->
      6 > val.length > 2

    islength3: (val, elem, self) ->
      val.length is 3

    islength4: (val, elem, self) ->
      val.length is 4

    dateformatvalidation: (val, elem, self) ->
      DateTimeHelper.isCorrectDateFormat(val)

    datevalidation: (val, elem, self) ->
      moment(val, ["MM/DD/YYYY"], true).isValid()

    mindiscountcheck: (val, el, self) ->
      minDiscount = Extranet?.FeatureFlags?.minimumDiscount
      return false if not minDiscount

      # converting into numbers just for safety
      valid = +val >= +minDiscount

      if not valid
        self.$element.attr 'title', I18n.t('js.modules.inventory.validation_messages.min_discount_check_error', {minDiscount})

      valid

    baseratecheck: (val, elem, self) ->
      return true if val.length is 0

      parentSelector = self.$element.attr 'parent-selector'
      childSelector = self.$element.attr 'child-selector'

      baseRateEl = self.$element.parents(parentSelector).find(childSelector)

      return true unless _.isEmpty baseRateEl.val()
      self.$element.attr 'title', I18n.t('js.modules.inventory.validation_messages.base_rate_check_error')

      return false

    nonnegativeintegercheck: (val, elm, self) ->
      reg_exp = /^[\d]+$/
      reg_exp.test val?.trim()

    positiveintegercheck: (val, elm, self) ->
      reg_exp = /^[1-9][\d]*$/
      reg_exp.test val?.trim()

    cancellationwindowcheck: (val, elm, self) ->
      units = $('#cancellation-options').find(':selected').val()

      reg_exp = /^[\d]+$/
      return false unless reg_exp.test(val)

      if units == 'days'
        hours = val * 24
      else
        hours = val

      maxCancellationHours = Extranet?.FeatureFlags?.maximumCancellationHours
      valid = hours <= maxCancellationHours
      if not valid
        self.$element.attr 'title', I18n.t('js.modules.inventory.validation_messages.cancellation_window_check_error', {maxCancellationHours})

      valid

  listeners:
    # return true to ignore field validation
    onFieldValidate: (element) ->
      return true unless $(element).is ':visible'
      false
