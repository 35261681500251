class Extranet.Presenters.PsFieldsErrPresenter
  @translationKeys =
    allKeys: 'inventory.ps_fields_err_presenter'
    objectType: 'inventory.ps_fields_err_presenter.object_type'

  @defaultPresenter = Extranet.Presenters.DefaultFailurePresenter
  @helper = Extranet.Helpers.ValidationPresentersHelper

  @getErrorMessages: ({failedValidation, hotelInfo})->
    errorMessages = []
    validationAttrs = _.pick failedValidation, 'description', 'restrictionType'
    productSetInfo = @helper.getProductSetInfo hotelInfo, failedValidation.entityUuid
    messages = '<ul>'
    for failure in failedValidation?.failures
      messages += '<li>' + @getMessage(failure, validationAttrs) + '</li>'
    messages += '</ul>'

    errorMessages.push messages

    {
      messages: errorMessages
      objectUuid: failedValidation.entityUuid
      objectType: @helper.getTranslations @translationKeys.objectType
      objectName: productSetInfo?.name
    }

  @getMessage: (failure, validationAttrs)->
    translations = @helper.getTranslations @translationKeys.allKeys
    switch failure.instance
      when 'restriction.promotionalWindow' then translations.missing_promotional_window
      when 'restriction.minAverageDiscountPercentage' then translations.missing_min_avg_disc_percentage
      when 'restriction.minDailyDiscountPercentage' then translations.missing_min_daily_disc_percentage
      when 'restriction.maxBlackoutDates' then translations.missing_max_blackout_dates
      when 'restriction.minDailyAllotment' then translations.missing_min_daily_allotment
      when 'restriction.minimumLengthOfStay' then translations.missing_min_length_of_stay
      when 'restriction.maximumLengthOfStay' then translations.missing_max_length_of_stay
      when 'dealUuid' then translations.missing_deal_uuid
      when 'dealSeoName' then translations.missing_deal_seo_name
      when 'optionUuid' then translations.missing_option_uuid
      else translations.description + ':' + validationAttrs.description +
      '; ' + @defaultPresenter.getMessage failure
