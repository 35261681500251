###
This class will be used if no matching failure presenter found for a failure.

Format of failure message:
{
  actual: "null"
  instance: "restriction.promotionalWindow"
  restriction: "not null"
}
###

class Extranet.Presenters.DefaultFailurePresenter
  @helper = Extranet.Helpers.ValidationPresentersHelper
  @translationsKey = 'inventory.default_failure_presenter'

  @getMessage: (failure)->
    translations = @helper.getTranslations @translationsKey
    if failure.restriction
      message = translations.restriction + ' = '
      message += failure.restriction + '; '

    if failure.instance
      message += translations.instance + ' = '
      message += failure.instance + '; '

    if failure.actual
      message += translations.actual + ' = '
      message += failure.actual

    return message
