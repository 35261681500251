class Extranet.Presenters.RecentAutoUpdates

  toHash: (model) ->
    hash =
      larRateUpdatedAt: @_formatDateTime model.get('larRateUpdatedAt')
      grpnRateUpdatedAt: @_formatDateTime model.get('grpnRateUpdatedAt')

  _formatDateTime: (dateTime) ->
    formatType = 'string'
    timeZoneIdentifier = Extranet.ClientConfig?.hotelData?.timeZone?.identifier
    if dateTime then DateTimeHelper.formatDateTime dateTime, formatType, timeZoneIdentifier else null