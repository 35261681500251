Extranet.Common.Utilities.Render =

  updateDropdownOptions: (fieldType, dropdownSelector, params, options={})->
    {includeEmptyOption, optionValueKey, optionNameKey, selectedOption, silent, successCallback, errorCallback} = options

    optionValueKey ?= 'uuid'
    optionNameKey ?= 'name'

    if fieldType in ["countries", "states", "cities", "time_zones"]
      dropdownSelector.find('option').remove()
      dropdownSelector.attr("disabled", true).addClass "loading"
      if includeEmptyOption
        pleaseSelectText = I18n.translate('js.modules.common.form.please_select')
        dropdownSelector.append "<option value=''>" + pleaseSelectText + "</option>"
      $.ajax
        url: "/v2/getaways/extranet/#{fieldType}"
        type: "GET"
        data: params
        global: false
        success: (options) ->
          dropdownSelector.attr("disabled", false).removeClass "loading"
          for option in options
            selected = if option[optionValueKey] is selectedOption then 'selected' else ''
            dropdownSelector.append "<option value='#{option[optionValueKey]}' #{selected}>#{option[optionNameKey]}</option>"
          dropdownSelector.trigger('change') unless silent
          successCallback?(options)
        error: ->
          dropdownSelector.attr("disabled", false).removeClass "loading"
          errorCallback?()
