class Extranet.Presenters.RatePlan
  
  format: (data, roomModel) ->
    name: data.name
    abbreviation: data.internalName
    bonusAdd: data.bonusAdd?.name
    roomType: roomModel.get 'name'
    url: @_getUrl data
  
  _getUrl: (data) ->
    options =
      hotelUuid: data.hotelUuid
      roomTypeUuid: data.roomTypeUuid
      ratePlanUuid: data.uuid
      productSetUuid: data.productSetUuid
    ExtranetUrlBuilder.getBdRatePlanUrl options if data.productSetUuid