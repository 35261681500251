# html_edit.coffee
# ======
#
# Authors:
#   Venkata Harikrishna Nukala
#
# This is written as JQuery plugin. This plug-in allows to edit html text
# directly as text. It is a wrapper over "jqueryte" plugin. More info can be
# found @ http://jqueryte.com
#
# usage:
# $('#fineprint').xnetHtmlEdit()

xnetHtmlEditFunc  = ($)->

  defaultOptions=
    b: true
    br: true
    button: false
    center: false
    color: false
    fsize: false
    fsizes: false
    funit: false
    format: false
    formats: false
    i: false
    indent: false
    link: false
    linkType: false
    left: false
    ol: false
    outdent: false
    placeholder: false
    remove: false
    right: false
    rule: false
    source: false
    status: true
    sub: false
    strike: false
    sup: false
    title: false
    u: false
    ul: false
    unlink: false
    css: "htmledit"

  $.fn.xnetHtmlEdit = (options={})->
    opts = _.extend {}, defaultOptions, options # Enabling the custom options to be passed while initializing
    this.jqte(opts)

xnetHtmlEditFunc(jQuery)
