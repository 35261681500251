class Extranet.Collections.Bookings extends Backbone.PageableCollection

  queryParams =
    pageSize: 'limit'
    totalPages: null
    totalRecords: null
    page: null
    _csrf: $('#csrf_token').val()
    offset: ->
      (@state.currentPage-1) * @state.pageSize

  url : () ->
    "/v2/getaways/extranet/hotels/#{@hotelUuid}/reservations"

  initialize: (options) ->
    @hotelUuid = Extranet.ClientConfig?.hotelData?.uuid

  fetch: (options) ->
    options = _.extend options, {type: 'POST'}
    Backbone.PageableCollection.prototype.fetch.call this, options

  state:
    pageSize: 25

  queryParams: queryParams

  updateQueryParams: (searchParams)->
    @queryParams = _.extend {}, queryParams, searchParams

  parseState: (response, queryParams, state, options) ->
    totalRecords = response.pagination?.count or 0
    {pageSize, currentPage} = state
    recordFrom = (currentPage-1)*pageSize + 1
    recordTo = recordFrom + (response.reservations?.length or 0) - 1
    { totalRecords, recordFrom, recordTo }

  parseRecords: (response, options) ->
    _.map response.reservations, (reservation) ->
      _.extend reservation, {timeZoneIdentifier: response.hotelData.timeZone.identifier}
