require "./ps_rate_plan_row_view"

class Extranet.Views.PsRatePlanListView extends Backbone.View

  Extranet.Common.Utilities.Mixin.extends this, Extranet.Helpers.ChildManager

  templateName: 'products_templates_ps_rate_plan_list'

  selectors:
    tableBody: 'tbody'
    ratePlanTable: '.rate-plan-table'

  initialize: (options) ->
    @hotelUuid = options.hotelUuid
    @productSetUuid = options.productSetUuid
    @dealOptions = options.dealOptions
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    @productSetModel = options.productSetModel
    @ratePlanList = @productSetModel.get 'ratePlans'
    @roomTypesList = @productSetModel.get 'roomTypes'
    @ratePlanList = _.map @ratePlanList, (ratePlan) =>
      ratePlan.productSetUuid = @productSetModel.get 'uuid'
      ratePlan.hotelUuid = @productSetModel.get 'hotelUuid'
      ratePlan

  render: () ->
    hasModels = @ratePlanList.length > 0
    @$el.html @autoTemplate.render @templateName, {
      newRatePlanUrl: ExtranetUrlBuilder.getBdRatePlanUrl {@hotelUuid,@productSetUuid}
      hasModels: hasModels
      accessPermissionSubKey: @productSetModel.get 'status'
    }
    @renderRatePlans() if hasModels

  renderRatePlans: () ->
    dealOptions = @getDealOptionsArray() if @dealOptions
    status = @productSetModel.get 'status'
    @ratePlanOptionsMap = []
    _.each @ratePlanList, (ratePlanHash) =>
      roomTypeHash = @roomTypesList?[ratePlanHash.roomTypeUuid]
      ratePlanRowView = new Extranet.Views.PsRatePlanRowView({ratePlanHash, roomTypeHash, dealOptions, @hotelUuid, @productSetUuid, status})
      @listenTo ratePlanRowView, 'ratePlanDeleted', @deleteRatePlan
      @listenTo ratePlanRowView, 'optionChanged', @ratePlanOptionChange
      @addChild ratePlanRowView
      @ratePlanOptionsMap.push {view: ratePlanRowView, optionUuid: ratePlanHash.optionUuid}
      @$el.find(@selectors.tableBody).append ratePlanRowView.render().el

  getDealOptionsArray: ()->
    _.map @dealOptions, (option)->
      {
        id: option.id
        name: option.name
        inventoryProductId: option.inventoryProductId
      }

  deleteRatePlan: (ratePlan) ->
    @ratePlanOptionsMap = _.reject @ratePlanOptionsMap, (ratePlanToOption) ->
      ratePlanToOption.view.getRatePlanUuid() is ratePlan.uuid
    @ratePlanList = _.reject @ratePlanList, (hash) ->
      ratePlan.uuid is hash.uuid
    if @ratePlanList.length is 0
      @render()
    @productSetModel.set 'ratePlans', @ratePlanList

  ratePlanOptionChange: (options = {})->
    hash = _.find @ratePlanOptionsMap, (obj)->
      obj.optionUuid is options.optionUuid

    if hash
      hash.optionUuid = ""
      hash.view.reSetOption()

    hash = _.find @ratePlanOptionsMap, (obj)->
      obj.view is options.view
    hash.optionUuid = options.optionUuid

  save: ()->
    requests = []
    _.each @ratePlanOptionsMap, (obj)->
      requests.push obj.view.save()
    return $.when.apply undefined, requests
