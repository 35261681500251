class Extranet.Collections.RateDescriptions extends Backbone.Collection

  url : () ->
    "/extranet/api/hotels/#{@hotelUuid}/rate_descriptions"

  initialize: () ->
    @hotelUuid = Extranet.ClientConfig.hotelData?.uuid

  parse: (response) ->
    {rateDescriptions} = response
    rateDescriptions

  save: (rateDescriptions, successCallback, errorCallback)->
    $.ajax
      type: 'PUT'
      url: @url()
      data: JSON.stringify({rateDescriptions})
      dataType:"json"
      contentType: "application/json; charset=utf-8"
      headers:
        'x-csrf-token': $('#csrf_token').val()
      success: successCallback
      error: errorCallback

  fetchCount: (successCallback, errorCallback)->
    $.ajax
      url: "/extranet/api/hotels/#{@hotelUuid}/rate_descriptions_count"
      global: false
      success: successCallback if successCallback
      error: errorCallback if errorCallback
