'use strict'

###*
# Method to pass data to Optimize so the event/data shows in the splunk warehouse
#
# @async
# @param {String} eventName - Name of the event to emit to splunk
# @param {Object} [payload] - Data to emit to splunk (Optional)
###
trackEvent = (eventName, payload = {}) ->
  requestIdleCallback ->
    if typeof payload isnt 'object'
      payload = { data: payload }

    OptimizeSuite?.TrackingHub?.add("#{eventName}", payload)
  return

module.exports = trackEvent
