class Extranet.Views.RatesUploadView extends Backbone.View

  events:
    'click button.select-file': 'renderNotification'
    'click button.cancel': 'closeModal'
    'click button.close': 'closeModal'
    'click button.submit': 'uploadFile'
    'change div.file-upload .upload': 'updateFileName'

  templateName: 'admin_templates_rates_upload'

  selectors:
    notification: '.notification'
    message: '.message'
    fileUploadButton: 'div.file-upload'
    fileUploadSelector: 'div.file-upload .upload'
    closeModalButton: '.close-modal'
    selectedfile: '.selected-file'
    buttonsContainer: '.buttons'
    allButtons: '.buttons .btn'
    submitButton: 'button.submit'
    closeButton: 'button.close'

  initialize: (options={}) ->
    {@isDealRatesUpload, @placeholder} = options

  render: ()->
    @$el.html Extranet.Templates[@templateName].render
      fileUploadUrl: ExtranetUrlBuilder.getProductSetRatesUploadUrl()

    if @isDealRatesUpload
      notificationMessage = "You are about to submit Deal Rates"
    else
      notificationMessage = "You are about to submit QL2 Rates"

    @renderNotification notificationMessage
    @renderMessage 'Please select a CSV file to upload rates'

    $(@placeholder).html this.el
    @enableFileUpload()
    this

  renderNotification: (message='', status='')->
    notificationEl = @$(@selectors.notification)
    notificationEl.html message

    notificationEl.removeAttr('class') # Removing all the css classes
    notificationEl.addClass 'notification'

    switch status
      when "success" then notificationEl.addClass 'icon-check-circle'
      when "error" then notificationEl.addClass 'icon-close'
      else notificationEl.addClass ''

  renderSuccessNotification: (message='')->
    @renderNotification message, 'success'

  renderErrorNotification: (message='')->
    @renderNotification message, 'error'

  renderMessage: (message='')->
    messageEl = @$(@selectors.message)
    messageEl.html message

  clearMessage: ()->
    @renderMessage ''

  closeModal: ()->
    @$(@selectors.fileUploadSelector).val('')
    @$(@selectors.closeModalButton).click()

  updateFileName: (fileName)=>
    @$(@selectors.selectedfile).html fileName

    if fileName
      @renderNotification "You are about to submit Deal Rates"
      @clearMessage()
      @hideFileUploadButton()
      @displaySubmitButton()
    else
      @hideSubmitButton()
      @displayFileUploadButton()
      @renderMessage 'Please select a valid CSV file to upload rates'

  clearFileName: ()->
    @$(@selectors.selectedfile).html ""

  displayFileUploadButton: ()->
    @$(@selectors.fileUploadButton).removeClass 'hide'

  hideFileUploadButton: ()->
    @$(@selectors.fileUploadButton).addClass 'hide'

  displayCloseButton: ()->
    @$(@selectors.allButtons).addClass 'hide'
    @$(@selectors.buttonsContainer).addClass 'text-center'
    @$(@selectors.closeButton).removeClass 'hide'

  displaySubmitButton: ()->
    @$(@selectors.submitButton).removeClass 'hide'

  hideSubmitButton: ()->
    @$(@selectors.submitButton).addClass 'hide'

  successCallback: (result, textStatus, jqXHR)=>
    @displayCloseButton()
    @clearFileName()
    @renderSuccessNotification result?.message
    @renderMessage "You can now check updated rates and allotments on inventory"

  failureCallback: (jqXHR, textStatus, errorThrown)=>
    if jqXHR.status is 403
      errorMessage = jqXHR.responseJSON?.errorMessage
    else if jqXHR.status is 413
      errorMessage = I18n.translate('js.modules.admin.upload_max_file_size_error', {limit: jqXHR.responseJSON?.limit})
    else
      errorMessage = 'The server is busy or down' # TODO: Should handle Try again flow

    @hideSubmitButton()
    @clearFileName()
    @displayFileUploadButton()
    @renderErrorNotification(errorMessage)
    errorDescription = I18n.translate('js.modules.admin.upload_rates_error_description', {lineBreak: "<br/>"})
    @renderMessage(errorDescription)

  uploadFile: ()->
    @fileUploadData.submit().done(@successCallback).fail(@failureCallback)

  enableFileUpload: ()->
    @$(@selectors.fileUploadSelector).fileupload {
      add: (e, data)=>
        @updateFileName data.files?[0]?.name
        @fileUploadData = data

      headers:
        "x-csrf-token": $('#csrf_token').val()
    }

    @$(@selectors.fileUploadSelector).click()
