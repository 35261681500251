require "../presenters/ps_rate_plan_details_presenter"
require "./room_details_view"

class Extranet.Views.PsRatePlanDetailsView extends Backbone.View
  Extranet.Common.Utilities.Mixin.extends this, Extranet.Helpers.ChildManager

  utility = Extranet.Common.Utilities.Utility

  createNewRoom : "add-room"
  MINIMUMSTAY = 1
  MIN_LENGTH_OF_STAY_MAX_VAL = 14
  MAXIMUMSTAY = 29
  templateName: 'products_templates_ps_rate_plan_details'
  el : '.bd-rate-plan-details'

  events:
    'click #save-bd-rateplan': 'saveRatePlan'
    'click #cancel-bd-rateplan': 'cancelRatePlan'
    'change select.room-type': 'changeRoomType'
    'change select#advancePurchaseWindow': 'setChanged'
    'change select#minLengthOfStay': 'changeMaxLengthOfStay'
    'change select#maxLengthOfStay': 'changeMinLengthOfStay'
    'change input[type=text]': 'setChanged'
    'change #applyCcFee': 'setChanged'
    'click #clear-bonus-add': 'clearBonusAdd'
    'change #bonus-name': 'hideOrShowClearBonusAddLink'
    'change #bonus-value': 'hideOrShowClearBonusAddLink'
    'change #cancellation-options': 'validateCancellationWindow'

  selectors:
    alertMessage: '.alert-message'
    modalBody: '.modal-body'
    modalSelector: '#addRoomType'
    roomTypesSelector: '.room-type'
    addNewRoom: '.room-type [value="add-room"]'
    cancellationWindow: '#cancellation-window'
    bonusAdd:
      name: '#bonus-name'
      value: '#bonus-value'

  initialize: (options) ->
    {@hotelUuid, @productSetUuid, @ratePlanUuid, @productSetModel} = options
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    @alertMessage = new Extranet.Views.AlertMessage {selector: @selectors.alertMessage}
    @rooms = new Extranet.Collections.Rooms {hotelUuid: options.hotelUuid}
    @psRatePlanPresenter = new Extranet.Presenters.PsRatePlanPresenter @templateName, @productSetModel
    @errorPresenter = new Extranet.Presenters.Error()
    @dataChanged = {}
    fetchRatePlan = (options, cb)=>
      @model = new Extranet.Models.RatePlanModel
        uuid: options.ratePlanUuid
        hotelUuid: @hotelUuid
        productSetUuid: @productSetUuid
      @model.fetch
        success: () =>
          cb(options)
        error: (model, response, options)=>
          requestId = options?.xhr?.getResponseHeader('x-request-id')
          @alertMessage.renderDefaultError(requestId)

    fetchRoomsInfo = (options) =>
      @rooms.fetch
        success: () =>
          @render()
        error: (model, response, options)=>
          requestId = options?.xhr?.getResponseHeader('x-request-id')
          @alertMessage.renderDefaultError(requestId)

    if options.ratePlanUuid
      fetchRatePlan options, fetchRoomsInfo
    else
      # New Rateplan
      fetchRoomsInfo options

  render: () ->
    {model, maxLengthOfStayList, minLengthOfStayList, advancePurchaseWindow} = @psRatePlanPresenter.toHash @model
    unless @model
      isNewRatePlan = true
      rooms = @rooms.getLarRooms()
    else
      isNewRatePlan = false
      rooms = @rooms.get model?.roomTypeUuid
      rooms = rooms?.toJSON()

    @$el.html @autoTemplate.render @templateName,
      model: model
      maxLengthOfStayList: maxLengthOfStayList
      minLengthOfStayList: minLengthOfStayList
      advancePurchaseWindow: advancePurchaseWindow
      rooms : rooms
      isNewRatePlan: isNewRatePlan
      accessPermissionSubKey: @productSetModel.get 'status'
      currencySymbol: utility.getCurrencySymbol(Extranet.ClientConfig?.hotelData?.rateCurrencyCode)
      datePickerPlaceholder: DateTimeHelper.getDateFormat 'numeric'
      isRateThresholdEnabled: @productSetModel.get('livePricingRule') is 'dailyDiscount'

    @$el?.parsley('destroy')?.parsley(Extranet.ParsleyConfig)
    @datePickerAdjustment()
    @hideOrShowClearBonusAddLink()

  saveRatePlan: () ->
    if not @validate()
      @alertMessage.renderDefaultValidationError()
      return
    startDate = DateTimeHelper.formatDate @$("#startDate").val(), 'api'
    endDate = DateTimeHelper.formatDate @$('#endDate').val(), 'api'
    if not DateTimeHelper.isBeforeDate startDate, endDate
      errorMessage = I18n.translate("js.modules.products.templates_ps_rate_plan_details.date_error")
      @alertMessage.renderError(errorMessage)
      return

    @alertMessage.hide()
    hash = @psRatePlanPresenter.getHashToSave this
    unless @model
      # Post works only with roomtypeuuid
      @model = new Extranet.Models.RatePlanModel {@hotelUuid, roomTypeUuid:hash.roomTypeUuid, @productSetUuid}

    @model.save hash,
      wait: true
      patch: true # For the partial update
      success: (model, response, options)=>
        message = I18n.translate('js.modules.common.alerts.success.rateplan_saved')
        requestId = options?.xhr?.getResponseHeader('x-request-id')
        @alertMessage.renderSuccess(message, requestId)
      error: (model, response, options)=>
        requestId = options?.xhr?.getResponseHeader('x-request-id')
        responseJSON = response?.responseJSON
        ratePlanRestrictionNetrateFoundError = @errorPresenter.findError responseJSON, 'RATE_PLAN_RESTRICTION_NET_RATE'
        if ratePlanRestrictionNetrateFoundError
          message = @errorPresenter.format ratePlanRestrictionNetrateFoundError
          @alertMessage.renderError message, requestId
        else
          @alertMessage.renderDefaultError(requestId)

  changeURL: () ->
    bdDetailsUrl = ExtranetUrlBuilder.getBdDetailsFragment
      productSetUuid: @productSetUuid
    Backbone.history.navigate bdDetailsUrl, {trigger:true}

  changeRoomType: (e) ->
    e.preventDefault()
    roomTypeId = @$(e.currentTarget).val()
    if roomTypeId is @createNewRoom
      @renderAddRoomView()
      @$(e.currentTarget).val("")

  renderAddRoomView: () ->
    @roomDetailsView?.remove()
    @roomDetailsView = new Extranet.Views.RoomDetailsView collection:@rooms
    @listenTo @roomDetailsView, 'newRoomAdded' , @addNewRoom
    @$(@selectors.modalSelector).addClass 'show-modal'  # It is done by default only on anchor tag
    @$(@selectors.modalBody).html @roomDetailsView.render().el

  addNewRoom: (roomHash) ->
    return unless roomHash
    @roomDetailsView?.closeView()
    @roomDetailsView?.remove()
    @$(@selectors.addNewRoom).before("<option value=#{roomHash.uuid}>#{roomHash.name}</option>")

  cancelRatePlan: (e) ->
    e.preventDefault()
    @render()

  datePickerAdjustment: () ->
    @$("#startDate").datepicker
      onSelect: (selectedDate) =>
        @$("#endDate").datepicker "option", "minDate", selectedDate
        return

    @$("#endDate").datepicker
      onSelect: (selectedDate) =>
        @$("#startDate").datepicker "option", "maxDate", selectedDate
        return
    return

  changeMinLengthOfStay: (e) ->
    # Max Length needs to be updated only if changed
    changedInputId = e.currentTarget.id
    @dataChanged[changedInputId] = true
    maxLengthSelected = +@$(e.currentTarget).val()
    unless maxLengthSelected #No Max length chosen, permit all values of minlength
      for value in [MINIMUMSTAY..MIN_LENGTH_OF_STAY_MAX_VAL]
        @$("#minLengthOfStay [value=#{value}]").attr("disabled",false)
      return
    for value in [MINIMUMSTAY..maxLengthSelected]
      @$("#minLengthOfStay [value=#{value}]").attr("disabled",false)

    if maxLengthSelected >=  MIN_LENGTH_OF_STAY_MAX_VAL
      for value in [maxLengthSelected+1..MIN_LENGTH_OF_STAY_MAX_VAL+1]
        @$("#minLengthOfStay [value=#{value}]").attr("disabled",true)
    else
      for value in [maxLengthSelected+1..MIN_LENGTH_OF_STAY_MAX_VAL]
        @$("#minLengthOfStay [value=#{value}]").attr("disabled",true)

  changeMaxLengthOfStay: (e) ->
    minLengthSelected = +@$(e.currentTarget).val()
    for value in [MINIMUMSTAY..minLengthSelected-1]
      @$("#maxLengthOfStay [value=#{value}]").attr("disabled",true)
    for value in [minLengthSelected..MAXIMUMSTAY]
      @$("#maxLengthOfStay [value=#{value}]").attr("disabled",false)

  validate: () ->
    return @$el.parsley('validate') and @validateBonusAdd()

  validateBonusAdd: () ->
    $bonusAddName = @$(@selectors.bonusAdd.name)
    $bonusAddValue = @$(@selectors.bonusAdd.value)
    bonusAddValue = $bonusAddValue.val()
    bonusAddName = $bonusAddName.val()
    if (bonusAddName and bonusAddValue) or (not bonusAddName and not bonusAddValue)
      $bonusAddName.removeClass('error')
      $bonusAddValue.parent('.input-field').removeClass('error')
      return true

    unless bonusAddName
      $bonusAddName.addClass('error')
    unless bonusAddValue
      $bonusAddValue.parent('.input-field').addClass('error')
    return false

  setChanged: (e) ->
    changedInputId = e.currentTarget.id
    @dataChanged[changedInputId] = true

  clearBonusAdd: ()->
    @$(@selectors.bonusAdd.name).val('')
    @$(@selectors.bonusAdd.value).val('')
    @$('#clear-bonus-add').addClass('hide')

  hideOrShowClearBonusAddLink: ()->
    if @$(@selectors.bonusAdd.name).val() or @$(@selectors.bonusAdd.value).val()
      @$('#clear-bonus-add').removeClass('hide')
    else
      @$('#clear-bonus-add').addClass('hide')

  validateCancellationWindow: ()->
    @$(@selectors.cancellationWindow).parsley('destroy').parsley(Extranet.ParsleyConfig)
    @$(@selectors.cancellationWindow).parsley('validate')
