require "../presenters/ps_rate_plan_row_presenter"

class Extranet.Views.PsRatePlanRowView extends Backbone.View

  tagName: 'tr'

  templateName: 'products_templates_ps_rate_plan_row'

  selectors:
    closeModalButton: '.close-modal'
    ratePlanOptions: '#rate-plan-options'

  events:
    'click #delete-ratePlan': 'deleteRatePlan'
    'click #cancel-ratePlan': 'closeModal'
    'change #rate-plan-options': 'optionChange'

  initialize: (options = {}) ->
    @dealOptions = options.dealOptions
    @ratePlanHash = options.ratePlanHash
    @roomTypeHash = options.roomTypeHash
    @status = options.status
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    @alertMessage= Extranet.Helper.AlertView.getAlertView()
    @model =  new Extranet.Models.RatePlanModel
      uuid: options.ratePlanHash?.uuid
      hotelUuid: options.hotelUuid
      productSetUuid: options.productSetUuid

  render:() ->
    presenter = new Extranet.Presenters.PsRatePlanRowPresenter()
    modelHash = presenter.toHash(@ratePlanHash, @roomTypeHash, @dealOptions, @status)
    @$el.html(@autoTemplate.render @templateName, modelHash)
    @$(@selectors.ratePlanOptions).val(@ratePlanHash.optionUuid)
    this

  closeModal: (e) ->
    e?.preventDefault()
    @$(@selectors.closeModalButton).click()

  deleteRatePlan: (e) ->
    e.preventDefault()
    @closeModal()

    hotelUuid = @ratePlanHash?.hotelUuid
    ratePlanUuid = @ratePlanHash?.uuid
    roomTypeUuid = @ratePlanHash?.roomTypeUuid

    @model.destroy
      url: ExtranetUrlBuilder.getDeleteRatePlanUrl({hotelUuid, ratePlanUuid, roomTypeUuid})
      headers:
        'x-csrf-token': $('#csrf_token').val()
      wait: true
      patch: true
      success: (model, response, options)=>
        @trigger 'ratePlanDeleted', @ratePlanHash
        @remove()
        message = I18n.translate('js.modules.common.alerts.success.rateplan_deleted')
        requestId = options?.xhr?.getResponseHeader('x-request-id')
        @alertMessage.renderSuccess(message, requestId)
      error: (model, response, options)=>
        requestId = options?.xhr?.getResponseHeader('x-request-id')
        @alertMessage.renderDefaultError(requestId)

  optionChange: (e)->
    @optionChanged = true
    hash =
      view: this
      optionUuid: @$(e.currentTarget).val()
    @trigger 'optionChanged', hash

  save: (options = {})->
    return unless @optionChanged
    optionUuid = @$(@selectors.ratePlanOptions).val()
    hash =
      optionUuid: optionUuid
      inventoryProductId: @getInventoryProductId(optionUuid)
      _csrf: $('#csrf_token').val()


    ajaxCall = @model.save hash,
      wait: true
      error: (model, response, options)=>
        requestId = options?.xhr?.getResponseHeader('x-request-id')
        @alertMessage.renderDefaultError(requestId)

    ajaxCall

  getInventoryProductId: (optionUuid) ->
    _.findWhere(@dealOptions, {id: optionUuid}).inventoryProductId

  reSetOption: ()->
    @$(@selectors.ratePlanOptions).val("")

  getRatePlanUuid: () ->
    @model.get 'uuid'
