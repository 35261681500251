require '../presenters/contact'
require './contact_details'

class Extranet.Views.ContactRow extends Backbone.View

  events:
    'click .name': '_showContactDetailsModal'
    'click .set-main-contact-button.disabled': '_showContactDetailsModal'
    'click .set-main-contact-button:not(.disabled)': '_setAsMainContact'
    'click .delete-contact-button': '_showDeleteContactConfirmationModal'
  
  tagName: 'tr'
  className: 'contact-row'
  templateName: 'profile_contact_row'

  initialize: (options) ->
    @hotelModel = options.hotelModel
    @alertMessage = Extranet.Helper.AlertView.getAlertView()
    @contactDetailsEl = options.contactDetailsEl
    @deleteContactConfirmationModal = options.deleteContactConfirmationModal
    @presenter = new Extranet.Presenters.Contact()

  render: () ->
    @hash = @presenter.toHash @model
    @$el.html TemplateHelper.render @templateName, @hash
  
  _showContactDetailsModal: () ->
    contactDetailsView = new Extranet.Views.ContactDetails
      model: @model
      hotelModel: @hotelModel
    contactDetailsView.render()
    @contactDetailsEl.html contactDetailsView.el
  
  _setAsMainContact: () ->
    return unless Extranet.RolePermissions?.contact?.edit or Extranet.RolePermissions?.contact?.notifications?.edit
    @alertMessage.hide()
    constants = @presenter.getConstants()
    oldMainContact = @collection.find (model) -> model.get('notifications') is constants.MUST_NOTIFY
    saveAttributes = 
      _csrf: $('#csrf_token').val()
    oldMainContactAttributes = _.extend {notifications: constants.NOTIFY}, saveAttributes
    newMainContactAttributes = _.extend {notifications: constants.MUST_NOTIFY}, saveAttributes
    options = {wait: true}
    oldMainContactRequest = oldMainContact?.save oldMainContactAttributes, options
    newMainContactRequest = @model.save newMainContactAttributes, options
    onFailure = (oldMainContactResponse, newMainContactResponse) =>
      requestIds = []
      requestIds.push newMainContactResponse?[2]?.getResponseHeader 'x-request-id'
      requestIds.push oldMainContactResponse?[2]?.getResponseHeader 'x-request-id'
      @alertMessage.renderDefaultError requestIds.join(', ')
    onSuccess = (oldMainContactResponse, newMainContactResponse) =>
      requestIds = []
      requestIds.push newMainContactResponse?[2]?.getResponseHeader 'x-request-id'
      requestIds.push oldMainContactResponse?[2]?.getResponseHeader 'x-request-id'
      message = I18n.translate 'js.modules.common.alerts.success.default'
      @alertMessage.renderSuccess message, requestIds.join(', ')
    $.when(oldMainContactRequest, newMainContactRequest).fail(onFailure).done(onSuccess)

  _deleteContact: () ->
    return unless Extranet.RolePermissions?.contact?.edit
    if @hash.isMainContact
      message = I18n.translate 'js.modules.common.alerts.validation.can_not_delete_main_contact'
      @alertMessage.renderError message
      return
    @alertMessage.hide()
    @_hideDeleteContactConfirmationModal()
    # deleting contact doesn't remove it from the database, but simply breaks the association with hotel
    contacts = _.map @collection.models, (model) -> model.id
    contacts = _.reject contacts, (contact) => contact is @model.id
    saveAttributes =
      _csrf: $('#csrf_token').val()
      contacts: contacts
    options = 
      patch: true
      wait: true
    onSuccess = (data, textStatus, jqXHR) =>
      message = I18n.translate 'js.modules.common.alerts.success.contact_deleted'
      requestId = jqXHR?.getResponseHeader 'x-request-id'
      @alertMessage.renderSuccess message, requestId
    onFailure = (jqXHR, textStatus, errorThrown) =>
      requestId = jqXHR?.getResponseHeader 'x-request-id'
      @alertMessage.renderDefaultError requestId
    @hotelModel.save(saveAttributes, options).then(onSuccess, onFailure)
  
  _showDeleteContactConfirmationModal: () ->
    $(@deleteContactConfirmationModal).find('.btn.cancel').on 'click', @_hideDeleteContactConfirmationModal.bind(this)
    $(@deleteContactConfirmationModal).find('.btn.approve').on 'click', @_deleteContact.bind(this)
    $(@deleteContactConfirmationModal).modal 'show'
  
  _hideDeleteContactConfirmationModal: () -> 
    $(@deleteContactConfirmationModal).find('.btn.cancel').off 'click'
    $(@deleteContactConfirmationModal).find('.btn.approve').off 'click'
    $(@deleteContactConfirmationModal).modal 'hide'
