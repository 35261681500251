window.Extranet.Views.WeekInventory ?= {}

class Extranet.Views.WeekInventory.AvailableRoomsView extends Backbone.View

  tagName: 'tr'
  className: 'room-row'
  templateName: 'inventory_templates_weekview_available_rooms'
  selectors:
    overlayContainer: '.overlay-container'
    soldOutText: (date)->
      ".soldout-text[data-date=#{date}]"
    dayCell: (date)->
      ".day-cell input[data-date=#{date}]"
  events:
    'change :text': 'fireAvailabilityChange'

  initialize: (data={})->
    {datecells} = data
    @roomInventoryCells = @getRoomInventory datecells
    @setProductSet datecells
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate

  setProductSet:(datecells)->
    dateCell = _.find datecells, (dateCell)->
      dateCell.productSet?.uuid isnt undefined
    @productSet = dateCell?.productSet

  getRoomInventory: (datecells)->
    roomInventory = {}

    for dateCell in datecells
      unless roomInventory[dateCell.date]
        roomInventory[dateCell.date] = dateCell
        roomInventory[dateCell.date].isPastDate = DateTimeHelper.isPastDate(dateCell.date, dateCell.hotel?.timeZone?.identifier)

    roomInventoryCells = _.values roomInventory
    roomInventoryCells

  render: () ->
    # When render is called two or more times, it removes old elements
    # and adds new elements. Events bind to old element gets
    # removed and new events will not bind again. So calling delegateEvents
    # explicitly to make sure events get binded every time render is
    # called.
    @dataChanged = false
    @delegateEvents()
    this.$el.html @autoTemplate.render @templateName,
      roomInventoryCells: @roomInventoryCells
      accessPermissionSubKey: @productSet?.status or 'lastMinute'
    this

  getRoomTypeUuid: ->
    if @roomInventoryCells?.length > 0
      @roomInventoryCells[0]?.roomType?.uuid

  getHotelUuid: ->
    if @roomInventoryCells?.length > 0
      @roomInventoryCells[0]?.hotel?.uuid

  save:(requestIdPrefix)->
    unless @dataChanged
      return
    inventory = @getInventoryToSave()

    apiClient = new Extranet.Inventory.Utilities.InventoryApiClient()
    hotelUuid = @getHotelUuid()
    roomTypeUuid = @getRoomTypeUuid()

    # If product set is live or approved, add productSet uuid to enable validation
    url = ExtranetUrlBuilder.getUploadInventoryUrl
      hotelUuid: hotelUuid
      roomTypeUuid: roomTypeUuid
      qs: {productSetUuid: @productSet?.uuid} if @productSet?.status in ['live', 'approved']

    csrfToken = $('#csrf_token').val()

    saveHash =
      inventory: inventory
      _csrf: csrfToken

    headers = {'request-id-prefix': requestIdPrefix}

    callback = ()->
      # noop

    apiClient.save(url, saveHash, headers, callback)

  getInventoryToSave: () ->
    inventory= []
    this.$(':text:not(:disabled)').each (input)->
      val = $(this).val()
      unless _.isEmpty( val?.toString() )
        hash = {}
        hash.date = $(this).data 'date'
        hash.inventoryCount = parseInt val
        hash.stopSell = false
        inventory.push hash

    inventory

  getInventoryCount: (date)->
    val = this.$("[type=text][data-date=#{date}]").val()
    unless _.isEmpty val
      return parseInt val

    # when rate is cloed, UI will not have inventory count.
    # So fetching this value from inventory data.
    dayInventory = _.find @inventory, (dayData)->
      dayData.date is date
    if dayInventory.closedOut then dayInventory.inventoryCount else val

  fireAvailabilityChange: (e)->
    e.preventDefault()
    @dataChanged = true
