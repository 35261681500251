require '../../presenters/rateplan_summary_presenter'

RATES_RESTRICTIONS_FIELDS = ['baseRate','blackout', 'bookingLimit', 'grouponRate', 'LAR','marginPercentage','minAllotment','minPercent','stayOnly']
INVENTORY_FIELDS = ['ratePlanInventory']

class Extranet.Views.RatePlanSummaryView extends Backbone.View

  templateName: 'inventory_templates_monthview_rateplan_summary'

  settingsEventMgrKey: Extranet.Helpers.EventManagerHelper.SETTINGS_KEY

  selectors:
    baseRate: 'input.base-rate'
    LAR: 'input.lar'
    grouponRateAndLarContainer: '.lar-groupon-rate-container'
    grouponRate: 'input.groupon-rate'
    netRate: 'input.net-rate'  # only visible to hotelier
    ratePlanInventory: 'input.rate-plan-inventory'
    grouponRateEl : '.groupon-rate-field'
    netRateEl: '.net-rate-field'
    marginPercentage: 'input.margin-percentage'
    minAllotment: 'input.min-allotment'
    minPercent: 'input.min-percent'
    bookingLimit: 'input.booking-limit'
    ratePlanRow: '.rate-plan-row'
    blackoutStatus: '.blackout-status'
    blackoutText: '.blackout-text'
    ratesSection: '.rates-section'
    restrictionsLink: '.restrictions-link'
    restrictionsSection: '.restrictions-section'
    noCheckInStatus: '.no-check-in-status'
    noCheckInText: '.no-check-in-text'
    soldCountText: '.sold-count-text'
    discountPercentageContainer: '.discount-percentage-container'
    discountPercentage: '.discount-percentage-container .discount-percentage'
    grouponRateUpdatePreventionModal: '#groupon-rate-update-prevention-modal'

  permissionsKeyMapping:
    marginPercentage: 'margin_percentage'
    minAllotment: 'min_allotment'
    minPercent: 'min_percent'
    bookingLimit: 'booking_limit'
    baseRate: 'base_rate'
    LAR: 'LAR'
    grouponRate: 'groupon_rate'
    stayOnly: 'stay_only'
    ratePlanInventory: 'rate_plan_inventory'

  events:
    'keyup input[type=text]': 'updateChangedFlag'
    'change input[type=text]': 'changetoFixedValue'
    'change input.groupon-rate': 'updateNetRate'
    'keyup input.lar,input.groupon-rate,input.min-percent': 'updateDiscountPercentage'
    'change input.net-rate': 'updateGrouponRate'
    'click .blackout-status': 'toggleBlackoutStatus'
    'click .no-check-in-status': 'toggleNoCheckInStatus'
    'click .restrictions-link': 'toggleRestrictions'
    'focus input.groupon-rate[readonly]': '_showGrouponRateUpdatePreventionModal'
    'click #groupon-rate-update-prevention-modal .btn.approve': '_rollbackToReady'
    'click #groupon-rate-update-prevention-modal .btn.cancel': '_hideGrouponRateUpdatePreventionModal'

  initialize: (options) ->
    @settingsHelper = Extranet.Settings
    @ratePlanData = options.ratePlanData
    @productSetModel = options.productSetModel
    @isReadonly = options.isReadonly
    @marginPercentage = @ratePlanData[0]?.marginPercentage or 0
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    @alertMessage = Extranet.Helper.AlertView.getAlertView()
    @utility = new Extranet.Common.Utilities.Utility
    @ratesUtility = Extranet.Inventory.RatesUtility
    @permissions = new Extranet.Common.Permissions
    @presenter = new Extranet.Presenters.RatePlanSummaryPresenter
    @settingsEventManagerView = Extranet.Helpers.EventManagerHelper.getEventManagerView(@settingsEventMgrKey)
    @statusHelper = Extranet.Helpers.ProductSetStatusHelper
    @listenTo @settingsEventManagerView, 'grouponRate', @showGrouponRate
    # only hotelier can view net rate
    @listenTo @settingsEventManagerView, 'netRate', @showNetRate

  render: () ->
    @resetChangedFlags()
    @hash = @presenter.toHash(@ratePlanData)
    @hash.allowRollback = @productSetModel?
    @hash.accessPermissionSubKey = @getAccessPermissionSubKey()
    this.$el.html @autoTemplate.render @templateName, @hash
    rateTypeSelected = @settingsHelper.getRateType()
    @_showRestrictions(0) if @$(@selectors.bookingLimit).parent().hasClass('error')
    # rateTypeSelected will default to grouponRate and only hotelier can change it
    if rateTypeSelected is 'grouponRate' then @showGrouponRate() else @showNetRate()

    soldCountText = I18n.translate 'js.modules.inventory.sold_count'
    @$(@selectors.soldCountText).text(soldCountText)
    this

  getAccessPermissionSubKey: ()->
    if @isReadonly
      accessPermissionSubKey = 'readonly'
    else
      accessPermissionSubKey = @getProductSetStatus() or 'created'

    accessPermissionSubKey

  getProductSetUuid: ->
    @productSetModel?.get('uuid') or @ratePlanData?[0]?.productSet?.uuid

  getProductSetStatus: ->
    @productSetModel?.get('status') or @ratePlanData?[0]?.productSet?.status

  getLivePricingSetting: ->
    @productSetModel?.get('livePricingRule') or @ratePlanData?[0]?.productSet?.livePricingRule

  addValidationFailures: ->
    errorsHash = @presenter.getErrorsHash @ratePlanData
    @$(@selectors.ratePlanInventory).parent().addClass('error') if errorsHash.inventory
    @$(@selectors.baseRate).parent().addClass('error') if errorsHash.baseRate
    @$(@selectors.LAR).parent().addClass('error') if errorsHash.lar
    @$(@selectors.grouponRate).parent().addClass('error') if errorsHash.grouponRate
    @$(@selectors.marginPercentage).parent().addClass('error') if errorsHash.marginPercentage
    @$(@selectors.minAllotment).parent().addClass('error') if errorsHash.minAllotment
    @$(@selectors.minPercent).parent().addClass('error') if errorsHash.minPercent
    if errorsHash.bookingLimit
      @$(@selectors.bookingLimit).parent().addClass('error')
      @_showRestrictions(0)

  resetChangedFlags: ->
    @changedFlags =
      baseRate: false
      blackout: false
      bookingLimit: false
      grouponRate: false
      LAR: false
      marginPercentage: false
      minAllotment: false
      minPercent: false
      ratePlanInventory: false
      stayOnly: false

  getAccessPermissions: ->
    viewPermissions = Extranet.RolePermissions.inventory.templates_monthview_rateplan_summary
    accessPermissionSubKey = @getAccessPermissionSubKey()
    accessPermissions = viewPermissions?[accessPermissionSubKey]
    accessPermissions

  canEdit: (fieldName) ->
    permissionsHash = @getAccessPermissions()
    permissionsKey = @permissionsKeyMapping[fieldName] or fieldName
    @permissions.canEdit(permissionsHash, permissionsKey)

  showNetRate: ->
  # this is only available to hoteliers!
    @$(@selectors.grouponRateAndLarContainer).removeClass 'highlight'
    @$(@selectors.discountPercentageContainer).addClass 'hide'
    @$(@selectors.grouponRateEl).hide()
    @$(@selectors.netRateEl).show()

  showGrouponRate: ->
    @$(@selectors.grouponRateAndLarContainer).addClass 'highlight'
    @_preventGrouponRateUpdateForApprovedAndLivePricingEnabledDeal()
    @showApprovedDiscountPercentageForApprovedDeal()
    @updateDiscountPercentage()
    @$(@selectors.grouponRateEl).show()
    @$(@selectors.netRateEl).hide()

  updateGrouponRate: ->
    netRate = parseFloat(@$(@selectors.netRate).val())
    grouponRateVal = @ratesUtility.computeGrouponRate netRate, @marginPercentage
    @$(@selectors.grouponRate).val(grouponRateVal)

  updateNetRate: ->
    grouponRate = parseFloat(@$(@selectors.grouponRate).val())
    netRateVal = @ratesUtility.computeNetRate grouponRate, @marginPercentage
    @$(@selectors.netRate).val(netRateVal)

  showApprovedDiscountPercentageForApprovedDeal: ->
    shouldDisplayApprovedDiscountPercentage = @getLivePricingSetting() in ["dailyDiscount"] and
      @getProductSetStatus() in ['approved','live','finished'] and
      @hash.approvedDiscountPercentage?
    if shouldDisplayApprovedDiscountPercentage
      @$(@selectors.discountPercentageContainer).removeClass 'hide'
    else
      return

  # update and render the discount percentage of current lar and groupon rate
  updateDiscountPercentage: ->
    return if @getProductSetStatus() in ['approved','live','finished']

    discountPercentage = @getDiscountPercentage()
    minDiscountPercentage = @getValue(@selectors.minPercent) or 0

    @$(@selectors.discountPercentageContainer).removeClass('hide valid invalid')

    if discountPercentage?
      if discountPercentage.hasMultipleValues
        discountPercentageText = I18n.translate('js.modules.inventory.multi_discount_percentage', discountPercentage)
      else
        discountPercentageText = I18n.translate('js.modules.inventory.single_discount_percentage', discountPercentage)

      @$(@selectors.discountPercentage).text(discountPercentageText)
      if discountPercentage.min < minDiscountPercentage
        @$(@selectors.discountPercentageContainer).addClass 'invalid'
      else
        @$(@selectors.discountPercentageContainer).addClass 'valid'
    else
      @$(@selectors.discountPercentageContainer).addClass 'hide'

  # compute the discount percentage of the current lar and groupon sell rate
  getDiscountPercentage: ->
    grouponRate = @getValue(@$(@selectors.grouponRate))
    larRate = @getValue(@$(@selectors.LAR))

    switch
      when @isChanged('LAR') and @isChanged('grouponRate')
        @presenter.getDiscountPercentage([larRate],[grouponRate])
      when @isChanged 'LAR'
        # use same lar rates to compute the range of discount percentages if only lar is changed
        larRateList = _.map @hash.larRateList, () -> larRate
        @presenter.getDiscountPercentage(larRateList, @hash.grouponRateList)
      when @isChanged 'grouponRate'
        # use same groupon rates to compute the range of discount percentages if only groupon rate is changed
        grouponRateList = _.map @hash.grouponRateList, () -> grouponRate
        @presenter.getDiscountPercentage(@hash.larRateList, grouponRateList)
      else
        @hash.discountPercentage

  toggleBlackoutStatus: (e) ->
    return unless @canEdit('blackout')
    @changedFlags['blackout'] = true

    blackoutText = I18n.translate('js.modules.inventory.blackout')
    @$(@selectors.blackoutText).text(blackoutText)

    if @$(@selectors.blackoutStatus).hasClass('blackout') or @$(@selectors.blackoutStatus).hasClass('partial')
      @$(@selectors.blackoutStatus).removeClass('partial blackout')
      @$(@selectors.ratesSection).slideDown(Extranet.Constants.BLACKOUT_TOGGLE_DURATION)
    else
      @$(@selectors.blackoutStatus).removeClass('partial none')
      @$(@selectors.blackoutStatus).addClass('blackout')
      @$(@selectors.ratesSection).slideUp(Extranet.Constants.BLACKOUT_TOGGLE_DURATION)

  toggleNoCheckInStatus: ()->
    return unless @canEdit('stayOnly')
    @changedFlags['stayOnly'] = true
    noCheckInText = I18n.translate('js.modules.inventory.no_check_in')
    @$(@selectors.noCheckInText).text(noCheckInText)
    if @$(@selectors.noCheckInStatus).hasClass('no-check-in') or @$(@selectors.noCheckInStatus).hasClass('partial')
      @$(@selectors.noCheckInStatus).removeClass('partial no-check-in')
    else
      @$(@selectors.noCheckInStatus).removeClass('partial none')
      @$(@selectors.noCheckInStatus).addClass('no-check-in')

  toggleRestrictions: (e)->
    restrictionLink = $(e.currentTarget)
    if restrictionLink.hasClass('active') then @_hideRestrictions() else @_showRestrictions()

  updateChangedFlag: (e) ->
    element = e.currentTarget
    classList = element.classList
    if _.contains classList, 'base-rate'
      @changedFlags.baseRate = true
    if _.contains classList, 'booking-limit'
      @changedFlags.bookingLimit = true
    if _.contains classList, 'groupon-rate'
      @changedFlags.grouponRate = true
    if _.contains classList, 'lar'
      @changedFlags.LAR = true
    if _.contains classList, 'margin-percentage'
      @changedFlags.marginPercentage = true
    if _.contains classList, 'min-allotment'
      @changedFlags.minAllotment = true
    if _.contains classList, 'min-percent'
      @changedFlags.minPercent = true
    if _.contains classList, 'rate-plan-inventory'
      @changedFlags.ratePlanInventory = true

  changetoFixedValue: (e) ->
    @updateChangedFlag(e)
    element = e.currentTarget
    classList = element.classList

    if _.contains(classList, 'base-rate') \
    or _.contains(classList, 'lar') \
    or _.contains(classList, 'groupon-rate') \
    or _.contains(classList, 'net-rate')
      $(element).val @ratesUtility.fixedValue($(element).val())

  getUrlParameters: ->
    if @ratePlanData?.length > 0
      hotelUuid = @ratePlanData[0]?.hotel?.uuid
      roomTypeUuid = @ratePlanData[0]?.roomType?.uuid
      productSetUuid = @ratePlanData[0]?.productSet?.uuid
      ratePlanUuid = @ratePlanData[0]?.ratePlan?.uuid
      {hotelUuid, roomTypeUuid, ratePlanUuid}

  getRates: (hash) ->
    return [] if @$(@selectors.blackoutStatus).hasClass('blackout')

    baseRate =
      date: hash.date
      type: 'base'

    grouponRate =
      date: hash.date
      type: 'bookingDeal'

    if @shouldSave 'baseRate'
      baseRate.rate = @ratesUtility.getRateValue(@$el.find(@selectors.baseRate).val())

    if @shouldSave 'LAR'
      baseRate.ql2Price = @ratesUtility.getRateValue(@$el.find(@selectors.LAR).val())

    if @shouldSave 'grouponRate'
      grouponRate.rate = @ratesUtility.getRateValue(@$el.find(@selectors.grouponRate).val())

    if @shouldSave 'marginPercentage'
      grouponRate.marginPercentage = @getValue(@selectors.marginPercentage)

    rates = []
    rates.push(baseRate) if @shouldSave('baseRate') or @shouldSave('LAR')
    rates.push(grouponRate)  if @shouldSave('grouponRate') or @shouldSave('marginPercentage')
    rates

  getValue: (selector) ->
    value = @$el.find(selector).val()
    unless _.isEmpty(value)
      value = +value
    value

  getRestrictions: (hash) ->
    return [] unless @shouldSaveRestrictions()

    restrictions =
      date: hash.date
      minDiscountPercentage: @getValue(@selectors.minPercent) if @shouldSave 'minPercent'
      minDailyAllotment: @getValue(@selectors.minAllotment) if @shouldSave 'minAllotment'
      bookingLimit: @getValue(@selectors.bookingLimit) if @shouldSave 'bookingLimit'
      blackout: @getBlackoutToSave(hash.restrictions) if @shouldSave 'blackout'
      closedToArrival: @$(@selectors.noCheckInStatus).hasClass('no-check-in') if @shouldSave 'stayOnly'
      closedOut: false
      noLastNight: false
    restrictions

  shouldSaveRestrictions: ()->
    @shouldSave('minPercent') or @shouldSave('minAllotment') or @shouldSave('blackout') or @shouldSave('stayOnly') or @shouldSave('bookingLimit')

  isChanged: (fields...) ->
    _.any fields, (field) => @changedFlags[field]

  shouldSave: (fieldName) ->
    @canEdit(fieldName) && @isChanged(fieldName)

  # if value is changed then submitting with changed value,
  # otherwise, checking the source of blackout(i.e. ratePlan or dailyRate)
  # and submitting 'none' if source is 'ratePlan' otherwise existing value
  getBlackoutToSave: (restrictions) ->
    unless @canEdit('blackout')   # TODO add state information
      return

    blackoutValue = if @$(@selectors.blackoutStatus).hasClass('blackout') then 'black'
    else 'white'

    if @isChanged 'blackout'
      blackout = blackoutValue
    else
      blackoutSource = restrictions?.blackoutSource   # if initially no restrictions, none is saved
      blackout = if blackoutSource is 'dailyRate' then restrictions?.blackout else 'none'
    blackout

  getInventoryToSave: () ->
    _.map @ratePlanData, (hash) =>
      date: hash.date
      inventoryCount: @getValue(@selectors.ratePlanInventory) if @shouldSave 'ratePlanInventory'

  saveInventory: (requestIdPrefix) =>
    unless @isChanged(INVENTORY_FIELDS...)
      return

    saveHash =
      inventory: @getInventoryToSave()
      _csrf: $('#csrf_token').val()

    urlParams = @getUrlParameters()
    # If product set is live or approved, add productSet uuid to enable validation only for hotel users
    if @getProductSetStatus() in ['live', 'approved'] and not Extranet.RolePermissions.is_admin_user
      urlParams.queryParams = {productSetUuid: @getProductSetUuid()}

    url = ExtranetUrlBuilder.getUploadRatePlanInventoryUrl(urlParams)
    apiClient = new Extranet.Inventory.Utilities.InventoryApiClient()

    headers = {'request-id-prefix': requestIdPrefix}
    callback = ()->  # noop

    apiClient.save(url, saveHash, headers, callback)

  saveRates: (requestIdPrefix)=>
    unless @isChanged(RATES_RESTRICTIONS_FIELDS...)
      return

    saveHash =
      rates: []
      restrictions: []
      _csrf: $('#csrf_token').val()

    _.each @ratePlanData, (hash) =>
      rates = @getRates hash
      restrictions = @getRestrictions hash
      saveHash.rates = saveHash.rates.concat rates
      saveHash.restrictions = saveHash.restrictions.concat restrictions

    urlParams = @getUrlParameters()

    url = ExtranetUrlBuilder.getUploadRatesUrl(urlParams)
    apiClient = new Extranet.Inventory.Utilities.InventoryApiClient()

    headers = {'request-id-prefix': requestIdPrefix}
    callback = ()->
      # noop

    apiClient.save(url, saveHash, headers, callback)

  _hideRestrictions: (duration) ->
    duration ?= Extranet.Constants.RESTRICTIONS_TOGGLE_DURATION
    @$(@selectors.restrictionsSection).slideUp(duration)
    @$(@selectors.restrictionsLink).removeClass('active')

  _showRestrictions: (duration) ->
    duration ?= Extranet.Constants.RESTRICTIONS_TOGGLE_DURATION
    @$(@selectors.restrictionsSection).slideDown(duration)
    @$(@selectors.restrictionsLink).addClass('active')

  _preventGrouponRateUpdateForApprovedAndLivePricingEnabledDeal: () ->
    approvedAndLivePricingEnabled =
      @getLivePricingSetting() in ['dailyDiscount'] and @getProductSetStatus() in ['approved', 'live']
    @$(@selectors.grouponRate).prop 'readonly', approvedAndLivePricingEnabled

  _showGrouponRateUpdatePreventionModal: () ->
    @$(@selectors.grouponRateUpdatePreventionModal).modal 'show'

  _hideGrouponRateUpdatePreventionModal: () ->
    @$(@selectors.grouponRateUpdatePreventionModal).modal 'hide'

  _rollbackToReady: () ->
    options =
      _csrf: $("#csrf_token").val()
      newState: {name: 'ready'}
      hotelUuid: @productSetModel.get 'hotelUuid'
      productSetModel: @productSetModel
    onSuccess = (data, textStatus, jqXHR) =>
      newStatus = "<span class='product-set-status #{options.newState.name}'>#{options.newState.name}</span>"
      message = I18n.translate 'js.modules.common.alerts.success.status_changed', {newStatus}
      requestId = jqXHR?.getResponseHeader 'x-request-id'
      @alertMessage.renderSuccess message, requestId
    onFailure = (jqXHR, textStatus, errorThrown) =>
      requestId = jqXHR?.getResponseHeader 'x-request-id'
      @alertMessage.renderDefaultError requestId
    @statusHelper.changeStateTo(options).then onSuccess, onFailure
