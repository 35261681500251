require "../../presenters/ps_row_presenter"

class Extranet.Views.PSRowView extends Backbone.View

  templateName: 'admin_templates_promotions_row'

  tagName: 'tr'

  initialize: (options) ->
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    {@model} = options

  render: () ->
    @presenter = new Extranet.Presenters.PSRowPresenter
    hash = @presenter.toHash @model
    @$el.html @autoTemplate.render @templateName, hash
    this
