require './room_details'
require './room_info_row'

class Extranet.Views.RoomInfo extends Backbone.View

  selectors:
    roomsList: '#rooms-list'
    roomDetails: '.room-details'

  events:
    'click #add-room-button': '_showAddRoomModal'
  
  el: '#room-info'
  templateName: 'profile_room_info'

  initialize: (options) ->
    @alertMessage = Extranet.Helper.AlertView.getAlertView()
    @roomsCollection = new Extranet.Collections.Rooms {hotelUuid: @model.id}
    @listenTo @roomsCollection, 'add', @_renderRooms
    @listenTo @roomsCollection, 'sync', @_renderRooms

  render: () ->
    @$el.html TemplateHelper.render @templateName, {}
    @_fetchRooms()

  _fetchRooms: () ->
    @_showLoading()
    options =
      reset: true
      global: false
      data:
        include_rate_plans: true
    onFailure = (jqXHR, textStatus, errorThrown) =>
      requestId = jqXHR?.getResponseHeader 'x-request-id'
      @alertMessage.renderDefaultError requestId
    onFinish = () =>
      @_hideLoading()
    @roomsCollection.fetch(options).fail(onFailure).always(onFinish)
  
  _renderRooms: () ->
    @_clearRoomsList()
    @roomsCollection.each (model) =>
      roomInfoRowView = new Extranet.Views.RoomInfoRow 
        model: model
        roomDetailsEl: @$(@selectors.roomDetails)
      roomInfoRowView.render()
      @$(@selectors.roomsList).append roomInfoRowView.el

  _showAddRoomModal: (e) ->
    return unless Extranet.RolePermissions?.roomType?.new
    roomDetailsView = new Extranet.Views.RoomDetails
      collection: @roomsCollection
      model: new Extranet.Models.Room {hotelUuid: @roomsCollection?.hotelUuid}
    roomDetailsView.render()
    @$(@selectors.roomDetails).html roomDetailsView.el

  _clearRoomsList: () ->
    @$(@selectors.roomsList).empty()

  _showLoading: () ->
    @$el.addClass 'loading'

  _hideLoading: () ->
    @$el.removeClass 'loading'