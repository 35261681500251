require '../presenters/bookings_filters'

class Extranet.Views.BookingsFilters extends Backbone.View

  el: '#bookings-filters'
  templateName: 'bookings_filters'

  selectors:
    alertMessage: '.alert-message'
    checkInBegin: '.check-in-begin'
    checkInEnd: '.check-in-end'
    firstName: '.first-name'
    lastName: '.last-name'
    roomsDropdown: '.rooms'
    bookingId: '.booking-id'
    bookingsReceivedFrom: '.bookings-received-from'
    bookingsReceivedTo: '.bookings-received-to'

  events:
    'click #search-bookings': '_startSearch'
    'click #reset-bookings-filters': '_resetBookingsFilters'

  initialize: ()->
    @alertMessage = new Extranet.Views.AlertMessage {selector: @selectors.alertMessage}
    @presenter = new Extranet.Presenters.BookingsFilters()
    @roomscollection = new Extranet.Collections.Rooms
      hotelUuid: Extranet.ClientConfig?.hotelData?.uuid

  render: () ->
    hash = { datePickerPlaceholder: DateTimeHelper.getDateFormat 'numeric' }
    @$el.html TemplateHelper.render(@templateName, hash)
    @_initializeDatePicker()
    @_loadRooms()

  updateSearchParams: (searchParams={})->
    @searchParams = searchParams
    @_updateBookingsFiltersSelections()

  _updateBookingsFiltersSelections: ()->
    checkInBegin = DateTimeHelper.formatDate @searchParams.check_in_begin, 'numeric'
    checkInEnd = DateTimeHelper.formatDate @searchParams.check_in_end, 'numeric'
    bookingsReceivedFrom = DateTimeHelper.formatDate @searchParams.booking_date_begin, 'numeric'
    bookingsReceivedTo = DateTimeHelper.formatDate @searchParams.booking_date_end, 'numeric'
    @$(@selectors.checkInBegin).val(checkInBegin)
    @$(@selectors.checkInEnd).val(checkInEnd)
    @$(@selectors.firstName).val(@searchParams.first_name)
    @$(@selectors.lastName).val(@searchParams.last_name)
    @$(@selectors.bookingId).val(@searchParams.booking_code)
    @$(@selectors.bookingsReceivedFrom).val(bookingsReceivedFrom)
    @$(@selectors.bookingsReceivedTo).val(bookingsReceivedTo)
    @_updateRoomSelection()

  _updateRoomSelection: ()->
    if @searchParams.rate_plan
      @$(@selectors.roomsDropdown).val(@searchParams.rate_plan)
    else if @searchParams.room_type
      @$(@selectors.roomsDropdown).val(@searchParams.room_type)

  _initializeDatePicker: ()->
    @$(@selectors.checkInBegin).datepicker
      onSelect: (minDate) => @$(@selectors.checkInEnd).datepicker('option', {minDate})

    @$(@selectors.checkInEnd).datepicker
      onSelect: (maxDate) => @$(@selectors.checkInBegin).datepicker('option', {maxDate})

    @$(@selectors.bookingsReceivedFrom).datepicker
      onSelect: (minDate) => @$(@selectors.bookingsReceivedTo).datepicker('option', {minDate})

    @$(@selectors.bookingsReceivedTo).datepicker
      onSelect: (maxDate) => @$(@selectors.bookingsReceivedFrom).datepicker('option', {maxDate})

  _startSearch: ()->
    searchParams = @_getSearchParams()
    if _.isEmpty(searchParams)
      @alertMessage.renderError(I18n.translate("js.modules.common.alerts.validation.please_select_one_filter_below"))
    else
      queryString = $.param(searchParams)
      # Changing the query params in the URL will trigger the bookings search
      window.location.hash = "?#{queryString}"

  _resetBookingsFilters: ()->
    @$('input, select').val('').change()
    # Defaulting to today
    today = DateTimeHelper.formatDate DateTimeHelper.getCurrentTime(), 'numeric'
    @$(@selectors.checkInBegin).val(today)
    @$(@selectors.checkInEnd).val(today)
    @_startSearch()

  _getSearchParams: ()->
    searchParams =
      check_in_begin: DateTimeHelper.formatDate @$(@selectors.checkInBegin).val(), 'api'
      check_in_end: DateTimeHelper.formatDate @$(@selectors.checkInEnd).val(), 'api'
      first_name: @$(@selectors.firstName).val()
      last_name: @$(@selectors.lastName).val()
      booking_code: @$(@selectors.bookingId).val()
      booking_date_begin: DateTimeHelper.formatDate @$(@selectors.bookingsReceivedFrom).val(), 'api'
      booking_date_end: DateTimeHelper.formatDate @$(@selectors.bookingsReceivedTo).val(), 'api'

    selectedRoomOption = @$(@selectors.roomsDropdown).find('option:selected')

    if selectedRoomOption.attr('type') is 'RatePlan'
      searchParams.rate_plan = selectedRoomOption.val()
    else if selectedRoomOption.attr('type') is 'RoomType'
      searchParams.room_type = selectedRoomOption.val()

    _.omit searchParams, (value) -> _.isEmpty(value)

  _loadRooms: ()->
    roomsDropdown = @$(@selectors.roomsDropdown)
    roomsDropdown.empty().addClass 'loading'

    options =
      reset: true
      global: false
      data:
        include_rate_plans: true
    @roomscollection.fetch(options)
    .done(()=>
      @_renderRooms()
    ).always(()=>
      roomsDropdown.removeClass 'loading'
    )

  _renderRooms: ()->
    rooms = @presenter.formatRooms(@roomscollection)
    roomsDropdown = @$(@selectors.roomsDropdown)
    roomsDropdown.html TemplateHelper.render('bookings_rooms_dropdown', {rooms})
    @_updateRoomSelection()
    roomsDropdown.select2({dropdownAutoWidth : true})
