require "./product_set_info_tab_view"
require "./rates/productset_rates_view"
require "./product_owners_update_view"
require "./product_set_status_rollback_view"
require "./product_set_status_buttons_view"
require "../../../inventory/frontend/views/month_view/ql2_upload_view"
require "../../../inventory/frontend/views/inventory_download"

class Extranet.Views.PSManagerView extends Backbone.View

  Extranet.Common.Utilities.Mixin.extends this, Extranet.Helpers.ChildManager
  RATES = 'rates'
  urlBuilder = ExtranetUrlBuilder
  eventManagerKey: Extranet.Helpers.EventManagerHelper.PS_MONTH_VIEW_KEY
  productsEventManagerKey: Extranet.Helpers.EventManagerHelper.PRODUCTS_KEY
  templateName: 'products_templates_product_set_manager'

  selectors:
    bdTabs: '#bd-tabs'
    infoTab: 'ul.bd-tabs #info'
    ratesTab: 'ul.bd-tabs #rates'
    ratesSection: '#ps-rates-section'
    dealTitle: '.deal-title'
    editableDealTitle: '.editable-deal-name'
    dealTitleName: '.deal-title .deal-title-name'
    breadCrumbDealName: '.bread-crumb #deal-name'
    ownersDropdown: '.owners-dropdown'
    inventoryDownload: '.inventory-download'
    productSetStatusRollbackContainer: '.product-set-status-rollback-container'
    productSetStatusButtonsContainer: '.product-set-status-buttons-container'
    ql2UploadContainer: '.ql2-upload-container'

  events:
    'click .deal-title .icon-pencil': 'editDealName'
    'focusout .editable-deal-name': 'changeDealTitle'
    'keyup .editable-deal-name': 'keyupListener'

  initialize: (options= {}) ->
    @hotelUuid = options.hotelUuid
    @productSetUuid = options.productSetUuid
    @placeHolder = options.placeHolder
    @ratePlanUuid = options.ratePlanUuid if options.ratePlanUuid
    @alertMessage = Extranet.Helper.AlertView.getAlertView()
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    Extranet.Helpers.EventManagerHelper.initialize(@eventManagerKey)
    @eventManagerView = Extranet.Helpers.EventManagerHelper.getEventManagerView(@eventManagerKey)
    Extranet.Helpers.EventManagerHelper.initialize(@productsEventManagerKey)
    @productsEventManager = Extranet.Helpers.EventManagerHelper.getEventManagerView(@productsEventManagerKey)
    @listenTo @productsEventManager, 'psDetailsRendered', @renderOwners
    @listenTo @eventManagerView, 'stateChangeSuccess', @displayRatesTab

  render: (options= {}) ->
    @view = options.view
    @alertMessage.hide()
    if @productSetModel
      @renderProductSet options
    else
      @fetchProductSet options

    this

  fetchProductSet: (options = {}) ->
    @productSetModel = new Extranet.Models.ProductSetModel({uuid : @productSetUuid, hotelUuid: @hotelUuid})
    @listenTo @productSetModel, 'sync', @updateBreadCrumb
    @productSetModel.fetch
      success: =>
        @renderProductSet(options)
      error: (model, response, options)=>
        requestId = options?.xhr?.getResponseHeader('x-request-id')
        @alertMessage.renderDefaultError(requestId)

  renderProductSet: (options = {}) ->
    hash=
      name: @productSetModel.get('name')
      status: @productSetModel.get('status')
      infoTabUrl: urlBuilder.getBdDetailsFragment({@productSetUuid})
      ratesTabUrl: urlBuilder.getProductSetRatesFragment({@productSetUuid})
      productsPageUrl: ExtranetUrlBuilder.getProductsPageUrl({@hotelUuid})
      accessPermissionSubKey: @productSetModel.get('status')

    options.productSetModel = @productSetModel
    @$el.html @autoTemplate.render @templateName, hash
    @placeHolder.html @$el
    @placeHolder.removeClass 'hide'
    @displayRatesTab()
    @renderProductSetStatusRollback()
    @renderProductSetStatusButtons()
    if @_shouldDisplayRecentAutoUpdates()
      @_renderRecentAutoUpdates options

    if @view is RATES and @shouldDisplayRatesTab()
      @renderRates(options)
      @renderQl2Upload()
      @renderInventoryDownload()
    else
      @renderBDInfo options

    @show()

  shouldDisplayRatesTab: ()->
    @productSetModel.get('status') isnt 'created'

  displayRatesTab: ()->
    if @shouldDisplayRatesTab()
      @$(@selectors.ratesTab).removeClass('hide')
    else
      @$(@selectors.ratesTab).addClass('hide')
      if @view is RATES
        window.location.hash = urlBuilder.getBdDetailsFragment({@productSetUuid})

  renderBDInfo: (options)->
    @$(@selectors.infoTab).addClass 'active'
    @psRatesTabView?.hide()
    @psInfoTabView ?= @addChild new Extranet.Views.PSInfoTabView _.extend options, {@eventManagerKey}
    @psInfoTabView.render options

  renderOwners: (productSetModel) ->
    @psOwnersView ?= @addChild new Extranet.Views.ProductOwnersUpdateView
      el: @selectors.ownersDropdown
      productSetModel: productSetModel
    @psOwnersView.render()

  renderInventoryDownload: ()->
    @inventoryDownloadView ?= @addChild new Extranet.Views.InventoryDownload
      el: @selectors.inventoryDownload
      productSetUuid: @productSetUuid
      hotelUuid: @hotelUuid
      travelWindow: @getTravelWindow()
    @inventoryDownloadView.render()

  renderQl2Upload: ()->
    @ql2UploadView ?= @addChild new Extranet.Views.Ql2UploadView
      el: @selectors.ql2UploadContainer
      productSetUuid: @productSetUuid
      hotelUuid: @hotelUuid
      eventManagerKey: @eventManagerKey
    @ql2UploadView.render()

  renderProductSetStatusRollback: ()->
    @productSetStatusRollbackView ?= @addChild new Extranet.Views.ProductSetStatusRollbackView
      el: @selectors.productSetStatusRollbackContainer
      productSetModel: @productSetModel
      hotelUuid: @hotelUuid
    @productSetStatusRollbackView.render()

  renderProductSetStatusButtons: ()->
    @productSetStatusButtonsView ?= @addChild new Extranet.Views.ProductSetStatusButtonsView
      el: @selectors.productSetStatusButtonsContainer
      productSetModel: @productSetModel
      hotelUuid: @hotelUuid
      isRatesTab: @view is RATES

    @productSetStatusButtonsView.render()

  # 17719 - duplicated often
  getTravelWindow: ()->
    ratePlans = @productSetModel?.get('ratePlans')
    return {} unless ratePlans?.length
    startDates = []
    endDates = []
    for ratePlan in ratePlans
      {startAt, endAt} = ratePlan.travelWindow
      startDates.push(startAt)
      endDates.push(endAt)

    startAt = _.reduce(startDates, (min, next) -> if min < next then min else next)
    endAt = _.reduce(endDates, (max, next) -> if max > next then max else next)

    {startAt, endAt}

  renderRates: (options)->
    @$(@selectors.ratesTab).addClass 'active'
    @psInfoTabView?.hide()
    @psRatesTabView ?= @addChild new Extranet.Views.ProductSetRatesView options
    @psRatesTabView.render options

  editDealName: (e) ->
    @$(@selectors.dealTitle).addClass('hide')
    @$(@selectors.editableDealTitle).removeClass('hide')
    @$(@selectors.editableDealTitle).focus()
    elemLen = @$(@selectors.editableDealTitle).val().length
    @$(@selectors.editableDealTitle).get(0).setSelectionRange(elemLen, elemLen)

  changeDealTitle: (e) ->
    if $(@selectors.editableDealTitle).parsley('validate')
      @$(@selectors.dealTitleName).text(@$(@selectors.editableDealTitle).val())
      @$(@selectors.dealTitle).removeClass('hide')
      @$(@selectors.editableDealTitle).addClass('hide')

  keyupListener: (e) ->
    code = if e.keyCode then e.keyCode else e.which
    if code is 13 # Enter key
      @changeDealTitle(e)
    if code is 27
      @revertDealTitleChange()

  revertDealTitleChange: ->
    dealTitle = @productSetModel.get('name')
    @$(@selectors.editableDealTitle).val(dealTitle)
    @$(@selectors.editableDealTitle).addClass('hide')
    @$(@selectors.dealTitle).removeClass('hide')

  updateBreadCrumb: ()->
    $(@selectors.breadCrumbDealName).text(' > ' + @productSetModel.get('name'))

  _shouldDisplayRecentAutoUpdates: ()->
    @productSetModel.get('status') not in ['created', 'finished']

  _renderRecentAutoUpdates: (options) ->
    return unless Extranet.ClientConfig?.recentAutoUpdatesEnabled
    unless @recentAutoUpdatesView
      @recentAutoUpdatesView = new Extranet.Views.RecentAutoUpdates options
    @recentAutoUpdatesView.render()
