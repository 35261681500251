class Extranet.Presenters.SfDealPresenter

  @toHash: (dealModel)->
    return {} if not dealModel
    hash =
      dealUuid: dealModel.id
      dealSeoName: dealModel.get 'permalink'
      opportunityId: dealModel.get 'salesforceId'
      restriction:
        promotionalWindow:
          startAt: dealModel.get 'launchAt'
          endAt: dealModel.get 'closeAt'

    hash
