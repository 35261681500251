class Extranet.Helpers.ProductSetStatusHelper
  # Live and Finished states can't be set explicitly. Hence not adding them here.

  @statusAbbreviations:
    "created": "C"
    "shop-rates": "SR"
    "ready": "R"
    "review-violations": "RV"
    "approved": "A"
    "live": "L"
    "finished": "F"

  @states:
    CREATED:
      name: 'created'
      validations: ['']  # no validation is needed for now

    SHOP_RATES:
      name: 'shop-rates'
      # validations to perform before coming to this state
      validations: ['state_transition']

    READY:
      name:'ready'
      validations: ['state_transition', 'product_set', 'ql2_rates']

    READY_WITH_EMPTY_LARS:
      name:'ready'
      validations: ['state_transition', 'product_set']

    REVIEW_VIOLATIONS:
      name: 'review-violations'
      validations: ['state_transition']

    APPROVED:
      name: 'approved'
      validations: ['state_transition', 'product_set', 'rates_restrictions_no_ql2', 'inventory']

    APPROVE_WITH_VIOLATIONS:
      name: 'approved'
      validations: ['state_transition']

    VIOLATIONS_ONLY:
      validations: ['product_set', 'rates_restrictions_no_ql2', 'inventory']

    # alias for ready and without validations
    REJECT:
      name: 'ready'
      validations: ['state_transition']

  @changeStateTo: (options)->
    {newState, hotelUuid, productSetModel, _csrf} = options

    if newState.validations
      queryString = "restriction_validation=" + newState.validations.join()

    statusChangeUrl =
      ExtranetUrlBuilder.getPsStatusChangeApiUrl
        hotelUuid: hotelUuid
        productSetUuid: productSetModel?.id
        qs: queryString

    hash =
      status: newState.name
      _csrf: _csrf

    eventManagerKey = Extranet.Helpers.EventManagerHelper.PS_MONTH_VIEW_KEY
    eventMgr =
      Extranet.Helpers.EventManagerHelper.getEventManagerView eventManagerKey

    jqxhr = $.ajax
      type: "PUT"
      url: statusChangeUrl
      dataType:"json"
      contentType: "application/json; charset=utf-8"
      data: JSON.stringify hash
      success: (data)->
        options = {status: data?.status}
        productSetModel.set('status', data?.status)
        eventMgr.triggerEvent 'stateChangeSuccess', options

      error: (jqxhr, status, errorThrown)=>
        if jqxhr.status isnt 403
          return

        # There are some validation failures.
        errorMsgs = jqxhr.responseJSON
        errors = errorMsgs?.errors or errorMsgs?.nestedErrors
        eventMgr.triggerEvent 'statusChangeFailure',
          errors: errors
          newState: newState

    return jqxhr
