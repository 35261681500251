###
$.parseParams - parse query string paramaters into an object.
Parses a given queryString. Works even if there is a '?'' before the querystring
Usage $.parseParams(queryString)
###
(($) ->
  re = /([^&=]+)=?([^&]*)/g
  decodeRE = /\+/g # Regex for replacing addition symbol with a space
  decode = (str) ->
    decodeURIComponent str.replace(decodeRE, " ")

  $.parseParams = (query) ->
    params = {}
    query = query.substr(1)  if query.substr(0, 1) is "?"  if query
    e = undefined
    while e = re.exec(query)
      k = decode(e[1])
      v = decode(e[2])
      if k.substring(k.length - 2) is "[]"
        k = k.substring(0, k.length - 2)
        (params[k] or (params[k] = [])).push v
      else
        params[k] = v
    params

  return
) jQuery
