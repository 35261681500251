class Extranet.Views.WeekNavigatorView extends Backbone.View

  templateName: 'inventory_templates_weekview_week_selector'
  events:
    'click #next-week': 'selectNextWeek'
    'click #previous-week': 'selectPreviousWeek'

  initialize: ()->
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate

  render:(dates=[])->
    @dates = dates
    formattedDates = []
    for date in @dates
      formattedDates.push @getDateHash date

    # render dates
    this.$el.html @autoTemplate.render @templateName,
      dates: formattedDates

    @delegateEvents()
    this

  # 17719 - this is for displaying the big week selector that will say something
  # like "SUN FEB 21" stacked on top of one another. Pull into helper to be
  # reused for month calendar
  getDateHash: (date) ->
    day: moment(date).format 'ddd'
    date: moment(date).format 'DD'
    month: moment(date).format 'MMM'

  selectNextWeek: (e)->
    e.preventDefault()
    currentLastDay = @dates[@dates.length-1]
    dates = @getNextWeek currentLastDay
    @trigger 'weekChange', dates

  selectPreviousWeek: (e)->
    e.preventDefault()
    currentFirstDay = @dates[0]
    dates = @getPreviousWeek currentFirstDay
    @trigger 'weekChange', dates

  # 17712 - needs timezone
  # 17719 - I believe this logic is duplicated elsewhere
  getNextWeek: (currentLastDay)->
    mdate = moment(currentLastDay)
    startDate = mdate.weekday(7).clone()  # Sunday of next week
    endDate = mdate.weekday(6).clone()  # Saturday of next week
    @expandDates {startDate, endDate}

  # 17712 - needs timezone
  # 17719 - I believe this logic is duplicated elsewhere
  getPreviousWeek: (currentFirstDay)->
    mdate = moment currentFirstDay
    startDate = mdate.weekday(-7).clone()  # Sunday of previous week
    endDate = mdate.weekday(6).clone()  # Saturday of previous week
    @expandDates {startDate, endDate}

  # It expands dates in between startDate and endDate inclusive
  # 17719 - this is the same as getDatesInRange in CommonDateHelper - eventually remove this one
  # copied this from inventory date helper - not sure if it needs to return moment dates, CommonDateHelper returns api formatted strings
  expandDates: (dates)->
    allDates = []
    currentDate = moment dates.startDate
    endDate = moment dates.endDate

    while endDate.diff(currentDate) >=0
      allDates.push currentDate.clone()
      currentDate = currentDate.add 1, 'days'

    allDates
