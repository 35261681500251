class Extranet.Views.InventoryDownload extends Backbone.View

  templateName: 'inventory_templates_inventory_download'

  selectors:
    closeButton: '.close-modal'
    alertMessage: "#from_date_greater"
    checkIn: '#check_in'
    checkOut: '#check_out'

  initialize: (options)->
    @hotelUuid = options.hotelUuid
    @productSetUuid = options.productSetUuid
    @travelWindow = @getTravelWindowForDisplay(options.travelWindow)
    @datePickerPlaceholder = DateTimeHelper.getDateFormat 'numeric'
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    @alertMessage = new Extranet.Views.AlertMessage {selector: @selectors.alertMessage}
    @datePickerAdjustment()

  events:
    'click #submit_download': 'processDownload'
    'click #cancel_download' : 'cancel'
    'click .close-modal' : 'emptyDates'

  render: () ->
    this.$el.html @autoTemplate.render @templateName, {@travelWindow, @datePickerPlaceholder}
    this.$(@selectors.checkIn).datepicker()
    this.$(@selectors.checkOut).datepicker()
    this

  getTravelWindowForDisplay: (travelWindow) ->
    startAt = DateTimeHelper.formatDate travelWindow?.startAt, 'numeric' if travelWindow?.startAt?
    endAt = DateTimeHelper.formatDate travelWindow?.endAt, 'numeric' if travelWindow?.endAt?
    return {startAt, endAt}

  cancel: (e) ->
    @hideErrorDiv()
    e.preventDefault()
    e.stopPropagation()
    @$(@selectors.closeButton).click()

  processDownload: (e) ->
    e.preventDefault()
    e.stopPropagation()
    form = $($(@el).find('form'))
    form.parsley Extranet.ParsleyConfig
    if form.parsley 'validate'
      formObj = form.serializeObject()
      beginDate = DateTimeHelper.formatDate formObj.check_in, 'api'
      endDate = DateTimeHelper.formatDate formObj.check_out, 'api'
      fields = 'expectedRates'

      if not DateTimeHelper.isBeforeDate beginDate, endDate
        message = I18n.translate('js.modules.common.alerts.validation.from_date_less_than_to_date')
        @alertMessage.renderError(message)
        return

      options = {check_in_begin:beginDate, check_in_end:endDate, hotelUuids: @hotelUuid, fields: fields}
      if @productSetUuid
        options.productType = "bookingDeal"
        options.productSetUuids = @productSetUuid
      else
        options.productType = "lastMinute"
        options.onlyLiveRatePlans = true

      @$el.find('#cancel_download').trigger('click')
      # TODO: [GETAWAYS-23273] Remove conditional and always call getRatesInventoryDownloadUrl
      if Extranet.FeatureFlags?.unified_rates_inventory_report
        window.open ExtranetUrlBuilder.getRatesInventoryDownloadUrl(options) 
      else
        window.open ExtranetUrlBuilder.getInventoryDownloadUrl(options)
      return

  hideErrorDiv: () ->
    @alertMessage.hide()

  datePickerAdjustment: () ->
    $("#check_in").datepicker
      onSelect: (selected) ->
        $("#check_out").datepicker "option", "minDate", selected
        return

    $("#check_out").datepicker
      onSelect: (selected) ->
        $("#check_in").datepicker "option", "maxDate", selected
        return
    return
