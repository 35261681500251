require "./products_search/ps_search_view"

class Extranet.Views.SearchManagerView extends Backbone.View

  Extranet.Common.Utilities.Mixin.extends this, Extranet.Helpers.ChildManager

  el: '#search_tab'
  templateName: 'admin_templates_search_page'
  selectors:
    psSearchSelector: '#ps_search'
    downloadSelector: '#search-download'

  events:
    'click #productset-download': 'downloadPromotions'

  initialize: (options) ->
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate

  render: () ->
    hotelSearchUrl = ExtranetUrlBuilder.getMyHotelsUrl
      role: 'admin'
    psSearchUrl = ExtranetUrlBuilder.getAdminPromotionsSearchUrl()
    @$el.html @autoTemplate.render @templateName,
      hotelSearchUrl: hotelSearchUrl
      psSearchUrl: psSearchUrl
    @psSearchView ?= new Extranet.Views.PSSearchView {el: @selectors.psSearchSelector}
    @addChild @psSearchView
    @psSearchView.render()
    @show()

  downloadPromotions:() ->
    @psSearchView.download()
