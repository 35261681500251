class Extranet.Helpers.LocationSelector

  constructor: (@countrySelector, @stateSelector, @citySelector, options) ->
    @hasStates = false

    @updateDropdown = Extranet.Common.Utilities.Render.updateDropdownOptions
    @countrySelector.on 'change', @_handleCountryChange
    @stateSelector.on 'change', @_handleStateChange

    {@includeEmptyOption, defaults, @forceCityFetch} = options
    @forceCityFetch ?= false
    @includeEmptyOption ?= true
    @render(defaults)

  render: (defaults={}) ->
    {@countryUuid, @stateUuid, @cityUuid} = defaults
    @_renderStateNotApplicableStatus()
    @_resetStateAndCityDropdown()
    @_renderCountryDropdown(true)

  _resetStateAndCityDropdown: () ->
    @_disableStateSelectorDropdown()
    @_disableCitySelectorDropdown()
    @_showStateSelectorDropdown()

  _handleCountryChange: (e) =>
    @stateSelector.val('')
    @citySelector.val('')
    @_renderStateDropdown()

  _handleStateChange: (e) =>
    @citySelector.val('')
    @_renderCityDropdown()

  _renderCountryDropdown: (useDefault=false) ->
    options =
      includeEmptyOption: @includeEmptyOption
      selectedOption: if useDefault then @countryUuid
      silent: true
      successCallback: (countries) => @_renderStateDropdown(useDefault)
    @updateDropdown 'countries', @countrySelector, {}, options

  _renderStateDropdown: (useDefault=false) ->
    countryUuid = @countrySelector.val()
    options =
      includeEmptyOption: @includeEmptyOption
      selectedOption: if useDefault then @stateUuid
      silent: true
      successCallback: (states) =>
        @_toggleHasStates states.length > 0
        @_renderCityDropdown(useDefault)
    if countryUuid
      @updateDropdown 'states', @stateSelector, {country_uuid: countryUuid}, options
    else
      @_resetStateAndCityDropdown()

  _renderCityDropdown: (useDefault=false) ->
    countryUuid = @countrySelector.val()
    stateUuid = @stateSelector.val()
    options =
      includeEmptyOption: @includeEmptyOption
      selectedOption: if useDefault then @cityUuid
      silent: true
    if @hasStates and stateUuid
      @updateDropdown 'cities', @citySelector, {state_uuid: stateUuid}, options
    else if (not @hasStates and countryUuid) or @forceCityFetch
      @updateDropdown 'cities', @citySelector, {country_uuid: countryUuid}, options
    else
      @_disableCitySelectorDropdown()

  _toggleHasStates: (hasStates) ->
    @hasStates = hasStates
    if hasStates then @_showStateSelectorDropdown() else @_hideStateSelectorDropdown()

  # Cosmetic Methods

  _renderStateNotApplicableStatus: () ->
    @stateSelector.siblings('.state-not-applicable').remove()
    @stateSelector.after('<div class="state-not-applicable">N/A</div>')

  _hideStateSelectorDropdown: () ->
    @stateSelector.addClass('hide')
    @stateSelector.next().removeClass('hide')

  _showStateSelectorDropdown: () ->
    @stateSelector.removeClass('hide')
    @stateSelector.next().addClass('hide')

  _enableCitySelectorDropdown: () ->
    @citySelector.attr('disabled', false)

  _disableCitySelectorDropdown: () ->
    @citySelector.attr('disabled', true)

  _enableStateSelectorDropdown: () ->
    @stateSelector.attr('disabled', false)

  _disableStateSelectorDropdown: () ->
    @stateSelector.attr('disabled', true)
