'use strict'

ls_ttl = require 'localstorage-ttl'
tracky = require '../../cookie_consent/helpers/m_trackEvent'

ttl_ms = 1000 * 60 * 60 * 24 * 30 * 6  # Six months

storageKey = 'xnet-cookies-gdpr-ack'
bodyClass = 'gdpr-cb-shown'

callConsentService = (action, origin = '') ->
  matchC3s = document.cookie.match(/c3s=\d+-\d+-([0-3])/)

  consentClass = if matchC3s then matchC3s[1] else 3
  url = "#{origin}/extranet/api/consent-service/#{action}/v1?c3s=#{consentClass}"
  fetch(url, { credentials: 'include' })
    .then (res) ->
      if not res.ok
        throw('failed so set consent due to api status: ' + res.status)
      return
    .catch (e) ->
      tracky e.event, e.error
      return

gdprCookieBanner = (origin = '')->
  banner = document.getElementById 'ls-gdpr-cookie-banner'
  return unless banner

  if not document.cookie.match(/c3s=\d+-\d+-[0-3]{1}/)
    #  If no c3s cookie exists, then call consent service to create it,
    #  delete the local storage key, and continue to show the banner.
    callConsentService("notice", origin)
    console.log("removing local storage")
    localStorage.removeItem(storageKey)
  else
    try
      if ls_ttl.get storageKey
      #  If the user has already consented to our use of cookies,
      #  then we do not need to present the banner.
        return

  closeBanner = ->
    banner.parentNode.removeChild(banner)
    document.body.classList.remove(bodyClass)
    callConsentService("notice_all", origin)

    ls_ttl.set(storageKey, true, ttl_ms)

  banner.classList.remove 'ls-hide'
  document.body.classList.add(bodyClass)
  acknowledgeLink = document.getElementById 'ls-accept-cookies'
  acknowledgeLink.addEventListener('click', closeBanner, { once: true })

module.exports = {
  gdprCookieBanner
}
