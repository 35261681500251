require "../../../../products/frontend/helpers/productset_status_helper"
require "../../presenters/product_set_validation_presenter"

class Extranet.Views.StatusChangeValidationMessagesView extends Backbone.View

  statusHelper = Extranet.Helpers.ProductSetStatusHelper

  templateName: 'inventory_templates_monthview_status_change_validation_messages'
  violationMessages: undefined

  events:
    'click #show-violation-details': 'showViolationDetails'
    'click #hide-violation-details': 'hideViolationDetails'
    'click button.approve': 'approveProductSet'
    'click button.reject': 'rejectProductSet'
    'click button.submit': 'submitProductSet'
    'click button.submit-with-empty-lar': 'submitWithEmptyLar'
    'click button.cancel-violation-submit': 'cancelSubmission'
    'click .modal button.cancel': 'closeConfirmationPopup'
    'click .single-violation-summary-header-hide': 'toggleViolationSingleSummary'
    'click .single-violation-summary-header-show': 'toggleViolationSingleSummary'

  initialize: (options)->
    @eventManager = options.eventManager
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    @hotelUuid = options.hotelUuid
    @productSetModel = options.productSetModel
    @presenter = Extranet.Presenters.ProductSetValidationPresenter
    @failedValidationPresenter = Extranet.Presenters.FailedValidationDatesPresenter
    @alertMessage = Extranet.Helper.AlertView.getAlertView()
    @listenTo @eventManager, 'fetchInventory', @updateHotelInfo
    @listenTo @eventManager, 'statusChangeFailure', @renderStatusChangeFailure

  updateHotelInfo: ({hotelInfo})->
    @hotelInfo = hotelInfo

  render: ()->
    actionButtonPermissions = @getActionButtonPermissions()
    @$el.html @autoTemplate.render @templateName, {@violationMessages, actionButtonPermissions}
    if actionButtonPermissions?.showActionButtons
      @eventManager.triggerEvent 'hideProductSetStatusChangeButtons'
    @show()
    this

  renderStatusChangeFailure: ({errors})->

    # Collecting all the failed validations from each error
    @failedValidations = []
    for error in errors
      if error?.failedValidations
        for failedValidation in error.failedValidations
          @failedValidations.push(failedValidation)

    @violationMessages = @presenter.getViolationMessages(@failedValidations, @hotelInfo)
    @render()
    @showViolationDetails() if @violationMessages
    @failedValidationPresenter.format({errors})

  getAccessPermissions: () ->
    accessPermissionSubKey = @productSetModel?.get('status') or 'lastMinute'
    viewPermissions = Extranet.RolePermissions.inventory?.templates_monthview_buttons
    accessPermissions = viewPermissions?[accessPermissionSubKey]
    accessPermissions

  getActionButtonPermissions:()->
    accessPermissions = @getAccessPermissions()
    return unless accessPermissions

    showApproveButton = accessPermissions.approve?.view
    showRejectButton = accessPermissions.reject?.view
    showSubmitButton = accessPermissions.submit?.view
    showSubmitWithEmptyLarButton = @_shouldShowSubmitWithEmptyLarButton()

    showActionButtons = showApproveButton or showRejectButton or showSubmitButton or showSubmitWithEmptyLarButton

    {showActionButtons, showApproveButton, showRejectButton, showSubmitButton, showSubmitWithEmptyLarButton}

  _shouldShowSubmitWithEmptyLarButton: ()->
    accessPermissions = @getAccessPermissions()

    # Button should not be displayed when the user does not have permission
    return false unless accessPermissions?.submit_with_empty_lar?.view

    # Button should not be displayed if any non QL2 rates validation error found
    for failedValidation in @failedValidations
      return false unless failedValidation.restrictionType is "ql2Rates"

    return true

  showViolationDetails: ()->
    if @violationMessages
      $("#show-violation-details").addClass('hide')
      $("#hide-violation-details").removeClass('hide')
      @$(".violation-details").slideDown(200)
    else
      @fetchViolationDetails()

  hideViolationDetails: ()->
    $("#show-violation-details").removeClass('hide')
    $("#hide-violation-details").addClass('hide')
    @$(".violation-details").slideUp(200)

  fetchViolationDetails: ()->
    queryString = "restriction_validation=" + statusHelper.states.VIOLATIONS_ONLY.validations.join(",")

    statusChangeUrl =
      ExtranetUrlBuilder.getPsStatusChangeApiUrl
        hotelUuid: @hotelUuid
        productSetUuid: @productSetModel.get('uuid')
        qs: queryString

    jqxhr = $.ajax
      type: "PUT"
      url: statusChangeUrl
      dataType:"json"
      contentType: "application/json; charset=utf-8"
      data: {}
      headers:
        'x-csrf-token': $('#csrf_token').val()
      success: (data)=>
        @violationMessages = @presenter.getViolationMessages(data.failedValidations, @hotelInfo)
        @render()
        @showViolationDetails() if @violationMessages

      error: (jqxhr, status, errorThrown)=>
        requestId = jqxhr?.getResponseHeader('x-request-id')
        @alertMessage.renderDefaultError(requestId)

  approveProductSet: ()->
    @closeConfirmationPopup()
    options =
      newState: statusHelper.states.APPROVE_WITH_VIOLATIONS
      hotelUuid: @hotelUuid
      productSetModel: @productSetModel
      _csrf: $("#csrf_token").val()

    ajaxCall = statusHelper.changeStateTo(options)
    ajaxCall.done( (data, textStatus, jqxhr)=>
      message = I18n.translate('js.modules.common.alerts.success.approved_with_violations')
      requestId = jqxhr?.getResponseHeader('x-request-id')
      @alertMessage.renderSuccess(message, requestId)
    ).fail( (jqxhr)=>
      requestId = jqxhr?.getResponseHeader('x-request-id')
      @alertMessage.renderDefaultError(requestId)
    )

  rejectProductSet: ()->
    @closeConfirmationPopup()
    options =
      newState: statusHelper.states.REJECT
      hotelUuid: @hotelUuid
      productSetModel: @productSetModel
      _csrf: $("#csrf_token").val()

    ajaxCall = statusHelper.changeStateTo(options)
    ajaxCall.done( (data, textStatus, jqxhr)=>
      newState = "<span class='#{options.newState.name} product-set-status'>#{options.newState.name}</span>"
      message = I18n.translate('js.modules.common.alerts.success.moved_to_rejected', {newState})
      requestId = jqxhr?.getResponseHeader('x-request-id')
      @alertMessage.renderAlert(message, requestId)
    ).fail( (jqxhr)=>
      requestId = jqxhr?.getResponseHeader('x-request-id')
      @alertMessage.renderDefaultError(requestId)
    )

  submitProductSet: ()->
    @closeConfirmationPopup()
    options =
      newState: statusHelper.states.REVIEW_VIOLATIONS
      hotelUuid: @hotelUuid
      productSetModel: @productSetModel
      _csrf: $("#csrf_token").val()

    ajaxCall = statusHelper.changeStateTo(options)
    ajaxCall.done( (data, textStatus, jqxhr)=>
      newState = "<span class='#{options.newState.name} product-set-status'>#{options.newState.name}</span>"
      message = I18n.translate('js.modules.common.alerts.success.submitted_with_violations', {newState})
      requestId = jqxhr?.getResponseHeader('x-request-id')
      @alertMessage.renderSuccess(message, requestId)
    ).fail( (jqxhr)=>
      requestId = jqxhr?.getResponseHeader('x-request-id')
      @alertMessage.renderDefaultError(requestId)
    )

  submitWithEmptyLar: ()->
    @closeConfirmationPopup()
    options =
      newState: statusHelper.states.READY_WITH_EMPTY_LARS
      hotelUuid: @hotelUuid
      productSetModel: @productSetModel
      _csrf: $("#csrf_token").val()

    ajaxCall = statusHelper.changeStateTo(options)
    ajaxCall.done( (data, textStatus, jqxhr)=>
      newState = "<span class='#{options.newState.name} product-set-status'>#{options.newState.name}</span>"
      message = I18n.translate('js.modules.common.alerts.success.submitted_with_empty_lars', {newState})
      requestId = jqxhr?.getResponseHeader('x-request-id')
      @alertMessage.renderSuccess(message, requestId)
    ).fail( (jqxhr)=>
      requestId = jqxhr?.getResponseHeader('x-request-id')
      @alertMessage.renderDefaultError(requestId)
    )

  cancelSubmission: ()->
    @hide()
    @eventManager.triggerEvent 'showProductSetStatusChangeButtons'

  closeConfirmationPopup: ()->
    @$el.find('.modal .close-modal').click()

  show: ()->
    @$el.removeClass('hide')

  hide: ()->
    @$el.addClass('hide')

  toggleViolationSingleSummary: (e) ->
    currentToggleLinkParent = $(e.currentTarget).parent('.single-violation-summary')
    currentToggleLinkParent.find(".single-violation-summary-header-show").toggleClass("hide")
    currentToggleLinkParent.find(".single-violation-summary-header-hide").toggleClass("hide")
    currentToggleLinkParent.find(".single-violation-summary-list").slideToggle()
