require "./optimize"
require "./namespace"
require "./templates"
require "./settings"
require "./highlight_input"
require "./trim_input"
require "../jquery_extensions/html_edit"
require "../jquery_extensions/parse_params"
require "../jquery_extensions/when_all"
require "../jquery_extensions/serialize_object"

$(document).ready ->
  $('body .search .icon-arrow-right-large').click (e) ->
    e.preventDefault()
    target = $(e.target)
    href = target.attr 'href'
    searchBox = target.parent().find 'input[type=search]'
    location.href = "#{href}?hotel_name=#{searchBox.val()}"

  $(document).tooltip
      position:
        my: "center bottom-10"
        at: "center top"
        using: ( position, feedback )->
          $( this ).css( position )
          $( "<div>" ).addClass( "arrow" ).appendTo( this )
          return

  $.datepicker.setDefaults dateFormat: 'yy/mm/dd'

  # Loading timezones data to moment
  if moment and Extranet.MomentData
    moment.tz.load(Extranet.MomentData)

$(document).ajaxStart ->
  $('.page-loading').show()

$(document).ajaxStop ->
  $('.page-loading').hide()

$.fn.modal = (action)->
  switch action
    when 'show'
      $(this).addClass('show-modal')
      $('body').addClass('modal-open')
    when 'hide'
      $(this).removeClass('show-modal')
      $('body').removeClass('modal-open')
    when 'toggle'
      $(this).toggleClass('show-modal')
      $('body').toggleClass('modal-open')
