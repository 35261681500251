class Extranet.Presenters.Payments
  toHash: (collection) ->
    payments = collection.toJSON()

    payments = _.map payments, (payment) =>
      timeZoneIdentifier = payment.timeZoneIdentifier or 'UTC'
      dateTimeFormat = 'string'

      hash =
        checkDate: DateTimeHelper.formatDate payment.checkDate, dateTimeFormat, timeZoneIdentifier
        checkNumber: payment.checkNumber
        amountFormatted: payment.moneyAmount.amountFormatted
        statementUuid: payment.statementUuid

    {payments}
