require "./roomtype_summary_view"

class Extranet.Views.SummaryCollectionView extends Backbone.View

  Extranet.Common.Utilities.Mixin.extends this, Extranet.Helpers.ChildManager

  selectors:
    roomTypes: '.room-types'
    roomInfo: '.room-info'

  templateName: 'inventory_templates_monthview_summary_collection'

  events:
    'click #save-rates': 'saveRatesAndInventory'
    'click #reset-rates': 'reset'

  initialize: (options = {}) ->
    @options = options
    @hotelUuid = options.hotelUuid
    @productSetUuid = options.productSetUuid
    @productSetModel = options.productSetModel
    @eventManagerKey = options.eventManagerKey
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    @alertMessage = Extranet.Helper.AlertView.getAlertView()
    @inventoryUtility = Extranet.Inventory.RatesUtility
    @eventManagerView = Extranet.Helpers.EventManagerHelper.getEventManagerView(@eventManagerKey)
    @inventoryData = options?.inventoryData
    @listenTo @eventManagerView, 'highlightFailureDates', @addValidationFailures
    @errorPresenter = new Extranet.Presenters.Error()

  render: () ->
    datesList = _.collect @inventoryData, (hash) ->
      hash.date
    dateRange = @getSelectedDateRange datesList
    this.$el.html @autoTemplate.render @templateName,
      dateRange: dateRange
      available: Extranet.Helper.Inventory.getTotalInventoryCount(@inventoryData)
      accessPermissionSubKey: @productSetModel?.get('status') or 'lastMinute'
      noDateSelected: @options?.noDateSelected
      dateOutsideTravelWindow: @options?.dateOutsideTravelWindow
      someDatesSkipped: @options?.someDatesSkipped
      onlyPastDatesSelected: @options?.onlyPastDatesSelected
      multipleInventoryManagementModesFound: @options?.multipleInventoryManagementModesFound
      isReadonlyUser: Extranet.RolePermissions?.is_readonly_user
      showSaveButtons: dateRange and not @isReadonly() and not @options?.multipleInventoryManagementModesFound

    unless @options?.multipleInventoryManagementModesFound
      @renderRoomTypes()

    this.$el.find(@selectors.roomInfo).parsley(Extranet.ParsleyConfig)
    this

  isReadonly: ()->
    for data in @inventoryData
      return true if DateTimeHelper.isPastDate data.date, data.hotel?.timeZone?.identifier
    return false

  # 17719 - this is like getting the travel window, logic is duplicated
  getSelectedDateRange: (datesList = [])->
    distinctDates = _.uniq datesList

    if distinctDates.length is 0
      return undefined
    else if distinctDates.length is 1
      return DateTimeHelper.formatDate distinctDates[0], 'string'

    # 17719 - the ability to compare string dates is duplicated elsewhere also
    minDate = distinctDates.reduce (d1, d2) ->
      if d1 < d2  then d1 else d2

    maxDate = distinctDates.reduce (d1, d2) ->
      if d1 > d2  then d1 else d2

    minDateString = DateTimeHelper.formatDate minDate, 'string'
    maxDateString = DateTimeHelper.formatDate maxDate, 'string'
    # 17719 - formatting a range of dates as a string is also done elsewhere for weekview
    "#{minDateString} - #{maxDateString}"

  renderRoomTypes: () ->
    roomTypesList = _.groupBy @inventoryData, (item) ->
      item.roomType.uuid

    @roomTypesViewList = []
    _.each roomTypesList, (roomType) =>
      roomTypeSummaryView = @addChild new Extranet.Views.RoomTypeSummaryView
        roomTypeData: roomType
        productSetModel: @productSetModel
        isReadonly: @isReadonly()

      @$el.find(@selectors.roomTypes).append roomTypeSummaryView.render().el

      @roomTypesViewList.push roomTypeSummaryView

  validate: ()->
    @$(@selectors.roomInfo).parsley('destroy').parsley(Extranet.ParsleyConfig)
    @$(@selectors.roomInfo).parsley 'validate'

  saveRatesAndInventory: (e) ->
    unless @validate()
      @alertMessage.renderDefaultValidationError()
      return

    requestIdPrefix = Extranet.Common.Utilities.Utility.getRandomString(20)

    deferreds = []
    _.each @roomTypesViewList, (roomTypeView) =>
      deferreds.push roomTypeView.save(requestIdPrefix)

    @monitorRequests(deferreds, requestIdPrefix)

  monitorRequests: (deferreds, requestIdPrefix)->
    errorsList = []
    apiFailure = false
    responseJSON = null
    dailyRateRestrictionNetrateFoundError = false
    $.whenAll(deferreds).then(
      finalSuccess = () =>
        @eventManagerView.triggerEvent 'ratesInventorySaved'
        @eventManagerView.triggerEvent 'validationSuccess'
        message = I18n.translate('js.modules.common.alerts.success.saved_rates_and_inventory')
        @alertMessage.renderSuccess(message, requestIdPrefix)

      finalFailure = () =>
        if errorsList?.length
          @eventManagerView.triggerEvent 'validationFailure', {
            errors: errorsList,
            inventoryFailure: true
          }
        if apiFailure
          if dailyRateRestrictionNetrateFoundError
            message = @errorPresenter.formatErrorEntity dailyRateRestrictionNetrateFoundError
            @alertMessage.renderError message, requestIdPrefix
          else
            @alertMessage.renderDefaultError(requestIdPrefix)

      eachRequestComplete = (jqxhr, state, data) =>
        if state is 'error'
          if jqxhr.status isnt 403
            apiFailure = true
            responseJSON = jqxhr.responseJSON
            dailyRateRestrictionNetrateFoundError = dailyRateRestrictionNetrateFoundError or @errorPresenter.findErrorEntity responseJSON, "DAILY_RATE_RESTRICTION_NET_RATE"
            return
          if jqxhr?.responseJSON?.failedValidations
            errorsList.push jqxhr.responseJSON
    )

  reset: ->
    @removeChildren()
    @render()

  addValidationFailures: ->
    _.each @roomTypesViewList, (roomTypeSummaryView) ->
      roomTypeSummaryView.addValidationFailures()
