Extranet.Templates =
  admin_header: require '../../../admin/header.html.mustache'
  admin_no_tax_results: require '../../../admin/no_tax_results.html.mustache'
  admin_tax_row: require '../../../admin/tax_row.html.mustache'
  admin_taxes: require '../../../admin/taxes.html.mustache'
  admin_templates_no_search_results: require '../../../admin/templates/no_search_results.html.mustache'
  admin_templates_promotions_results: require '../../../admin/templates/promotions_results.html.mustache'
  admin_templates_promotions_row: require '../../../admin/templates/promotions_row.html.mustache'
  admin_templates_rates_upload: require '../../../admin/templates/rates_upload.html.mustache'
  admin_templates_rates_upload_buttons: require '../../../admin/templates/rates_upload_buttons.html.mustache'
  admin_templates_search_page: require '../../../admin/templates/search_page.html.mustache'
  admin_templates_search_promotions_form: require '../../../admin/templates/search_promotions_form.html.mustache'
  admin_templates_select_states: require '../../../admin/templates/select_states.html.mustache'
  admin_templates_tax_details: require '../../../admin/templates/tax_details.html.mustache'

  admin_rates_inventory_upload: require '../../../admin/templates/rates_inventory_upload.html.mustache'

  booking_information_popup: require '../../../bookings/templates/booking_information_popup.html.mustache'
  bookings_download_button: require '../../../bookings/templates/download_button.html.mustache'
  bookings_filters: require '../../../bookings/templates/bookings_filters.html.mustache'
  bookings_page: require '../../../bookings/templates/bookings_page.html.mustache'
  bookings_printable_table: require '../../../bookings/templates/bookings_printable_table.html.mustache'
  bookings_rooms_dropdown: require '../../../bookings/templates/rooms_dropdown.html.mustache'
  bookings_table: require '../../../bookings/templates/bookings_table.html.mustache'

  calendarV2_calendar: require '../../../calendarV2/calendar.html.mustache'
  calendarV2_calendar_cell: require '../../../calendarV2/calendar_cell.html.mustache'
  calendarV2_partial_blackout: require '../../../calendarV2/partial_blackout.html.mustache'

  common_templates_alert_message: require '../../../common/templates/alert_message.html.mustache'
  common_pagination_footer: require '../../../common/pagination_footer.html.mustache'
  common_recent_auto_updates: require '../../../common/templates/recent_auto_updates.html.mustache'

  hotel_search_templates_search_results: require '../../../hotel_search/templates/search_results.html.mustache'
  hotels_templates_success_popup: require '../../../hotels/templates/success_popup.html.mustache'

  inventory_common_inventory_filter: require '../../../inventory_common/inventory_filter.html.mustache'
  inventory_rate_plan_popup: require '../../../inventory/rate_plan_popup.html.mustache'
  inventory_templates_inventory_download: require '../../../inventory/templates/inventory_download.html.mustache'
  inventory_templates_month_view_v2: require '../../../inventory/templates/month_view_v2.html.mustache'
  inventory_templates_monthview_lm_rateplan_summary: require '../../../inventory/templates/monthview/lm_rateplan_summary.html.mustache'
  inventory_templates_monthview_product_set_rules: require '../../../inventory/templates/monthview/product_set_rules.html.mustache'
  inventory_templates_monthview_rateplan_summary: require '../../../inventory/templates/monthview/rateplan_summary.html.mustache'
  inventory_templates_monthview_status_change_validation_messages: require '../../../inventory/templates/monthview/status_change_validation_messages.html.mustache'
  inventory_templates_monthview_roomtype_summary: require '../../../inventory/templates/monthview/roomtype_summary.html.mustache'
  inventory_templates_monthview_single_violation_summary: require '../../../inventory/templates/monthview/single_violation_summary.html.mustache'
  inventory_templates_monthview_summary_collection: require '../../../inventory/templates/monthview/summary_collection.html.mustache'
  inventory_templates_monthview_validation_messages: require '../../../inventory/templates/monthview/validation_messages.html.mustache'
  inventory_templates_ql2_upload: require '../../../inventory/templates/ql2_upload.html.mustache'
  inventory_templates_weekview_available_rooms: require '../../../inventory/templates/weekview/available_rooms.html.mustache'
  inventory_templates_weekview_filters: require '../../../inventory/templates/weekview/filters.html.mustache'
  inventory_templates_weekview_inventory: require '../../../inventory/templates/weekview/inventory.html.mustache'
  inventory_templates_weekview_rate_cell: require '../../../inventory/templates/weekview/rate_cell.html.mustache'
  inventory_templates_weekview_rate_plan: require '../../../inventory/templates/weekview/rate_plan.html.mustache'
  inventory_templates_weekview_room_inventory: require '../../../inventory/templates/weekview/room_inventory.html.mustache'
  inventory_templates_weekview_section: require '../../../inventory/templates/weekview_section.html.mustache'
  inventory_templates_weekview_summary: require '../../../inventory/templates/weekview/summary.html.mustache'
  inventory_templates_weekview_week_selector: require '../../../inventory/templates/weekview/week_selector.html.mustache'

  payments_page: require '../../../payments/templates/payments_page.html.mustache'

  products_templates_ps_rate_plan_list: require '../../../products/templates/ps_rate_plan_list.html.mustache'
  products_templates_ps_rate_plan_row: require '../../../products/templates/ps_rate_plan_row.html.mustache'
  products_templates_product_set_creation: require '../../../products/templates/product_set_creation.html.mustache'
  products_templates_product_set_details: require '../../../products/templates/product_set_details.html.mustache'
  products_templates_product_set_manager: require '../../../products/templates/product_set_manager.html.mustache'
  products_templates_product_set_status_buttons: require '../../../products/templates/product_set_status_buttons.html.mustache'
  products_templates_product_set_status_rollback: require '../../../products/templates/product_set_status_rollback.html.mustache'
  products_templates_inventory_section: require '../../../products/templates/inventory_section.html.mustache'
  products_templates_products_row: require '../../../products/templates/products_row.html.mustache'
  products_templates_product_notes: require '../../../products/templates/product_notes.html.mustache'
  products_templates_product_owners: require '../../../products/templates/product_owners.html.mustache'
  products_templates_product_owners_update: require '../../../products/templates/product_owners_update.html.mustache'
  products_templates_products_table: require '../../../products/templates/products_table.html.mustache'
  products_templates_ps_rate_plan_details: require '../../../products/templates/ps_rate_plan_details.html.mustache'
  products_templates_admin_delete_popup: require '../../../products/templates/admin_delete_popup.html.mustache'
  products_templates_room_details: require '../../../products/templates/room_details.html.mustache' # This file should be deprecated when rate plan details page make use of room_details.coffee instead of room_details_view.coffee

  room_mapping_templates_rate_description: require '../../../room_mapping/templates/rate_description.html.mustache'
  room_mapping_templates_room_type_rate_descriptions: require '../../../room_mapping/templates/room_type_rate_descriptions.html.mustache'

  profile_page: require '../../../profile/templates/profile_page.html.mustache'
  profile_basic_info: require '../../../profile/templates/basic_info.html.mustache'
  profile_channel_manager: require '../../../profile/templates/channel_manager.html.mustache'
  profile_channel_manager_mapping: require '../../../profile/templates/channel_manager_mapping.html.mustache'
  profile_contacts: require '../../../profile/templates/contacts.html.mustache'
  profile_contact_details: require '../../../profile/templates/contact_details.html.mustache'
  profile_contact_row: require '../../../profile/templates/contact_row.html.mustache'
  profile_description: require '../../../profile/templates/description.html.mustache'
  profile_rate_plans: require '../../../profile/templates/rate_plans.html.mustache'
  profile_rate_plan_row: require '../../../profile/templates/rate_plan_row.html.mustache'
  profile_room_details: require '../../../profile/templates/room_details.html.mustache'
  profile_room_info: require '../../../profile/templates/room_info.html.mustache'
  profile_room_info_row: require '../../../profile/templates/room_info_row.html.mustache'
  profile_taxes: require '../../../profile/templates/taxes.html.mustache'
  profile_taxes_selection: require '../../../profile/templates/taxes_selection.html.mustache'
  profile_tax_row: require '../../../profile/templates/tax_row.html.mustache'
  profile_tax_selection_row: require '../../../profile/templates/tax_selection_row.html.mustache'

window.TemplateHelper =
  render: (templateName, hash) ->
    template = Extranet.Templates[templateName]
    return "Template:#{templateName} not found" unless template
    hashForTemplate = hash or {}
    hashForTemplate.permissions = Extranet.RolePermissions
    hashForTemplate = I18n.mixin hashForTemplate if I18n.mixin
    template.render hashForTemplate, Extranet.Templates
