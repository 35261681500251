class DateCellUtility
  @LAST_MINUTE: 'lastMinute'

  @getDateCells: (dateCellsList, hotelInfo, dates, addDateOutsideTravelWindow = true, addNonBlackOut=true) ->
    @addNonBlackOut = addNonBlackOut
    dataByDate = _.groupBy dateCellsList, (dateCell) ->
      dateCell.date

    _.each dates, (date) =>
      dateCells = dataByDate[date] or []
      defaultDateCells = @getDefaultDateCells dateCells, hotelInfo, date, addDateOutsideTravelWindow
      if defaultDateCells.length > 0
        dateCellsList = dateCellsList.concat defaultDateCells

    sortedDateCellsByDate = _.sortBy(dateCellsList, (dateCell)-> dateCell.date)
    sortedDateCellsByRatePlan = _.sortBy(sortedDateCellsByDate, (dateCell)-> dateCell.ratePlan?.name)
    sortedDateCellsByRatePlan

  @getDefaultDateCells: (dateCells, hotelInfo, date, addDateOutsideTravelWindow) ->
    dateCellsList = []
    _.each hotelInfo, (hotelInfoCell) =>
      ratePlanUuid = hotelInfoCell.ratePlan?.uuid
      dateCell = _.find dateCells, (dateCell) ->
        dateCell.ratePlan?.uuid is ratePlanUuid
      unless dateCell
        dateCell = @getDateCell hotelInfoCell, date, addDateOutsideTravelWindow
        dateCellsList.push dateCell if dateCell

    dateCellsList

  @getDateCell: (hotelInfoCell, date, addDateOutsideTravelWindow) ->
    isDateinTravelWindow = @isDateinTravelWindow date, hotelInfoCell?.ratePlan
    addDateCellByTravelWindow = isDateinTravelWindow or addDateOutsideTravelWindow
    addDateCellByblackout = @addNonBlackOut
    if addDateCellByTravelWindow and addDateCellByblackout
      dateCell =  @clone hotelInfoCell
      dateCell.date = date
      dateCell.rates = []
      dateCell.inventory = {}
      dateCell.restrictions = {}
      dateCell.dateOutsideTravelWindow = not isDateinTravelWindow if addDateOutsideTravelWindow
      dateCell

  # 17719 - does this fail if neither start or end is passed and I defaulted
  #   both to today? need to investigate. Dupe is in date helper
  # 17712 - needs timezone
  @isDateinTravelWindow: (date, ratePlan) ->
    if ratePlan?.productType is @LAST_MINUTE
      return true
    travelWindow = ratePlan?.travelWindow
    start = travelWindow?.startAt
    end   = travelWindow?.endAt
    unless start or end
      return false
    startDate = new Date(start)
    endDate   = new Date(end)
    date = new Date(date)
    date >= startDate and date <= endDate

  @clone: (hash) ->
    if window?
      clonedHash = $.extend true, {}, hash
    else
      clonedHash = deepmerge {}, hash
    clonedHash

if window?
  _ = window._
  moment = window.moment
  window.Extranet.Inventory.DateCellUtility = DateCellUtility
else
  _ = require 'underscore'
  deepmerge = require 'deepmerge'
  moment = require 'moment'
  module.exports = DateCellUtility
