require '../../bookings/frontend/router'
require '../../inventory/frontend/inventory_router'
require '../../products/frontend/products_router'
require '../../profile/frontend/router'
require '../../room_mapping/frontend/views/room_mapping_view'
require '../../payments/frontend/views/payments_view'

require './views/create_hotel_form_view'

selectors =
  profileTab: '#profile-tab'
  bookingsTab: '#bookings-tab'
  productsTab: '#products-tab'
  inventoryTab: '#inventory-tab'
  roomMappingTab: '#room-mapping-tab'
  payments: '#payments-tab'

if Extranet.ClientConfig?.page is 'hotels'
  $(document).ready ->
    switch Extranet.ClientConfig?.view
      when 'profile'
        $(selectors.profileTab).addClass('active')
        Extranet.profileRouter = new Extranet.Routers.Profile()
        Backbone.history.start()
      when 'products'
        $(selectors.productsTab).addClass('active')
        Extranet.productsRouter = new Extranet.Routers.ProductsRouter()
        Backbone.history.start()
      when 'bookings'
        $(selectors.bookingsTab).addClass('active')
        bookingsRouter = new Extranet.Routers.Bookings()
        Backbone.history.start()
      when 'inventory'
        $(selectors.inventoryTab).addClass('active')
        Extranet.inventoryRouter = new Extranet.Routers.InventoryRouter()
        Backbone.history.start()
      when 'room_mapping'
        $(selectors.roomMappingTab).addClass('active')
        roomMappingView = new Extranet.Views.RoomMappingView()
        roomMappingView.render()
      when 'payments'
        $(selectors.payments).addClass('active')
        paymentsView = new Extranet.Views.Payments()
        paymentsView.render()

if $('body').hasClass('new-hotel')
  $(document).ready ->
    createHotelFormView = new Extranet.Views.CreateHotelFormView()
    createHotelFormView.render()
