require "./rate_cell_view"

class  Extranet.Views.WeekInventory.RatePlanView extends Backbone.View
  Extranet.Common.Utilities.Mixin.extends this, Extranet.Helpers.ChildManager

  eventManagerKey: Extranet.Helpers.EventManagerHelper.SETTINGS_KEY

  tagName: 'tr'
  className: 'rate-plan-row'
  templateName: 'inventory_templates_weekview_rate_plan'

  selectors:
    grouponRateTitle: '.groupon-rate-title'
    grouponRate: '.groupon-rate'
    netRateTitle: '.net-rate-title'
    netRate: '.net-rate'
    hideRestrictionsLink: '.hide-restrictions'
    showRestrictionsLink: '.show-restrictions'
    restrictionsSection: '.restrictions-section'
    modal: (ratePlanUuid) ->
      "#ratePlanPopup-#{ratePlanUuid} .modal-body"

  events:
    'click .rate-plan-name': 'renderRatePlanPopup'
    'click .hide-restrictions': 'hideRestrictions'
    'click .show-restrictions': 'showRestrictions'

  initialize: (options) ->
    @datecells = options.datecells
    {hotel, roomType, ratePlan, @productSet} = @datecells[0]
    @hotelUuid = hotel?.uuid
    @roomTypeUuid = roomType?.uuid
    @productSetUuid = @productSet?.uuid
    @ratePlanUuid = ratePlan?.uuid
    @ratePlanName = ratePlan?.internalName
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate

    @isLastMinute = ratePlan?.productType is 'lastMinute'
    unless @isLastMinute
      @ratePlanUrl = ExtranetUrlBuilder.getBdRatePlanUrl({@hotelUuid, @productSetUuid, @ratePlanUuid})

    @rateCellViews = @getRateCellViews()
    @settingsEventManager = Extranet.Helpers.EventManagerHelper.getEventManagerView(@eventManagerKey)
    @listenTo @settingsEventManager, 'grouponRate', @showGrouponRate
    @listenTo @settingsEventManager, 'netRate', @showNetRate

  render: (options) ->
    this.$el.html @autoTemplate.render @templateName,
      ratePlanName: @ratePlanName
      ratePlanUuid: @ratePlanUuid
      ratePlanUrl: @ratePlanUrl
      isLastMinute: @isLastMinute
      accessPermissionSubKey: @productSet?.status or 'lastMinute'

    options.placeHolder.append @el

    for rateCellView in @rateCellViews
      this.$el.append rateCellView.render().el

    @setRateType()
    this

  getRateCellViews: ()->
    rateCellViews = []

    for rateCell in @datecells
      rateCellView = @addChild new Extranet.Views.WeekInventory.RateCellView rateCell
      rateCellViews.push rateCellView
    rateCellViews

  renderRatePlanPopup: ()->
    unless @ratePlanPopView
      @ratePlanPopView = @addChild new Extranet.Views.RatePlanReadOnlyView {
        ratePlanData: @datecells
        el: @selectors.modal @ratePlanUuid
      }
    @ratePlanPopView.render()

  getUrlParameters: ->
    if @datecells?.length > 0
      hotelUuid = @datecells[0]?.hotel?.uuid
      roomTypeUuid = @datecells[0]?.roomType?.uuid
      ratePlanUuid = @datecells[0]?.ratePlan?.uuid
      {hotelUuid, roomTypeUuid, ratePlanUuid}

  save: (requestIdPrefix) ->
    saveHash =
      rates: []
      restrictions: []
      _csrf: $('#csrf_token').val()

    for rateCellView in @rateCellViews
      {rates, restrictions} = rateCellView.getHashToSave()
      if rates?.length > 0
        saveHash.rates = saveHash.rates.concat rates

      if restrictions
        saveHash.restrictions = saveHash.restrictions.concat restrictions

    if saveHash.rates.length is 0 and saveHash.restrictions.length is 0
      return

    urlParams = @getUrlParameters()

    url = ExtranetUrlBuilder.getUploadRatesUrl(urlParams)
    apiClient = new Extranet.Inventory.Utilities.InventoryApiClient()

    headers = {'request-id-prefix': requestIdPrefix}

    callback = ()->
      # noop

    apiClient.save(url, saveHash, headers, callback)

  showGrouponRate: ()->
    @$(@selectors.grouponRateTitle).removeClass('hide')
    @$(@selectors.grouponRate).removeClass('hide')
    @$(@selectors.netRateTitle).addClass('hide')
    @$(@selectors.netRate).addClass('hide')

  showNetRate: ()->
    @$(@selectors.grouponRateTitle).addClass('hide')
    @$(@selectors.grouponRate).addClass('hide')
    @$(@selectors.netRateTitle).removeClass('hide')
    @$(@selectors.netRate).removeClass('hide')

  setRateType: ()->
    rateType = Extranet.Settings.getRateType()
    if rateType is Extranet.Settings.NET_RATE
      @showNetRate()
    else
      @showGrouponRate()

  showRestrictions: ()->
    @$(@selectors.showRestrictionsLink).addClass('hide')
    @$(@selectors.hideRestrictionsLink).removeClass('hide')
    @$(@selectors.restrictionsSection).slideDown(Extranet.Constants.BLACKOUT_TOGGLE_DURATION)

  hideRestrictions: ()->
    @$(@selectors.hideRestrictionsLink).addClass('hide')
    @$(@selectors.showRestrictionsLink).removeClass('hide')
    @$(@selectors.restrictionsSection).slideUp(Extranet.Constants.BLACKOUT_TOGGLE_DURATION)
