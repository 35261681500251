class Extranet.Presenters.AdvancePurchaseWindowHelper
  @getAdvancePurchaseWindow: (ratePlanRestriction) ->
    ADVANCE_PURCHASE_MIN = 1
    ADVANCE_PURCHASE_MAX = 14
    ADVANCE_PURCHASE_MULTIPLIER = -1440
    advancePurchaseWindow = []
    selectedValue = ratePlanRestriction?.advancePurchaseEnd ? null

    advancePurchaseWindow.push
      value: null
      text: 0
      isSelected: null is selectedValue

    # advancePurchaseEnd follows the following pattern
    # 0: null
    # 1: 0
    # 2: -1440
    # 3: -2880 and so on adding -1440
    for i in [ADVANCE_PURCHASE_MIN .. ADVANCE_PURCHASE_MAX]
      advancePurchaseWindow.push
        value: ADVANCE_PURCHASE_MULTIPLIER * (i-1)
        text: i
        isSelected: ((selectedValue isnt null) and ((i-1) is (selectedValue/ADVANCE_PURCHASE_MULTIPLIER)))

    advancePurchaseWindow
