require "./ps_row_view"

class Extranet.Views.PSTableView extends Backbone.View

  templateName: 'admin_templates_promotions_results'
  noResultsTemplate: 'admin_templates_no_search_results'

  selectors:
    tableSelector: 'tbody'
    rolesDescription: '.roles-description'
    noSearchResult: '#no_search_result'

  events:
    'mouseenter .roles-tooltip a': 'showOrHideRolesToolTip'
    'mouseleave .roles-tooltip a': 'showOrHideRolesToolTip'

  initialize: (options)->
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    {@el} = options

  render: (productSets) ->
    if productSets?.length > 0
      @$el.find(@selectors.noSearchResult)?.hide()
      @$el.find(@selectors.tableSelector).show()
      @$el.html @autoTemplate.render @templateName, {}
      productSets.forEach (model) =>
        psRowView = new Extranet.Views.PSRowView {model}
        @$el.find(@selectors.tableSelector).append psRowView.render().el
    else
      @$el.find(@selectors.tableSelector).hide()
      @$el.html @autoTemplate.render @noResultsTemplate, {}
      @$el.find(@selectors.noSearchResult)?.show()

  showOrHideRolesToolTip: () ->
    @$(@selectors.rolesDescription).toggleClass('hide')
