class Extranet.Presenters.RatePlanPresenter

  ###
  # For render
  ###

  toHash: (ratePlan)->
    deepCopy = true
    data = {}
    unless ratePlan
      data =
        name: 'Getaway Tonight'
        internalName: 'TONIG'
        blackoutDays:
          none: true
        finePrint: "Non refundable"
      return data

    data = $.extend deepCopy, {}, ratePlan.toJSON()

    # TODO: convert value adds for rendering

    # convert max length of stay to hash
    restriction = data.restrictions?[0]
    if restriction
      maxLength = restriction.maxLengthOfStay
      data.lengthOfStay={}
      data.lengthOfStay[maxLength] = maxLength
    return data

  gettoolTipTitle: ()->
    tooptipMsgs=
      abbreviationTitle :"This is your internal abbreviation for this Rate Plan. It will only be displayed in the Extranet."
      additionalValueTitle: "This Information will be shown to the customer. See preview."
      finePrintTitle: "List of restrictions or extra fees (including resort fees) associated with this rate plan. This will be shown to the customer. See Preview"
      maxNumOfDaysForHotelTitle: "Select the number of consecutive days that a customer can book at the Getaway Tonight rate that is provided."

  getValueAdds: (model)->
    valueAdds = Extranet.Presenters.ValueAdds.get()

    unless model
      return @_splitValueAdds valueAdds

    modelValueAdds = model.get 'valueAdds'
    if modelValueAdds
      for temp in modelValueAdds
        valueAddObj = _.find valueAdds, (va)->
          va.uuid is temp.uuid

        valueAddObj.checked = true

    @_splitValueAdds valueAdds

  # value adds are displayed in two columns. So we need to
  # divide them into two.
  _splitValueAdds: (valueAdds)->
    len = valueAdds?.length
    return unless len

    len += 1
    mid = MathHelper.divide len, 2
    rightHalf = valueAdds.splice parseInt mid
    [valueAdds, rightHalf]

  ###
  # For save
  ###

  getHashToSave: (view) ->
    saveAttrs = {}
    saveAttrs.activeStatus = "active"
    saveAttrs.name = view.$('#name').val()
    saveAttrs.description = view.$('#description').val()
    saveAttrs.internalName = view.$('#internalName').val()
    saveAttrs.finePrint = view.$('#fineprint').val()
    saveAttrs.nonRefundable = view.$('#nonRefundable').is(':checked')
    saveAttrs.valueAdds = @collectValueAdds view
    saveAttrs.restrictions = @getRestrictions view

    # CSRF token
    saveAttrs._csrf = $('#csrf_token').val()
    saveAttrs


  getRestrictions: (view) ->
    restrictions=
      advancePurchaseStart: 540
      advancePurchaseEnd: 1380

    restrictions.productType = 'lastMinute'
    restrictions.maxLengthOfStay = view.$('#maxLengthOfStay').val()
    restrictions.blackOutDates = @getBlackoutDates view
    # There are only two client platforms available
    # 3D992740-7A26-11E3-981F-0800200C9A66 = mobile
    # A02B6030-7A26-11E3-981F-0800200C9A66 = web
    # For last minute we support only mobile platform.
    # Hardcoding these values for now. It will be removed
    # when the endpoint is available
    restrictions.clientPlatforms = ["3D992740-7A26-11E3-981F-0800200C9A66"]

    [restrictions]

  getBlackoutDates: (view)->
    blackOutDates = []
    view.$('.blackout-date .date').each (input)->
      date = $(this).data 'date'
      blackOutDates.push date

    blackOutDates

  collectValueAdds: (view) ->
    valueAdds = []
    view.$('.value-add').each (element)->
      if $(this).is(':checked')
        valueAdds.push $(this).val()

    valueAdds
