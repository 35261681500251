require "./week_view_filter"
require "./week_selector_view"
require "./week_view_inventory"

class Extranet.Views.WeekView extends Backbone.View
  Extranet.Common.Utilities.Mixin.extends this, Extranet.Helpers.ChildManager
  events: {}

  templateName: 'inventory_templates_weekview_section'
  selectors:
    weekViewInvetory: '#inventory'
    weekViewFilter: '#week-view-filter'
    weekHeader: '.week-header'

  initialize: () ->
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate

  render: (options) ->
    @$el.html @autoTemplate.render @templateName, {}

    unless @inventory
      @inventory = @addChild new Extranet.Views.WeekViewInventory
        el: @$ @selectors.weekViewInvetory

    unless @filter
      @filter = @addChild new Extranet.Views.WeekViewFilter
        el: @$ @selectors.weekViewFilter
      @listenTo @filter, 'filterChange', @handleWeekFilterChange

    unless @weekNavigator
      @weekNavigator = @addChild new Extranet.Views.WeekNavigatorView
        el: @$ @selectors.weekHeader
      @listenTo @weekNavigator, 'weekChange', @filter.updateWeek

    @filter.render options

    this

  handleWeekFilterChange:(filters)->
    @weekNavigator.render filters.dates
    @inventory.render filters
