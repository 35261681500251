class Extranet.Views.PSSearchFilterView extends Backbone.View

  Extranet.Common.Utilities.Mixin.extends this, Extranet.Helpers.ChildManager

  templateName: 'admin_templates_search_promotions_form'

  selectors:
    alertMessage: '.alert-message'
    countrySelector: '#promotions_search_form .country-selector'
    stateSelector: '#promotions_search_form .state-selector'
    citySelector: '#promotions_search_form .city-selector'
    formSelector: '#promotions_search_form'
    ownerIdSelector: '#promotionOwner'
    ownerIdTypeSelector: '#promotionOwnerRoles'

  events:
    'click .promotions-search-btn': 'submitSearch'

  initialize: (options) ->
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    @alertMessage = new Extranet.Views.AlertMessage {selector: @selectors.alertMessage}

  render: () ->
    @$el.html @autoTemplate.render @templateName, {}
    @renderLocationSelector()

  renderLocationSelector: () ->
    @countrySelector = @$el.find(@selectors.countrySelector)
    @stateSelector = @$el.find(@selectors.stateSelector)
    @citySelector = @$el.find(@selectors.citySelector)
    @locationSelector = new Extranet.Helpers.LocationSelector @countrySelector, @stateSelector, @citySelector, {}

  submitSearch: (e) ->
    e?.preventDefault()
    @searchParameters = @$(@selectors.formSelector).serializeObject()
    ownerIdType = @$(@selectors.ownerIdTypeSelector).val()
    ownerId= @$(@selectors.ownerIdSelector).val()

    unless @validateOwnersField()
      @alertMessage.renderDefaultValidationError()
      return

    @searchParameters[ownerIdType] = ownerId if ownerIdType and ownerId
    @searchParameters.region_uuid =
      if @searchParameters.city_uuid then @searchParameters.city_uuid
      else if @searchParameters.state_uuid then @searchParameters.state_uuid
      else @searchParameters.country_uuid
    @searchParameters = _.omit @searchParameters, 'city_uuid', 'state_uuid', 'country_uuid'
    @trigger 'searchSubmitted', @searchParameters

  validateOwnersField: () ->
    $ownerIdType = @$(@selectors.ownerIdTypeSelector)
    $ownerId= @$(@selectors.ownerIdSelector)

    ownerIdVal = $ownerId.val()
    ownerIdTypeVal = $ownerIdType.val()
    if (ownerIdVal and ownerIdTypeVal) or (not ownerIdVal and not ownerIdTypeVal)
      $ownerId.removeClass 'error'
      $ownerIdType.removeClass 'error'
      @alertMessage.hide()
      return true

    unless ownerIdVal
      $ownerId.addClass 'error'
      $ownerIdType.removeClass 'error'
    unless ownerIdTypeVal
      $ownerIdType.addClass 'error'
      $ownerId.removeClass 'error'
    return false
