require './contact_row'
require './contact_details'

class Extranet.Views.Contacts extends Backbone.View

  selectors:
    contactsList: '#contacts-list'
    contactDetails: '.contact-details'
    deleteContactConfirmationModal: '#delete-contact-confirmation-modal'
  
  events:
    'click #add-contact-button': '_showAddContactModal'

  el: '#contacts'
  templateName: 'profile_contacts'

  initialize: (options) ->
    @alertMessage = Extranet.Helper.AlertView.getAlertView()
    @contactsCollection = new Extranet.Collections.Contacts()
    @contactRolesCollection = new Extranet.Collections.ContactRoles()
    @listenTo @contactsCollection, 'add', @_renderContacts
    @listenTo @contactsCollection, 'sync', @_renderContacts
    @listenTo @model, 'change', @_fetchContacts
    
  render: () ->
    @$el.html TemplateHelper.render @templateName, {}
    @_fetchContacts()
    @_fetchContactRoles()

  _fetchContacts: () ->
    @_showLoading()
    options =
      data: {hotel_uuid: @model.id}
      reset: true
      global: false
    onFailure = (jqXHR, textStatus, errorThrown) =>
      requestId = jqXHR?.getResponseHeader 'x-request-id'
      @alertMessage.renderDefaultError requestId
    onFinish = () =>
      @_hideLoading()
    @contactsCollection.fetch(options).fail(onFailure).always(onFinish)
  
  _renderContacts: () ->
    @_clearContactsList()
    @contactsCollection.each (model) =>
      contactRowView = new Extranet.Views.ContactRow
        collection: @contactsCollection
        model: model
        hotelModel: @model
        contactDetailsEl: @$(@selectors.contactDetails)
        deleteContactConfirmationModal: @selectors.deleteContactConfirmationModal
      contactRowView.render()
      @$(@selectors.contactsList).append contactRowView.el

  _showAddContactModal: (e) ->
    return unless Extranet.RolePermissions?.contact?.edit
    contactDetailsView = new Extranet.Views.ContactDetails
      collection: @contactsCollection
      model: new Extranet.Models.Contact 
        hotelUuid: @contactsCollection?.hotelUuid
      hotelModel: @model
    contactDetailsView.render()
    @$(@selectors.contactDetails).html contactDetailsView.el

  _fetchContactRoles: () ->
    options = 
      global: true
    onSuccess = (data, textStatus, jqXHR) =>
      Extranet.ClientConfig?.ContactRoles = data
    @contactRolesCollection.fetch(options).then(onSuccess)
  
  _clearContactsList: () ->
    @$(@selectors.contactsList).empty()

  _showLoading: () ->
    @$el.addClass 'loading'

  _hideLoading: () ->
    @$el.removeClass 'loading'