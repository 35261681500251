require "./rateplan_summary_view"
require "./lm_rateplan_summary_view"
require "../../presenters/validations/failed_validation_dates_presenter"

class Extranet.Views.RoomTypeSummaryView extends Backbone.View
  Extranet.Common.Utilities.Mixin.extends this, Extranet.Helpers.ChildManager

  selectors:
    ratePlans: '.rate-plans'
    roomAvailabilityInput: '.room-availability input'
    roomAvailability: '.amount-field.room-availability'

  events:
    'change .room-availability input[type=text]': 'updateChangedFlag'

  templateName: 'inventory_templates_monthview_roomtype_summary'

  initialize: (options) ->
    @productSetModel = options.productSetModel
    @isReadonly = options.isReadonly
    @roomTypeData = options.roomTypeData or []
    @setProductSet()
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    @inventoryUtility = Extranet.Inventory.RatesUtility
    @failedValidationPresenter = Extranet.Presenters.FailedValidationDatesPresenter
    @apiClient = new Extranet.Inventory.Utilities.InventoryApiClient()

  setProductSet:()->
    dateCell = _.find @roomTypeData, (dateCell)->
      dateCell.productSet?.uuid isnt undefined
    @productSet = dateCell?.productSet

  render: () ->
    @inventoryChanged = false
    roomTypeInfo = @roomTypeData?[0]?.roomType
    roomTypeHash =
      inventoryCount: @getMinInventoryHash()
      isRoomTypeInventoryMode: @_isRoomTypeInventoryMode()
      name: roomTypeInfo?.name or ""
      soldCount: @_getSumSoldCount()
      errors: @getErrorsHash(@roomTypeData)

    this.$el.html @autoTemplate.render @templateName,
      roomType: roomTypeHash
      accessPermissionSubKey: @getAccessPermissionSubKey()

    @renderRatePlans()
    this

  _isRoomTypeInventoryMode: ()->
    @productSet?.inventoryManagementMode is Constants.INVENTORY_MANAGEMENT_MODE.ROOM_TYPE

  getAccessPermissionSubKey: ()->
    if @isReadonly
      accessPermissionSubKey = 'readonly'
    else
      accessPermissionSubKey = @productSetModel?.get('status') or 'lastMinute'

    accessPermissionSubKey

  getErrorsHash: (roomTypeData) ->
    inventoryFailureList = _.collect roomTypeData, (dateCell) =>
      failedEntities =  @failedValidationPresenter.getFailedEntities dateCell
      _.contains(failedEntities, "inventoryCount")

    errors =
      availability: @inventoryUtility.computeOr inventoryFailureList

    errors

  addValidationFailures: ->
    errorsHash = @getErrorsHash(@roomTypeData)
    @$(@selectors.roomAvailability).addClass('error') if errorsHash.availability

    _.each @ratePlanViewList, (ratePlanSummaryView) ->
      ratePlanSummaryView.addValidationFailures()

  getMinInventoryHash: ->
    inventoryCountList = _.collect @roomTypeData, (dateHash) ->
      dateHash?.roomTypeInventory?.inventoryCount

    inventoryCountList = _.filter inventoryCountList, (value) ->
      value isnt undefined and value isnt null

    @inventoryUtility.getMinHash inventoryCountList

  _getSumSoldCount: () ->
    soldCountList = _.collect @roomTypeData, (dateCell) ->
      dateCell.soldCount
    @inventoryUtility.getSum _.compact soldCountList

  renderRatePlans: ->
    ratePlansList = _.groupBy @roomTypeData, (item) ->
      item.ratePlan?.uuid

    @ratePlanViewList = []
    _.each ratePlansList, (ratePlan) =>
      if ratePlan?[0].ratePlan?.productType is 'lastMinute'
        ratePlanSummaryView = @addChild new Extranet.Views.LmRatePlanSummaryView
          ratePlanData: ratePlan
          isReadonly: @isReadonly

      if ratePlan?[0].ratePlan?.productType is 'bookingDeal'
        ratePlanSummaryView = @addChild new Extranet.Views.RatePlanSummaryView
          ratePlanData: ratePlan
          productSetModel: @productSetModel
          isReadonly: @isReadonly

      @$el.find(@selectors.ratePlans).append ratePlanSummaryView.render().el

      @ratePlanViewList.push ratePlanSummaryView

  updateChangedFlag: (e) ->
    @inventoryChanged = true

  getRoomTypeUuid: ->
    if @roomTypeData?.length > 0
      @roomTypeData[0]?.roomType?.uuid

  getHotelUuid: ->
    if @roomTypeData?.length > 0
      @roomTypeData[0]?.hotel?.uuid

  getInventoryToSave: ->

    inventoryList = []

    inventoryValue = @$el.find(@selectors.roomAvailabilityInput).val()

    selectedDates = _.collect @roomTypeData, (hash) ->
      hash.date

    distinctSelectedDates = _.uniq selectedDates

    _.each distinctSelectedDates, (date) =>
      inventory =
        date: date
        stopSell: false        # TODO: updated later
        inventoryCount: parseInt inventoryValue
      inventoryList.push inventory

    inventoryList

  saveRoomTypeInventory: (requestIdPrefix)->
    unless @_isRoomTypeInventoryMode()
      return

    inventoryValue = @$el.find(@selectors.roomAvailabilityInput).val()
    unless inventoryValue?.length > 0 and @inventoryChanged
      return


    hotelUuid = @getHotelUuid()
    roomTypeUuid = @getRoomTypeUuid()
    inventory = @getInventoryToSave()

    # If product set is live or approved, add productSet uuid to enable validation only for hotel users
    unless Extranet.RolePermissions.is_admin_user
      qs = {productSetUuid: @productSet?.uuid} if @productSet?.status in ['live', 'approved']

    url = ExtranetUrlBuilder.getUploadInventoryUrl
      hotelUuid: hotelUuid
      roomTypeUuid: roomTypeUuid
      qs: qs

    csrfToken = $('#csrf_token').val()
    saveHash =
      inventory: inventory
      _csrf: csrfToken

    headers = {'request-id-prefix': requestIdPrefix}
    callback = ()->
      # noop

    @apiClient.save(url, saveHash, headers, callback)

  save: (requestIdPrefix)->
    requests = []

    # Adding room type inventory update request
    requests.push @saveRoomTypeInventory(requestIdPrefix)

    # Adding rate plan rates and inventory update requests for each rate plan
    _.each @ratePlanViewList, (ratePlanView) ->
      requests.push ratePlanView.saveRates(requestIdPrefix)
      requests.push ratePlanView.saveInventory(requestIdPrefix)

    $.when.apply(null, requests)
