class Extranet.Views.LoginFormView extends Backbone.View

  el: 'form#login-form'

  selectors:
    region: '#region'
    errorMessage: '.error-message'

  events:
    'keyup input': 'handleKeyPress'
    'click #submit': 'submitForm'


  initialize: () ->
    @$el.attr('data-bind', true)
    @$el.parsley(Extranet.ParsleyConfig)
    @selectSavedRegion()

  handleKeyPress: (e)->
    e.preventDefault()
    code = e.keyCode or e.which
    if code is 13
      @submitForm()

  submitForm: ()->
    event.preventDefault()
    unless @validate()
      errorMessage = I18n.translate("js.modules.common.alerts.validation.default")
      $(@selectors.errorMessage).html(errorMessage)
      return

    console.log 'Execution of the login function'
    grecaptcha.execute('6LdRFXwqAAAAAK2nrLPcXxybj5OSEUxsQwrC-r5G', { action: 'submit' })
      .then (token) ->
        console.log('Success event happened')
        form = document.getElementById('login-form')
        input = document.createElement('input')
        input.type = 'hidden'
        input.name = 'recaptcha_token'
        input.value = token
        form.appendChild(input)
        HTMLFormElement.prototype.submit.call(form)
#        $.ajax
#          type: "POST"
#          url: "/extranet/login"
#          dataType:"json"
#          contentType: "application/json; charset=utf-8"
#          data: JSON.stringify
#            _csrf: $("#csrf_token").val()
##            usedRatePlan: usedRatePlan
#          error: (jqxhr)=>
#            requestId = jqxhr?.getResponseHeader('x-request-id')
#            @alertMessage.renderDefaultError(requestId)
       .catch (err) ->
         errorMessage = 'grecaptcha execution failed.'
         console.log(errorMessage)

  selectSavedRegion: ()->
    savedRegion = Cookie.get('user_region')
    $(@selectors.region).val(savedRegion) if savedRegion

  validate: ()->
    return @$el.parsley('validate')