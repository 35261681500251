require "./product_set_details_view"
require "./ps_rate_plan_details_view"

class Extranet.Views.PSInfoTabView extends  Backbone.View
  Extranet.Common.Utilities.Mixin.extends this, Extranet.Helpers.ChildManager

  RATEPLANS = 'ratePlanDetailsView'
  el: '#tab-information'

  initialize: (options) ->
    @hotelUuid = options.hotelUuid
    @hotelConnected = options.hotelConnected
    @productSetUuid = options.productSetUuid
    @productSetModel = options.productSetModel
    @alertMessage = Extranet.Helper.AlertView.getAlertView()
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    @psDetailsView = new Extranet.Views.PSDetailsView {@hotelUuid, @hotelConnected, @productSetUuid, @productSetModel}
    @addChild @psDetailsView

  render: (options= {}) ->
    @view = options.view
    if @view is RATEPLANS
      @renderRatePlan options
    else
      @renderPsDetails options

  renderPsDetails: (options = {})->
    @ratePlanDetailsView?.hide()
    @psDetailsView.render options
    @show()
    this

  renderRatePlan: (options = {}) ->
    @psDetailsView?.hide()
    @ratePlanDetailsView?.remove()
    @ratePlanDetailsView = new Extranet.Views.PsRatePlanDetailsView options
    @show()
    this
