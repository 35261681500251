MathHelper = function () {
  function MathHelper() {}

  MathHelper.divide = function (a, b) {
    return a / b;
  };

  MathHelper.calculatePercentage = function (baseValue, discountValue) {
    if (!baseValue || !discountValue) return;
    temp = this.divide(discountValue, baseValue);
    temp = temp * 100;
    temp = temp.toFixed(2);
    temp = 100 - temp;
    return +temp.toFixed(2);
  };

  return MathHelper;
}.call(this);

if (typeof window !== 'undefined' && window !== null) {
  window.MathHelper = MathHelper;
} else {
  module.exports = MathHelper;
}
