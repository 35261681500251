# This file should be deprecated when rate plan details page make use of room_details.coffee instead of room_details_view.coffee

class Extranet.Presenters.RoomDetailsPresenter

  toHash : (model) ->
    deepCopy = true
    data = $.extend deepCopy, {}, model.toJSON()
    data

  getHashToSave: (view) ->
    saveAttrs = {}

    saveAttrs.name = view.$('#name').val()
    saveAttrs.internalName = saveAttrs.name
    saveAttrs.description = view.$('#room-description').val()
    saveAttrs._csrf = $('#csrf_token').val()
    saveAttrs
