###
WhenAll Jquery plugin. Returns only after all the deferreds are complete (Either success or failure).

Usage $.whenAll([deferreds]).then
  () -> Final Success callback
  () -> Final Failure callback
  () -> Individual callback for each of the deferreds. Arguments same as success() and failure callbacks in ajax

We create a single deferred, which notifies everyTime one of the passed deferreds either fails or passess
Source: https://gist.github.com/fearphage/4341799

###

(($) ->
  slice = [].slice
  $.whenAll = (array) ->
    updateFunc = (index, contexts, values) ->
      ->
        failed++ if (values isnt resolveValues)
        # Call the progress callback with the completed deferreds response
        deferred.notifyWith contexts[index] = this, values[index] = slice.call(arguments)
        unless --remaining
          # All deferreds are complete. Call it with the appropriate final Callback
          deferred[((if not failed then "resolve" else "reject")) + "With"] contexts, values
        return
    # Input can be an array of deferreds, or else multiple deferreds as arguments
    resolveValues = (if arguments.length is 1 and $.isArray(array) then array else slice.call(arguments))
    length = resolveValues.length
    remaining = length
    deferred = $.Deferred()
    counter = 0
    failed = 0
    rejectContexts = Array(length)
    rejectValues = Array(length)
    resolveContexts = Array(length)

    while counter < length
      if (value = resolveValues[counter]) and $.isFunction(value.promise)
        # Update the done and failure callbacks of current deferred to notify the newly created Deferred
        value.promise().done(updateFunc(counter, resolveContexts, resolveValues)).fail updateFunc(counter, rejectContexts, rejectValues)
      else
        # This is not a promise, send back the object to progress function
        deferred.notifyWith this, value
        --remaining
      counter++
    deferred.resolveWith resolveContexts, resolveValues  unless remaining
    deferred.promise()

  return
) jQuery
