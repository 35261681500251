class Extranet.Presenters.RoomInfo

  constructor: () ->
    @ratePlanPresenter = new Extranet.Presenters.RatePlan()

  toHash: (model) ->
    hash = model.pick 'name', 'description', 'uuid'
    hash.ratePlans = @_getRatePlans model
    hash
  
  _getRatePlans: (model) ->
    _.map model.getRatePlans(), (ratePlan) => @ratePlanPresenter.format ratePlan, model