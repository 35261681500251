require "../../presenters/product_set_validation_presenter"

###
This view depends on Inventory Data which may not be available when it is
initialized. It listens to Event Manager's 'fetchInventory' event for getting
inventory data. It should be initialised before fetching inventory sothat it
can catch the inventory data.
###
class Extranet.Views.ValidationErrMsgView extends Backbone.View

  templateName: 'inventory_templates_monthview_validation_messages'

  selectors:
    validationMessages: '.validation-msgs'

  events:
    'click .text-close': 'hideView'

  # This is a map between validator and presenter
  presenters:
    'productsetstatetransitionvalidator': Extranet.Presenters.StateTransitionErrsPresenter
    'ql2validator': Extranet.Presenters.Ql2RatesErrorsPresenter
    'productsetinventoryvalidator': Extranet.Presenters.InventoryValidationErrsPresenter
    'productsetratesvalidator': Extranet.Presenters.RatesRestrictionsErrPresenter
    'productsetfieldsvalidator': Extranet.Presenters.PsFieldsErrPresenter

  initialize: (options)->
    @eventMgr = options.eventManager
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    eventManagerKey = Extranet.Helpers.EventManagerHelper.PS_MONTH_VIEW_KEY
    @listenTo @eventMgr, 'validationFailure', @render
    @listenTo @eventMgr, 'validationSuccess', @hide
    @listenTo @eventMgr, 'fetchInventory', @updateInventory

  updateInventory: (inventoryData)->
    @hotelInfo = inventoryData?.hotelInfo

  # I do not see this ever being called, at least not for review-violations
  render: ({errors, newState, inventoryFailure})->
    @$el.removeClass 'hide'
    errorMessages = []
    for error in errors
      unless error.failedValidations
       continue

      for failedValidation in error?.failedValidations
        validatorKey = failedValidation.validator.toLowerCase()
        presenter = @presenters[validatorKey]
        messageHash = presenter.getErrorMessages
          failedValidation: failedValidation
          hotelInfo: @hotelInfo
          newState: newState

        errorMessages.push messageHash

    finalHash = @getFinalHash errorMessages
    hash =
      errorMessages: finalHash
      inventoryTitle: true if inventoryFailure
    @$el.html @autoTemplate.render @templateName, hash
    this

  # I do not see this ever being called, at least not for review-violations
  getFinalHash: (errorMessages)->
    # Group all related messages
    errorMessagesByObject = _.groupBy errorMessages, (singleMessage)->
      singleMessage.objectUuid

    messagesArray = _.toArray errorMessagesByObject
    finalHash = []
    for messageObj in messagesArray
      objectName = messageObj[0].objectName
      objectType = messageObj[0].objectType
      messages = []

      for msg in messageObj
        messages = messages.concat msg.messages

      finalHash.push {objectType, objectName, messages}

    finalHash

  hideView: (e)->
    e.preventDefault()
    @$(@selectors.validationMessages).slideToggle()

  # Hides the entire view
  hide: () ->
    @$el.addClass 'hide'
