# value_adds.coffee
# ======
#
# Authors:
#   Venkata Harikrishna Nukala
#
# This is a helper class for fetching value adds of rate plans.
# It follows singleton pattern and it fetches data synchronously.

class Extranet.Presenters.ValueAdds
  _valueAdds = null

  # returns cloned array so that caller can edit it
  @get= ()->
    @_clone _valueAdds ? @_fetchValueAdds()

  @_fetchValueAdds= ()->
    $.ajax
      url: "/v2/getaways/extranet/value_adds"
      async: false
      success: (data)->
        _valueAdds = data

    _valueAdds

  @_clone: (hash)->
    deepCopy = true
    $.extend deepCopy, [], hash
