class Extranet.Presenters.ProductSetStatusPresenter

  prevStatuses =
    "created": []
    "shop-rates": [{status: "created"}]
    "ready": [{status: "created"}, {status: "shop-rates"}]
    "review-violations": [{status: "created"}, {status: "shop-rates"}, {status: "ready", disabled: true}]
    "approved": [{status: "created"}, {status: "shop-rates"}, {status: "ready"}]
    "live": [{status: "created"}, {status: "shop-rates"}, {status: "ready"}]
    "finished": [{status: "created"}, {status: "shop-rates"}, {status: "ready"}]

  getPrevStatuses: (status)->
    prevStatuses[status] or []
