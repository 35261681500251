class Extranet.Models.DealModel extends Backbone.Model

  urlRoot: "/getaways/extranet/deals/"

  parse: (response) ->
    deal = response.deal
    permalink = deal?.permalink
    if permalink
      # Extracting only the last component from the url
      # Example: From https://groupon.co.uk/deals/ga-merchant-name-in-writeup-1449785191 to ga-merchant-name-in-writeup-1449785191
      deal.permalink = permalink.substr(permalink.lastIndexOf('/') + 1)

    deal
