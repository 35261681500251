require '../presenters/description'

class Extranet.Views.Description extends Backbone.View

  selectors:
    textEditor: '#description-textarea'
    actionButtons: '.action-buttons button'

  events:
    'click #update-description-button': '_update'
    'click #reset-description-button': '_reset'

  el: '#description'
  templateName: 'profile_description'

  initialize: (options) ->
    @alertMessage = Extranet.Helper.AlertView.getAlertView()
    @presenter = new Extranet.Presenters.Description()
    @errorPresenter = new Extranet.Presenters.Error()

  render: () ->
    @hash = @presenter.toHash @model
    @$el.html TemplateHelper.render @templateName, @hash
    @$(@selectors.textEditor).xnetHtmlEdit
      change: => @_handleDataChange()
  
  _handleDataChange: (e) ->
    @$(@selectors.actionButtons).removeAttr 'disabled'
    
  _update: (e) ->
    e.preventDefault()
    return unless Extranet.RolePermissions?.hotel?.description?.edit
    @alertMessage.hide()
    saveAttrs = @presenter.getHashToSave this
    options =
      patch: true
      wait: true
    onSuccess = (data, textStatus, jqXHR) =>
      @render()
      message = I18n.translate 'js.modules.common.alerts.success.hotel_saved'
      requestId = jqXHR?.getResponseHeader 'x-request-id'
      @alertMessage.renderSuccess message, requestId
    onFailure = (jqXHR, textStatus, errorThrown) =>
      requestId = jqXHR?.getResponseHeader 'x-request-id'
      @alertMessage.renderDefaultError requestId
    @model.save(saveAttrs, options).then(onSuccess, onFailure)

  _reset: (e) ->
    e.preventDefault()
    @alertMessage.hide()
    @render()