class Extranet.Views.Ql2UploadView extends Backbone.View

  UPLOAD_EVENT_NAME = "ql2uploaded"

  templateName: 'inventory_templates_ql2_upload'
  ql2InputId: 'ql2-file-upload-file'
  selectors:
    alertMessage: '.ql2-upload-alert-message'
    closeModalButton: '.close-modal'
    ql2Input: '#ql2-file-upload-file'
    selectedFile: '#uploadFile'

  initialize: (options)->
    @hotelUuid = options.hotelUuid
    @productSetUuid = options.productSetUuid
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    @messages = I18n.translations.js.modules.inventory.templates_ql2_upload.messages
    @alertMessage = Extranet.Helper.AlertView.getAlertView()
    @eventManagerView = Extranet.Helpers.EventManagerHelper.getEventManagerView(options.eventManagerKey)

  render: ()->
    ql2UploadUrl = ExtranetUrlBuilder.ql2UploadUrl {@hotelUuid, @productSetUuid}
    @$el.html @autoTemplate.render @templateName, {
      ql2UploadUrl
    }
    @enableQL2Upload()

    this

  enableQL2Upload: ()->
    @$el.find(@selectors.ql2Input).fileupload {
      add: (e, data)=>
        @$el.find(@selectors.selectedFile).val data.files?[0]?.name
        ajaxCall = data.submit()
        ajaxCall.done(@refreshInventory).fail(@failureCallback)

      headers:
        "x-csrf-token": $('#csrf_token').val()
    }

  refreshInventory: (data, textStatus, jqxhr)=>
    @closeView()
    # TODO: trigger event for refreshing the inventory.
    requestId = jqxhr?.getResponseHeader('x-request-id')
    @alertMessage.renderSuccess(@messages.success, requestId)
    @eventManagerView.triggerEvent UPLOAD_EVENT_NAME

  # NOTE: Enable it on demand
  # destroyFileUploader: ()->
  #   @$el.find(@selectors.ql2Input).fileupload('destroy')

  failureCallback: (jqxhr) =>
    requestId = jqxhr?.getResponseHeader('x-request-id')
    if jqxhr.status is 403 and jqxhr.responseJSON?.errorCode
      errorMessage = @getErrorMessage jqxhr.responseJSON?.errorCode
      @alertMessage.renderError(errorMessage, requestId)
    else
      @alertMessage.renderDefaultError(requestId)
    @closeView()

  closeView: ()->
    @$el.find(@selectors.closeModalButton).click()

  getErrorMessage: (errorCode) ->
    @messages[errorCode]
