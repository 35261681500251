require "../../presenters/tax_details_presenter"

class Extranet.Views.TaxDetailsView extends Backbone.View
  templateName: 'admin_templates_tax_details'

  events:
    'click #save-tax': 'save'
    'click #cancel-tax': 'cancel'
    'click .close-modal': 'remove'
    'change #calculationType': 'handleCalculationType'

  selectors:
    taxInformation: '#tax-information'
    closeButton: '.close-modal'
    errorMessage: "#tax-information .alert-message"
    countrySelector: '#tax-information .country-selector'
    stateSelector: '#tax-information .state-selector'
    citySelector: '#tax-information .city-selector'

  initialize: (options= {})->
    if options.model
      @model = options.model
    @alertMessage= options.alertMessage
    @errorMessage = new Extranet.Views.AlertMessage {selector: @selectors.errorMessage}
    @autoTemplate = Extranet.Common.Utilities.AutoTemplate
    {@selectedStateUuid,@selectedCountryUuid,@selectedCityUuid} = options

  render: ()->
    hash = {}
    presenter = new Extranet.Presenters.TaxDetailsPresenter()
    if @model
      hash = presenter.toHash(@model)
    else
      hash.currencyOptions = presenter.getCurrencyOptions()

    this.$el.html(@autoTemplate.render(@templateName, hash))
    @countrySelector = $(@el).find(@selectors.countrySelector)
    @stateSelector = $(@el).find(@selectors.stateSelector)
    @citySelector = $(@el).find(@selectors.citySelector)
    defaults =
      countryUuid: @selectedCountryUuid
      stateUuid: @selectedStateUuid
      cityUuid: if @model then @model.get('city')?.uuid else @selectedCityUuid
    @locationSelector = new Extranet.Helpers.LocationSelector @countrySelector, @stateSelector, @citySelector, {defaults: defaults}
    @handleCalculationType()
    @$(@selectors.taxInformation).parsley(Extranet.ParsleyConfig)
    this

  handleCalculationType: ()->
    calculationType = @$('#calculationType').val()
    currencyCodeEl = @$('#currencyCode')
    if calculationType is 'amount'
      currencyCodeEl.removeClass('hide')
    else
      currencyCodeEl.addClass('hide')

  save: (e)->
    if not @$(@selectors.taxInformation).parsley('validate')
      @errorMessage.renderDefaultValidationError()
      return
    @errorMessage.hide()
    e.preventDefault()
    presenter = new Extranet.Presenters.TaxDetailsPresenter()
    hash = presenter.getHashToSave this
    if @model
      @saveTax hash
    else
      @createTax hash

  saveTax: (hash)->
    # @model.set hash
    @model.save hash,
      patch: true
      success: (model, response, options)=>
        @removeThisView()
        message = I18n.translate('js.modules.common.alerts.success.tax_updated')
        requestId = options?.xhr?.getResponseHeader('x-request-id')
        @alertMessage.renderSuccess(message, requestId)
      error: (model, response, options)=>
        requestId = options?.xhr?.getResponseHeader('x-request-id')
        message = I18n.translate('js.modules.common.alerts.error.tax_not_updated')
        @errorMessage.renderError(message, requestId)

  createTax: (hash) ->
    model = new Extranet.Models.Tax()
    model.save hash,
      wait: true
      success: (model, response, options) =>
        @collection.add([model])
        @removeThisView()
        message = I18n.translate('js.modules.common.alerts.success.tax_created')
        requestId = options?.xhr?.getResponseHeader('x-request-id')
        @alertMessage.renderSuccess(message, requestId)
      error: (model, response, options)=>
        message = I18n.translate('js.modules.common.alerts.error.tax_not_created')
        requestId = options?.xhr?.getResponseHeader('x-request-id')
        @errorMessage.renderError(message, requestId)

  cancel: (e)->
    e.preventDefault()
    @removeThisView()

  removeThisView: () ->
    @$(@selectors.closeButton).click()
